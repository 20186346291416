import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from "ngx-toastr";
import { TranslateService } from '@ngx-translate/core';
import { PreferenceType, StoreService } from '../..';
import { TokenService, BarcodeTypes, PreferenceSubmission } from '@next/shared/common';
import { NextAdminService } from '@next/shared/next-services';

/* eslint-disable no-var */
declare var mwbScanner: any;

@Component({
  selector: 'next-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BarcodeScannerComponent implements OnInit, OnDestroy {

  @ViewChild('container', { static: true, read: ViewContainerRef }) container: ViewContainerRef;
  @Output() scanResult: EventEmitter<any> = new EventEmitter<any>();

  selectedProfileId: string;
  preferenceId: string;

  // i18n translation strings
  cameraNotFoundMessage = this.translate.instant('BARCODE.CAMERAS_NOT_FOUND_MESSAGE');
  cameraNotFoundSubject = this.translate.instant('BARCODE.CAMERAS_NOT_FOUND_SUBJECT');
  cameraNoPermission = this.translate.instant('BARCODE.ERROR_PERMISSION');
  cameraErrorMessage = this.translate.instant('BARCODE.ERROR_MESSAGE');
  cameraErrorSubject = this.translate.instant('BARCODE.ERROR_SUBJECT');

  constructor (
    public modalRef: BsModalRef,
    private toastr: ToastrService,
    private translate: TranslateService,
    public stateSvc: StoreService,
    private adminSvc: NextAdminService,
    private tokenService: TokenService
  ) {}


  ngOnInit(): void {
    // To modify the settings go the ProjectY.Angular/tools/cognex folder and read the Interlace_Dev_Instructions.txt file
    // ie. barcode types to scan, timeout.  A list of settings are here: https://cmbdn.cognex.com/knowledge/cognex-mobile-barcode-sdk-for-web/installation-with-plain-js
    // For reference, the active turned on barcodes are 39, 128, and Aztec
    mwbScanner.startScanning(this.scanHandler.bind(this), 5,15,90,80);

    // get default preference profile id for this user
    this.adminSvc.getPreference(this.tokenService.getIdentity().id, PreferenceType.DefaultBarcode).subscribe(result => {
      if (result && result.length && result[0].data) {
        this.selectedProfileId = result[0].data.id;

        // If the profileId doesn't exist, then set this to the first one
        if (!this.stateSvc.barcodeScanningProfiles.find(p => p.id === this.selectedProfileId)) {
          this.selectedProfileId = this.stateSvc.barcodeScanningProfiles[0].id;
        }

        this.preferenceId = result[0].id;
      } else {
        this.selectedProfileId = this.stateSvc.barcodeScanningProfiles[0].id;
      }
    });
  }

  ngOnDestroy(): void {
    mwbScanner.closeScanner();
  }

  scanHandler(scanResult) {
    if (scanResult.type === 'Error') {
      if (scanResult.errorDetails.name === 'NotAllowedError') {
        this.toastr.error(`${this.cameraNoPermission}`, this.cameraErrorSubject, {disableTimeOut: true});
      } else if (scanResult.errorDetails.name === 'NotFoundError') {
        this.toastr.error(`${this.cameraNotFoundMessage}`, this.cameraNotFoundSubject, {disableTimeOut: true});
      }
       else {
        this.toastr.error(`${this.cameraErrorMessage} ${scanResult.code}`, this.cameraErrorSubject, {disableTimeOut: true});
      }
    } else if (scanResult.type !== 'Cancel') {
      this.parseScanResult(scanResult);
    }
  }

  parseScanResult(scanResult) {
    const type = this.stateSvc.barcodeScanningProfiles.find(
      (p) => p.id === this.selectedProfileId
    ).barcodeType;
    let canParse = false;
    switch (scanResult.type) {
      case 'Code 128':
        if (type === BarcodeTypes.OneTwoEight) {
          canParse = true;
        }
        break;
      case 'AZTEC':
        if (type === BarcodeTypes.Aztec) {
          canParse = true;
        }
        break;
      case 'Datamatrix':
        if (type === BarcodeTypes.DM) {
          canParse = true;
        }
        break;
      case 'PDF417':
        if (type === BarcodeTypes.PDF417) {
          canParse = true;
        }
        break;
      case 'QR':
        if (type === BarcodeTypes.QR) {
          canParse = true;
        }
        break;
      case 'Code 39':
        if (type === BarcodeTypes.ThreeOfNine) {
          canParse = true;
        }
        break;
    }

    if (canParse) {
      this.scanResult.emit({
        profileId: this.selectedProfileId, scannedCode: scanResult.code
       });
    } else {
      this.scanResult.emit({
        profileId: '', scannedCode: ''
       });
    }
  }

  exit(): void {
    this.modalRef.hide();
  }

  profileSelected(ev) {

    const preference: PreferenceSubmission = {
      id: this.preferenceId,
      userid: this.tokenService.getIdentity().id,
      type: PreferenceType.DefaultBarcode,
      data: {id: ev.target.value}
    }

    this.adminSvc.savePreference([preference]).subscribe((result) => {
      this.preferenceId = result[0].id;
    });
  }

}
