<div class="ilh-modal-header row d-flex flex-column bg-light px-2 py-1">
  <div class="d-flex justify-content-between">
    <div class="px-0">
      <button id="btn-modal-close" class="btn btn-sm btn-action-cancel"
              (click)="cancel.emit()">{{ 'CLINICAL.ADD_FORMS_MODAL.CANCEL' | translate }}</button>
    </div>
    <div class="px-0 flex-fill text-right">
      <button *ngIf="!hideActionPrint" id="btn-print" class="btn btn-sm btn-action"
              [disabled]="!active.length" (click)="print.emit()">{{ 'CLINICAL.ADD_FORMS_MODAL.PRINT' | translate }}</button>
      <button *ngIf="!hideActionSign" id="btn-sign" class="btn btn-sm btn-action"
              [disabled]="!active.length" (click)="sign.emit()">{{ 'CLINICAL.ADD_FORMS_MODAL.FILL' | translate }}</button>
      <button *ngIf="!hideActionAdd" id="btn-add" class="btn btn-sm btn-action"
              [disabled]="!active.length" (click)="add.emit()">{{ 'CLINICAL.ADD_FORMS_MODAL.ADD' | translate }}</button>
    </div>
  </div>
  <div class="d-flex justify-content-between">
    <div class="flex-fill"></div>
    <div class="flex-fill text-center"><span class="h5 mb-0" *ngIf="showTitle">{{ title }}</span></div>
    <div class="flex-fill text-right"><div class="selected-counter mb-0 mx-1">({{active.length}}) {{ 'CLINICAL.ADD_FORMS_MODAL.SELECTED' | translate }}</div></div>
  </div>
</div>
