import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import {
  Appointment,
  AppointmentFormStatus,
  AppointmentStateTranslationPath,
} from '@next/shared/common';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
@Directive({
  selector: '[nextDisplayModelProperty]',
})
export class DisplayModelPropertyDirective implements OnInit {
  @Input() propertyPath: string = null;
  @Input() appointment: Appointment = null;

  statusNONE: string;
  statusNOTSTARTED: string;
  statusINPROGRESS: string;
  statusACTIONREQUIRED: string;
  statusCOMPLETE: string;

  constructor(private element: ElementRef, private translateSvc: TranslateService, private datePipe: DatePipe) {
    this.statusNONE = this.translateSvc.instant(AppointmentStateTranslationPath.none);
    this.statusNOTSTARTED = this.translateSvc.instant(AppointmentStateTranslationPath.not_started);
    this.statusINPROGRESS = this.translateSvc.instant(AppointmentStateTranslationPath.in_progress);
    this.statusACTIONREQUIRED = this.translateSvc.instant(AppointmentStateTranslationPath.action_required);
    this.statusCOMPLETE = this.translateSvc.instant(AppointmentStateTranslationPath.complete);
  }

  ngOnInit(): void {
    this.setInnerText(this.element);
  }

  private setInnerText(el: ElementRef): void {
    const displayText: string = this.getDisplayText();
    el.nativeElement.innerText = displayText;
    el.nativeElement.title = displayText;
  }

  private getDisplayText(): string {
    const appointment = this.appointment;
    const patientData = this.appointment?.patientdata;

    const value = appointment[this.propertyPath] ?? patientData[this.propertyPath];

    switch (this.propertyPath) {
      case 'calendarstartdatetime':
      case 'calendarenddatetime':
      case 'admitdatetime':
      case 'updatedon':
      case 'createdon':
      case 'dischargedatetime':
      case 'eventdatetime': {
        return this.datePipe.transform(value, 'MM/dd/yyyy hh:mm a');
      }
      case 'birthdate': {
        return this.datePipe.transform(value, 'MM/dd/yyyy');
      }
      case 'formstatus': {
        let formStatus: string = value;
        switch (formStatus) {
          case '':
          case undefined:
          case null:
          case AppointmentFormStatus.none:
            formStatus = this.statusNONE;
            break;
          case AppointmentFormStatus.not_started:
            formStatus = this.statusNOTSTARTED;
            break;
          case AppointmentFormStatus.in_progress:
            formStatus = this.statusINPROGRESS;
            break;
          case AppointmentFormStatus.action_required:
            formStatus = this.statusACTIONREQUIRED;
            break;
          case AppointmentFormStatus.complete:
            formStatus = this.statusCOMPLETE;
            break;
        }
        return formStatus;
      }
      default:
        return value ?? '';
    }
  }
}
