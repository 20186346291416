import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldDTO, GuidedExperienceDTO, PageDTO } from '@next/shared/common';

@Component({
  selector: 'next-viewer-page',
  templateUrl: './viewer-page.component.html',
  styleUrls: ['./viewer-page.component.css']
})

export class ViewerPageComponent {
  @Input() form: UntypedFormGroup;
  @Input() formId: string;
  @Input() experience: GuidedExperienceDTO;
  @Input() page: PageDTO;
  @Input() initialState: any;
  @Input() attachments: any[];
  @Input() needsValidation: boolean;
  @Output() valueChanged: EventEmitter<FieldDTO> = new EventEmitter<FieldDTO>();

  emitValueChanged(field: FieldDTO) {
    this.valueChanged.emit(field);
  }
}
