import { Injectable } from '@angular/core';
import { Appointment, SearchByType } from '@next/shared/common';
import { NextClinicalService } from '@next/shared/next-services';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { concatAll, filter, first, toArray } from 'rxjs/operators';
import { StoreService } from '../state/store.service';

@Injectable({ providedIn: 'root' })
export class FacilityDepartmentFilteringService {
  constructor(
    private stateSvc: StoreService,
    private toastr: ToastrService,
    private nextClinicalService: NextClinicalService,
    private translateSvc: TranslateService) { }

  search(searchByType: SearchByType) {
    this.searchAppointments(searchByType);
    this.searchPatients(searchByType);
  }

  private searchPatients(searchByType: SearchByType): void {
    this.stateSvc.searchedPatients.error = '';
    const searchString: string = this.stateSvc.searchedPatients.filter;
    const searchFacility: string = this.stateSvc.selectedFacility.facilityid;
    const searchDepartment: string = this.stateSvc.selectedDepartment.departmentid;
    if (!searchString?.length) return;
    if (searchString.length < 2) {
      this.stateSvc.searchedPatients.error = this.translateSvc.instant('PATIENT_SEARCH.SEARCH_RESTRICTION_LABEL');
      return;
    }

    // NOTE: Upcoming update won't rely on client-side filtering
    // Instead of -1 for 'All Facilities' and 'All Departments', we could send repeating URL query params in our GETs
    // EG: servicingFacility=WC1&servicingFacility=WC2&department=D1&department=D2..etc
    // This way would need no client-side manipulation of the search response.
    // What to include in the repeating query param would be driven by what facility user selected (or if 'All', only the facilities they have access to)
    // For Departments, the repeating param would use selected department, or all department ids nested within facilities defined above
    this.nextClinicalService.getPatients(searchByType, searchString, searchFacility, searchDepartment).subscribe({
      next: (patients) => {
        this.stateSvc.searchedPatients.patients = patients;
        if (!this.stateSvc.searchedPatients.patients.length) {
          this.stateSvc.searchedPatients.error = this.translateSvc.instant('PATIENT_SEARCH.SEARCH_NOT_FOUND', {name: this.stateSvc.searchedPatients.filter});
        }
      },
      error: (error) => {
        if (searchByType === SearchByType.DOB && error.status == 400) {
          this.toastr.error(this.translateSvc.instant('PATIENT_SEARCH.DOB_PARSE_ERROR'), '', {disableTimeOut: true});
        } else {
          this.toastr.error(error.message, '', {disableTimeOut: true});
        }
      }
    });
  }

  private searchAppointments(searchByType: SearchByType): void {
    this.stateSvc.searchedAppointments.error = '';
    const searchString: string = this.stateSvc.searchedPatients.filter;
    const searchFacility: string = this.stateSvc.selectedFacility.facilityid;
    const searchDepartment: string = this.stateSvc.selectedDepartment.departmentid;
    if (!searchString?.length) return;
    if (searchString.length < 2) {
      this.stateSvc.searchedAppointments.error = this.translateSvc.instant('PATIENT_SEARCH.SEARCH_RESTRICTION_LABEL');
      return;
    }

    // NOTE: Upcoming update won't rely on client-side filtering
    // Instead of -1 for 'All Facilities' and 'All Departments', we could send repeating URL query params in our GETs
    // EG: servicingFacility=WC1&servicingFacility=WC2&department=D1&department=D2..etc
    // This way would need no client-side manipulation of the search response.
    // What to include in the repeating query param would be driven by what facility user selected (or if 'All', only the facilities they have access to)
    // For Departments, the repeating param would use selected department, or all department ids nested within facilities defined above
    this.nextClinicalService.getAppointments(searchByType, searchString, searchFacility, searchDepartment).pipe(
      first(),
      concatAll(),
      filter((appointment: Appointment) => this._isAccessibleFacility(appointment)),
      filter((appointment: Appointment) => this._isAccessibleDepartment(appointment)),
      toArray()
    ).subscribe({
      next: response => {
        this.stateSvc.searchedAppointments.appointments = response || [];
        if (!this.stateSvc.searchedAppointments.appointments.length) {
          this.stateSvc.searchedAppointments.error = this.translateSvc.instant('PATIENT_SEARCH.SEARCH_NOT_FOUND', {name: this.stateSvc.searchedPatients.filter});
        }
      },
      error: error => {
        if (searchByType === SearchByType.DOB && error.status == 400) {
          this.toastr.error(this.translateSvc.instant('PATIENT_SEARCH.DOB_PARSE_ERROR'), '', {disableTimeOut: true});
        } else {
          this.toastr.error(error.message, '', {disableTimeOut: true});
        }
      }});
  }

  private _isAccessibleFacility(appointment: Appointment): boolean {
    const skipFacilityFilter: boolean = !appointment.servicingfacility || !this.stateSvc.allFacilities.length || !this.stateSvc.allFacilities.some(f => f.id !== '-1') || !this.stateSvc.facilitiesByUserGroup.some(f => f.id !== '-1');
    const isAccessibleFacility: boolean = (this.stateSvc.selectedFacility.id === '-1')
      ? this.stateSvc.facilitiesByUserGroup.some(facility => facility.facilityid === appointment.servicingfacility)
      : this.stateSvc.selectedFacility.facilityid === appointment.servicingfacility;
    return skipFacilityFilter || isAccessibleFacility;
  }

  private _isAccessibleDepartment(appointment: Appointment): boolean {
    const skipDepartmentFilter: boolean = !appointment.department ||  !this.stateSvc.allDepartments.length || !this.stateSvc.allDepartments.some(d => d.id !== '-1') || !this.stateSvc.departments.some(d => d.id !== '-1');
    const isAccessibleDepartment: boolean = (this.stateSvc.selectedFacility.id === '-1')
      ? this.stateSvc.departments.some(d => d.departmentid === appointment.department)
      : this.stateSvc.selectedFacility.departments.some(d => d.departmentid === appointment.department);
    return skipDepartmentFilter || isAccessibleDepartment;
  }
}
