
<!--Hidden canvas used to render the barcode image-->
<canvas id="canvas-barcode" class="d-none"></canvas>

<button id="barcode-thumbnail-container" (click)="openBarcodeModalAsync()">
  <img
    id="barcode-thumbnail-image"
    class="p-0 m-0"
    height="50px"
    width="50px"
    alt="Remote Transfer Thumbnail Image"
    [src]="barcodeThumbnailImageSrc" />
  <fa-icon class="icon-overlay" [icon]="['fas', 'eye']"></fa-icon>
</button>

<ng-template #barcodeModalTemplate>
  <div class="d-flex justify-content-center align-items-center pt-2 pb-4">
    <h5 class="modal-title-text m-0">{{'BARCODE_LAUNCHER.MODAL_TITLE' | translate}}</h5>
  </div>

  <div class="d-flex justify-content-center align-items-center my-2">
    <img
      id="barcode-image-full"
      height="66%"
      width="66%"
      alt="Remote Transfer QR Code"
      [src]="barcodeImageSrc" />
  </div>

  <div *ngIf="isTestEnvironment" class="d-flex justify-content-center align-items-center my-2">
    <a target="_blank" rel="noopener noreferrer" [href]="[barcodeUrl]">Barcode URL</a>
  </div>

  <div class="d-flex justify-content-start align-items-center px-4 py-3">
    <button
      id="btn-close-barcode-modal"
      class="btn btn-text p-0"
      (click)="closeModal()">
      {{'BARCODE_LAUNCHER.MODAL_CLOSE' | translate}}
    </button>
  </div>
</ng-template>
