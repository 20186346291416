import { Component } from '@angular/core';

@Component({
  selector: 'next-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent  {
  close() {
    window.open('','_parent','');
    window.close();
  }

}
