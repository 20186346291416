import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { AppPattern } from "../consants/pattern.constant";

export class NextValidators {

  static powerBiUrl(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {

      const isValidPowerBiUrl = AppPattern.POWERBI_URL.test(control.value);

      return !isValidPowerBiUrl
        ? { pattern: true }
        : null;
    }
  }

  static url(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const isValidUrl = AppPattern.URL.test(control.value);

      const isValidLocalUrl = AppPattern.URL_LOCAL.test(control.value);

      return !isValidUrl && !isValidLocalUrl
        ? { pattern: true }
        : null;
    }
  }

  static encryptionKey(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      // The length must be 32, unless it is Base64 encoded.  However the key is still 32 bytes.
      const isCorrectLength = control.value?.length === 32 || control.value?.length === 44;
      if (!isCorrectLength) {
        return { encryptionkey: { requiredLength: 32 } };
      }

      // If it was 44, encure that it was actually encoded properly.  It should end with a single '='.
      if (control.value?.length === 44 && control.value[43] !== '=') {
        return { encryptionkey: { pattern: true } };
      }

      return null;
    }
  }

  static numeric(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const isNumeric = control.value?.length === 0 || AppPattern.NUMERIC.test(control.value);

      return !isNumeric
        ? { numeric: true }
        : null;
    }
  }
}
