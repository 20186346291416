import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  pure: false
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: number, trailStr: string = '...'): string {
    if (value.length <= limit) {
      return value;
    }

    // default total length is limit + len(trailStr)
    return value.substring(0, limit).trim() + trailStr;
  }
}
