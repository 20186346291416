import { BehaviorSubject } from "rxjs";
import { FormDTO, FormStatus } from "./form.model";

export class SelectedForms {

    private formsToFill: FormToFill[] = [];
    private counter: number = 1;
    private userSelectedCurrentForm: FormDTO = null;
    public currentFormChanged = new BehaviorSubject(null);
    private _currentForm: FormDTO = null;
    public userClosedFormsWizard: boolean = false;

    constructor(forms: FormDTO[]) {
      this.counter = 0;
      if (forms) {
        forms.forEach(form => {
          this.formsToFill.push({
            form: form,
            isProcessed: false,
            userSkippedRequiredFields: false,
            order: this.counter++
          });
        });
      }
    }

    get forms() {
        return this.formsToFill.map(f => f.form);
    }

    get formsNotProcessed(): FormDTO[] {
        return this.formsToFill.filter(f => !f.isProcessed && f.form.status !== FormStatus.Completed).map(f => f.form);
    }

    get areAllFormsProcessed(): boolean {
        return this.formsToFill.filter(f => !f.isProcessed && f.form.status !== FormStatus.Completed).length === 0;
    }

    get currentForm(): FormDTO {
        if (this.userClosedFormsWizard) {
            return null;
        }

        if (this.userSelectedCurrentForm) {
            if (this._currentForm !== this.userSelectedCurrentForm) {
                this._currentForm = this.userSelectedCurrentForm;
            }
            return this.userSelectedCurrentForm;
        }

        const remainingForms = this.formsToFill.filter(f => !f.isProcessed && f.form.status !== FormStatus.Completed);
        if (remainingForms?.length > 0) {
            const currentForm = remainingForms.sort((a,b) => (a.order > b.order) ? 1: -1)[0].form
            if (this._currentForm !== currentForm) {
                this._currentForm = currentForm;
                this.currentFormChanged.next(null);
            }

            return currentForm;
        }
        if (this._currentForm) {
            this._currentForm = null;
            this.currentFormChanged.next(null);
        }
        return null;
    }

    get isLastForm() : boolean {
        // only one form not processed
        const formsLeft = this.formsToFill.filter(f => !f.isProcessed && f.form.status !== FormStatus.Completed);
        if (formsLeft.length === 0) {
            return true;
        } else if (formsLeft.length === 1) {
            return formsLeft[0].form === this._currentForm;
        }
        return false;
    }

    get isLastFormWithOtherDirtyForms() : boolean {
        return this.isLastForm && this.formsToFill.filter(f => f.isProcessed).length > 0
    }

    SetCurrentFormToProcessed() {
        if (this.currentForm) {
            this.formsToFill.find(f => f.form.id === this.currentForm.id).isProcessed = true;
            this.userSelectedCurrentForm = null;
        }
    }

    SkipForm() {
        if (this.currentForm) {
            this.SetCurrentFormToProcessed();
            this.formsToFill.find(f => f.form === this.currentForm).userSkippedRequiredFields = true;
        }
    }

    Add(form: FormDTO): void {
      this.formsToFill.push({
        form: form,
        isProcessed: false,
        userSkippedRequiredFields: false,
        order: this.counter++
      });
    }

    AddMultiple(forms: FormDTO[]): void {
      const ftf: FormToFill[] = forms.map((form) => {
        return {
          form: form,
          isProcessed: false,
          userSkippedRequiredFields: false,
          order: this.counter++
        };
      });
      this.formsToFill.push(...ftf);
    }

    remove(form: FormDTO): void {
      this.formsToFill = this.formsToFill.filter(f => form.id !== f.form.id);
    }

    removeMultiple(forms: FormDTO[]): void {
      this.formsToFill = this.formsToFill.filter(f => !forms.some(s => s.id === f.form.id));
    }

    EmptyAll() {
      this.formsToFill = [];
    }

    resetAllFormsToNotProcessed() {
        this.formsToFill.forEach(f => f.isProcessed = false);
        this.userSelectedCurrentForm = null;
        this.userClosedFormsWizard = false;
    }

    update(form: FormDTO) {
        const existingForm = this.formsToFill.find(f => f.form.id === form.id)?.form;
        if (existingForm) {
          existingForm.historyid = form.historyid;
          existingForm.status = form.status;
          existingForm.updatedon = form.updatedon;
          existingForm.tomovelater = form.tomovelater;
        }
    }

    makeCurrentForm(form: FormDTO) {
        this.userClosedFormsWizard = !form; // if a form is closed, then make the current form null and close the wizard
        this.userSelectedCurrentForm = form;
        this.currentFormChanged.next(null);
    }

    get length(): number {
        return this.formsToFill.length;
    }
}

export class FormToFill {
    form: FormDTO;
    isProcessed: boolean;
    userSkippedRequiredFields: boolean;
    order: number;
}
