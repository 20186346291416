import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { StateViewerService } from '../../state/state-viewer.service';
import { StoreService } from '../../state/store.service';
import { FlaggedFormsService } from '../../services/flagged-forms.service';
import { AppointmentForms, FormDTO, FormType, Patient } from '@next/shared/common';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { App } from '@next/shared/ui';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'next-move-flagged-forms-modal',
  templateUrl: './move-flagged-forms-modal.component.html',
  styleUrls: ['./move-flagged-forms-modal.component.scss']
})
export class MoveFlaggedFormsModalComponent implements OnInit, OnDestroy {
  @Input() openedFrom: FormType = null;
  @Input() selectedPatient: Patient = null;
  @Input() excludeFormsForPatientId: string = null;
  @Input() excludeFormsForAppointmentId: string = null;
  @Output() modalClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() modalFormsToMove: EventEmitter<FormDTO[]> = new EventEmitter<FormDTO[]>();

  typeLabel: string = '';
  labelPatients: string = this.storeSvc.enterpriseSettings.patients || this.translateSvc.instant('ENTERPRISE_SETTINGS.LABELS.DEFAULT_PATIENT');
  labelPatient = this.singularizeWord(this.labelPatients);
  labelAppointments: string = this.storeSvc.enterpriseSettings.appointments || this.translateSvc.instant('ENTERPRISE_SETTINGS.LABELS.DEFAULT_APPOINTMENT');
  labelAppointment = this.singularizeWord(this.labelAppointments);
  observerCleanup$: Subject<void> = new Subject();
  flaggedPatientForms: FormDTO[] = [];
  flaggedAppointmentForms: AppointmentForms[] = [];

  constructor(
    public stateViewerSvc: StateViewerService,
    private toastSvc: ToastrService,
    private translateSvc: TranslateService,
    public storeSvc: StoreService,
    public spinnerSvc: NgxSpinnerService,
    private flaggedFormsSvc: FlaggedFormsService) { }

  ngOnInit(): void {
    this.stateViewerSvc.selectedFormsFromMoveFormsModal = [];
    this.spinnerSvc.show(App.SPINNERS.MAIN_SPINNER);
    this.typeLabel = this.openedFrom == FormType.Appointment ? this.labelAppointment : this.labelPatient;

    this.getLatestFlaggedFormsForPatientAndAppointment();
  }

  private getLatestFlaggedFormsForPatientAndAppointment() {
    if (this.selectedPatient) {
      combineLatest([
        this.flaggedFormsSvc.getFlaggedFormsForPatient(this.selectedPatient),
        this.flaggedFormsSvc.getFlaggedFormsForAppointments(this.selectedPatient, true),
      ]).pipe(
        takeUntil(this.observerCleanup$)
      ).subscribe({
        next: ([flaggedFormsForPatient, appointmentsWithFlaggedForms]) => {
          for (const appointmentForm of appointmentsWithFlaggedForms) {
            const facility = this.storeSvc.allFacilities.find(f => f.facilityid === appointmentForm.appointment.servicingfacility);
            this.applyFacility(appointmentForm.appointment, facility);
            const department = this.storeSvc.allDepartments.find(d => d.departmentid === appointmentForm.appointment.department);
            this.applyDepartment(appointmentForm.appointment, department);
          }
          this.updateFlaggedForms([flaggedFormsForPatient, appointmentsWithFlaggedForms]);
        },
        error: () => this.handleFailure()
      });
    } else {
      this.handleFailure();
    }
  }

  applyFacility(appointment, facilityData) {
    const facilityPrefill = {
      facilityname: facilityData.name,
      facilityshortname: facilityData.shortname,
      facilityid: facilityData.facilityid
    };
    Object.assign(appointment, facilityPrefill);
  }

  applyDepartment(appointment, departmentData) {
    const departmentPrefill = {
      departmentname: departmentData.name,
      departmentshortname: departmentData.shortname,
      departmentid: departmentData.departmentid
    };
    Object.assign(appointment, departmentPrefill);
  }

  private updateFlaggedForms(data: [FormDTO[], AppointmentForms[]]): void {
    this.spinnerSvc.hide(App.SPINNERS.MAIN_SPINNER);
    this.flaggedPatientForms = this.flaggedFormsSvc.excludeFormsForPatientId(data[0], (this.excludeFormsForPatientId ?? ''));
    this.flaggedAppointmentForms = data[1].filter(form => form.appointment.id !== (this.excludeFormsForAppointmentId ?? ''));
  }

  private handleFailure(): void {
    this.spinnerSvc.hide(App.SPINNERS.MAIN_SPINNER);
    this.toastSvc.error(this.translateSvc.instant("TOASTR_MESSAGE.GET_PATIENT_FORMS_ERROR"),'', { disableTimeOut: true });
  }

  emitMoveForms(): void {
    this.modalFormsToMove.emit(Array.from(this.stateViewerSvc.selectedFormsFromMoveFormsModal));
  }

  private singularizeWord(word: string) {
    if (word.endsWith("ses") || word.endsWith("xes") || word.endsWith("zes")) {
      return word.slice(0, -3);
    }
    if (word.endsWith("shes") || word.endsWith("ches")) {
      return word.slice(0, -4);
    }
    if (word.endsWith("ies")) {
      return word.slice(0, -3) + "y";
    }
    return word.slice(0, -1);
  }

  ngOnDestroy(): void {
    this.observerCleanup$.next();
    this.observerCleanup$.complete();
  }
}
