import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  DynamicFieldArrayType,
  FieldDTO,
  ViewerFieldType,
} from '@next/shared/common';

@Component({
  selector: 'next-viewer-element',
  templateUrl: './viewer-element.component.html',
})
export class ViewerElementComponent {
  ViewerFieldType: typeof ViewerFieldType = ViewerFieldType; // allow this enum to be used in the template
  DynamicFieldArrayType: typeof DynamicFieldArrayType = DynamicFieldArrayType;

  @Input() form: UntypedFormGroup;
  @Input() formId: string;
  @Input() field: FieldDTO;
  @Input() initialState: any;
  @Input() needsValidation: boolean;
  @Input() attachments: any[];

  @Output() statusChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() valueChanged: EventEmitter<FieldDTO> = new EventEmitter<FieldDTO>();

  statusChangeReceived(event): any {
    this.emitFieldStatus(event);
  }

  private emitFieldStatus(eventVar: any): void {
    this.statusChange.emit(eventVar);
  }

  emitValueChanged(field: FieldDTO) {
    this.valueChanged.emit(field);
  }
}
