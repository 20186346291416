import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { NextExperienceService } from '../../experience.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { GuidedExperienceDTO } from '@next/shared/common';

@Injectable({
  providedIn: 'root',
})
export class NextLayersAssociationResolverService {
  constructor(private experienceSvc: NextExperienceService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<GuidedExperienceDTO[]> | Observable<never> {
    const id:string = route.paramMap.get('experienceId');
    return this.experienceSvc.getLayerAssociations(id);
  }
}
