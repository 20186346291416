import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { Subject, Subscription } from "rxjs";
import { throttleTime } from "rxjs/operators";
import { animate, animation, style, transition, trigger } from "@angular/animations";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "[nextConfirmClick]",
  template: `
    <ng-container>
      <div [ngStyle]="{ 'width' : fixedWidth ? '55px' : 'auto' }" 
        data-test="confirm-click-button"
        id="confirm-click-button"
        class="d-flex justify-content-center align-items-center overflow-hidden">
        <span *ngIf="!isConfirmingAction" @fadeInOut [@.disabled]="disableAnimation">
          <fa-icon [icon]="icon" [size]="iconSize"></fa-icon>
        </span>
        <span *ngIf="isConfirmingAction" @fadeInOut [@.disabled]="disableAnimation">
          {{ text }}
        </span>
      </div>
    </ng-container>
  `,
  styles: [`
    div {
      font-size: 0.9em;
    }
  `],
  animations: [
    trigger("slideLeftRight", [
      transition(":enter",
        animation([
          style({ transform: "translate(-75px, 0)" }),
          animate("0.3s ease-in", style({ transform: "translate(0)" }))
        ])
      ),
      transition(":leave",
        animation([
          style({ transform: "translate(0)" }),
          animate("0.3s ease-out", style({ transform: "translate(-75px, 0)" }))
        ])
      )
    ]),
    trigger("slideRightLeft", [
      transition(":enter",
        animation([
          style({ transform: "translate(75px, 0)" }),
          animate("0.3s ease-in", style({ transform: "translate(0)" }))
        ])
      ),
      transition(":leave",
        animation([
          style({ transform: "translate(0)" }),
          animate("0.3s ease-out", style({ transform: "translate(75px, 0)" }))
        ])
      )
    ]),
    trigger("fadeInOut", [
      transition(":enter",
        animation([
          style({opacity: 0}),
          animate("0.5s ease-in", style({opacity: 1}))
        ])
      ),
      transition(":leave",
        animation([
          style({visibility: "hidden"})
        ])
      )
    ])
  ]
})
export class ConfirmClickComponent implements AfterViewInit, OnDestroy, OnInit {

  @Input() icon: IconProp = ["far", "trash-alt"];
  @Input() iconSize: SizeProp = "1x";
  @Input() text: string = "Confirm";
  @Input() fixedWidth: boolean = true;
  @Input()
  get disableAnimation(): boolean { return this._disableAnimation || !this._viewIsInitialized; }
  set disableAnimation(value: boolean) { this._disableAnimation = value; }
  @Input() clickDelay: number = 500;
  @Output() confirmClickTriggered: EventEmitter<any> = new EventEmitter<any>();
  @Output() confirmClickCanceled: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickConfirmed: EventEmitter<any> = new EventEmitter<any>();

  private _confirmClicks: Subject<void> = new Subject();
  private _confirmClickSub: Subscription;
  private _confirmButtonClickedEvent: CustomEvent = new CustomEvent("confirmationButtonClicked");
  private _disableAnimation: boolean;
  private _viewIsInitialized: boolean; // Track when view is initialized to prevent animation from triggering during page load.
  isConfirmingAction: boolean;

  ngAfterViewInit(): void {
    this._viewIsInitialized = true;
  }

  ngOnDestroy(): void {
    this._confirmClickSub.unsubscribe();
  }

  ngOnInit(): void {
    this._confirmClickSub = this._confirmClicks
      .pipe(throttleTime(this.clickDelay))
      .subscribe(() => {
        if (this.isConfirmingAction) {
          this.clickConfirmed.emit();
        } else {
          this.confirmClickTriggered.emit();
          this.isConfirmingAction = true;
        }
      });
  }

  @HostListener("click", ["$event"])
  private onClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.isConfirmingAction) {
      document.dispatchEvent(this._confirmButtonClickedEvent);
    }

    this._confirmClicks.next();
  }

  /**
   * Cancel the confirmation action if:
   *  - User clicks outside the confirmation button.
   *  - User double-clicks on the confirmation button.
   *  - User clicks a different confirmation button.
   *  - User presses the escape key.
   */
  @HostListener("document:click", ["$event"])
  @HostListener("document:dblclick", ["$event"])
  @HostListener("document:keydown.escape", ["$event"])
  @HostListener("document:confirmationButtonClicked", ["$event"])
  private cancelAction = () => {
    this.isConfirmingAction = false;
    this.confirmClickCanceled.emit();
  }
}
