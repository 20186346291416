import {Component, HostListener, Input, OnInit} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';


@Component({
  selector: 'next-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent implements OnInit {

  @Input() title: Observable<string>;
  @Input() message: Observable<string>;
  @Input() cancelButton: Observable<string>;
  @Input() confirmButton: Observable<string>;

  inputForm: UntypedFormGroup;
  onClose: Subject<string> = new Subject<string>();

  constructor(
    public modalRef: BsModalRef,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.inputForm = this.fb.group({
      newvalue: ''
    });
  }

  confirm(): void {
    const form = this.inputForm.value;
    this.onClose.next(form.newvalue);
    this.modalRef.hide();
  }

  decline(): void {
    this.onClose.next(null);
    this.modalRef.hide();
  }

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler() {
    this.confirm();
  }
}
