import { HttpClient, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ApiService, Appointment, AppointmentExtended, FormFastConfig, FormFastConfigService, Patient, PatientExtended, TokenService } from "@next/shared/common";
import { Observable } from "rxjs";


@Injectable({
    providedIn: 'root'
  })
export class PatientService extends ApiService {

  // TODO: Note this service was originally made for patient portal
  // API calls for next-clinical app should be in clinical service, not this
    constructor(@Inject(FormFastConfigService) config: FormFastConfig, tokenService: TokenService, private http: HttpClient) {
        super(config, tokenService);
    }

    sendVerificationCode(encryptedEmail: string): Observable<any> {
        const req = new HttpRequest('POST', `${this.config.apiUrl}patient/token`, {email: encryptedEmail}, this.getHeaders());
        return this.protectedEndpoint<any>(this.http.request(req));
      }

    verifyCode(encryptedEmail: string, verificationCode: string): Observable<any> {
      return this.protectedEndpoint<any>(
        this.http.get<any>(`${this.config.apiUrl}patient/verificationcode/${verificationCode}?email=${encryptedEmail}`, this.getHeaders()));
    }

    verifypatient(encryptedEmail: string, lastFourSSN: string, birthDate: string): Observable<any> {
      return this.protectedEndpoint<any>(
        this.http.get<any>(`${this.config.apiUrl}patient/verify?email=${encryptedEmail}&lastFourSSN=${lastFourSSN}&birthDate=${birthDate}`, this.getHeaders()));
    }

    getPatientAppointments(patientId: string): Observable<AppointmentExtended<Appointment>[]> {
      return this.protectedEndpoint<AppointmentExtended<Appointment>[]>(
        this.http.get<AppointmentExtended<Appointment>[]>(`${this.config.apiUrl}appointment/patient/${patientId}`, this.getHeaders()));
    }

    getPatientAppointmentsWithFlaggedForms(patientId: string): Observable<AppointmentExtended<Appointment>[]> {
      return this.protectedEndpoint<AppointmentExtended<Appointment>[]>(
        this.http.get<AppointmentExtended<Appointment>[]>(`${this.config.apiUrl}appointment/patient/${patientId}/withFlaggedForms`, this.getHeaders()));
    }

    getPatientIndexAppointmentsWithFlaggedForms(patientId: string) {
      return this.protectedEndpoint<AppointmentExtended<Appointment>[]>(
        this.http.get<AppointmentExtended<Appointment>[]>(`${this.config.apiUrl}appointment/patient/${patientId}/withFlaggedForms?indexed=true`, this.getHeaders()));
    }

    getPatient(): Observable<PatientExtended<Patient>> {
      return this.protectedEndpoint<PatientExtended<Patient>>(
        this.http.get<PatientExtended<Patient>>(`${this.config.apiUrl}patient/token`, this.getHeaders()));
    }
}
