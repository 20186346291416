import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  jwtHelper = new JwtHelperService();

  hasAccessToken(): boolean {
    return !!this.getAccessToken();
  }

  /**
   * - _Returns ___access_token___ from Session Storage_ */
  getAccessToken(): string {
    return sessionStorage.getItem('access_token') ?? '';
  }

  getIntegrationToken(): string {
    return sessionStorage.getItem('integration_token');
  }

  getIdentity(): any {
    return JSON.parse(sessionStorage.getItem('identity'));
  }

  saveAccessToken(token: string) {
    sessionStorage.setItem('access_token', token);
  }

  saveIntegrationToken(token: string) {
    sessionStorage.setItem('integration_token', token);
  }

  saveIdentity(identity: object) {
    sessionStorage.setItem('identity', JSON.stringify(identity));
  }

  clear() {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('integration_token');
    sessionStorage.removeItem('identity');
  }

  clearLastAction() {
    sessionStorage.removeItem('lastAction');
  }

  isIdInCurrentUsersGroups(id) {
    const groups =  this.jwtHelper.decodeToken(this.getAccessToken()).groups;
    return groups.includes(id);
  }

  getSessionTokenExpirationTime(): number {
    return this.jwtHelper.decodeToken(this.getAccessToken()).exp;
  }
}
