<div class="container-fluid modal-content" style='max-height: 75vh'>
  <next-add-forms-header
    [active]="active"
    [hideActionAdd]="hideActionAdd"
    [hideActionPrint]="hideActionPrint"
    [hideActionSign]="hideActionSign"
    [showTitle]="showTitle"
    [title]="translateSvc.instant('CLINICAL.ADD_FORMS_MODAL.TITLE', enterpriseName)"
    (cancel)="modalClose.emit()"
    (print)="onPrint()"
    (sign)="modalSign.emit(flattenPacketOutput ? flatten(active) : active)"
    (add)="modalAdd.emit(flattenPacketOutput ? flatten(active) : active)">
  </next-add-forms-header>

  <div class="row p-2 bg-light">
    <div class="input-group ilh-input-group input-group-sm">
      <div class="input-group-prepend ilh-input-side">
          <span class="input-group-text">
            <fa-icon id="search-icon" class='ilh-bar-child text-muted' [icon]='["fas", "search"]' size="lg"></fa-icon>
          </span>
      </div>

      <input
        id="form-search"
        name="from-search"
        type="text"
        class="form-control ilh-input-field"
        autocomplete="off"
        [(ngModel)]="filter"
        [placeholder]="['PATIENT_FORMS.PLACE_HOLDER' | translate:enterpriseName]"
        (keyup)="onSearch($event)"
        nextAutoFocusElement/>

      <div class="input-group-append ilh-input-side">
          <span class="input-group-text" *ngIf="filter.length">
            <fa-icon id="search-reset" class="ilh-bar-child text-muted cursor-pointer" [icon]="['far', 'times-circle']"
                     size="lg" (click)="clearFilter()"></fa-icon>
          </span>
        <span class="input-group-text">
            <fa-icon id="search-favorite" class="ilh-bar-child cursor-pointer color-primary"
                     [icon]="favFilter ? ['fas', 'heart'] : ['far', 'heart']"
                     size="lg" (click)="onFavFilter()" tabindex="0" (keyup.enter)="onFavFilter()"></fa-icon>
          </span>
        <span class="input-group-text">
            <div class="ilh-bar-child">
              <next-tag-search id="btn-tags-search" #tagSearch [activeTags]='tags.activeTags' [placementLeft]="true" [appendTo]='"modal-container"'
                               (tagSearch)='onTagFilter($event)' iconSize="lg" tabindex="1"></next-tag-search>
            </div>
          </span>
      </div>
    </div>
  </div>

  <div *ngIf="tags" class='row bg-light py-1'>
    <next-tag-pill [tags]="tags.activeTags" (remove)="removeActiveTag($event)"></next-tag-pill>
  </div>

  <div class="row px-2 pb-2 border-top list-container">
    <!--NODE TREE VIEW-->
    <ng-container *ngIf='treeMode'>
      <ng-container *ngIf='previous.length > 1'>
        <div class='col-6 text-muted truncate p-0 text-left'>
          <button id='library-back-button' class='ih-btn-primary-o float-left tab-focus-visible' (click)='openPrevious()'>
            <fa-icon [icon]='["fas", "chevron-left"]' class='mr-2'></fa-icon>
            {{ 'PATIENT_FORMS.ADD_FORMS.BACK' | translate }}
          </button>
        </div>
        <div class='col-6 text-muted truncate p-0 text-right'>
          <button id='library-home-button' class='ih-btn-primary-o tab-focus-visible' (click)='openRoot()'>
            <fa-icon [icon]='["fas", "home"]' class='mr-2'></fa-icon>
            {{ 'PATIENT_FORMS.ADD_FORMS.HOME' | translate }}
          </button>
        </div>

        <div class='col-12 pl-3 p-0 mt-2'>
          {{ previousLabel[previousLabel.length - 1 ] }}
        </div>
      </ng-container>
      <div *ngIf='!treeExperiences.length' class='container-fluid list-container p-4 text-center text-muted'>
        {{ 'PATIENT_FORMS.ADD_FORMS.NO_CONTENT' | translate }}
      </div>

      <div *ngIf='treeExperiences.length' class='d-flex justify-content-center w-100 px-2 pt-2'>
        <button id='print-and-sign-btn-add-all' class='row cursor-pointer w-100 tab-focus-visible' (click)='toggleSelectAddAllCheckbox()'>
          <span id='print-and-sign-checkbox-add-all'
            class="p-0 justify-content-center hover-checkbox left-folder ih-btn-primary-o">
            <fa-icon *ngIf="!addAllCheckboxSelected" class='visible' [icon]="!isSelectedAddAll() ? ['far', 'circle'] : ['fas', 'check-circle']"></fa-icon>
            <fa-icon *ngIf="addAllCheckboxSelected" class='visible' [icon]="isSelectedAddAll() ? ['fas', 'check-circle'] : ['far', 'circle']"></fa-icon>
          </span>
          <span id='print-and-sign-right-btn-add-all' class="px-0 pt-1 item-content-l ih-btn-primary-o">
            {{ 'CLINICAL.ADD_FORMS_MODAL.SELECT_ALL' | translate }}
          </span>
        </button>
      </div>

      <div *ngIf='treeExperiences.length' class='container-fluid list-container column mt-2 px-0'>
        <div *ngFor="let item of treeExperiences; let i = index; let last = last" class="d-flex p-2 justify-content-center w-100 item">

          <!--FOLDER-->
          <button *ngIf="item.type === 'Folder'" id='{{ "btn-folder-container_" + i }}' class="position-relative row cursor-pointer hover-item w-100 p-1" role='button' type="button" (click)='openFolder(item)'>
            <span id='{{ "folder_" + i }}' class="p-0 align-items-center justify-content-center width-40px"></span>
            <span class="p-0 item-content-l">
              {{ item.name }}
              <br>
              <fa-icon [icon]="['fas', 'folder-open']" class="mx-1"></fa-icon>
              {{ 'MANAGE_FORMS.CATEGORY_LABEL' | translate }}
              <span class='float-right'>
                {{ item.children.length + ('MANAGE_FORMS.CATEGORY_COUNT' | translate) }}
              </span>
            </span>
            <span class="position-absolute d-flex w-100 justify-content-end align-self-start pr-3">
              <fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
            </span>
          </button>

          <!--EXPERIENCE-->
          <div *ngIf="item.type === 'Form'" class="row hover-item w-100 p-1" [ngClass]="{ 'active-item' : isSelected(item) }">
            <button
              id='{{ "checkbox-exp-list_" + i }}'
              class="p-0 align-items-center justify-content-center hover-checkbox width-40px"
              (click)='selectExperienceCheckbox(item)'>
              <fa-icon [ngClass]="{ 'visible' : checkboxSelect }" [icon]="isSelected(item) ? ['fas', 'check-circle'] : ['far', 'circle']"></fa-icon>
            </button>
            <button id='{{ "button-right-exp-list_" + i }}'
              class="p-0 rounded-0 item-content-l"
              (click)='selectExperienceButton(item)'>
              {{ item.name }}
              <br>
              <fa-icon [icon]="['fas', 'file-alt']" class='mx-1'></fa-icon>
              {{ 'MANAGE_FORMS.FORM_LABEL' | translate:formLabel }}
            </button>
          </div>

          <!--PACKET-->
          <div *ngIf="item.type === 'Packet'" class="row hover-item w-100 p-1" [ngClass]="{ 'active-item' : isSelected(item) }">
            <button
              id='{{ "checkbox-packet-list_" + i }}'
              class="p-0 align-items-center justify-content-center hover-checkbox width-40px"
              (click)='selectExperienceCheckbox(item)'>
              <fa-icon [ngClass]="{ 'visible' : checkboxSelect }" [icon]="isSelected(item) ? ['fas', 'check-circle'] : ['far', 'circle']"></fa-icon>
            </button>
            <button
              id='{{ "button-packet-list_" + i }}'
              class="p-0 rounded-0 item-content-l"
              (click)='selectExperienceButton(item)'>
              {{ item.name }}
              <br>
              <fa-icon [icon]="['fas', 'copy']" class='mx-1'></fa-icon>
              {{ 'MANAGE_FORMS.PACKET_LABEL' | translate }}
            </button>
          </div>
          <br>
        </div>
      </div>
    </ng-container>

    <!--FLAT LIST VIEW-->
    <div *ngIf='listExperiences.length && !treeMode' class='container-fluid list-container column mt-2 px-0'>
      <ng-container *ngFor="let item of listExperiences | sortBy: 'ASC':'name'; index as i; let last = last">
        <div class='d-flex p-2 justify-content-center w-100 item'>
          <div id='{{ "btn-form-flat-container_" + i }}' class='row cursor-pointer hover-item w-100 py-2' [ngClass]="{ 'active-item' : isSelected(item) }">
            <button id='{{ "checkbox-form-flat-list_" + i }}'
                    class="p-0 align-items-center justify-content-center hover-checkbox width-40px"
                    (click)='selectExperienceCheckbox(item)'>
              <fa-icon [ngClass]="{ 'visible' : checkboxSelect }" [icon]="isSelected(item) ? ['fas', 'check-circle'] : ['far', 'circle']"></fa-icon>
            </button>
            <button id='{{ "button-right-form-flat-list_" + i }}'
                    class="p-0 rounded-0 item-content-l"
                    (click)='selectExperienceButton(item)'>
              {{ item.name }}
              <br>
              <ng-container *ngIf='item.type && item.type === "Packet"'>
                <fa-icon [icon]="['fas', 'copy']" size='lg' class='mr-2'></fa-icon>{{ 'MANAGE_FORMS.PACKET_LABEL' | translate }}
              </ng-container>
              <ng-container *ngIf='!item.type || item.type !== "Packet"'>
                <fa-icon [icon]="['fas', 'file-alt']" size='lg' class='mr-2'></fa-icon>{{ 'MANAGE_FORMS.FORM_LABEL' | translate:formLabel }}
              </ng-container>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <ngx-spinner name="add-form-spinner" [fullScreen]="false" size="medium" color="#000000" type="ball-clip-rotate"></ngx-spinner>
</div>


