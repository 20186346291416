import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {
  FormDTO,
  FormEmbedded,
  FormStatus,
  FormType,
  GxProcessing,
  LogoService
} from '@next/shared/common';
import {
  ArchiveService,
  NextAdminService,
  NextExperienceService,
  NextFormService, NextImageService,
  PatientService,
  UserResolverService,
} from '@next/shared/next-services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first, takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import { StoreService } from '../state/store.service';
import { StateViewerService } from '../state/state-viewer.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FormsTableComponent } from '../forms-table/forms-table.component';
import { FlaggedFormsService } from '../services/flagged-forms.service';
import { FormsUtilityService } from '../services/forms-utility.service';

@Component({
  selector: 'next-flagged-forms-table',
  templateUrl: './flagged-forms-table.component.html',
  styleUrls: ['../forms-table/forms-table.component.scss']
})
export class FlaggedFormsTableComponent extends FormsTableComponent implements OnInit, OnChanges, OnDestroy {
  protected readonly FormType = FormType;
  flagToMoveLaterLabel: string;
  removeFlagToMoveLabel: string;

  constructor (
    protected userSvc: UserResolverService,
    protected modalSvc: BsModalService,
    protected experienceSvc: NextExperienceService,
    protected translateSvc: TranslateService,
    protected formSvc: NextFormService,
    public stateViewerSvc: StateViewerService,
    protected router: Router,
    protected toastrSvc: ToastrService,
    protected patientSvc: PatientService,
    protected archiveSvc: ArchiveService,
    protected stateSvc: StoreService,
    protected gxProcessing: GxProcessing,
    protected changeDetectorRef: ChangeDetectorRef,
    protected logoService: LogoService,
    protected imageSvc: NextImageService,
    protected nextAdminService: NextAdminService,
    protected spinnerSvc: NgxSpinnerService,
    protected flaggedFormsSvc: FlaggedFormsService,
    protected formsUtilSvc: FormsUtilityService
  ) {
    super(
      userSvc,
      modalSvc,
      experienceSvc,
      translateSvc,
      formSvc,
      stateViewerSvc,
      router,
      toastrSvc,
      patientSvc,
      archiveSvc,
      stateSvc,
      gxProcessing,
      changeDetectorRef,
      logoService,
      imageSvc,
      nextAdminService,
      spinnerSvc,
      formsUtilSvc,
      flaggedFormsSvc
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected callGetFormsByAppointmentObservable(): void {
    const selectedAppointment = this.stateSvc.searchedAppointments.selectedAppointments[0] || this.stateSvc.todaysAppointments.selectedAppointments[0];
    combineLatest([
      this.flaggedFormsSvc.getFlaggedFormsForPatient(this.patientData),
      this.flaggedFormsSvc.getFlaggedFormsForAppointments(this.patientData, true),
      this.formSvc.getFormsByAppointments(this.patientData.id, this.assignToId)
    ]).pipe(
      takeUntil(this.observerCleanup$)
    ).subscribe({
      next: ([flaggedFormsForPatient, appointmentsWithFlaggedForms, formsByAppointments]) => {
        this.hasFlaggedFormsForAppointment = this.flaggedFormsSvc.checkHasFlaggedFormsToMove(flaggedFormsForPatient, appointmentsWithFlaggedForms, null, selectedAppointment);
        super.getFormsByAppointment(formsByAppointments);
      }
    });
  }

  protected updateStateServiceSelectedForms(): void {
    super.updateStateServiceSelectedForms();
    this.populateMenuWithFlagToMoveLabel();
  }

  loadMenuSettings() {
    super.loadMenuSettings();
    this.flagToMoveLaterLabel = this.translateSvc.instant('PATIENT_FORMS.TABLE_MENU.FLAG_TO_MOVE_LATER');
    this.removeFlagToMoveLabel = this.translateSvc.instant('PATIENT_FORMS.TABLE_MENU.REMOVE_FLAG_TO_MOVE');
  }

  private removeFlagToMoveMenuItem(): void {
    this.menuItems = this.menuItems.filter(
      (item) =>
        item.label !== this.flagToMoveLaterLabel &&
        item.label !== this.removeFlagToMoveLabel &&
        item.label !== this.archiveLabel
    );
  }

  private populateMenuWithFlagToMoveLabel(): void {
    if (this.stateViewerSvc?.selectedForms?.forms?.length) {
      this.removeFlagToMoveMenuItem();

      const hasOnlyFlaggedForms = this.stateViewerSvc.selectedForms.forms.map(form => form.tomovelater)
        .reduce((accumulator, currentValue) => accumulator && currentValue, true);

      const flagLabel = hasOnlyFlaggedForms ? this.removeFlagToMoveLabel : this.flagToMoveLaterLabel;

      this.menuItems.push({
        label: flagLabel,
        command: () => this.commandToggleToMoveLaterValues(),
      });
      this.menuItems.push({
        label: this.archiveLabel,
        command: () => this.commandArchive(),
      });
    }
  }

  rowMenuClicked(form: FormEmbedded): void {
    if (!this.selectedForms.some(f => f.id === form.id)) {
      this.selectedForms = [form];
    }

    this.updateStateServiceSelectedForms();
    this.selectedForm = form;
    this.menuItems.find(x => x.label === this.archiveLabel).disabled = form.status.toLowerCase() === FormStatus.NotStarted;
  }

  private commandToggleToMoveLaterValues(): void {
    if (this.stateViewerSvc?.selectedForms?.forms?.length) {
      const hasOnlyFlaggedForms = this.stateViewerSvc.selectedForms.forms.map(form => form.tomovelater)
        .reduce((accumulator, currentValue) => accumulator && currentValue, true);

      const personID: string = this.stateViewerSvc.selectedForms.forms[0].patientdataid;
      const formIDs: string[] = this.stateViewerSvc.selectedForms.forms.map(form => form.id);
      this.formSvc.bulkUpdateToMoveLater(personID, formIDs, !hasOnlyFlaggedForms).pipe(
        first()
      ).subscribe({
        next: (forms: FormDTO[]) => {
          const updatedForms = this.stateViewerSvc.selectedForms;
          forms.forEach((form) => updatedForms.update(form));
          this.stateViewerSvc.selectedForms = updatedForms;
        },
        error: () => {
          this.toastrSvc.error(this.translateSvc.instant('APPOINTMENTS.TOASTR_MESSAGE.GET_PATIENT_FORMS_ERROR'), '', {disableTimeOut: true});
        }
      });
    }
  }
}
