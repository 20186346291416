import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  AnnotationDTO,
  CalculationResult,
  CheckboxFieldDTO,
  ColumnLayout,
  DateTimeDefaults,
  DropdownFieldDTO,
  FieldDTO,
  FormSubmission,
  GuidedExperienceDTO,
  GuidedExperienceInstanceDTO,
  GxProcessing,
  PhotoDTO,
  Popup,
  RadiogroupFieldDTO,
  Section,
  SubmissionData,
  SubmissionDataType,
  SubmissionType,
  TextboxFieldDTO,
  TextboxInputType,
  ViewerFieldType,
  WrittenSigDTO,
} from '@next/shared/common';
import { DateRangeValidatorFn } from '../utilities';
import { CurrencyPipe } from '@angular/common';

@Injectable()
export class ViewerService {
  formId: string;
  form: UntypedFormGroup;

  private expSub: BehaviorSubject<GuidedExperienceInstanceDTO> = new BehaviorSubject<GuidedExperienceInstanceDTO>(null);
  private selectedPageSub: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private selectedPage: number = 0;
  private viewedPages: Set<number> = new Set();

  constructor (
    private gxProcessing: GxProcessing,
    private currencyPipe: CurrencyPipe
  ) { }

  nextPage(): void {
    this.selectedPage++;
    this.selectedPageSub.next(this.selectedPage);
  }

  prevPage(): void {
    this.selectedPage--;
    this.selectedPageSub.next(this.selectedPage);
  }

  addViewedPage(pageNum: number): void {
    this.viewedPages.add(pageNum);
  }

  removeViewedPage(pageNum: number): void {
    this.viewedPages.delete(pageNum);
  }

  setViewedPages(pageNum: number): void {
    for (let i = pageNum; i >= 0; i--) {
      this.viewedPages.add(i);
    }
  }

  pageViewed(pageNum: number): boolean {
    return this.viewedPages.has(pageNum);
  }

  /**
   * initialize()
   * Takes an GX Instance, and returns a preprocessed submission, attachments, prefill, experience, and formData
   * Also sets page tracking for Web Viewer
   * @param logo
   * @param instance */
  async initialize(instance: GuidedExperienceInstanceDTO, logo: { src: string | ArrayBuffer, id: string } = null): Promise<any> {
    const prefill: unknown = instance.prefill ?? { };
    const submission: FormSubmission = instance.submission ?? null;
    this.selectedPage = submission?.metadata?.page ?? 0;
    this.selectedPageSub.next(this.selectedPage);
    this.setViewedPages(this.selectedPage);
    const processed = await this.gxProcessing.preProcessing(instance.experience, submission, prefill, logo);
    this.setupInitialForm(processed.data, processed.experience);
    this.expSub.next(instance);
    return {
      submission: submission,
      attachments: instance.attachments ?? [],
      prefill: instance.prefill,
      experience: processed.experience,
      initialData: processed.data
    };
  }

  getSelectedPage(): Observable<number> {
    return this.selectedPageSub.asObservable();
  }

  /**
   * Invoke the calculation service on the merged prefill, initial data, and submit data, returns
   * the final processed form data.
   *
   * @param config - The experience from which calculations were attached
   * @param submissionType - The condition context determining which calculations to invoke
   * @param initialState - submission data created from prefill on viewer load, includes all fields
   * @param state - the submission data after save/submit field processing
   * @param prefillData - the immutable prefill data loaded into the Experience
   */
  async processCalculations(config: GuidedExperienceDTO, submissionType: SubmissionType, initialState: any, state: any, prefillData: any) {
    if (!config) return;
    let formData = { ...initialState, ...state };
    Object.keys(formData).forEach(k => (formData[k] == null) && delete formData[k]);

    // Process calculations
    if (submissionType === SubmissionType.Saved) {
      if (config.onSave) {
        const calculationResults = await this.gxProcessing.runCalculation(config.onSave, null, config, formData, prefillData).toPromise();
        formData = Object.assign({}, formData, calculationResults.fields);
      }
    }
    else if (submissionType === SubmissionType.Submitted) {
      if (config.onSubmit) {
        const calculationResults = await this.gxProcessing.runCalculation(config.onSubmit, null, config, formData, prefillData).toPromise();
        formData = Object.assign({}, formData, calculationResults.fields);
      }
    }

    return formData;
  }

  async getCalculationData(calculationName: string, fieldName: string, config: GuidedExperienceDTO, state: SubmissionData, prefillData: any): Promise<CalculationResult> {
    return await this.gxProcessing.runCalculation(calculationName, fieldName, config, state, prefillData).toPromise();
  }

  getDataEntryValue(data): any {
    switch (data.Type) {
      case SubmissionDataType.Text:
      case SubmissionDataType.TypedSignature:
        return data.Value['Text'];
      case SubmissionDataType.Drawing:
      case SubmissionDataType.Signature:
        return data.Value['Strokes'];
      case SubmissionDataType.File:
        return data.Value['FileID'];
    }
  }

  setupInitialForm(initData: SubmissionData, experience: GuidedExperienceDTO): void {
    const possibleFields: FieldDTO[] = this.gxProcessing.getAllFields(experience).filter(f => f.name);
    const fieldsToInit: any[] = possibleFields.filter(f => f.name && initData[f.name] && this.getDataEntryValue(initData[f.name])?.length);

    fieldsToInit.forEach(f => {
      if (!this.form.contains(f.name)) {
        let valueFormGroup: UntypedFormGroup;
        const initValue: SubmissionData['data'] = initData[f.name];
        const options: any = f.required ? { validators: [Validators.required] } : { validators: [] };

        switch (f.type) {
          case ViewerFieldType.TEXTBOX:
            options.updateOn = 'blur';
            if ((f as TextboxFieldDTO).inputType === TextboxInputType.DATE) {
              options.validators.push(DateRangeValidatorFn(f));
            }
            else if ((f as TextboxFieldDTO).inputType === TextboxInputType.SHORTDATE2 || (f as TextboxFieldDTO).inputType === TextboxInputType.SHORTDATE4) {
              options.validators.push(DateRangeValidatorFn(f));
            }
            else if ((f as TextboxFieldDTO).inputType === TextboxInputType.NUM) {
              if (f.minValue) {
                options.validators.push(Validators.min(f.minValue));
              }
              if (f.maxValue) {
                options.validators.push(Validators.max(f.maxValue));
              }
            }
            else if ((f as TextboxFieldDTO).inputType === TextboxInputType.CURRENCY && typeof initValue.Value.Text === 'string') {
              initValue.Value.Text = this.currencyPipe.transform(initValue.Value.Text, '', '', '.2-2').replace(/,/g, '');
            }

            valueFormGroup = new UntypedFormGroup({
              Text: new UntypedFormControl(initValue.Value?.Text || '', options),
              Format: f.type === ViewerFieldType.TEXTBOX
                ? new UntypedFormControl(f.inputType)
                : new UntypedFormControl(TextboxInputType.ALPHANUM)
            });

            /* Date input-type requires a control for preferred format */
            if (f.inputType === TextboxInputType.DATE || f.inputType === TextboxInputType.TIME || f.inputType === TextboxInputType.DATETIME) {
              valueFormGroup.addControl('OutputFormat', new UntypedFormControl(f.dateFormat || DateTimeDefaults[f.inputType].value));
            }
            break;
          case ViewerFieldType.CHECKBOX:
          case ViewerFieldType.DROPDOWN:
          case ViewerFieldType.RADIOGROUP:
            valueFormGroup = new UntypedFormGroup({
              Text: new UntypedFormControl(initValue.Value?.Text || '', options),
              Format: new UntypedFormControl(TextboxInputType.ALPHANUM)
            });
            break;
          case ViewerFieldType.PHOTO:
            valueFormGroup = new UntypedFormGroup({
              FileID: new UntypedFormControl(initValue.Value?.FileID || '', options),
              FormID: new UntypedFormControl(initValue.Value?.FormID || '')
            });
            break;
          case ViewerFieldType.ANNOTATION:
            valueFormGroup = new UntypedFormGroup({
              Strokes: new UntypedFormControl(initValue?.Value?.Strokes || [], options),
              ImageSource: new UntypedFormControl(initValue?.Value?.ImageSource || f.imageSource || '')
            });
            break;
          case ViewerFieldType.WRITTENSIG:
            valueFormGroup = new UntypedFormGroup({
              SignedDate: new UntypedFormControl(initValue?.Value?.SignedDate || ''),
              Strokes: new UntypedFormControl(initValue?.Value?.Strokes || '', options),
              Text: new UntypedFormControl(initValue?.Value?.Text || '', options)
            });
            break;
        }

        if (valueFormGroup) {
          this.form.addControl(f.name, new UntypedFormGroup({
            Type: new UntypedFormControl(initValue.Type),
            Value: valueFormGroup,
          }));
        }
      }
      else {
        this.form.get(f.name).patchValue(initData[f.name]);
      }
    });
  }


  validateOneFieldPopup(fields: FieldDTO[], form: UntypedFormGroup): boolean {
    //TODO: remove this, refactor validateFields()
    if (!this.gxProcessing.containsInputFields(fields)) {
      return true;
    }
    for (const field of fields) {
      if (form.get(field.name) || field.type === ViewerFieldType.POPUP) {
        let typedField: any;
        if (field.type === ViewerFieldType.CHECKBOX) {
          typedField = field as CheckboxFieldDTO;
          if (form.get(typedField.name).get('Value')?.valid) {
            if (form.get(typedField.name).get('Value').get('Text').value) {
              return true;
            }
            else if (typedField.falseFields?.length > 0) {
              if (this.validateOneFieldPopup(typedField.falseFields, this.form)) {
                return true;
              }
            }
          }
        }
        else if (field.type === ViewerFieldType.TEXTBOX) {
          typedField = field as TextboxFieldDTO;
          if (form.get(typedField.name).get('Value')?.get('Text')?.valid && form.get(typedField.name).get('Value').get('Text').value) {
            return true;
          }
        }
        else if (field.type === ViewerFieldType.DROPDOWN || field.type === ViewerFieldType.RADIOGROUP) {
          typedField = field as TextboxFieldDTO;
          if (form.get(typedField.name).valid && form.get(typedField.name).value.Value.Text) {
            return true;
          }
        }
        else if (field.type === ViewerFieldType.ANNOTATION) {
          typedField = field as AnnotationDTO;
          if (form.get(typedField.name).valid && form.get(typedField.name).value.Value.Strokes.length > 0) {
            return true;
          }
        }
        else if (field.type === ViewerFieldType.POPUP) {
          typedField = field as Popup;
          if (typedField.popupField.fields?.length > 0 && this.validateOneFieldPopup(typedField.popupField.fields, this.form)) {
            return true;
          }
        }

        else if (field.type === ViewerFieldType.WRITTENSIG) {
          typedField = field as WrittenSigDTO;
          if ((form.get(field.name).value.Value.Text || form.get(field.name).value.Value.Strokes.length > 0)) {
            return true;
          }
        }
        else if (form.get(field.name).valid && form.get(field.name).value) {
          return true;
        }
      }
    }
    return false;
  }

  validateSectionSingleField(fields: FieldDTO[]): boolean {
    const fillableFields = this.gxProcessing.getAllSubFields(fields);
    if (!fillableFields.length) {
      return true;
    } else {
      const hasFilledValidField: boolean = fillableFields.some(f => this.form.get(f.name)?.valid && this.getFormFieldValue(f));
      const hasInvalidField: boolean = fillableFields.some(f => this.form.get(f.name)?.invalid);
      return hasFilledValidField && !hasInvalidField;
    }
  }

  validateFields(fields: FieldDTO[], form: UntypedFormGroup, includeRequiredValidation: boolean = true): boolean {
    for (const field of fields) {
      let typedField: any = null;
      if (field.type === ViewerFieldType.TEXTBOX) {
        typedField = field as TextboxFieldDTO;
        if (form.get(field.name) && form.get(field.name).invalid) {
          if (includeRequiredValidation || !form.get(field.name).get('Value').get('Text').errors.required) {
            return false;
          }
        }
      } else if (field.type === ViewerFieldType.CHECKBOX) {
        typedField = field as CheckboxFieldDTO;
        if (form.get(field.name)) {
          if (typedField.required && form.get(field.name).invalid) {
            return false;
          }
          if (form.get(typedField.name).value.Value.Text) {
            if (typedField.trueFields) {
              if (!this.validateFields(typedField.trueFields, form, includeRequiredValidation)) {
                return false;
              }
            }
          } else if (typedField.falseField) {
            if (typedField.falseFields) {
              if (!this.validateFields(typedField.falseFields, form, includeRequiredValidation)) {
                return false;
              }
            }
          }
        }

      } else if (field.type === ViewerFieldType.DROPDOWN) {
        typedField = field as DropdownFieldDTO;
        if (typedField.required && form.get(field.name).invalid) {
          if (includeRequiredValidation || !form.get(field.name).get('Value').get('Text').errors.required) {
            return false;
          }
        }
        for (const entry of typedField.switch) {
          entry.fields.forEach(() => {
            if (entry.when === form.get(field.name).value.Value.Text) {
              if(!this.validateFields(entry.fields, form, includeRequiredValidation)) {
                return false;
              }
            }
          });
        }

      } else if (field.type === ViewerFieldType.RADIOGROUP) {
        typedField = field as RadiogroupFieldDTO;
        if (typedField.required && form.get(field.name).invalid) {
          if (includeRequiredValidation || !form.get(field.name).get('Value').get('Text').errors.required) {
            return false;
          }
        }
        for (const entry of typedField.switch) {
          entry.fields.forEach(() => {
            if (entry.when === form.get(field.name).value.Value.Text) {
              if (!this.validateFields(entry.fields, form, includeRequiredValidation)) {
                return false;
              }
            }
          });
        }
      } else if (field.type === ViewerFieldType.POPUP) {
        typedField = field as Popup;
        if (!typedField.popupField.validateOneField) {
          if (!this.validateFields(typedField.popupField.fields, form, includeRequiredValidation)) {
            return false;
          }
        } else if (includeRequiredValidation && !this.validateOneFieldPopup(typedField.popupField.fields, form)) {
          return false;
        }
      } else if (field.type === ViewerFieldType.SECTION) {
        typedField = field as Section;
        if (includeRequiredValidation && !this.validateSectionSingleField(typedField.fields)) {
          return false;
        }
      } else if (field.type === ViewerFieldType.WRITTENSIG) {
        typedField = field as WrittenSigDTO;
        if (typedField.required && (!form.get(field.name).value.Value.Text && !form.get(field.name).value.Value.Strokes.length)) {
          return false;
        }
      } else if (field.type === ViewerFieldType.PHOTO) {
        typedField = field as PhotoDTO;
        if (typedField.required && (form.get(field.name).invalid)) {
          return false;
        }
      } else if (field.type === ViewerFieldType.ANNOTATION) {
        typedField = field as AnnotationDTO;
        if (form.get(typedField.attachmentName)?.invalid) {
          return false;
        }
      } else if (field.type === ViewerFieldType.COLUMNLAYOUT) {
        typedField = field as ColumnLayout;
        let columnsAreValid = true;
        for (const column of typedField.columns) {
          if (includeRequiredValidation && !this.validateFields(column.fields, form, includeRequiredValidation)) {
            columnsAreValid = false;
          }
        }

        if(!columnsAreValid) {
          return false;
        }
      }
    }
    return true;
  }

  getFormFieldValue(field: FieldDTO): any {
    switch (field.type) {
      case ViewerFieldType.TEXTBOX:
      case ViewerFieldType.RADIOGROUP:
      case ViewerFieldType.DROPDOWN:
      case ViewerFieldType.CHECKBOX:
        return this.form.get(field.name)?.value?.Value?.Text || '';

      case ViewerFieldType.WRITTENSIG:
        if (this.form.get(field.name)?.value?.Type === 'Signature') {
          return (this.form.get(field.name).get('Value').get('Strokes')?.value.length > 0) ? this.form.get(field.name).get('Value').get('Strokes').value : '';
        }
        else {
          return this.form.get(field.name).get('Value').get('Text').value || '';
        }

      case ViewerFieldType.ANNOTATION:
        if (this.form.get(field.name)?.value?.Value?.Strokes && this.form.get(field.name).value.Value.Strokes.length > 0) {
          return this.form.get(field.name).value.Value.Strokes;
        }
        else {
          return '';
        }

      case ViewerFieldType.PHOTO:
        return this.form.get(field.name).value || '';

      default:
        return null;
    }
  }
}
