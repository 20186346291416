import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { last, map } from 'rxjs/operators';
import {
  ApiService,
  Form,
  FormDTO,
  FormFastConfig,
  FormFastConfigService,
  NextAnnotationResponse,
  TaskDTO,
  TokenService,
  UploadFile,
  FunctionDTO,
  UploadFileResponse,
  GuidedExperienceInstanceDTO,
} from '@next/shared/common';

@Injectable({
  providedIn: 'root'
})
export class NextFormService extends ApiService {
  validFormTypes: string[] = ['application/pdf'];

  constructor (
    @Inject(FormFastConfigService) config: FormFastConfig,
    tokenService: TokenService,
    private http: HttpClient
  ) {
    super(config, tokenService);
  }

  // NOTE: This Form HTTP Service is being used for both 'Form' (PDF Files) and 'FormDTO' (literal form objects
  // created from the viewer), and also Functions, and Tasks.
  // Consider breaking this service up into multiple

  /**
   * Returns a base 64 Encoded PDF (a rastered PDF)
   * NOTE: we could change to a different header here to better reflect the response payload
   * @param data
   */
  generateForm(data: any): Observable<string> {
    return this.protectedEndpoint<string>(
      this.http.post<string>(`${this.config.apiUrl}form/generateform`, data, this.getHeaders())
    );
  }

  /**
   * Returns all Form objects belonging to a patient, by patient id
   * @param pid - patientdata id
   */
  getFormsByPatients(pid: string): Observable<FormDTO[]> {
    return this.protectedEndpoint<any[]>(
      this.http.get<any>(`${this.config.apiUrl}form/${pid}/patient`, this.getHeaders())
    ).pipe(
      map(results => results ? results.map(result => this.formatFormDTO(result)) : [] ));
  }

  /**
   * Returns all Form objects belonging to potentially multiple patients, by patient MPI
   * @param pid - patientdata id, from this patient record, get all form objects by patient.mpi
   */
  getFormsByPatientIndex(pid: string): Observable<FormDTO[]> {
    return this.protectedEndpoint<any>(
      this.http.get<any>(`${this.config.apiUrl}form/${pid}/mpi`, this.getHeaders())
    ).pipe(
      map(results => results ? results.map(result => this.formatFormDTO(result)) : [] ));
  }

  /**
   * Returns all forms by appointment id
   * @param pid - patientdata id
   * @param aid - appointment id
   */
  getFormsByAppointments(pid: string, aid: string): Observable<FormDTO[]> {
    return this.protectedEndpoint<any>(
      this.http.get<any>(`${this.config.apiUrl}form/${pid}/${aid}/appointment`, this.getHeaders())
    ).pipe(
      map(results => results ? results.map(result => this.formatFormDTO(result)) : [] ));
  }

  /**
   * Returns form object by form id
   * @param formId - form id
   */
  getForm(formId: string): Observable<FormDTO> {
    return this.protectedEndpoint<FormDTO>(
      this.http.get<FormDTO>(`${this.config.apiUrl}form/${formId}`, this.getHeaders())
    ).pipe(
      map(result => this.formatFormDTO(result)));
  }

  /**
   * Inserts forms into patient or appointment, returning the result.
   * These forms do not have a form history, thus no submission data.
   * @param personForms
   */
  bulkFormInsert(personForms: FormDTO[]): Observable<FormDTO[]> {
    return this.protectedEndpoint<FormDTO[]>(
      this.http.post<FormDTO[]>(`${this.config.apiUrl}form/bulk/add`, personForms, this.getHeaders())
    ).pipe(
      map(results => results ? results.map(result => this.formatFormDTO(result)) : [] ));
  }

  /**
   * Delete forms
   * @param personForms
   */
  removeFormsFromModel(personForms: FormDTO[]): Observable<boolean> {
    const options = Object.assign(this.getHeaders(), { body: personForms });
    return this.protectedEndpoint<boolean>(
      this.http.delete<any>(`${this.config.apiUrl}form/bulk/remove`, options)
    );
  }

  /**
   * Sets the toMoveLater values belonging to the Forms by ID
   * @param pid - Patient ID, target appointments 'hasMovedForms' properties are updated from this patient ID
   * @param formIds - ID of forms to update
   * @param value - Boolean, the value to set toMoveLater property of forms to
   */
  bulkUpdateToMoveLater(pid: string, formIds: string[], value: boolean): Observable<FormDTO[]> {
    return this.protectedEndpoint<FormDTO[]>(
      this.http.put<FormDTO[]>(`${this.config.apiUrl}form/${pid}/bulkUpdateToMoveLater/${value}`, formIds, this.getHeaders())
    ).pipe(
      map(results => results ? results.map(result => this.formatFormDTO(result)) : [] ));
  }

  /**
   * Reassigns a form to the target patient id and appointment id
   * @param pid - patient id
   * @param aid - appointment id (optional)
   * @param formIds - form IDs to be reassigned
   */
  moveFlaggedForms(pid: string, aid: string, formIds: string[]): Observable<FormDTO[]> {
    return this.protectedEndpoint<FormDTO[]>(
      this.http.put<FormDTO[]>(`${this.config.apiUrl}form/${pid}/${aid}/moveFlaggedForms`, formIds, this.getHeaders())
    ).pipe(
      map(results => results ? results.map(result => this.formatFormDTO(result)) : [] ));
  }

  getTask(id: string): Observable<TaskDTO> {
    return this.protectedEndpoint<TaskDTO>(
      this.http.get<TaskDTO>(`${this.config.apiUrl}task/${id}`, this.getHeaders())
    );
  }

  reassignSignatureTask(formId: string, userId: string, fieldIds: string) {
    return this.protectedEndpoint<TaskDTO>(
      this.http.post<TaskDTO>(`${this.config.apiUrl}task/user/${userId}/reassign?formid=${formId}&fieldids=${fieldIds}`, this.getHeaders())
    );
  }

  // Form Objects are PDFs (Consider renaming to Documents, TemplatePDFs, PDFs, etc..)
  // The following functions should be moved to a different service
  getForms(): Observable<Form[]> {
    return this.protectedEndpoint<Form[]>(
      this.http.get<Form[]>(`${this.config.apiUrl}pdftemplate`, this.getHeaders())
    );
  }

  getFormFields(id: string): Observable<NextAnnotationResponse> {
    return this.protectedEndpoint<NextAnnotationResponse>(
      this.http.get<NextAnnotationResponse>(`${this.config.apiUrl}pdftemplate/${id}/schema`, this.getHeaders())
    );
  }

  getPdfBinaryData(url: string) {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((res: any) => {
        return new Blob([res], { type: 'application/pdf', endings: 'native' });
      }));
  }

  // Adding a download flag that will be added as a query param, so we can log the event in our activity history
  getPdf(id: string, download: boolean = false): Observable<Form> {
    return this.protectedEndpoint<Form>(
      this.http.get<Form>(`${this.config.apiUrl}pdftemplate/${id}?download=${download}`, this.getHeaders())
    );
  }

  downloadForm(url: string): any {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');

    return this.http.get(url, {
      headers: headers, responseType: 'blob'
    });
  }

  uploadPDF(file: File): Observable<HttpEvent<UploadFileResponse>> {
    const formData: FormData = new FormData();
    formData.append('name', file.name.endsWith('.pdf') ? file.name.substring(0, file.name.length - 4) : file.name);
    formData.append('description', file.name.endsWith('.pdf') ? file.name : file.name + '.pdf');
    formData.append('pdf-form', file, file.name.endsWith('.pdf') ? file.name : file.name + '.pdf');
    const uploadFileReq = new HttpRequest('POST', `${this.config.apiUrl}pdftemplate`, formData, this.getUploadHeaders());

    return this.http.request<UploadFileResponse>(uploadFileReq).pipe(
      last() // Only return the final event to the caller.
    );
  }

  deletePDF(document: Form) {
    return this.protectedEndpoint<any>(
      this.http.delete(`${this.config.apiUrl}pdftemplate/${document.id}`, this.getHeaders())
    );
  }

  upload(files: UploadFile[]) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      file.progress = 0;
      if (!this.isValidFileType(file.file)) {
        file.status = -1;
      } else {
        const formData: FormData = new FormData();
        formData.append('name', file.file.name.replace('.pdf', ''));
        formData.append('description', file.file.name);
        formData.append('pdf-form', file.file, file.file.name);
        const req = new HttpRequest('POST', `${this.config.apiUrl}pdftemplate`, formData, this.getUploadHeaders());
        this.protectedEndpoint<any>(this.http.request(req)).subscribe(
          event => {
            if (event.type === HttpEventType.UploadProgress) {
              files[i].progress = Math.ceil(+(event.loaded / event.total).toFixed(2) * 100);
            }
          },
          () => {
            files[i].status = 0;
            files[i].progress = 0;
          },
          () => {
            files[i].status = 1;
            files[i].progress = 0;
          }
        );
      }
    }
  }

  private isValidFileType(file: File): boolean {
    return this.validFormTypes.includes(file.type.toString());
  }

  // Functions should probably be moved to their own HTTP service wrapper as well
  getFunctionsByName(name: string): Observable<FunctionDTO[]> {
    return this.protectedEndpoint<FunctionDTO[]>(
      this.http.get<FunctionDTO[]>(`${this.config.apiUrl}function/${name}/getbyname`, this.getHeaders())
    );
  }

  getAllActiveFunctions(): Observable<FunctionDTO[]> {
    return this.protectedEndpoint<FunctionDTO[]>(
      this.http.get<FunctionDTO[]>(`${this.config.apiUrl}function/getAllActive`, this.getHeaders())
    );
  }

  createFunction(func: FunctionDTO): Observable<FunctionDTO> {
    return this.protectedEndpoint<FunctionDTO>(
      this.http.post<FunctionDTO>(`${this.config.apiUrl}function/`, func, this.getHeaders())
    );
  }

  updateFunction(func: FunctionDTO): Observable<FunctionDTO> {
    return this.protectedEndpoint<FunctionDTO>(
      this.http.put<FunctionDTO>(`${this.config.apiUrl}function`, func, this.getHeaders())
    );
  }

  deleteFunction(func: FunctionDTO): Observable<FunctionDTO> {
    func.isdeleted = true;
    return this.updateFunction(func);
  }

  getFieldsMapping(fields): Observable<any> {
    return this.protectedEndpoint<any>(
      this.http.post<FunctionDTO>(`${this.config.apiUrl}pdftemplate/fieldmapping`, fields, this.getHeaders())
    );
  }

  validateFunctionName(name: string | undefined) {
    try { new Function(`let ${name}`) } catch(e) { return e.message }
    return '';
  }

  getFormsList(patientId: string, appointmentId?: string, formIds?: string[]) : Observable<FormDTO[]> {
    let url = `${this.config.apiUrl}form/${patientId}/list`;

    if (formIds?.length > 0) {
      url += `?formIds=${formIds}`;
    } else if (appointmentId) {
      url += `?appointmentId=${appointmentId}`;
    }

    return this.protectedEndpoint<FormDTO[]>(this.http.get<any>(url, this.getHeaders()));
  }

  getGuidedExperience(formId: string): Observable<GuidedExperienceInstanceDTO> {
    return this.protectedEndpoint<GuidedExperienceInstanceDTO>(this.http.get<any>(
      `${this.config.apiUrl}form/${formId}/GuidedExperience`, this.getHeaders())
    );
  }

  private formatFormDTO(data: any): FormDTO {
    return {
      id: data.id,
      name: data.name,
      experienceid: data.experienceid,
      experienceversionid: data.experienceversionid,
      version: data.version,
      tags: data.tags,
      pdftemplateid: data.pdftemplateid,
      updatedon: data.updatedon,
      createdon: data.createdon,
      historyid: data.historyid,
      lastarchivedon: data.lastarchivedon,
      status: data.status,
      patientdataid: data.personid,
      patientdata: data.patientdata,
      appointmentid: data.appointmentid,
      appointment: data.appointment,
      mrn: data.mrn,
      firstname: data.firstname,
      lastname: data.lastname,
      servicingfacility: data.servicingfacility,
      datasetcount: data.datasets?.length ?? 0,
      datasets: data.datasets,
      statusorder: data.statusorder,
      tomovelater: data.tomovelater,
      accountnumber: data.accountnumber,
      data: data.data
    } as FormDTO;
  }
}
