<div class="input-group mr-sm-2" [class.input-group-border]="border">
  <div class="input-group-prepend">
    <div class="input-group-text"><fa-icon [icon]="['fas', 'search']"></fa-icon></div>
  </div>
  <input
    type="text"
    class="form-control"
    id="field-filter"
    name="field-filter"
    [ngModel]="filter"
    (ngModelChange)="onFilterChanged($event)"
    (keydown.enter)="onFilterChanged(filter)"
    placeholder="{{ placeholder || 'FILTER_PLACEHOLDER' | translate }}"
    autocomplete="off"
    nextAutoFocusElement
    [shouldFocus]="shouldFocus" />
</div>
