import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_BASE_HREF } from '@angular/common';
import { environment } from '../environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import {
  AuthInterceptor,
  NextClinicalService,
  NextExperienceService,
  NextImageService,
  NextSubmissionService,
  CacheInterceptor,
  HttpProgressInterceptor,
  NextRouterService
} from '@next/shared/next-services';
import {
  ServiceWorkerModule,
  SwRegistrationOptions,
} from '@angular/service-worker';
import { ToastrModule } from 'ngx-toastr';
import { ErrorComponent } from './error/error.component';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { FormSubmissionService } from '@next/next-clinical/feature-shared';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Router } from '@angular/router';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.baseHref + 'assets/i18n/', `.json?version=${environment.appVersion}`);
}

const childRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('@next/next-clinical/feature-shell').then(m => m.NextClinicalFeatureShellModule)
  },
  {
    path: 'error',
    component: ErrorComponent
  }
];

@NgModule({
  declarations: [AppComponent, ErrorComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(childRoutes, { canceledNavigationResolution: 'computed', onSameUrlNavigation: 'reload' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('safety-worker.js'),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: true
    }),
    NgxSpinnerModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    FontAwesomeModule
  ],
  providers: [
    { provide: SwRegistrationOptions, useFactory: () => ({ enabled: environment.production }) },
    { provide: APP_BASE_HREF, useValue: environment.baseHref },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpProgressInterceptor, multi: true },
    { provide: Router, useClass: NextRouterService },
    NextExperienceService,
    NextImageService,
    NextSubmissionService,
    NextClinicalService,
    FormSubmissionService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
