import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  DataMappingConfig,
  DisplayColumn,
  GridSetting,
  SettingEnum,
} from '@next/shared/common';
import {
  NextAdminService,
  UserResolverService,
} from '@next/shared/next-services';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Table } from 'primeng/table';
import { GridColumnModalComponent } from '../grid-column-modal/grid-column-modal.component';
import { GridSettingModalComponent } from '../grid-setting-modal/grid-setting-modal.component';
import { GridSettingSaveModalComponent } from '../grid-setting-save-modal/grid-setting-save-modal.component';
import { StoreService } from '../state/store.service';
import { Subject, first, lastValueFrom } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { App } from '@next/shared/ui';

@Component({
  selector: 'next-grid-configuration',
  templateUrl: './grid-configuration.component.html',
  styleUrls: ['./grid-configuration.component.scss']
})
export class GridConfigurationComponent implements OnInit, OnDestroy {

  @Input() table: Table;
  @Input() screen: SettingEnum;
  @Input() sortby: string;
  @Input() filterby: string;
  @Input() dateRangeFilter: any;

  @Output() gridHeadersUpdate: EventEmitter<any[]> = new EventEmitter<any[]>();

  userId: string;
  currentGridId: string = null;
  currentGridName: string;
  isDefaultSetting: boolean;
  displaycolumn: any;
  gridHeaders: Array<any>
  gridColumnModal: BsModalRef;
  gridSettingModal: BsModalRef;
  modalRef: BsModalRef;
  observerCleanup$: Subject<void> = new Subject<void>();

  constructor (
    private adminSvc: NextAdminService,
    public userSvc: UserResolverService,
    private translateSvc: TranslateService,
    private modalSvc: BsModalService,
    private toastSvc: ToastrService,
    public stateSvc: StoreService,
    private spinnerSvc: NgxSpinnerService) {
    this.userId = this.userSvc.user.oid;
  }

  async ngOnInit(): Promise<void> {
    const screenState = this.stateSvc.screenStates.getScreenState(this.screen);
    if (screenState?.selectedTableConfig) {
      this.setDisplayName(screenState.selectedTableConfig.name);
      await this.applySavedSettings(screenState.selectedTableConfig.gridId);
    }
    else {
      this.setDefaultSetting();
    }
  }

  ngOnDestroy() {
    this.observerCleanup$.next();
    this.observerCleanup$.complete();
  }

  onGridSettingModal() {
    const config: any = {
      class: 'modal-lg',
      backdrop: true,
      keyboard: false,
      ignoreBackdropClick: true,
      initialState: {
        sortby: this.sortby || null,
        filterby: this.filterby || null,
        displaycolumn: this.displaycolumn || null,
        screen: this.screen,
        userid: this.userId
      }
    };
    this.gridSettingModal = this.modalSvc.show(GridSettingModalComponent, config);
    this.gridSettingModal.content.onClose.pipe(first()).subscribe(async result => {
      if (result) {
        this.gridSettingModal.hide();
        await this.setView(result);
      }
    });
  }

  onGridColumnModal() {
    const config: ModalOptions = {
      class: 'modal-lg',
      backdrop: true,
      keyboard: false,
      ignoreBackdropClick: true,
      initialState: {
        displaycolumn: this.displaycolumn || null,
        screen: this.screen,
      }
    };
    this.gridColumnModal = this.modalSvc.show(GridColumnModalComponent, config);
    this.gridColumnModal.content.onClose.subscribe(result => {
      if (result) {
        this.setColumnHeaders(result);
      }
    });
  }

  setColumnHeaders(result: DisplayColumn[]) {
    this.gridHeaders = [];
    const updated = this.filterColumnHeaders(result, this.stateSvc.dataMapping[this.screen]);
    this.setColumnsByCustomView(updated);
    this.displaycolumn = updated;
  }

  filterColumnHeaders(displayColumns: DisplayColumn[], dataMapping: DataMappingConfig[]) {
    const updated: DisplayColumn[] = displayColumns.reduce((acc: DisplayColumn[], current: DisplayColumn) => {
      const column = dataMapping.find((dataMap) => dataMap.id === current.id || dataMap.FieldName === current.FieldName);
      if (column) {
       acc.push({
          id: column.id,
          FieldName: column.FieldName,
          DisplayName: column.DisplayName
        });
      }
      return acc;
    }, []);
    return updated;
  }

  setColumnsByCustomView(result) {
    result?.forEach(cond => {
      this.gridHeaders.push({
        field: cond.FieldName,
        header: cond.DisplayName
      })
    });

    this.gridHeadersUpdate.emit(this.gridHeaders)
    this.stateSvc.formsGridConfigFirstLoad$.next();
    this.stateSvc.formsGridConfigFirstLoad$.complete();
  }

  async applySavedSettings(id: string, viewChanged: boolean = false): Promise<void> {
    this.currentGridId = id;
    try {
      await this.spinnerSvc.show(App.SPINNERS.MAIN_SPINNER);
      const settings = await lastValueFrom(this.adminSvc.getGridSetting(id));
      const setting = settings?.[0];

      // If for some reason the setting in the session storage is not found in the database, set the default setting
      if (!setting) {
        this.setDefaultSetting();
        return;
      }

      this.isDefaultSetting = setting?.isdefault;
      this.setColumnHeaders(setting?.displaycolumn.data);
      if(this.gridHeaders.length > 6) {
        this.table.scrollDirection = "both";
      }

      this.table.clear();

      if (this.screen === SettingEnum.Appointment) {
        if (!this.stateSvc.enterpriseSettings.explicitRecordSearch && (this.stateSvc.searchedAppointments.dateFilter?.length === 0 || viewChanged)) {
          if (setting.daterangefilter) {
            //this.dateRangeFilter = setting.daterangefilter;
            const startDate = this.getRelativeDate(setting.daterangefilter.offSetDays);
            const endDate = this.getRelativeDate(setting.daterangefilter.offSetDays + setting.daterangefilter.numberOfDays);
            if (startDate && endDate) {
              this.stateSvc.searchedAppointments.dateFilter = [startDate, endDate];
              this.stateSvc.searchedAppointments.isNewSearch = false;
            }
            else if (!startDate && !endDate && (!setting.daterangefilter && !this.stateSvc.searchedAppointments.isNewSearch)) {
              this.stateSvc.searchedAppointments.dateFilter = [];
              this.stateSvc.searchedAppointments.error = '';
            }
          }
          else {
            this.stateSvc.searchedAppointments.dateFilter = [];
            this.stateSvc.searchedAppointments.error = '';
          }
          this.stateSvc.gridViewSelectionChange = viewChanged;
        }
      }

      if (setting?.filterby) {
        this.table.filters = setting.filterby;
      }

      if (setting?.sortby) {
        this.table.sortField = setting.sortby.field;
        this.table.sortOrder = setting.sortby.order;
      }

    } catch (error) {
      this.toastSvc.error(this.translateSvc.instant('SHARED_GRID.SAVE_MENU.APPLY_ERROR'));
    } finally {
      await this.spinnerSvc.hide(App.SPINNERS.MAIN_SPINNER);
    }
  }

  setDisplayName(name: string): void {
    this.currentGridName = name ?? this.getSettingName(this.screen);
  }

  save(): void {
     const data: GridSetting = {
      userid:  this.userId,
      id: this.currentGridId,
      isdefault: this.isDefaultSetting,
      settingname: this.currentGridName,
      displaycolumn: {data: this.displaycolumn ?? null},
      sortby: this.sortby === undefined ? null : this.sortby,
      filterby: this.filterby === undefined ? null : this.filterby,
      screen: this.screen === undefined ? null : this.screen,
      daterangefilter: this.dateRangeFilter ?? null
     };

     this.adminSvc.updateSetting(data).subscribe(() => {
      this.toastSvc.success(this.translateSvc.instant('SHARED_GRID.SAVE_MENU.SAVE_MESSAGE'));
    });
  }

  saveAs(): void {
    const config: any = {
      class: 'modal-lg',
      backdrop: true,
      keyboard: false,
      ignoreBackdropClick: true,
      initialState: {
        sortby: this.sortby || null,
        filterby: this.filterby || null,
        displaycolumn: this.displaycolumn || null,
        screen: this.screen,
        userid: this.userId,
        daterangefilter: this.dateRangeFilter ?? null
      }
    };

    this.modalRef = this.modalSvc.show(GridSettingSaveModalComponent, config);
    this.modalRef.content.onClose.subscribe(async result => {
       await this.setView(result);
    });
  }

  async setView(result): Promise<void> {
    if (result) {
      this.setDisplayName(result.name);
      await this.applySavedSettings(result.id, true);
      this.currentGridId = result.id;
      this.stateSvc.screenStates.setTableConfig(this.screen, {name: result.name, gridId: result.id});
    }
  }

  setDefaultSetting(): void {
    this.adminSvc.getAllGridSettings(this.screen, this.userId).subscribe(async data => {
      const record = data.find(x => x.isdefault == true);
      if (record) {
        await this.setView({
          id: record.id,
          name: record.settingname
        }).catch(async (err) => {
          this.toastSvc.error(err.message, '', { disableTimeOut: true });
          await this.createNewDefaultRecord();
        });
      } else {
        await this.createNewDefaultRecord();
      }
    });
  }

  createSetting(displaycolumn): void {
     const data: GridSetting = {
      userid: this.userId,
      isdefault: true,
      settingname: this.getSettingName(this.screen),
      displaycolumn: {data: displaycolumn ?? null},
      sortby: null,
      filterby: null,
      screen: this.screen,
      daterangefilter: this.screen === SettingEnum.Appointment? {
        offSetDays: 0,
        numberOfDays: 0,
      } :null
     };

    this.adminSvc.saveSetting(data).subscribe(async x => {
      await this.setView({
        id: x[0].id,
        name: data.settingname
      });
    });
  }

  private getSettingName(screen: string): string {
    return screen !== SettingEnum.Appointment ?
      this.translateSvc.instant('SHARED_GRID.SAVE_MENU.MY_DEFAULT_VIEW') :
      this.translateSvc.instant('SHARED_GRID.SAVE_MENU.TODAYS_LIST');
  }

  async createNewDefaultRecord(): Promise<void> {
    const config = this.stateSvc.dataMapping[this.screen];
    let colNames: string[];

    switch (this.screen) {
      case SettingEnum.Patient:
        colNames = ['lastname', 'firstname', 'birthdate', 'mrn', 'gender', 'address1'];
        break;
      case SettingEnum.Appointment:
        colNames = ['lastname', 'firstname', 'formstatus', 'servicingfacility', 'location', 'attendingphysicianlastname', 'attendingphysicianfirstname', 'calendarstartdatetime', 'updatedon'];
        break;
      case SettingEnum.Form:
        colNames = ['name', 'category', 'facility', 'tags'];
        break;
      default:
        colNames = [];
        break;
    }
    const columns = config.filter(x => colNames.includes(x.FieldName)).sort((a, b) => this.sortByColumn(a.FieldName, b.FieldName, colNames));
    this.createSetting(columns);
  }

  private sortByColumn(a: string, b: string, columns: string[]) {
    const aIndex = columns.indexOf(a);
    const bIndex = columns.indexOf(b);
    if (aIndex < bIndex) {
      return -1;
    }
    if (aIndex > bIndex) {
      return 1;
    }
    return 0;
  }

  private getRelativeDate(days: number): Date {
    return new Date(new Date().getTime() + (days * (24 * 60 * 60 * 1000)));
  }
}
