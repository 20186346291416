import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  EnterpriseSettings,
  Facility,
  NotificationSettings,
  ScannerProfile,
  SearchByType,
  SearchedAppointments,
  SearchedForms,
  SearchedFunctionDTOs,
  SearchedPatients,
  SavedScreenStates,
  TodaysAppointments,
  Department,
  DataMapping
} from '@next/shared/common';
import {TranslateService} from "@ngx-translate/core";
import {SigWebService} from "@next/shared/next-services";

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  constructor(
    private sigWebSvc: SigWebService,
    private translateSvc: TranslateService
  ) {}

  /**
   * Topaz Device Detected
   * @property usesTopaz - boolean: if true, NOVA detected a topaz device
   */
  usesTopaz: boolean = false;

  /**
   * Patients State
   * @property patients - array of patients retrieved from search
   * @private
   */

  readonly getFacilities$: Subject<void> = new Subject();

  private readonly _selectedFacility = new BehaviorSubject<Facility>({ id: '-1', name: this.translateSvc.instant('ADMINPANEL.FACILITY_SETUP.ALL_FACILITIES'), isactive: true, facilityid: '-1' });
  readonly selectedFacility$ = this._selectedFacility.asObservable();

  private readonly _selectedDepartment = new BehaviorSubject<Department>({ id: '-1', name: this.translateSvc.instant('LANDING_PAGE.ALL_DEPARTMENTS'), shortname: this.translateSvc.instant('LANDING_PAGE.ALL_DEPARTMENTS'), departmentid: '-1' });
  readonly selectedDepartment$ = this._selectedDepartment.asObservable();

  private readonly _gridViewSelectionChange = new Subject<boolean>();
  readonly gridViewSelectionChange$ = this._gridViewSelectionChange.asObservable();


  /**
   * allFacilities
   * - Literally all facilities, even those user does not have access to */
  private readonly _allFacilities = new BehaviorSubject<Facility[]>([]);
  readonly allFacilities$ = this._allFacilities.asObservable();
  get allFacilities(): Facility[] { return this._allFacilities.getValue(); }
  set allFacilities(facilities : Facility[]) {
    this._allFacilities.next(facilities);
  }

  /**
   * allDepartments
   * - Literally all departments, even those user does not have access to */
  private readonly _allDepartments = new BehaviorSubject<Department[]>([]);
  readonly allDepartments$ = this._allDepartments.asObservable();
  get allDepartments(): Department[] { return this._allDepartments.getValue(); }
  set allDepartments(departments: Department[]) {
    this._allDepartments.next(departments);
  }

  /**
   * facilitiesByUserGroup
   * - Facilities a user has access to */
  private readonly _facilitiesByUserGroup = new BehaviorSubject<Facility[]>([]);
  readonly facilitiesByUserGroup$ = this._facilitiesByUserGroup.asObservable();
  get facilitiesByUserGroup(): Facility[] { return this._facilitiesByUserGroup.getValue(); }
  set facilitiesByUserGroup(facilities : Facility[]) {
    this._facilitiesByUserGroup.next(facilities);
  }

  /**
   * departments
   * - Departments user has access to */
  private readonly _departments = new BehaviorSubject<Department[]>([]);
  readonly departments$ = this._departments.asObservable();
  get departments(): Department[] { return this._departments.getValue(); }
  set departments(departments : Department[]) {
    this._departments.next(departments);
  }

  formsGridConfigFirstLoaded = false;
  readonly formsGridConfigFirstLoad$: Subject<void> = new Subject();

  private readonly _screenStates = new BehaviorSubject<SavedScreenStates>(new SavedScreenStates());
  get screenStates(): SavedScreenStates { return this._screenStates.getValue(); }

  get searchByTypeEnum() {
    return SearchByType;
  }

  // Do not set a default search type here.
  // This will be set on tab load based on My Preferences with a fall back to Patient.
  // This is required because we need to know IF this has actually be set or not.
  private readonly _selectedSearchType = new BehaviorSubject<SearchByType>(null);
  get selectedSearchType(): SearchByType {
    return this._selectedSearchType.getValue();
  }

  set selectedSearchType(value: SearchByType) {
    this._selectedSearchType.next(value);
  }

  // Persist the user's search preference id, so we don't create duplicates.
  searchPreferenceId: string;

  private readonly _searchedPatients = new BehaviorSubject<SearchedPatients>(new SearchedPatients());

  set searchedPatients(searchedPatients: SearchedPatients) {
    this._searchedPatients.next(searchedPatients);
  }

  get searchedPatients(): SearchedPatients {
    return this._searchedPatients.getValue();
  }

  private readonly _searchedAppointments = new BehaviorSubject<SearchedAppointments>(new SearchedAppointments());

  set searchedAppointments(searchedAppointments: SearchedAppointments) {
    this._searchedAppointments.next(searchedAppointments);
  }

  get searchedAppointments(): SearchedAppointments {
    return this._searchedAppointments.getValue();
  }

  private readonly _searchedForms = new BehaviorSubject<SearchedForms>(new SearchedForms());
  get searchedForms(): SearchedForms {
    return this._searchedForms.getValue();
  }

  set searchedForms(value: SearchedForms) {
    this._searchedForms.next(value);
  }

  /**
   * Enterprise setting state
   * @property enterpriseSettings - enterprise settings that can be changed within the admin settings
   */
  private readonly _enterpriseSettings = new BehaviorSubject<EnterpriseSettings>({} as EnterpriseSettings);
  readonly enterpriseSettings$ = this._enterpriseSettings.asObservable();


  /**
   * Barcode scanning profiles data
   */
  private readonly _barcodeScanningProfiles = new BehaviorSubject<ScannerProfile[]>({} as ScannerProfile[]);
  public barcodeScanned = new BehaviorSubject(null);

   /**
   * DataMapping
   */
  private readonly _dataMapping = new BehaviorSubject<DataMapping>({} as DataMapping);
  readonly dataMapping$ = this._dataMapping.asObservable();

  /**
   * Notification Setting state
   * @property notificationSetting - notificationSetting settings that can be changed within the notificationSetting
   */
  private readonly _notificationSetting = new BehaviorSubject<NotificationSettings>({} as NotificationSettings);
  readonly notificationSetting$ = this._notificationSetting.asObservable();

  private readonly _todaysAppointments = new BehaviorSubject<TodaysAppointments>(new TodaysAppointments());
  set todaysAppointments(value: TodaysAppointments) {
    this._todaysAppointments.next(value);
  }

  get todaysAppointments(): TodaysAppointments {
    return this._todaysAppointments.getValue();
  }

  /**
   * Functions
   */
  private readonly _searchedFuntionDTOs = new BehaviorSubject<SearchedFunctionDTOs>(new SearchedFunctionDTOs());

  set searchedFunctionDTOs(searchedFunctionDTOs: SearchedFunctionDTOs) {
    this._searchedFuntionDTOs.next(searchedFunctionDTOs);
  }

  get searchedFunctionDTOs(): SearchedFunctionDTOs {
    return this._searchedFuntionDTOs.getValue();
  }

  /**
   * Forms Library State
   * @property selectedExperiences - experiences selected in the form library
   */
  private readonly _selectedExperiences = new BehaviorSubject<any[]>([]);
  readonly selectedExperiences$ = this._selectedExperiences.asObservable();

  private readonly _filteredExperiences = new BehaviorSubject<any[]>([]);

  /**
   * Patient form state
   */
  //TO DO: Create a class containing all UI states, for the moment this list is sufficient.
   private _patientFormOpenedAccordions: string[] = [];

   get patientFormOpenedAccordions(): string[] {
     return this._patientFormOpenedAccordions;
   }

   set patientFormOpenedAccordions(value: string[]) {
     this._patientFormOpenedAccordions = value;
   }

  clearPatientStore() {
    this.searchedPatients.filter = '';
    this.searchedPatients.error = '';
    this.searchedPatients.patients = [];
    this.searchedPatients.selectedPatient$.next(null);

    if (!this.searchedAppointments.dateFilter?.length) {
      this.searchedAppointments.error = '';
      this.searchedAppointments.appointments = [];
    }
  }

  clearFunctionStore() {
    this.searchedFunctionDTOs.filter = '';
    this.searchedFunctionDTOs.functionDTOs = [];
    this.searchedFunctionDTOs.clearSelectedFunctionDTO();
  }

  get selectedExperiences(): any[] {
    return this._selectedExperiences.getValue();
  }

  set selectedExperiences(val: any[]) {
    this._selectedExperiences.next(val);
  }

  get filteredExperiences(): any[] {
    return this._filteredExperiences.getValue();
  }

  set filteredExperiences(val: any[]) {
    this._filteredExperiences.next(val);
  }

  clearExperiencesStore() {
    this.selectedExperiences = [];
  }

  get enterpriseSettings(): EnterpriseSettings {
    return this._enterpriseSettings.getValue();
  }

  set enterpriseSettings(settings: EnterpriseSettings) {
    this._enterpriseSettings.next(settings);
  }

  get notificationSetting(): NotificationSettings {
    return this._notificationSetting.getValue();
  }

  set notificationSetting(settings: NotificationSettings) {
    this._notificationSetting.next(settings);
  }

  get selectedFacility() {
    return this._selectedFacility.getValue();
  }

  set selectedFacility(facility: Facility) {
     if (!facility) {
       const nullFacility: Facility = {
         id: '-1',
         name: this.translateSvc.instant('ADMINPANEL.FACILITY_SETUP.ALL_FACILITIES'),
         isactive: true,
         facilityid: '-1'
       };
       this._selectedFacility.next(nullFacility);
     }
     else {
       // This is the less invasive fix: facilityid: '-1'. In some parts of the code logic is dealing with id and others with facilityid
       if (!facility.facilityid) {
         facility.facilityid = facility.id;
       }
       this._selectedFacility.next(facility);
     }
  }

  get selectedDepartment() {
    return this._selectedDepartment.getValue();
  }

  set selectedDepartment(department: Department) {
     if (!department) {
       const nullDepartment: Department = {
         id: '-1',
         name: this.translateSvc.instant('LANDING_PAGE.ALL_DEPARTMENTS'),
         shortname: this.translateSvc.instant('LANDING_PAGE.ALL_DEPARTMENTS'),
         departmentid: '-1'
       };
       this._selectedDepartment.next(nullDepartment);
     }
     else {
       if (!department.departmentid) {
         department.departmentid = department.id;
       }
       this._selectedDepartment.next(department);
     }
  }

  get barcodeScanningProfiles() {
    return this._barcodeScanningProfiles.getValue();
  }

  set barcodeScanningProfiles(scanningProfiles: ScannerProfile[]) {
    this._barcodeScanningProfiles.next(scanningProfiles);
  }

  
  get gridViewSelectionChange() {
    return this._gridViewSelectionChange.observed
  }

  set gridViewSelectionChange(value: boolean) {
    this._gridViewSelectionChange.next(value);
  }

 
  get dataMapping() {
    return this._dataMapping.getValue();
  }

  set dataMapping(dataMapping: DataMapping) {
    this._dataMapping.next(dataMapping);
  }
  
}
