import {Inject, Injectable} from '@angular/core';
import { Subject } from 'rxjs';
import {ApiService, FormFastConfig, FormFastConfigService, TokenService} from "@next/shared/common";
import {HttpEvent} from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class HttpProgressService extends ApiService {

  constructor(
    @Inject(FormFastConfigService) config: FormFastConfig,
    tokenService: TokenService) {
    super(config, tokenService);
  }

  /** ___progress___
   * *******************
   * - Percent of the request that has been completed */
  progress$: Subject<number> = new Subject<number>();

  /** ___httpEvent___
   * *******************
   * - The HTTPEvent object that was captured by the http-progress interceptor */
  eventSub$: Subject<HttpEvent<unknown>> = new Subject<HttpEvent<unknown>>();

  /** ___completed___
   * *******************
   * - _complete.next()_ is invoked when the Observable<HttpEvent> is complete.
   * - Use this property as the value within _takeUntil()_ in your _HttpClient .pipe()_
   * so progress sub only lives for the life of the current _HttpRequest_.
   * NOTE: this may not be reliable for multiple requests simultaneously, only if they are all combined */
  completed: Subject<null> = new Subject<null>();
}
