import { SubmissionData } from "./submit.model";

/** ToDo: 'Form' is a deceptive name for this interface
 *    - This is actually an interface for retrieving PDF-Templates */
export interface Form {
  id: string;
  name: string;
  description: string;
  url: string;
  status: FormStatus;
  createdon: Date;
}

// Note: this interface could be in the next-clinical module, using it outside
//  that project may introduce circular dependencies
// Note: this interface includes prefill <key value pairs>
//  these KVPs have keys set to form-field names
export interface FormEmbedded extends FormDTO {
  prefill: unknown;
}

export interface FormDTO {
  id?: string;
  name: string;
  experienceid: string;
  experienceversionid: string;
  version: string;
  tags: string;
  pdftemplateid: string;
  updatedon?: Date;
  createdon?: Date;
  historyid: string;
  lastarchivedon?: Date;
  status: string;
  patientdataid: string;
  patientdata: any;
  appointmentid: string;
  appointment: any;
  mrn?: string;
  firstname?: string;
  lastname?: string;
  servicingfacility?: string;
  datasets: any[];
  datasetcount: number;
  statusorder?: number;
  tomovelater: boolean;
  accountnumber?: string;
  data?: any;
}

export interface TaskDTO {
  id?: string;
  name: string;
  formid: string;
  assignto: string;
  experienceid: string;
  experienceversionid: string;
  issuedon: string;
  completedon: string;
  status: TaskStatus;
  type: string;
  patientfirstname?: string
  patientlastname?: string;
  patientmiddlename?: string;
}

export interface UploadFileResponse {
  id: string,
  name: string,
  description: string,
  createdOn: Date,
  config: unknown,
  url: string,
  imageUrl: string
}

export enum FormType {
  Patient = 'patient',
  Appointment = 'appointment'
}

export enum SearchType {
  Patient = 'patient',
  Patient_Fullscreen = "patient_fullscreen",
  Appointment = 'appointment',
  Appointment_Fullscreen = "appointment_fullscreen",
  Dashboard = 'dashboard',
  Forms = 'forms'
}

export enum SearchByType {
  Patient = 'name',
  MRN = 'mrn',
  DOB = 'dob',
  AccountNumber = 'accountnumber',
  Barcode = 'barcode'
}

export enum TaskStatus {
  NotStarted = 'not_started',
  Incomplete = 'incomplete',
  Complete = 'complete'
}

export enum FormStatus {
  NotStarted = 'not_started',
  InProgress = 'in_progress',
  Completed  = 'complete',
  PatientSign  = 'patient_sign',
  StaffSign  = 'staff_sign',
  OtherSign  = 'other_sign'
}

export enum FormStatusSortOrder {
  NOT_STARTED = 1,
  IN_PROGRESS = 2,
  PATIENT_SIGN = 3,
  STAFF_SIGN = 4,
  OTHER_SIGN = 5,
  COMPLETE = 6
}

export enum PatientFormStateDisplay {
  not_started = 'Not Started',
  incomplete = 'Incomplete',
  complete = 'Complete',
  NOT_STARTED = 'Not Started',
  COMPLETE = 'Complete',
  PATIENT_SIGN = 'Patient to Sign',
  STAFF_SIGN = 'Staff to Sign',
  OTHER_SIGN = 'Other to Sign',
  IN_PROGRESS= 'In Progress',
  JUST_ADDED = "Just Added"
}

export interface Annotation {
  alignment: string;
  id: string;
  name: string;
  groupName: string;
  fieldLocking: any;
  fieldType: FieldType;
  readOnly: boolean;
  required: boolean;
  viewable: boolean;
  value: string;
  label: string;
  maxLength: string;
  multiLine: boolean;
  coordinates: any;
  textFormat: any;
  tooltip: string;
  multiSelect: boolean;
  editable: boolean;
  selectOptions: SelectOption[];
  groupMembers: Annotation[];
}

export enum FieldType {
  TextBox = 'TEXTBOX',
  CheckBox = 'CHECKBOX',
  DropDown = 'DROPDOWN',
  Radio = 'RADIO',
  Button = 'BUTTON',
  Hyperlink = 'HYPERLINK',
  Group = 'GROUP',
  Signature = 'SIGNATURE',
  DynamicArrayDropDown = 'DYNMAICARRAYDROPDOWN',
  DynamicArrayCheckBox = 'DYNAMICARRAYCHECKBOX',
  DynamicArrayRadio = 'DYNAMICARRAYRADIO',
  DynamicArrayToggle = 'DYNAMICARRAYTOGGLE',
}

export interface SelectOption {
  id: string;
  text: string;
  value: string;
  selected: boolean;
}

export enum CheckBoxValue {
  True = 'Checked',
  False = 'Unchecked'
}

export interface CollectionDTO {
  id: string;
  name: string;
  parent: string;
  type: CollectionType;
  expanded: boolean;
  tags: string;
}

export interface CollectionJunctionDTO {
  collection: string;
  element: string;
}

export enum CollectionType {
  Folder = 'Folder',
  Packet = 'Packet',
  Form = 'Form'
}

export enum MenuAction {
  Print = 'PRINT',
  FillOut = 'FILL OUT',
  Add = 'ADD FORM'
}

export type FormGenerateDTO = {
  status: typeof FormStatus | string;
  formid: string;
  pdftemplateid: string;
  submissionData: SubmissionData;
}