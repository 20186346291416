import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { FormFastConfig, FormFastConfigService, TokenService, tokenErrors } from '@next/shared/common';
import { catchError, first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NextAuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(@Inject(FormFastConfigService) private config: FormFastConfig, private tokenSvc: TokenService, private authService: NextAuthService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      const token = this.getAccessToken();

      if (token) {
        request = request.clone({
          setHeaders: {
            'x-access-token': token
          }
        });
      }

    } catch (err) {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      if (token) {
        request = request.clone({
          setHeaders: {
            'x-access-token': token
          }
        });
      }
    }
    return next.handle(request).pipe(
      catchError(err => this.handleAuthError(err))
    );
  }

  getAccessToken(): string {
    return this.tokenSvc.getAccessToken();
  }

  private handleAuthError(error: HttpErrorResponse): Observable<any> {
    switch (error.status) {
      case 403: {
        this.tokenSvc.clear(); // Wipe the token before we redirect
        this.router.navigateByUrl('/error');
        break;
      }
      case 401: {
        // TODO: Refactor this logic.  It mirrors auth.guard.ts::redirect(...)
        const params: URLSearchParams = new URLSearchParams(window.location.search);
        
        this.tokenSvc.clear();

        // If we have an OAuth error or an http Auth error from system that is not an expired_token error then redirect to error page
        // Otherwise, redirect to the authorization endpoint
        if (error?.error !== tokenErrors.expired_token) {
          this.router.navigateByUrl('/error');
          return;
        }
        
        this.authService.authorizationEndpoint(this.authService.connectionParams(params))
        .pipe(first())
        .subscribe(({ authorization_endpoint }) => {
          window.location.href = authorization_endpoint;
        });
        break;
      }
    }
    return throwError(() => error);
  }
}
