import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ApiService,
  FormFastConfig,
  FormFastConfigService,
  TokenService,
  ActivityHistory,
  EventType,
  PostActivityHistory
} from '@next/shared/common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ActivityLogService extends ApiService {
  constructor(@Inject(FormFastConfigService) config: FormFastConfig, tokenSvc: TokenService, private http: HttpClient) {
    super(config, tokenSvc);
  }
  getActivityHistoryByPatientId(
    patientId: string,
    appointmentId?: string,
  ): Observable<{items: ActivityHistory[], totalItems: number}> {
    const queryParams = new URLSearchParams();

    if (appointmentId) queryParams.append('appointmentId', appointmentId);
  
    const url = `${this.config.apiUrl}activitylog/${patientId}${queryParams ? `?${queryParams?.toString()}` : ''}`;
  
    return this.protectedEndpoint(this.http.get<{items: ActivityHistory[], totalItems: number}>(url, this.getHeaders())
    );
  }

  createActivityHistory(eventType: EventType, activityData: ActivityHistory) {
    const url = `${this.config.apiUrl}activitylog`;
    const body: PostActivityHistory = {
      eventType,
      activityData
    };
    return this.protectedEndpoint(this.http.post<PostActivityHistory>(url, body, this.getHeaders()));
  }
}
