import { Component, HostListener, Input, OnInit } from '@angular/core';
import { DisplayColumn } from '@next/shared/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { StoreService } from '../state/store.service';

@Component({
  selector: 'next-grid-column-modal',
  templateUrl: './grid-column-modal.component.html',
  styleUrls: ['./grid-column-modal.component.scss']
})
export class GridColumnModalComponent implements OnInit {
  sourceData: any[] = [];
  targetData: any[] = [];
  allColumns: any[] = [];
  onClose: Subject<any> = new Subject();

  @Input() displaycolumn: DisplayColumn[];
  @Input() screen: string;

  @HostListener('document:keyup.enter', ['$event'])
  onEnterPressed = () => this.save();

  @HostListener('document:keyup.escape', ['$event'])
  onEscapePressed = () => this.close();

  constructor(
    public stateSvc: StoreService,
    public modalRef: BsModalRef
  ) {  }

  ngOnInit() {
    this.getData();
  }

  close() {
    this.onClose.next(null);
    this.modalRef.hide();
  }

  save() {
    this.onClose.next(this.targetData);
    this.modalRef.hide();
  }

  async getData() {
    this.allColumns = this.stateSvc.dataMapping[this.screen];
    const idsToDelete = this.displaycolumn.map(function(elt) {return elt.id;});
    this.sourceData = this.allColumns.filter(function(elt) {return idsToDelete.indexOf(elt.id) === -1;}).sort((a, b) => b.SettingDisplayName > a.SettingDisplayName ? -1 : 1);
    idsToDelete.forEach(cv=> this.targetData.push(this.allColumns.find(elt => elt.id === cv)));
  }
}
