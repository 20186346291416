import { Directive, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { NgControl } from "@angular/forms";
import { pairwise, startWith, take } from "rxjs/operators";

@Directive({
  selector: '[nextFieldValidation]'
})
export class FormFieldValidationDirective implements OnInit {
  @HostBinding('class.field-invalid-glow') classInvalidGlow;
  @HostBinding('class.field-invalid') classInvalid;
  @HostBinding('class.field-valid') classValid;
  @Input() formSubmitted = false;
  @Input() hideValidationOnInit = false;

  constructor(private ngControl: NgControl) {
  }

  ngOnInit(): void {
    if (!this.hideValidationOnInit && !this.ngControl.control.value) {
      this.classInvalidGlow = true;
      this.setupValueChangeListener();
    }
  }

  setupValueChangeListener() {
    this.ngControl.control.valueChanges
      .pipe(startWith(null as string), pairwise(), take(1))
      .subscribe(() => {
        this.classInvalidGlow = false;
        this.classInvalid = this.ngControl.control.errors !== null;
      });
  }

  @HostListener('blur')
  onBlur() {
    const formField = this.ngControl.control;
    if (formField.errors && formField.touched) {
      this.classInvalid = true;
      this.classValid = false;
    }
    if (!formField.errors && formField.touched) {
      this.classInvalid = false;
      this.classValid = true;
    }
  }

  @HostListener('input', [ '$event.target' ])
  onInput() {
    const formField = this.ngControl.control;
    if (formField.errors) {
      this.classInvalid = true;
      this.classValid = false;
    }
    if (!formField.errors) {
      this.classInvalid = false;
      this.classValid = true;
    }
  }
}
