export interface PermissionSet {
  id: string;
  name: string;
  description: string;
  editable: boolean;
}
export interface Permission {
  id: IhPermissions;
  name: string;
  description: string;
  category: string;
  scope: string | null;
}
export interface PermissionToPermissionSet {
  permisionid: string;
  permissionsetid: string;
}

export enum PermissionCategory {
  uiRoles = 'ui',
  apiRoles = 'api',
}

export enum IhPermissions {
  STANDARD_USER = 'STANDARD_USER',
  DESIGNER = 'DESIGNER',
  DESIGNER_PUBLISH = 'DESIGNER_PUBLISH',
  ADMINISTRATOR = 'ADMINISTRATOR',
  SYSTEM_ADMINISTRATOR = 'SYSTEM_ADMINISTRATOR',
  PATIENT = 'PATIENT',
  REPORTING = 'REPORTING',
  FLAGGING = 'FLAGGING'
}
