import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormEmbedded,
  GuidedExperienceDTO,
  GxProcessing,
  ViewerIntegrationMode,
} from '@next/shared/common';
import {
  NextExperienceService,
  NextFormService,
  NextSubmissionService,
} from '@next/shared/next-services';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'next-form-view',
  templateUrl: './form-view.component.html',
  styleUrls: ['./form-view.component.css']
})
export class FormViewComponent implements OnInit, OnDestroy {
  @ViewChild('iframe') iframe: ElementRef;

  @Input() embeddedForm: FormEmbedded;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  experience: GuidedExperienceDTO;
  title: string = '';
  formData: any;
  logoUri: any;

  constructor (
    private formSvc: NextFormService,
    private experienceSvc: NextExperienceService,
    private gxProcessing: GxProcessing,
    private submissionSvc: NextSubmissionService
  ) { }

  async ngOnInit(): Promise<void> {
    (<any>window).integration = ViewerIntegrationMode.CLINICAL;
    this.title = this.embeddedForm.name;
    const templateId: string = this.embeddedForm.pdftemplateid;
    /** ToDo: prefill/calculations are not working in this component.
     *    - There are multiple ways to solve,
     *      1. If we had a form-history, we could just call the /form/<fid>/download
     *        - This API calls the filler svc, generating a flattened PDF, and returns it.
     *        - Prefill and Calculations would be already performed for this approach to work (its already flattened).
     *        - Having a form-history means that prefill and calculations would already been executed.
     *      2. Pass the prefill object into this component, and run the viewer preprocessing
     *        - Use your already acquired prefill object and feed it in.  This is 'side-loading.
     *        - This approach has less risk, but is less performant, and redundant
     *        - The advantage to this approach is the PDF is not flattened, so it can be electronically annotated */
    // this.formSvc.getPhiFormFile(this.target.id).subscribe(); // Get flattened PDF solution
    forkJoin([this.formSvc.getPdf(templateId), this.submissionSvc.getSubmission(this.embeddedForm.id)]).subscribe({
      next: async (responses) => {
        const
          eid = this.embeddedForm.experienceid || (this.embeddedForm as any).id,
          vid = this.embeddedForm.experienceversionid || (this.embeddedForm as any).vid;
        this.experience = vid
          ? await this.experienceSvc.getExperienceVersion(eid, vid).toPromise()
          : await this.experienceSvc.getExperience(eid).toPromise();
        const prefill = this.embeddedForm.prefill;
        const form = responses[0];
        this.gxProcessing.preProcessing(this.experience, responses[1], prefill || '', this.logoUri)
          .then((res) => {
            this.formData = res.data;
            (<any>window).loadGXData = this.loadGXData.bind(this);
            (<any>window).loadAnnotationState = this.loadAnnotationData.bind(this);
            this.iframe.nativeElement.src = `assets/pdfjs/web/viewer.html?file=${encodeURIComponent(form.url)}`;
          }).catch(err => console.dir(err));
      },
      error: (err) => {
        console.dir(err);
      }
    });
  }

  ngOnDestroy(): void {
    (<any>window).integration = undefined;
    (<any>window).loadGXData = undefined;
    (<any>window).loadAnnotationState = undefined;
  }

  loadGXData() {
    return this.gxProcessing.getAllFields(this.experience);
  }

  loadAnnotationData() {
    return this.formData;
  }
}
