import {Component, OnDestroy, OnInit} from '@angular/core';
import {Appointment, Patient, PreferenceSubmission, SearchByType} from '@next/shared/common';
import {NextAdminService, NextClinicalService, UserResolverService} from '@next/shared/next-services';
import {TranslateService} from '@ngx-translate/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from "ngx-toastr";
import {BarcodeScannerComponent, FacilityDepartmentFilteringService, PreferenceType, StateViewerService, StoreService} from '../..';
import {Subject, takeUntil} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";

@Component({
  selector: 'next-patient-search',
  templateUrl: './patient-search.component.html',
  styleUrls: ['./patient-search.component.css']
})
export class PatientSearchComponent implements OnInit, OnDestroy {

  constructor(
    private fb: UntypedFormBuilder,
    public stateSvc: StoreService,
    private translateSvc: TranslateService,
    private nextClinicalSvc: NextClinicalService,
    private toastSvc: ToastrService,
    private modalSvc: BsModalService,
    private adminSvc: NextAdminService,
    private userSvc: UserResolverService,
    public facilityDepartmentFilteringSvc: FacilityDepartmentFilteringService,
    private stateViewerSvc: StateViewerService) { }

  modalRef: BsModalRef;
  observerCleanup$: Subject<void> = new Subject();
  form: UntypedFormGroup = this.fb.group({ searchInput: [''], searchType: [''] });

  get searchType(): SearchByType { return this.form.get('searchType').value; }

  get searchByTypeEnum(): typeof SearchByType { return SearchByType; }

  get searchPlaceHolder(): string {
    switch (this.stateSvc.selectedSearchType) {
      case SearchByType.AccountNumber:
        return this.translateSvc.instant('PATIENT_SEARCH.ACCOUNT_PLACEHOLDER');
      case SearchByType.DOB:
        return this.translateSvc.instant('PATIENT_SEARCH.DOB_PLACEHOLDER');
      case SearchByType.MRN:
        return this.translateSvc.instant('PATIENT_SEARCH.MRN_PLACEHOLDER');
      case SearchByType.Patient:
        return this.translateSvc.instant('PATIENT_SEARCH.NAME_PLACEHOLDER');
      default:
        return this.translateSvc.instant('PATIENT_SEARCH.NAME_PLACEHOLDER');
    }
  }

  ngOnInit(): void {
    // If we already have a search preference set, skip loading.
    if (this.stateSvc.selectedSearchType) {
      this.initForm();
      return;
    }

    this.adminSvc.getPreference(this.userSvc.user.oid, PreferenceType.SearchByType).subscribe({
      next: (preferences: PreferenceSubmission[]) => {

        this.stateSvc.searchPreferenceId = preferences?.length > 0 ? preferences[0].id : '';

        this.stateSvc.selectedSearchType = preferences?.length > 0 && preferences[0].data?.searchByType
          ? preferences[0].data?.searchByType
          : SearchByType.Patient;

        this.initForm();
      }
    });
  }

  ngOnDestroy(): void {
    this.observerCleanup$.next();
    this.observerCleanup$.complete();
  }

  private initForm(): void {

    let searchType = this.stateSvc.selectedSearchType ?? SearchByType.Patient;

    // If search type is barcode, but we do not have any scanning profiles, default back to patient name search.
    if (searchType === SearchByType.Barcode && !this.stateSvc.barcodeScanningProfiles.length) {
      searchType = SearchByType.Patient;
    }

    this.form.patchValue({
      searchInput: this.stateSvc.searchedPatients?.filter ?? '',
      searchType: searchType
    });

    this.form.controls['searchType'].valueChanges.pipe(
      takeUntil(this.observerCleanup$)
    ).subscribe({
      next: (searchType: SearchByType) => this.onSearchTypeChanged(searchType)
    });

    this.form.controls['searchInput'].valueChanges.pipe(
      takeUntil(this.observerCleanup$)
    ).subscribe({
      next: (searchInput: string) => this.onSearchInputChanged(searchInput)
    });

    if (this.stateSvc.searchedPatients.filter) {
      this.facilityDepartmentFilteringSvc.search(this.searchType);
    }
  }

  showScanner() {
    this.modalRef = this.modalSvc.show(BarcodeScannerComponent, {
      class: 'modal-barcode-scanner',
      animated: false
    });

    this.stateSvc.searchedPatients.error = '';
    this.stateSvc.searchedAppointments.error = '';

    this.modalRef.content.scanResult.subscribe(scannedData => {
      this.modalRef.hide();
      this.stateSvc.searchedPatients.unSelectAllPatients();
      this.stateSvc.searchedAppointments.unselectAllAppointments();
      this.stateSvc.searchedAppointments.dateFilter = [];
      this.stateSvc.searchedAppointments.error = '';
      this.stateSvc.searchedAppointments.isAutoSearch = false;
      this.stateSvc.searchedAppointments.isNewSearch = true;


      // search patients by barcode
      this.nextClinicalSvc.getPatientsByBarcode(
        scannedData.profileId,
        scannedData.scannedCode,
        this.stateSvc.selectedFacility.facilityid,
        this.stateSvc.selectedDepartment.departmentid,
        this.stateViewerSvc.autoRefreshSettings?.lastRefreshedAt ?? 0
      ).subscribe({
        next: (patients: Patient[]) => {
          this.stateSvc.searchedPatients.patients = patients || [];
          if (!this.stateSvc.searchedPatients.patients.length) {
            this.stateSvc.searchedPatients.error = this.translateSvc.instant('PATIENT_SEARCH.BARCODE_SEARCH_NOT_FOUND');
          }
        },
        error: (e) => {
          if (e.status == 404) {
            this.stateSvc.searchedPatients.error = this.translateSvc.instant('PATIENT_SEARCH.BARCODE_SEARCH_NOT_FOUND');
          } else if (e.status === 400) {
            this.stateSvc.searchedPatients.error = this.translateSvc.instant('PATIENT_SEARCH.BARCODE_SEARCH_PARSE_ERROR');
          } else {
            this.toastSvc.error(e.message, '', { disableTimeOut: true });
          }
        }
      })

      this.nextClinicalSvc.getAppointmentsByBarcode(
        scannedData.profileId,
        scannedData.scannedCode,
        this.stateSvc.selectedFacility.facilityid,
        this.stateSvc.selectedDepartment.departmentid
      ).subscribe({
        next: (appointments: Appointment[]) => {
          this.stateSvc.searchedAppointments.appointments = appointments || [];
          if (!this.stateSvc.searchedAppointments.appointments.length) {
            this.stateSvc.searchedAppointments.error = this.translateSvc.instant('PATIENT_SEARCH.BARCODE_SEARCH_NOT_FOUND');
          }
        },
        error: (e) => {
          if (e.status == 404) {
            this.stateSvc.searchedAppointments.error = this.translateSvc.instant('PATIENT_SEARCH.BARCODE_SEARCH_NOT_FOUND');
          }
        }
      });

      this.stateSvc.barcodeScanned.next(null);
    });
  }

  resetSearchInput(): void {
    this.form.patchValue({ searchInput: '' });
  }

  private onSearchInputChanged(input: string): void {
    if (!input?.length) {
      this.stateSvc.clearPatientStore();
    }
    this.stateSvc.searchedPatients.filter = input;
  }

  private onSearchTypeChanged(searchType: SearchByType): void {
    if (!searchType) return;

    this.stateSvc.selectedSearchType = searchType;
    this.resetSearchInput();

    const searchPreference = {
      id: this.stateSvc.searchPreferenceId,
      userid: this.userSvc.user.oid,
      type: PreferenceType.SearchByType,
      data: { searchByType: searchType }
    };

    // Upsert user's search preference
    this.adminSvc.savePreference([searchPreference]).subscribe({
      next: (preferences: PreferenceSubmission[]) => {
        if (!preferences?.length) return;
        this.stateSvc.searchPreferenceId = preferences[0].id;
      }
    });
  }
}
