import { TagFilter } from "./tag.model";
import { Subject } from "rxjs";

export class SearchedForms {
  private _nameFilter: string;
  get nameFilter(): string { return this._nameFilter; }
  set nameFilter(value: string) { this._nameFilter = value; }

  private _tagFilter: TagFilter = { activeTags: [], experienceIds: new Set<string>() };
  get tagFilter(): TagFilter { return this._tagFilter; }
  set tagFilter(value: TagFilter) { this._tagFilter = value; }

  private _favoritesFilter: boolean;
  get favoritesFilter(): boolean { return this._favoritesFilter; }
  set favoritesFilter(value: boolean) { this._favoritesFilter = value; }

  private _criteria: any[] = [];
  get criteria(): any[] { return this._criteria; }
  set criteria(value: any[]) { this._criteria = value; }

  // Emits after forms have been deleted.
  readonly formsDeleted$: Subject<void> = new Subject();

  readonly flaggedFormsMovedFromPatientRecord$: Subject<void> = new Subject();
  readonly flaggedFormsMovedFromAppointments$: Subject<void> = new Subject();
}
