export enum ViewerIntegrationMode {
  RAW = '',
  CLINICAL = 'clinical',
  EMBEDDED = 'clinical_embedded',
  MULTI_MONITOR = 'multi_monitor'
}

export enum ViewerFieldType {
  TEXTBOX = 'textboxField',
  BARCODE = 'barcodeField',
  CHECKBOX = 'checkboxField',
  DROPDOWN = 'dropDownField',
  RADIOGROUP = 'radioField',
  FIELDDIVIDER = 'fieldDivider',
  FIELDSPACER = 'fieldSpacer',
  LINK = 'hyperlinkField',
  HEADER = 'fieldHeader',
  TEXTLABEL = 'labelField',
  WRITTENSIG = 'writtenSignatureField',
  POPUP = 'buttonField',
  SECTION = 'sectionField',
  PHOTO = 'pictureField',
  ANNOTATION = 'annotationField',
  RICHTEXT = 'richTextField',
  PAYMENTGATEWAY = 'paymentGateway',
  COLUMNLAYOUT = 'columnLayout',
  DYNAMICARRAYFIELD = 'dynamicArrayField'
}

export enum TextboxInputType {
  ALPHA = 'alpha',
  NUM = 'numeric',
  ALPHANUM = 'alphanumeric',
  DATE = 'date',
  TIME = 'time',
  DATETIME = 'date-time',
  CURRENCY = 'currency',
  PHONE = 'phone',
  EMAIL = 'email',
  POSTALCODE = 'postalcode',
  SOCIAL = 'ssn',
  CREDITCARDDIGITS = 'ccdigits',
  REGULAREXPRESSION = 'regularexpression',
  SHORTDATE2 = 'shortdate2',
  SHORTDATE4 = 'shortdate4'
}

export enum SignatureType {
  DrawnSignature = "drawnSignature",
  TypedSignature = "typedSignature",
  Initials = "initialsSignature",
  Signature = "signature"
}

export enum SignatureFor {
  Patient = "patient",
  Staff = "staff",
  Other = "other"
}

export enum SignatureForSource {
  None = '',
  Appointment = 'appointment',
  Patient = 'patient',
  UserGroup = 'usergroup'
}

export enum DisplayStatus {
  OPENED = 'open',
  CLOSED = 'closed'
}

export enum DynamicFieldArrayType {
  Checkbox = 'checkbox',
  Toggle = 'toggle',
  Dropdown = 'dropdown',
  Radio = 'radio'
}

export enum SignatureTimeStampLocation {
  TextField = 'textfield',
  InlineRight = 'inlineright',
  BottomRight = 'bottomright'
}

export enum DefaultDisplayMode {
  PDF = 'pdf',
  WEB = 'web',
}
