import { Component, Input, OnInit } from '@angular/core';
import {
  Appointment,
  FormDTO,
  FormEmbedded,
  FormStatus,
  FormType,
  Patient,
  PatientFormStateDisplay
} from "@next/shared/common";
import { StateViewerService } from "../state/state-viewer.service";
import { StoreService } from "../state/store.service";
import { TranslateService } from "@ngx-translate/core";
import { MenuItem } from "primeng/api";
import { ArchiveService, NextFormService, UserResolverService } from "@next/shared/next-services";
import { Router } from "@angular/router";
import { FormsUtilityService } from "../services/forms-utility.service";
import { ToastrService } from "ngx-toastr";
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from "rxjs";
import { App } from "@next/shared/ui";
import { NgxSpinnerService } from "ngx-spinner";
import { first } from 'rxjs/operators';

@Component({
  selector: 'next-forms-table-inline',
  templateUrl: './forms-table-inline.component.html',
  styleUrls: ['../forms-table/forms-table.component.scss']})
export class FormsTableInlineComponent implements OnInit {

  @Input() fromMoveFormsModal: boolean = false;
  @Input() openedFrom: FormType;
  @Input() appointment: Appointment;
  @Input() forms: FormDTO[];
  @Input() patient: Patient;
  columnHeaders: any[] = [];
  menuItems: MenuItem[] = [];
  shareMessage: string;
  archiveLabel: string;
  viewLabel: string;
  selectedForm: FormDTO;
  selectedForms: FormDTO[] = [];

  constructor(
    protected archiveSvc: ArchiveService,
    protected formSvc: NextFormService,
    protected formsUtilSvc: FormsUtilityService,
    protected router: Router,
    public stateViewerSvc: StateViewerService,
    protected storeSvc: StoreService,
    protected toastSvc: ToastrService,
    protected translateSvc: TranslateService,
    protected userSvc: UserResolverService,
    protected spinnerSvc: NgxSpinnerService) { }

  ngOnInit(): void {
    this.initFormsTable();
  }

  protected initFormsTable(): void {
    this.translateSvc.get([
      'PATIENT_FORMS.COLUMN_HEADERS',
      'PATIENT_FORMS.TABLE_MENU',
      'PATIENT_FORMS.SELECT_APPOINTMENT.MESSAGE'
    ], { appointment: this.storeSvc.enterpriseSettings.appointments }).pipe(
      first()).subscribe(configValues => {
      this.initColumnHeaders(configValues['PATIENT_FORMS.COLUMN_HEADERS']);
      this.initRowMenu(configValues['PATIENT_FORMS.TABLE_MENU']);
      this.shareMessage = configValues['PATIENT_FORMS.SELECT_APPOINTMENT.MESSAGE'];
    });
  }

  protected initColumnHeaders(columnHeadersConfig: any): void {
    this.columnHeaders = [
      { field: "name", header: columnHeadersConfig.NAME },
      { field: "status", header: columnHeadersConfig.STATUS },
      { field: "statusorder", header: columnHeadersConfig.STATUS },
      { field: "lastarchivedon", header: columnHeadersConfig.ARCHIVED },
      { field: "updatedon", header: columnHeadersConfig.MODIFIED },
      { field: "tags", header: columnHeadersConfig.TAG }
    ];
  }

  protected initRowMenu(menuConfig: any): void {
    this.archiveLabel = menuConfig.ARCHIVE;
    this.viewLabel = menuConfig.VIEW;

    if (this.userSvc.hasDemonstrationLicense) {
      this.menuItems.push({label: menuConfig.TO_TABLET_VIEW, command: () => this.commandTransferTablet()});
    }
    this.menuItems.push({label: menuConfig.VIEW, command: () => this.commandView()});
    this.menuItems.push({label: menuConfig.DELETE, command: () => this.commandDelete()});
    this.menuItems.push({label: menuConfig.SIGN, command: () => this.commandSign()});
    this.menuItems.push({label: menuConfig.PRINT_FORM, command: () => this.commandPrint()});
    if (this.userSvc.hasDemonstrationLicense) {
      this.menuItems.push({label: menuConfig.EMAIL_PATIENT, command: () => this.commandEmail()});
    }

    this.menuItems.push({label: this.archiveLabel, command: () => this.commandArchive()});
  }

  formatStatus(statusString: string): string {
    return PatientFormStateDisplay[statusString.toUpperCase()];
  }

  selectionChanged(forms: FormDTO[]): void {
    // PrimeNG table row change CB (only executes on checkbox click)
    forms.forEach(f => {
      f.appointment = this.appointment;
      f.patientdata = this.patient;
    });
    this.selectedForms = forms;
    this.updateStateServiceSelectedForms();
  }

  rowMenuClicked(form: FormEmbedded): void {
    // PrimeNG table row context ellipsis menu clicked CB
    form.appointment = this.appointment;
    form.patientdata = this.patient;
    if (!this.selectedForms.some(f => f.id === form.id)) {
      this.selectedForms = [form];
      this.updateStateServiceSelectedForms();
    }

    this.selectedForm = form;
    this.menuItems.find(x => x.label === this.archiveLabel).disabled = form.status.toLowerCase() === FormStatus.NotStarted;
  }

  rowClicked(form: FormEmbedded): void {
    // PrimeNG table row click row CB
    form.appointment = this.appointment;
    form.patientdata = this.patient;
    this.selectedForms = [form];
    this.updateStateServiceSelectedForms();
  }

  protected updateStateServiceSelectedForms(): void {
    if (this.fromMoveFormsModal) {
      let updatedForms = this.stateViewerSvc.selectedFormsFromMoveFormsModal;
      updatedForms = updatedForms.filter(f => !this.forms.some(s => s.id === f.id));
      updatedForms.push(...this.selectedForms);
      this.stateViewerSvc.selectedFormsFromMoveFormsModal = updatedForms;
    } else {
      const updatedForms = this.stateViewerSvc.selectedForms;
      updatedForms.removeMultiple(this.forms);
      updatedForms.AddMultiple(this.selectedForms);
      this.stateViewerSvc.selectedForms = updatedForms;
    }
  }

  // Row Menu Commands
  commandTransferTablet(): void {
    console.log("Transfer Tablet");
  }

  async commandView(): Promise<void> {
    try {
      await this.formsUtilSvc.viewFormAsync(this.selectedForm);
    } catch (e) {
      this.toastSvc.error(this.translateSvc.instant('TOASTR_MESSAGE.VIEW_FORM_ERROR'),'',{disableTimeOut: true});
    }
  }

  commandDelete(): void {
    this.formsUtilSvc.showDeleteFormsModal({
      onConfirm: async () => {
        try {
          await this.spinnerSvc.show(App.SPINNERS.MAIN_SPINNER);
          await this.formSvc.removeFormsFromModel(this.selectedForms).toPromise();
          const updatedForms = this.stateViewerSvc.selectedForms;
          updatedForms.removeMultiple(this.selectedForms);
          this.stateViewerSvc.selectedForms = updatedForms;
          this.selectedForms = [];
          this.storeSvc.searchedForms.formsDeleted$.next();
          await this.spinnerSvc.hide(App.SPINNERS.MAIN_SPINNER);
          this.toastSvc.success(this.translateSvc.instant('TOASTR_MESSAGE.DELETE_FORM_SUCCESS'));
        } catch (e) {
          await this.spinnerSvc.hide(App.SPINNERS.MAIN_SPINNER);
          this.toastSvc.error(this.translateSvc.instant('TOASTR_MESSAGE.DELETE_FORM_ERROR'),'',{disableTimeOut: true});
        }
      }
    });
  }

  async commandSign(): Promise<void> {
    await this.formsUtilSvc.fillOutFormsAsync();
  }

  async commandPrint(): Promise<void> {
    try {
      await this.formsUtilSvc.printFormsAsync(this.selectedForms);
    } catch (e) {
      this.toastSvc.error(this.translateSvc.instant('TOASTR_MESSAGE.PRINT_FORM_ERROR'),'',{disableTimeOut: true});
    }
  }

  commandEmail(): void {
    console.log("Email");
  }

  commandArchive() : void {
    this.spinnerSvc.show(App.SPINNERS.MAIN_SPINNER);
    const archivalObservables: Array<Observable<unknown>> = [];
    for (const form of this.stateViewerSvc.selectedForms.forms) {
      const http$: Observable<unknown> = this.archiveSvc.archive(form.id);
      archivalObservables.push(http$);
    }

    forkJoin(archivalObservables).subscribe({
      next: () => {
        this.spinnerSvc.hide(App.SPINNERS.MAIN_SPINNER);
        this.toastSvc.success(this.translateSvc.instant('TOASTR_MESSAGE.ARCHIVE_SUCCESS'));
      },
      error: () => {
        this.spinnerSvc.hide(App.SPINNERS.MAIN_SPINNER);
        this.toastSvc.error(this.translateSvc.instant('TOASTR_MESSAGE.ARCHIVE_ERROR'),'',{disableTimeOut: true});
      }
    });
  }
}
