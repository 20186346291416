<div class="container-fluid clinical-viewer-container ml-0 pt-2">
  <ng-template nextGenerateComponent></ng-template>
  <ng-container [ngSwitch]="(viewerMain?.instance?.viewMode$ | async)">
    <next-clinical-footer-pdf
      *ngSwitchCase="'pdf'"
      [viewerComponent]="viewerMain"
      (nextFormEvent)="nextForm()">
    </next-clinical-footer-pdf>
    <next-clinical-footer-web
      *ngSwitchCase="'web'"
      [viewerComponent]="viewerMain"
      (nextFormEvent)="nextFormWeb()">
    </next-clinical-footer-web>
  </ng-container>
</div>

<!--------------------------------------
-- MISSING CONTENT NOTIFICATION MODAL --
--------------------------------------->
<ng-template>
  <div class='p-2 text-center'>
    <h4 class='font-weight-bold pt-2'>{{ "FORM_VIEWER.MISSING_CONTENT.TITLE" | translate }}</h4>
    <div class='pt-3'>
      {{ "FORM_VIEWER.MISSING_CONTENT.CONTENT" | translate }}
    </div>
    <div class='pt-3'>
      <div class='float-left pl-5'>
        <button role='button' type='submit' id='missing-content-prompt-confirm' class='btn-ih px-1' (click)="missingContentModalResult(true)">
          {{ "FORM_VIEWER.MISSING_CONTENT.YES" | translate }}
        </button>
      </div>
      <div class='float-right pr-5'>
        <button role='button' type='reset' id='missing-content-prompt-cancel' class='btn-ih px-1' (click)="missingContentModalResult(false)">
          {{ "FORM_VIEWER.MISSING_CONTENT.NO" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-----------------------------------
-- LOCKED FIELD NOTIFICATION MODAL --
------------------------------------>
<ng-template #lockedFieldsPrompt>
  <div class='p-2 text-center'>
    <h4 class='font-weight-bold pt-2'>{{ "FORM_VIEWER.MISSING_CONTENT_IN_LOCKED_FIELD.TITLE" | translate }}</h4>
    <div class='pt-3'>
      {{ "FORM_VIEWER.MISSING_CONTENT_IN_LOCKED_FIELD.CONTENT" | translate }}
    </div>
    <div class='p2 locked-field-list'>
      <div *ngFor='let field of emptyLockedFields' class='d-flex'>
        {{ field }}
      </div>
    </div>
    <div class='pt-3'>
      <div class='d-flex justify-content-center'>
        <button role='button' type='reset' id='locked-fields-prompt-cancel' class='btn-ih px-1' (click)="exitLockedFieldsModal()">
          {{ "FORM_VIEWER.MISSING_CONTENT_IN_LOCKED_FIELD.CLOSE_BUTTON" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
