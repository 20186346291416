import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FieldBaseComponent } from '../field-base/field-base.component';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'next-toggle',
  templateUrl: './toggle.component.html',
  animations: [
    trigger('subField', [
      state('in', style({ opacity: 1 })),
      transition('void => in', [
        style({
          opacity: 0,
          transform: 'translateY(1rem)'
        }),
        animate('300ms ease-in-out')
      ]),
    ]),
  ]
})
export class ToggleComponent extends FieldBaseComponent implements OnInit, AfterViewInit {

  @Input() needsValidation: boolean;
  toggleValue: boolean = false;

  ngAfterViewInit(): void {
    super.processAfterViewInit(this.field);
    this.valueChangeSub();
  }

  ngOnInit(): void {
    super.processOnInit(this.field);
    if(this.form.get(this.field.name)){
      this.toggleValue = this.form.get(this.field.name).value?.Value?.Text;
    }
  }

  statusChangeReceived(event): any {
    this.statusChange.emit(event);
  }

  toggle(value: boolean) {
    this.form.patchValue({[this.field.name]: {Type: 'Text', Value: {Text: value, Format: 'alphanumeric'}}});
  }
}
