export enum BarcodeTypes {
    OneTwoEight = "128",
    ThreeOfNine = "3 of 9",
    Aztec = "Aztec",
    QR = "QR",
    PDF417 = "PDF417",
    DM = "DM"
}

export enum MappingTypes {
    AccountNumber = "Account Number",
    BirtDate = "Birth Date",
    MRN = "MRN",
    PatientFirstName = "Patient First Name",
    PatientLastName = "Patient Last Name",
    PatientMiddleName = "Patient Middle Name",  
}

export class ScannerProfile {
  id: string;
  profileName: string;
  barcodeType: BarcodeTypes;
  dataParsingLogic: string;
  mappings: Mapping[];

  constructor() { 
    this.mappings = [];
  }

}

export class Mapping {
    position: number;
    mappingType: MappingTypes;
}