import { Component } from '@angular/core';
import { TokenService } from '@next/shared/common';
import { NextAuthService } from '@next/shared/next-services';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'next-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent {

  constructor(private translateSvc: TranslateService,
              private authSvc: NextAuthService,
              private tokenService: TokenService,
              private toastr: ToastrService) { }

  logout() {
    const logoutURl = `?redirectUrl=${window.location.origin}`;

    // Get the logout url from the server and redirect the user to the logout auth provider page
    this.authSvc.Logout(logoutURl).subscribe({
      next: result => {
        // Clear the token
        this.tokenService.clear();
        this.tokenService.clearLastAction();
    
        // This is artificially added so when the user hits the back button, it will stay on this page.
        history.pushState(history.state, '', '/error');
    
        const url = new URL(result.logoutUrl);
        window.location.href = url.href;
      },
      error: () => {
        this.toastr.error(this.translateSvc.instant('LOGOUT.ERROR_MESSAGE'),'',{disableTimeOut: false });       
      }
    });
  }
}
