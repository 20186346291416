export interface AuthToken {
  connection: string;
  exp: string;
  iat: string;
  integration?: string;
  iss?: string;
  permissions?: any;
  sub: string;
  username: string;
  groups?: string[];
}

export interface AuthProvider {
  name: string;
  type: string;
  authorization_endpoint: string;
  token_endpoint: string;
  client_id: string;
  client_secret: string;
  redirect_uri: string;
  scopes: string;
}

export enum IntegrationType {
  AZURE = 'azure',
  SALESFORCE = 'salesforce'
}

export enum PreferenceType {
  DefaultSignatures = 'DEFAULTSIGNATURES',
  AccessCode = 'ACCESSCODE',
  Facility = 'FACILITY',
  Department = 'DEPARTMENT',
  DEA = 'DEA',
  NPI = 'NPI',
  Dashboard = 'DASHBOARD',
  DashboardDisplay = 'DASHBOARDDISPLAY',
  DefaultBarcode = "DEFAULTBARCODEID",
  SearchByType = "SEARCHBYTYPE",
  FavoriteExperiences = "FAVORITEEXPERIENCES",
  DesignerTimeStampFormat = "DESIGNER_FORMAT_TIMESTAMP",
  DesignerDateFormat = "DESIGNER_FORMAT_DATE",
  DesignerTimeFormat = "DESIGNER_FORMAT_TIME",
  DesignerDateTimeFormat = "DESIGNER_FORMAT_DATETIME"
}

export interface User {
  firstName: string;
  lastName: string;
  oid: string;
  email?: string;
  groups: any[];
  photo?: string;
  integration?: string;
  preferences?: any[];
}

export interface UserSF extends User {
  active?: boolean;
  address?: any;
  custom_permissions?: any;
  email?: string;
  email_verified?: boolean;
  family_name?: string;
  given_name?: string;
  is_app_installed?: boolean;
  language?: string;
  locale?: string;
  name?: string;
  nickname?: string;
  organization_id?: string;
  photos?: { picture, thumbnail };
  picture?: string;
  preferred_username?: string;
  profile?: string;
  sub?: string;
  updated_at?: string;
  urls?: { custom_domain, enterprise, feed_elements, feed_items, feeds, groups, metadata, partner, profile, query, recent, rest, search, sobjects, tooling_rest, tooling_soap, users }
  user_id?: string;
  user_type?: string;
  utcOffset?: number;
  zoneinfo?: string;
}
