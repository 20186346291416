import { Inject, Injectable } from '@angular/core';
import {
  ApiService,
  CollectionDTO,
  CollectionJunctionDTO,
  FormFastConfig,
  FormFastConfigService,
  GuidedExperienceDTO,
  LayerExperienceDTO,
  NextGuidedExperienceMetaData,
  TokenService,
} from '@next/shared/common';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable()
export class NextExperienceService extends ApiService {

  constructor (
    @Inject(FormFastConfigService) config: FormFastConfig,
    tokenService: TokenService,
    private http: HttpClient
  ) { super(config, tokenService); }

  getExperienceTemplates(): Observable<GuidedExperienceDTO[]> {
    return this.protectedEndpoint<any[]>(
      this.http.get<any[]>(`${this.config.apiUrl}experience/template/draft`, this.getHeaders())
    ).pipe(
      map(results => results.map(result => {
        return {
          annotations: [],
          description: result.description,
          formId: result.formId || '',
          id: result.id,
          vid: result.versionid,
          configversion: result.configversion,
          name: result.name,
          type: result.type,
          embedAttachments: result.data?.settings?.embedAttachments || false,
          sendToSuccessPage: result.data?.settings?.sendToSuccessPage || false,
          signAllStaff: result.data?.settings?.signAllStaff || false,
          experiencecreatedon: result.experiencecreatedon,
          publishedon: result.publishedon,          // publishedon refers to this version record, would be null for draft state experiences
          lastpublishedon: result.lastpublishedon,  // lastpublishedon refers to this version records parentversion.publishedon, and would be available for draft state experiences
          status: result.status,
          tags: result.tags,
          version: result.version,
          createdon: result.createdon,
          updatedon: result.updatedon,
          onLoad: '',
          onSave: '',
          onSubmit: '',
          pages: result.data?.pages || [],
          pdftemplate: result.pdftemplate,
          pdftemplateid: result.pdftemplateid,
          pdftemplatedescription: result.pdftemplatedescription,
          metadataformid: result.data?.settings?.metadataformid,
          metadataoldformid: result.data?.settings?.metadataoldformid,
          metadataarchiveenabled: result.data?.settings?.metadataarchiveenabled,
          metadataprintshopid: result.data?.settings?.metadataprintshopid,
          metadatadocumenttype: result.data?.settings?.metadatadocumenttype,
          metadatafacilities: result.data?.settings?.metadatafacilities,
          metadatadepartments: result.data?.settings?.metadatadepartments,
          metadatarequireslegalreview: result.data?.settings?.metadatarequireslegalreview,
          metadatalanguage: result.data?.settings?.metadatalanguage,
          metadatapolicy: result.data?.settings?.metadatapolicy,
          metadatapublicurl: result.data?.settings?.metadatapublicurl,
          metadataformowner: result.data?.settings?.metadataformowner,
          metadatastaffreviewrequired: result.data?.settings?.metadatastaffreviewrequired,
          metadatadefaultremotecccessto: result.data?.settings?.metadatadefaultremotecccessto,
          metadataapprovedby: result.data?.settings?.metadataapprovedby,
          metadataapproveddate: result.data?.settings?.metadataapproveddate
        } as GuidedExperienceDTO
      }))
    );
  }

  getExperienceLayers(): Observable<GuidedExperienceDTO[]> {
    return this.protectedEndpoint<any[]>(
      this.http.get<any[]>(`${this.config.apiUrl}experience/layer/draft`, this.getHeaders())
    ).pipe(
      map(results => results.map(result => {
        return {
          annotations: [],
          description: result.description,
          formId: result.formId || '',
          id: result.id,
          vid: result.versionid,
          configversion: result.configversion,
          name: result.name,
          type: result.type,
          embedAttachments: result.data?.settings?.embedAttachments || false,
          sendToSuccessPage: result.data?.settings?.sendToSuccessPage || false,
          signAllStaff: result.data?.settings?.signAllStaff || false,
          experiencecreatedon: result.experiencecreatedon,
          publishedon: result.publishedon,          // publishedon refers to this version record, would be null for draft state experiences
          lastpublishedon: result.lastpublishedon,  // lastpublishedon refers to this version records parentversion.publishedon, and would be available for draft state experiences
          status: result.status,
          tags: result.tags,
          version: result.version,
          createdon: result.createdon,
          updatedon: result.updatedon,
          onLoad: '',
          onSave: '',
          onSubmit: '',
          pages: result.data?.pages || [],
          pdftemplate: result.pdftemplate,
          pdftemplateid: result.pdftemplateid,
          pdftemplatedescription: result.pdftemplatedescription,
          metadataformid: result.data?.settings?.metadataformid,
          metadataoldformid: result.data?.settings?.metadataoldformid,
          metadataarchiveenabled: result.data?.settings?.metadataarchiveenabled,
          metadataprintshopid: result.data?.settings?.metadataprintshopid,
          metadatadocumenttype: result.data?.settings?.metadatadocumenttype,
          metadatafacilities: result.data?.settings?.metadatafacilities,
          metadatadepartments: result.data?.settings?.metadatadepartments,
          metadatarequireslegalreview: result.data?.settings?.metadatarequireslegalreview,
          metadatalanguage: result.data?.settings?.metadatalanguage,
          metadatapolicy: result.data?.settings?.metadatapolicy,
          metadatapublicurl: result.data?.settings?.metadatapublicurl,
          metadataformowner: result.data?.settings?.metadataformowner,
          metadatastaffreviewrequired: result.data?.settings?.metadatastaffreviewrequired,
          metadatadefaultremotecccessto: result.data?.settings?.metadatadefaultremotecccessto,
          metadataapprovedby: result.data?.settings?.metadataapprovedby,
          metadataapproveddate: result.data?.settings?.metadataapproveddate
        } as GuidedExperienceDTO
      }))
    );
  }

  getExperienceTemplatesPublished(): Observable<GuidedExperienceDTO[]> {
    return this.protectedEndpoint<any[]>(
      this.http.get<any[]>(`${this.config.apiUrl}experience/template/active`, this.getHeaders())
    ).pipe(
      map(results => results.map(result => {
        return {
          annotations: [],
          description: result.description,
          formId: result.formId || '',
          id: result.id,
          vid: result.versionid,
          configversion: result.configversion,
          name: result.name,
          type: result.type,
          embedAttachments: result.data?.settings?.embedAttachments || false,
          sendToSuccessPage: result.data?.settings?.sendToSuccessPage || false,
          signAllStaff: result.data?.settings?.signAllStaff || false,
          publishedon: result.publishedon,
          status: result.status,
          tags: result.tags,
          version: result.version,
          createdon: result.createdon,
          updatedon: result.updatedon,
          onLoad: result.onLoad || '',
          onSave: result.onSave || '',
          onSubmit: result.onSubmit || '',
          pages: result.data?.pages || [],
          pdftemplate: result.pdftemplate,
          pdftemplateid: result.pdftemplateid,
          pdftemplatedescription: result.pdftemplatedescription,
          metadataformid: result.data?.settings?.metadataformid,
          metadataoldformid: result.data?.settings?.metadataoldformid,
          metadataarchiveenabled: result.data?.settings?.metadataarchiveenabled,
          metadataprintshopid: result.data?.settings?.metadataprintshopid,
          metadatadocumenttype: result.data?.settings?.metadatadocumenttype,
          metadatafacilities: result.data?.settings?.metadatafacilities,
          metadatadepartments: result.data?.settings?.metadatadepartments,
          metadatarequireslegalreview: result.data?.settings?.metadatarequireslegalreview,
          metadatalanguage: result.data?.settings?.metadatalanguage,
          metadatapolicy: result.data?.settings?.metadatapolicy,
          metadatapublicurl: result.data?.settings?.metadatapublicurl,
          metadataformowner: result.data?.settings?.metadataformowner,
          metadatastaffreviewrequired: result.data?.settings?.metadatastaffreviewrequired,
          metadatadefaultremotecccessto: result.data?.settings?.metadatadefaultremotecccessto,
          metadataapprovedby: result.data?.settings?.metadataapprovedby,
          metadataapproveddate: result.data?.settings?.metadataapproveddate
        } as GuidedExperienceDTO;
      }))
    );
  }

  getLayerExperiencesPublished(): Observable<LayerExperienceDTO[]> {
    return this.protectedEndpoint<any[]>(
      this.http.get<any[]>(`${this.config.apiUrl}experience/layer/active`, this.getHeaders())
    ).pipe(
      map(results => results.map(result => {
        return {
          configversion: result.configversion,
          id: result.id,
          name: result.name,
          type: result.type,
          description: result.description,
          datasets: result.datasets,
          pages: result.data?.pages || [],
          fields: [],
          sources: result.metadata.sources,
          pdftemplate: result.pdftemplate,
          pdftemplateid: result.pdftemplateid,
          pdftemplateurl: result.pdftemplate.url,
          pdftemplatedescription: result.pdftemplatedescription,
          onLoad: result.onLoad || '',
          onSave: result.onSave || '',
          onSubmit: result.onSubmit || '',
        } as LayerExperienceDTO;
      })));
  }

  getLayerAssociations(experienceId: string): Observable<GuidedExperienceDTO[]> {
    return this.protectedEndpoint<any[]>(
      this.http.get<any>(`${this.config.apiUrl}experience/${experienceId}/associations`, this.getHeaders())
    ).pipe(
      map(results => {
        return results.map(result => {
          return {
            annotations: [],
            description: result.description,
            formId: result.formId || '',
            id: result.id,
            vid: result.versionid,
            configversion: result.configversion,
            name: result.name,
            type: result.type,
            embedAttachments: result.data?.settings?.embedAttachments || false,
            sendToSuccessPage: result.data?.settings?.sendToSuccessPage || false,
            signAllStaff: result.data?.settings?.signAllStaff || false,
            publishedon: result.publishedon,
            status: result.status,
            tags: result.tags,
            version: result.version,
            createdon: result.createdon,
            updatedon: result.updatedon,
            onLoad: result.onLoad || '',
            onSave: result.onSave || '',
            onSubmit: result.onSubmit || '',
            pages: result.data?.pages || [],
            pdftemplate: result.pdftemplate,
            pdftemplateid: result.pdftemplateid,
            pdftemplatedescription: result.pdftemplatedescription,
            metadata: result.metadata,
            metadataformid: result.data?.settings?.metadataformid,
            metadataoldformid: result.data?.settings?.metadataoldformid,
            metadataarchiveenabled: result.data?.settings?.metadataarchiveenabled,
            metadataprintshopid: result.data?.settings?.metadataprintshopid,
            metadatadocumenttype: result.data?.settings?.metadatadocumenttype,
            metadatafacilities: result.data?.settings?.metadatafacilities,
            metadatadepartments: result.data?.settings?.metadatadepartments,
            metadatarequireslegalreview: result.data?.settings?.metadatarequireslegalreview,
            metadatalanguage: result.data?.settings?.metadatalanguage,
            metadatapolicy: result.data?.settings?.metadatapolicy,
            metadatapublicurl: result.data?.settings?.metadatapublicurl,
            metadataformowner: result.data?.settings?.metadataformowner,
            metadatastaffreviewrequired: result.data?.settings?.metadatastaffreviewrequired,
            metadatadefaultremotecccessto: result.data?.settings?.metadatadefaultremotecccessto,
            metadataapprovedby: result.data?.settings?.metadataapprovedby,
            metadataapproveddate: result.data?.settings?.metadataapproveddate
          } as GuidedExperienceDTO;
        });
      }));
  }

  getExperience(experienceId: string): Observable<GuidedExperienceDTO> {
    return this.protectedEndpoint<any>(
      this.http.get<any>(`${this.config.apiUrl}experience/${experienceId}/active?$expand=pdftemplate`, this.getHeaders())
    ).pipe(
      map(result => {
        return {
          annotations: [],
          description: result.description,
          formId: result.formId || '',
          id: result.id,
          vid: result.versionid,
          configversion: result.configversion,
          name: result.name,
          type: result.type,
          embedAttachments: result.data?.settings?.embedAttachments || false,
          sendToSuccessPage: result.data?.settings?.sendToSuccessPage || false,
          signAllStaff: result.data?.settings?.signAllStaff || false,
          publishedon: result.publishedon,
          status: result.status,
          tags: result.tags,
          version: result.version,
          createdon: result.createdon,
          updatedon: result.updatedon,
          onLoad: result.onLoad || '',
          onSave: result.onSave || '',
          onSubmit: result.onSubmit || '',
          pages: result.data?.pages || [],
          pdftemplate: result.pdftemplate,
          pdftemplateid: result.pdftemplateid,
          pdftemplatedescription: result.pdftemplatedescription,
          metadata: result.metadata,
          metadataformid: result.data?.settings?.metadataformid,
          metadataoldformid: result.data?.settings?.metadataoldformid,
          metadataarchiveenabled: result.data?.settings?.metadataarchiveenabled,
          metadataprintshopid: result.data?.settings?.metadataprintshopid,
          metadatadocumenttype: result.data?.settings?.metadatadocumenttype,
          metadatafacilities: result.data?.settings?.metadatafacilities,
          metadatadepartments: result.data?.settings?.metadatadepartments,
          metadatarequireslegalreview: result.data?.settings?.metadatarequireslegalreview,
          metadatalanguage: result.data?.settings?.metadatalanguage,
          metadatapolicy: result.data?.settings?.metadatapolicy,
          metadatapublicurl: result.data?.settings?.metadatapublicurl,
          metadataformowner: result.data?.settings?.metadataformowner,
          metadatastaffreviewrequired: result.data?.settings?.metadatastaffreviewrequired,
          metadatadefaultremotecccessto: result.data?.settings?.metadatadefaultremotecccessto,
          metadataapprovedby: result.data?.settings?.metadataapprovedby,
          metadataapproveddate: result.data?.settings?.metadataapproveddate,
          modifiedby: result.modifiedby,
          publishedby: result.publishedby
        } as GuidedExperienceDTO;
      }));
  }

  getAllExperienceVersions(experienceId: string): Observable<GuidedExperienceDTO[]> {
    return this.protectedEndpoint<any>(
      this.http.get<any>(`${this.config.apiUrl}experience/${experienceId}/version`, this.getHeaders())
    ).pipe(
      map(results => results.map(result => {
        return {
          annotations: [],
          description: result.description,
          datasets: result.datasets,
          formId: '',
          id: result.id,
          vid: result.versionid,
          version: result.version,
          configversion: result.configversion,
          name: result.name,
          type: result.type,
          embedAttachments: false,
          publishedon: result.publishedon,
          status: result.status,
          createdon: result.createdon,
          updatedon: result.updatedon,
          onLoad: '',
          onSave: '',
          onSubmit: '',
          pages: result.data?.pages || [],
          pdftemplate: result.pdftemplate,
          pdftemplateid: result.pdftemplateid,
          pdftemplatedescription: result.pdftemplatedescription,
          metadatadefaultdisplaymode: result.data?.settings?.metadatadefaultdisplaymode,
          metadataformid: result.data?.settings?.metadataformid,
          metadataoldformid: result.data?.settings?.metadataoldformid,
          metadataarchiveenabled: result.data?.settings?.metadataarchiveenabled,
          metadataprintshopid: result.data?.settings?.metadataprintshopid,
          metadatadocumenttype: result.data?.settings?.metadatadocumenttype,
          metadatafacilities: result.data?.settings?.metadatafacilities,
          metadatadepartments: result.data?.settings?.metadatadepartments,
          metadatarequireslegalreview: result.data?.settings?.metadatarequireslegalreview,
          metadatalanguage: result.data?.settings?.metadatalanguage,
          metadatapolicy: result.data?.settings?.metadatapolicy,
          metadatapublicurl: result.data?.settings?.metadatapublicurl,
          metadataformowner: result.data?.settings?.metadataformowner,
          metadatastaffreviewrequired: result.data?.settings?.metadatastaffreviewrequired,
          metadatadefaultremotecccessto: result.data?.settings?.metadatadefaultremotecccessto,
          metadataapprovedby: result.data?.settings?.metadataapprovedby,
          metadataapproveddate: result.data?.settings?.metadataapproveddate
        } as GuidedExperienceDTO;
      })));
  }

  getExperienceVersion(experienceId: string, versionId: string): Observable<GuidedExperienceDTO> {
    return this.protectedEndpoint<any>(
      this.http.get<any>(`${this.config.apiUrl}experience/${experienceId}/version/${versionId}?$expand=pdftemplate`, this.getHeaders())
    ).pipe(
      map(result => {
        return {
          annotations: [],
          description: result.description,
          datasets: result.datasets,
          formId: '',
          id: result.id,
          vid: result.versionid,
          version: result.version,
          configversion: result.configversion,
          name: result.name,
          type: result.type,
          embedAttachments: result.data.settings?.embedAttachments || false,
          sendToSuccessPage: result.data.settings?.sendToSuccessPage || false,
          signAllStaff: result.data.settings?.signAllStaff || false,
          onLoad: (result.data.calculations) ? result.data.calculations.onLoad : '',
          onSave: (result.data.calculations) ? result.data.calculations.onSave : '',
          onSubmit: (result.data.calculations) ? result.data.calculations.onSubmit : '',
          header: result.data.header,
          pages: result.data.pages,
          pdftemplate: result.pdftemplate,
          pdftemplateid: result.pdftemplateid,
          pdftemplatedescription: result.pdftemplatedescription,
          tags: result.tags,
          metadatadefaultdisplaymode: result.data?.settings?.metadatadefaultdisplaymode,
          metadataformid: result.data?.settings?.metadataformid,
          metadataoldformid: result.data?.settings?.metadataoldformid,
          metadataarchiveenabled: result.data?.settings?.metadataarchiveenabled,
          metadataprintshopid: result.data?.settings?.metadataprintshopid,
          metadatadocumenttype: result.data?.settings?.metadatadocumenttype,
          metadatafacilities: result.data?.settings?.metadatafacilities,
          metadatadepartments: result.data?.settings?.metadatadepartments,
          metadatarequireslegalreview: result.data?.settings?.metadatarequireslegalreview,
          metadatalanguage: result.data?.settings?.metadatalanguage,
          metadatapolicy: result.data?.settings?.metadatapolicy,
          metadatapublicurl: result.data?.settings?.metadatapublicurl,
          metadataformowner: result.data?.settings?.metadataformowner,
          metadatastaffreviewrequired: result.data?.settings?.metadatastaffreviewrequired,
          metadatadefaultremotecccessto: result.data?.settings?.metadatadefaultremotecccessto,
          metadataapprovedby: result.data?.settings?.metadataapprovedby,
          metadataapproveddate: result.data?.settings?.metadataapproveddate,
          createdon: result.createdon,
          updatedon: result.updatedon,
          publishedon: result.publishedon,
          publishedby: result.publishedbyusername ?? result.publishedby,
          modifiedby: result.modifiedbyusername ?? result.modifiedby
        } as GuidedExperienceDTO
      }));
  }

  getExperienceWithMetadata(experienceId: string, versionId: string): Observable<GuidedExperienceDTO> {
    return this.protectedEndpoint<any>(
      this.http.get<any>(`${this.config.apiUrl}experience/${experienceId}/version/${versionId}/metadata`, this.getHeaders())
    ).pipe(
      map(result => {
        return {
          annotations: [],
          description: result.description,
          datasets: result.datasets,
          formId: '',
          id: result.id,
          vid: result.versionid,
          version: result.version,
          configversion: result.configversion,
          name: result.name,
          type: result.type,
          embedAttachments: result.data.settings?.embedAttachments || false,
          sendToSuccessPage: result.data.settings?.sendToSuccessPage || false,
          signAllStaff: result.data.settings?.signAllStaff || false,
          onLoad: (result.data.calculations) ? result.data.calculations.onLoad : '',
          onSave: (result.data.calculations) ? result.data.calculations.onSave : '',
          onSubmit: (result.data.calculations) ? result.data.calculations.onSubmit : '',
          header: result.data.header,
          pages: result.data.pages,
          pdftemplate: result.pdftemplate,
          pdftemplateid: result.pdftemplateid,
          pdftemplatedescription: result.pdftemplatedescription,
          metadata: result.metadata,
          metadatadefaultdisplaymode: result.data?.settings?.metadatadefaultdisplaymode,
          metadataformid: result.data?.settings?.metadataformid,
          metadataoldformid: result.data?.settings?.metadataoldformid,
          metadataarchiveenabled: result.data?.settings?.metadataarchiveenabled,
          metadataprintshopid: result.data?.settings?.metadataprintshopid,
          metadatadocumenttype: result.data?.settings?.metadatadocumenttype,
          metadatafacilities: result.data?.settings?.metadatafacilities,
          metadatadepartments: result.data?.settings?.metadatadepartments,
          metadatarequireslegalreview: result.data?.settings?.metadatarequireslegalreview,
          metadatalanguage: result.data?.settings?.metadatalanguage,
          metadatapolicy: result.data?.settings?.metadatapolicy,
          metadatapublicurl: result.data?.settings?.metadatapublicurl,
          metadataformowner: result.data?.settings?.metadataformowner,
          metadatastaffreviewrequired: result.data?.settings?.metadatastaffreviewrequired,
          metadatadefaultremotecccessto: result.data?.settings?.metadatadefaultremotecccessto,
          metadatashowreviewandfinalize: result.data?.settings?.metadatashowreviewandfinalize,
          metadataapprovedby: result.data?.settings?.metadataapprovedby,
          metadataapproveddate: result.data?.settings?.metadataapproveddate
        } as GuidedExperienceDTO;
      })
    );
  }

  saveExperience(experience: GuidedExperienceDTO, metadata: NextGuidedExperienceMetaData) {
    const pdfTemplateId = (metadata.sources && metadata.sources.length > 0)
      ? metadata.sources[metadata.sources.length - 1].id
      : null;

    const convertedExperience = {
      id: experience.id,
      versionid: experience.vid,
      designerversionid: experience.designerversionid,
      configversion: experience.configversion,
      name: experience.name,
      type: experience.type,
      description: experience.description,
      datasets: experience.datasets,
      pdftemplateid: pdfTemplateId,
      data: {
        header: experience.header,
        calculations: {
          onLoad: experience.onLoad,
          onSave: experience.onSave,
          onSubmit: experience.onSubmit
        },
        settings: {
          embedAttachments: experience.embedAttachments,
          sendToSuccessPage: experience.sendToSuccessPage,
          signAllStaff: experience.signAllStaff,
          metadataformid: experience.metadataformid,
          metadataoldformid: experience.metadataoldformid,
          metadataarchiveenabled: experience.metadataarchiveenabled,
          metadataprintshopid: experience.metadataprintshopid,
          metadatadocumenttype: experience.metadatadocumenttype,
          metadatafacilities: experience.metadatafacilities,
          metadatadepartments: experience.metadatadepartments,
          metadatarequireslegalreview: experience.metadatarequireslegalreview,
          metadatalanguage: experience.metadatalanguage,
          metadatapolicy: experience.metadatapolicy,
          metadatapublicurl: experience.metadatapublicurl,
          metadataformowner: experience.metadataformowner,
          metadatastaffreviewrequired: experience.metadatastaffreviewrequired,
          metadatadefaultremotecccessto: experience.metadatadefaultremotecccessto,
          metadatadefaultdisplaymode: experience.metadatadefaultdisplaymode,
          metadatashowreviewandfinalize: experience.metadatashowreviewandfinalize,
          metadataapprovedby: experience.metadataapprovedby,
          metadataapproveddate: experience.metadataapproveddate
        },
        form: [],
        pages: experience.pages,
      },
      metadata
    };

    if (experience.id && experience.id !== '') {
      return this.protectedEndpoint<any>(
        this.http.put<any>(
          `${this.config.apiUrl}experience/${experience.id}/version/${experience.vid}`, convertedExperience, this.getHeaders())
      ).pipe(
        map(result => {
          return {
            annotations: [],
            description: result.description,
            datasets: result.datasets,
            formId: '',
            id: result.id,
            vid: result.versionid,
            version: result.version,
            configversion: result.configversion,
            name: result.name,
            type: result.type,
            embedAttachments: result.data.settings?.embedAttachments || false,
            sendToSuccessPage: result.data.settings?.sendToSuccessPage || false,
            signAllStaff: result.data.settings?.signAllStaff || false,
            onLoad: (result.data.calculations) ? result.data.calculations.onLoad : '',
            onSave: (result.data.calculations) ? result.data.calculations.onSave : '',
            onSubmit: (result.data.calculations) ? result.data.calculations.onSubmit : '',
            pages: result.data.pages,
            pdftemplate: result.pdftemplate,
            pdftemplateid: result.pdftemplateid,
            pdftemplatedescription: result.pdftemplatedescription,
            metadataformid: result.data?.settings?.metadataformid,
            metadataoldformid: result.data?.settings?.metadataoldformid,
            metadataarchiveenabled: result.data?.settings?.metadataarchiveenabled,
            metadataprintshopid: result.data?.settings?.metadataprintshopid,
            metadatadocumenttype: result.data?.settings?.metadatadocumenttype,
            metadatafacilities: result.data?.settings?.metadatafacilities,
            metadatadepartments: result.data?.settings?.metadatadepartments,
            metadatarequireslegalreview: result.data?.settings?.metadatarequireslegalreview,
            metadatalanguage: result.data?.settings?.metadatalanguage,
            metadatapolicy: result.data?.settings?.metadatapolicy,
            metadatapublicurl: result.data?.settings?.metadatapublicurl,
            metadataformowner: result.data?.settings?.metadataformowner,
            metadatastaffreviewrequired: result.data?.settings?.metadatastaffreviewrequired,
            metadatadefaultremotecccessto: experience.metadatadefaultremotecccessto,
            metadataapprovedby: experience.metadataapprovedby,
            metadataapproveddate: experience.metadataapproveddate
          } as GuidedExperienceDTO
        })
      );
    } else {
      return this.protectedEndpoint<any>(
        this.http.post<any>(`${this.config.apiUrl}experience`, convertedExperience, this.getHeaders())
      ).pipe(
        map(result => {
          return {
            annotations: [],
            description: result.description,
            datasets: result.datasets,
            formId: '',
            id: result.id,
            vid: result.versionid,
            version: result.version,
            configversion: result.configversion,
            name: result.name,
            type: result.type,
            embedAttachments: result.data.settings?.embedAttachments || false,
            sendToSuccessPage: result.data.settings?.sendToSuccessPage || false,
            signAllStaff: result.data.settings?.signAllStaff || false,
            onLoad: (result.data.calculations) ? result.data.calculations.onLoad : '',
            onSave: (result.data.calculations) ? result.data.calculations.onSave : '',
            onSubmit: (result.data.calculations) ? result.data.calculations.onSubmit : '',
            pages: result.data.pages,
            pdftemplate: result.pdftemplate,
            pdftemplateid: result.pdftemplateid,
            pdftemplatedescription: result.pdftemplatedescription,
            metadataformid: result.data?.settings?.metadataformid,
            metadataoldformid: result.data?.settings?.metadataoldformid,
            metadataarchiveenabled: result.data?.settings?.metadataarchiveenabled,
            metadataprintshopid: result.data?.settings?.metadataprintshopid,
            metadatadocumenttype: result.data?.settings?.metadatadocumenttype,
            metadatafacilities: result.data?.settings?.metadatafacilities,
            metadatadepartments: result.data?.settings?.metadatadepartments,
            metadatarequireslegalreview: result.data?.settings?.metadatarequireslegalreview,
            metadatalanguage: result.data?.settings?.metadatalanguage,
            metadatapolicy: result.data?.settings?.metadatapolicy,
            metadatapublicurl: result.data?.settings?.metadatapublicurl,
            metadataformowner: result.data?.settings?.metadataformowner,
            metadatastaffreviewrequired: result.data?.settings?.metadatastaffreviewrequired,
            metadatadefaultremotecccessto: experience.metadatadefaultremotecccessto,
            metadataapprovedby: experience.metadataapprovedby,
            metadataapproveddate: experience.metadataapproveddate
          } as GuidedExperienceDTO
        })
      );
    }
  }

  publishExperience(experienceId: string, experienceVersionId: string) : Observable<GuidedExperienceDTO>{

    // first, publish the experience version
    const publish$ = this.protectedEndpoint<any>(this.http.post<any>(`${this.config.apiUrl}experience/${experienceId}/version/${experienceVersionId}/activate`, this.getHeaders()));

    // then copy into a new draft experience
    const copy$ = this.protectedEndpoint<any>(
      this.http.post<any>(`${this.config.apiUrl}experience/${experienceId}/version/${experienceVersionId}/copy`, this.getHeaders())
    ).pipe(
      map((resultCopy) => {
        return {
          annotations: [],
          description: resultCopy.description,
          datasets: [...resultCopy.datasets],
          formId: '',
          id: resultCopy.id,
          vid: resultCopy.versionid,
          version: resultCopy.version,
          configversion: resultCopy.configversion,
          name: resultCopy.name,
          type: resultCopy.type,
          embedAttachments: resultCopy.data.settings?.embedAttachments || false,
          sendToSuccessPage: resultCopy.data.settings?.sendToSuccessPage || false,
          signAllStaff: resultCopy.data.settings?.signAllStaff || false,
          onLoad: (resultCopy.data.calculations) ? resultCopy.data.calculations.onLoad : '',
          onSave: (resultCopy.data.calculations) ? resultCopy.data.calculations.onSave : '',
          onSubmit: (resultCopy.data.calculations) ? resultCopy.data.calculations.onSubmit : '',
          pages: resultCopy.data.pages,
          pdftemplate: resultCopy.pdftemplate,
          pdftemplateid: resultCopy.pdftemplateid,
          pdftemplatedescription: resultCopy.pdftemplatedescription,
          metadataformid: resultCopy.data?.settings?.metadataformid,
          metadataoldformid: resultCopy.data?.settings?.metadataoldformid,
          metadataarchiveenabled: resultCopy.data?.settings?.metadataarchiveenabled,
          metadataprintshopid: resultCopy.data?.settings?.metadataprintshopid,
          metadatadocumenttype: resultCopy.data?.settings?.metadatadocumenttype,
          metadatafacilities: resultCopy.data?.settings?.metadatafacilities,
          metadatadepartments: resultCopy.data?.settings?.metadatadepartments,
          metadatarequireslegalreview: resultCopy.data?.settings?.metadatarequireslegalreview,
          metadatalanguage: resultCopy.data?.settings?.metadatalanguage,
          metadatapolicy: resultCopy.data?.settings?.metadatapolicy,
          metadatapublicurl: resultCopy.data?.settings?.metadatapublicurl,
          metadataformowner: resultCopy.data?.settings?.metadataformowner,
          metadatastaffreviewrequired: resultCopy.data?.settings?.metadatastaffreviewrequired,
          metadatadefaultremotecccessto: resultCopy.data?.settings?.metadatadefaultremotecccessto,
          metadataapprovedby: resultCopy.data?.settings?.metadataapprovedby,
          metadataapproveddate: resultCopy.data?.settings?.metadataapproveddate
        } as GuidedExperienceDTO;
      })
    );

    //sequencing copy after publish since the version number should be copied after publish
    return publish$.pipe(
      mergeMap(() => { return copy$}),
      map((res)=> {return res})
    )
  }

  deleteExperience(expId: string): Observable<GuidedExperienceDTO> {
    return this.protectedEndpoint<GuidedExperienceDTO>(
      this.http.delete<GuidedExperienceDTO>(`${this.config.apiUrl}experience/${expId}`, this.getHeaders())
    );
  }

  buildFolders(treeNode: any[]) {
    return this.protectedEndpoint(
      this.http.post(`${this.config.apiUrl}collection/folders/`, treeNode, this.getHeaders())
    );
  }

  buildPackets(treeNode: any[]) {
    return this.protectedEndpoint(
      this.http.post(`${this.config.apiUrl}collection/packets/`, treeNode, this.getHeaders())
    );
  }

  getCollectionBundle(): Observable<[CollectionDTO[], CollectionJunctionDTO[], GuidedExperienceDTO[]]> {
    return this.protectedEndpoint<[CollectionDTO[], CollectionJunctionDTO[], GuidedExperienceDTO[]]>(
      this.http.get<[CollectionDTO[], CollectionJunctionDTO[], GuidedExperienceDTO[]]>(`${this.config.apiUrl}collection/bundle`, this.getHeaders())
    );
  }

  getCategories(): Observable<CollectionDTO[]> {
    return this.protectedEndpoint<CollectionDTO[]>(
      this.http.get<CollectionDTO[]>(`${this.config.apiUrl}collection/`, this.getHeaders())
    );
  }

  deleteJunctionsByExperienceId(eid: string): Observable<any>{
    return this.protectedEndpoint<any>(
      this.http.delete<any>(`${this.config.apiUrl}collection/assignments/experience/${eid}`, this.getHeaders())
    );
  }

  assignCategory(junctions): Observable<CollectionJunctionDTO[]>{
    return this.protectedEndpoint<CollectionJunctionDTO[]>(
      this.http.post<CollectionJunctionDTO[]>(`${this.config.apiUrl}collection/assignments/experience`, junctions, this.getHeaders())
    );
  }

  assignToCollection(junctions): Observable<any>{
    return this.protectedEndpoint<any>(
      this.http.post<any>(`${this.config.apiUrl}collection/assignments/collection`, junctions, this.getHeaders())
    );
  }

  getExperienceDatasets(id: string, versionid: string) {
    return this.protectedEndpoint<any>(
      this.http.get<any>(`${this.config.apiUrl}experience/${id}/version/${versionid}/datasets`, this.getHeaders())
    );
  }
}
