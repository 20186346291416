import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FormFastConfig } from '../config/formfast.config';
import { TokenService } from './token.service';

export abstract class ApiService {
  protected config: FormFastConfig;

  protected constructor (config: FormFastConfig, private authSvc: TokenService) {
    this.config = config;
  }

  /** getHeadersReportProgress()
   * : _This header factory will observe UP and DOWN payload progress._
   * - Headers like the ones generated in this routine
   * can display progress bars for either upload or download */
  getHeadersReportProgress() {
    return {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }),
      reportProgress: true,
      responseType: 'json',
      observe: 'events'
    } as any;
  }

  getHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
  }

  getUploadHeaders(): any {
    return {
      responseType: 'json',
      reportProgress: true,
      observe: 'events',
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest',
        'ngsw-bypass': 'bypassForUploadRequest'
      })
    } as any;
  }

  getPDFGenerateHeaders(): any {
    return {
      requestType: 'json',
      responseType: 'blob',
      reportProgress: true,
      observe: 'events',
      headers: new HttpHeaders({
        'X-Requested-With': 'XMLHttpRequest',
        'ngsw-bypass': 'bypassForDownloadRequest'
      })
    } as any;
  }

  protectedEndpoint<T>(stream: Observable<T>): Observable<T> {
    return stream.pipe(
      catchError(error => {
        if (error.status === 401) {
          this.authSvc.clear();
          //TODO
          // window.location.href = environment.baseHref + '../';
        }
        return throwError(() => error);
      })
    );
  }
}
