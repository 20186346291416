<p-accordion *ngFor="let appointmentForm of appointmentForms; index as i" styleClass="appointments-accordion shadow-sm mb-3">
  <p-accordionTab [(selected)]="appointmentForm.isOpen">
    <ng-template pTemplate="header">
      <!--------------------
      -- ACCORDION HEADER --
      --------------------->
      <ng-container>
        <div class="d-flex w-100 p-0">
          <div class="d-inline-flex flex-column align-items-center justify-content-around py-2 border-right" style="width: 50px">
            <p-badge [value]="appointmentForm.forms.length.toString() ?? '0'" size="medium"></p-badge>
            <fa-icon [icon]="(appointmentForm.isOpen ? ['fas', 'chevron-up'] : ['fas', 'chevron-down'])" size="lg" class="text-muted"></fa-icon>
          </div>

          <div *ngIf="appointmentForm.appointment" class="container-fluid" style="width: calc(100% - 50px)">
            <div class="row justify-content-between align-items-center pt-2 pb-1">
              <div class="col-12 col-lg-4 col-xl-3 order-1 p-1 text-truncate">
                <small *ngIf="appointmentHeaderConfiguration.A1.LABEL">{{ appointmentHeaderConfiguration.A1.LABEL + ': ' }}</small>
                <span nextDisplayModelProperty [propertyPath]="appointmentHeaderConfiguration.A1.VALUE" [appointment]="appointmentForm.appointment"></span>
              </div>
              <div class="col-12 col-lg-4 col-xl-3 order-2 p-1 text-truncate">
                <small *ngIf="appointmentHeaderConfiguration.A2.LABEL">{{ appointmentHeaderConfiguration.A2.LABEL + ': ' }}</small>
                <span nextDisplayModelProperty [propertyPath]="appointmentHeaderConfiguration.A2.VALUE" [appointment]="appointmentForm.appointment"></span>
              </div>
              <div class="col-12 col-lg-4 col-xl-3 order-4 order-xl-3 p-1 text-truncate">
                <small *ngIf="appointmentHeaderConfiguration.A3.LABEL">{{ appointmentHeaderConfiguration.A3.LABEL + ': ' }}</small>
                <span nextDisplayModelProperty [propertyPath]="appointmentHeaderConfiguration.A3.VALUE" [appointment]="appointmentForm.appointment"></span>
              </div>
              <div class="col-12 col-lg-4 col-xl-3 order-0 order-lg-3 order-xl-4 py-1 pl-1 pr-2 text-right text-truncate">
                <button id="{{ 'btn-add-form-appointment-' + i }}" class="borderless-btn" (click)="addForm($event, appointmentForm)">
                  <fa-icon class="mr-1" [icon]="['fas', 'plus']"></fa-icon>
                  {{ 'PATIENT_FORMS.ADD_FORMS.ADD_FORM' | translate }}
                </button>
              </div>
              <div class="col-12 col-lg-4 col-xl-3 order-5 p-1 text-truncate">
                <small *ngIf="appointmentHeaderConfiguration.B1.LABEL">{{ appointmentHeaderConfiguration.B1.LABEL + ': ' }}</small>
                <span nextDisplayModelProperty [propertyPath]="appointmentHeaderConfiguration.B1.VALUE" [appointment]="appointmentForm.appointment"></span>
              </div>
              <div class="col-12 col-lg-4 col-xl-3 order-6 p-1 text-truncate">
                <small *ngIf="appointmentHeaderConfiguration.B2.LABEL">{{ appointmentHeaderConfiguration.B2.LABEL + ': ' }}</small>
                <span nextDisplayModelProperty [propertyPath]="appointmentHeaderConfiguration.B2.VALUE" [appointment]="appointmentForm.appointment"></span>
              </div>
              <div class="col-12 col-lg-4 col-xl-3 order-7 p-1 text-truncate">
                <small *ngIf="appointmentHeaderConfiguration.B3.LABEL">{{ appointmentHeaderConfiguration.B3.LABEL + ': ' }}</small>
                <span nextDisplayModelProperty [propertyPath]="appointmentHeaderConfiguration.B3.VALUE" [appointment]="appointmentForm.appointment"></span>
              </div>
              <div class="col-12 col-lg-4 col-xl-3 order-8 p-1 text-truncate">
                <small *ngIf="appointmentHeaderConfiguration.B4.LABEL">{{ appointmentHeaderConfiguration.B4.LABEL + ': ' }}</small>
                <span nextDisplayModelProperty [propertyPath]="appointmentHeaderConfiguration.B4.VALUE" [appointment]="appointmentForm.appointment"></span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>
    <ng-template pTemplate="body">
      <next-forms-table-inline
        *ngIf="appointmentForm.forms?.length"
        [forms]="appointmentForm.forms"
        [patient]="appointmentForm.appointment.patientdata"
        [appointment]="appointmentForm.appointment">
      </next-forms-table-inline>
      <div *ngIf="!appointmentForm.forms?.length" class="d-flex justify-content-center align-items-center">
        <h6 class="mb-0 p-xl-4 p-2">{{'PATIENT_FORMS.NO_APPOINTMENT_FORMS_MESSAGE' | translate}}</h6>
      </div>
    </ng-template>
  </p-accordionTab>
</p-accordion>
