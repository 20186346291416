import { Patient, RemotePatientResponse } from "./patient.model";

export enum UhiSessionLocation {
  PATIENT =  'patient',
  ACCOUNT = 'account',
  DEFAULT = ''
}

export enum UhiSessionMode {
  DEVICE_TRANSFER = 'device_transfer',
  MULTI_MONITOR = 'multi_monitor',
  REMOTE_PATIENT = 'remote_patient',
  EMBEDDED = 'embedded'
}

// TODO: Normalize with Authorization model?
// UHI will never provide an integration token
// The raw property name for access_token is also not transformed into accessToken
export interface UhiAuthorization<T = object> {
  access_token: string;
  identity: T;
  preferences: Array<object>;
}

export interface UhiSession {
  iat: number;
  exp: string;
  user?: string; // this won't be present for remote patient sessions
  mrn: string;
  accountnumber: string;
  facility: string;
  mode: UhiSessionMode;
  location: UhiSessionLocation;
}

export interface UhiResponse {
  session: UhiSession;
  user?: UhiAuthorization;
  patient?: UhiAuthorization<Patient & RemotePatientResponse>;
}

export interface EncryptedSession {
  base64: string;
  urlEncoded: string;
}
