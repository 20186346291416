import {
  ApiService,
  FormFastConfig,
  FormFastConfigService,
  TokenService,
  Authorization,
  AzureActiveDirectoryGroup
} from '@next/shared/common';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NextAuthService extends ApiService {

  constructor(
    @Inject(FormFastConfigService) config: FormFastConfig,
    private tokenSvc: TokenService,
    private http: HttpClient) { super(config, tokenSvc); }

  authorizeCode(code: string, state: string): Observable<Authorization> {
    const body = {
      grant_type: 'authorization_code',
      code: code,
      state: state
    };

    return this.protectedEndpoint<Authorization>(
      this.http.post<any>(`${this.config.apiUrl}auth/oauth/token`, body, this.getHeaders()).pipe(
        map(result => {
          return {
            accessToken: result.access_token,
            integrationToken: result.integration_token,
            identity: result.identity,
            preferences: result.preferences
          } as Authorization
        }))
    );
  }

  refreshToken(): Observable<Authorization> {
    return this.protectedEndpoint<Authorization>(
      this.http.post<any>(`${this.config.apiUrl}auth/oauth/token`, {grant_type: 'refresh_token'}, this.getHeaders()).pipe(
        map(result => {
          return {
            accessToken: result.access_token
          } as Authorization
        }))
    );
  }

  Logout(logoutUrlParams: string): Observable<{logoutUrl: string}> {
    return this.protectedEndpoint(
      this.http.get<{logoutUrl: string}>(`${this.config.apiUrl}auth/oauth/logout${logoutUrlParams}`, this.getHeaders())
    );
  }

  authorizationEndpoint({state}:ReturnType<(typeof NextAuthService.prototype.connectionParams)>): Observable<{authorization_endpoint: string}> {
    return this.protectedEndpoint(this.http.get<{authorization_endpoint: string}>(`${this.config.apiUrl}auth/oauth/authorize?&state=${state}&autoRedirect=false`, this.getHeaders()));
  }

  getAllAzureActiveDirectoryGroups(): Observable<AzureActiveDirectoryGroup[]> {
    return this.protectedEndpoint<AzureActiveDirectoryGroup[]>(
      this.http.get<AzureActiveDirectoryGroup[]>(`${this.config.apiUrl}auth/AzureActiveDirectory/groups/getAll`, this.getHeaders())
    );
  }

  connectionParams(params: URLSearchParams): {
    state: string
  } {
    // Build our OAuth state, which contains a redirect url
    const state = {
      redirect: `${window.location.origin}${window.location.pathname}`,
    };

    params.delete('token');

    const urlParams = params.toString();
    if (urlParams) {
      state.redirect += `?${urlParams}`;
    }

    const base64State = btoa(JSON.stringify(state));
    return {
      state: base64State
    }
  }
}
