<div class="h-100 background-white overflow-hidden">

    <div *ngIf="usrSvc.hasDemonstrationLicense" class="container-fluid">

      <div class="row">
        <div class="col-xl-4 col-lg-6 col-md-8 workflows py-2 rounded">
          <div class="workflow-items">
            <div class="workflow-item" *ngFor="let item of workflows">
              <div class="col-12 p-1">
                <fa-icon class="workflow-icon" [icon]="['far', 'play-circle']"></fa-icon>&nbsp;
                <span>{{ item }}</span>
             </div>
             <hr class="mx-2 my-0">
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />

    <div class="h-100 background-white">

      <div class="row px-3">
        <div class="col titlebar">
          <div id="general-information">{{ 'PATIENT_PROCESSES.SECTION_TITLE' | translate }}</div>
        </div>
      </div>
      <div class="h-100">
        <div class="container-fluid">
          <div class='height-remaining'>
            <next-activity-log [patientId]="patientId" [appointmentId]="appointmentId" [eventType]="eventType"></next-activity-log>
          </div>
        </div>
      </div>
    </div>
  </div>
