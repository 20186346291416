import { Component, Input } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenService } from '@next/shared/common';
import { UserResolverService } from '@next/shared/next-services';
import { Router } from '@angular/router';
import { StoreService } from '../state/store.service';

export enum MenuItems {
  Dashboard = 'dashboard/view',
  Patients = 'patients',
  Appointments = 'appointments',
  Forms = 'library'
}
@Component({
  selector: 'next-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.scss']
})
export class DashboardMenuComponent {
  MenuItems: typeof MenuItems = MenuItems;  // Allow this enum to be used in the template

  @Input() title: string;
  @Input() activeItem = '';

  jwtHelper = new JwtHelperService();
  token = null;
  data: any;

  constructor(
    private tokenService: TokenService,
    private router: Router,
    public stateSvc: StoreService,
    public userSvc: UserResolverService
  ) {
    this.token = this.jwtHelper.decodeToken(this.tokenService.getAccessToken());
  }

  openPage(route: string) {
    this.router.navigate([route]);
  }

}
