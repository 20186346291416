import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  RendererStyleFlags2
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Directive({
  selector: '[nextUhiEmbedded]',
  exportAs: 'nextUhiEmbedded',
})
export class UhiEmbeddedDirective implements OnInit, OnDestroy {
  private obsCleanup = new Subject<void>();

  uhiEmbedded: boolean = false;
  @Input() set nextUhiEmbedded(state: true) {
    if (state) {
      this.updateEmbeddedState();
    }
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router
  ) {
  }
  
  ngOnInit(): void {
    this.uhiEmbedded = this.router['uhiEmbedded'];
  }

  ngOnDestroy(): void {
    this.obsCleanup.next();
    this.obsCleanup.complete();
  }

  private updateEmbeddedState(): void {
    if (this.router['uhiEmbedded']) {
      this.uhiEmbedded = true;
      this.renderer.setStyle(
        this.el.nativeElement,
        'display',
        'none',
        RendererStyleFlags2.Important
      );

    } else {
      this.renderer.removeStyle(this.el.nativeElement, 'display');
    }
  }
}
