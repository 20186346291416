import { BehaviorSubject } from "rxjs";
import { TaskDTO } from "./form.model";

export class SignQueue {

    public tasksToSign: TaskToSign[] = [];
    private _selectedTaskToSign: TaskToSign;

    constructor(tasks: TaskDTO[]) {

        this.tasksToSign = [];
        tasks.forEach(task => {
            this.tasksToSign.push({
            task: task,
            isCompleted: new BehaviorSubject<boolean>(false),
            isBypassed: false
            });
        });
    }

    select(task: TaskDTO) {
        this._selectedTaskToSign = this.tasksToSign.find(t => t.task === task);
    }

    unselect() {
        this._selectedTaskToSign = null;
    }

    get selectedTaskToSign() {
        return this._selectedTaskToSign;
    }
}


export class TaskToSign {
    task: TaskDTO;
    isCompleted = new BehaviorSubject<boolean>(false);
    isBypassed: boolean;
}