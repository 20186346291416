import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IDService, SphereConnection } from '@next/shared/common';
import { NextExternalConnectionService } from '@next/shared/next-services';

@Component({
  selector: 'next-payment-gateway',
  templateUrl: './payment-gateway.component.html',
  styleUrls: ['./payment-gateway.component.css']
})

export class PaymentGatewayComponent implements OnInit {
  static readonly aggregatorID: string = '1P6BYSZ';
  @Input() field :any;
  @Input() form : any;
  url: SafeResourceUrl;

  constructor (
    private sanitizer: DomSanitizer,
    private connectionService: NextExternalConnectionService,
    private idSvc: IDService
  ) { }

  ngOnInit(): void {
    const name: string = this._setupField('nameToFieldMapping');
    const postal: string = this._setupField('postalCodeToFieldMapping');
    const email: string = this._setupField('emailToFieldMapping');
    const accountNumber: string = this._setupField('accountNumberToFieldMapping');
    const payment: string = this._setupField('paymentToField') || this.field.paymentAmount;

    this.connectionService.getExternalConnectionById(this.field.externalConnection).subscribe(conn => {
      this.url = this._constructURL(conn.data, name, postal, email, accountNumber, payment);
    });
  }
  
  private _setupField(fieldProperty: string): string {
    return (this.field[fieldProperty] === '') ? '' : this.form.controls.get(fieldProperty)?.value?.Value?.Text ?? '';
  }

  private _constructURL(connection: SphereConnection, name: string, zip: string, email: string, accNumber: string, payment: string): SafeResourceUrl {
    const uuid: string = this.idSvc.generate().replace(/-/g, '').slice(0,25);

    // Note: when we support the Sphere Webhook, add this back in:
    // &response_url=${connection.sphereResponseUrl}
    return this.sanitizer.bypassSecurityTrustResourceUrl(`${connection.sphereUrl}?`+
      `trxcustid=${connection.sphereCustomerId}&trxcustid_licensekey=${connection.sphereLicenseKey}`+
      `&ticketno=${uuid}&amount=${payment}&checking_ach=${this.field.allowAchPayment ? "y" : "n" }&show_email=y`+
      `&storeonly=${this.field.storePaymentOnly ? "y" : "n"}&hide_ticket=y&hide_bottomlogo=y&name_on_card=${name}&postal=y`+
      `&amountequalorless=${this.field.allowPaymentEdit ? "y" : "n" }&amountequalorgreater=${this.field.allowPaymentEdit ? "y" : "n" }&baddress_postal=${zip}`+
      `&email_address=${email}&trxcustomfield[1]=${accNumber}`+
      `&aggregators=${PaymentGatewayComponent.aggregatorID}`
    );
  }
}
