import { Injectable } from '@angular/core';
import { Orientation } from '../models/orientation.model';
import { IRange } from '../models/patient.model';
import { TranslateService } from '@ngx-translate/core';
import { DataMapping, SettingEnum } from '../models/admin.model';



@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(private translateSvc: TranslateService) {}

  screenOrientation(height: number, width: number): string {

    if (height === undefined || width === undefined) {
        return null;
    }

    if (height > width) {
        return Orientation.portrait
    }

    if (width > height) {
        return Orientation.landscape
    }
  }

  public getCustomDateRanges(): IRange[] {
    const today = new Date()
    return [{
      value: [today, today],
      label: this.translateSvc.instant('APPOINTMENTS.CALENDAR_DATE_RANGE.TODAY')
    }, {
      value: [this.getDateByDaysFromToday(1), this.getDateByDaysFromToday(1)],
      label: this.translateSvc.instant('APPOINTMENTS.CALENDAR_DATE_RANGE.TOMORROW')
    }, {
      value: [today, this.getDateByDaysFromToday(7)],
      label: this.translateSvc.instant('APPOINTMENTS.CALENDAR_DATE_RANGE.NEXT7DAYS')
    }];
  }


  /**
  * Returns a date that is n days ahead of the current date.
  * @param daysAhead Number of days into the future to get the desired date.
  */
  private getDateByDaysFromToday(daysAhead: number): Date {
    return new Date(new Date().setDate(new Date().getDate() + daysAhead));
  }

  public getDataMapping(configData): DataMapping {
    return {
      [SettingEnum.Patient]: [
        ...configData['Patient'].map((x) => ({
          ...x,
          SettingDisplayName: `Patient - ${x.DisplayName}`,
        })),
      ],
      [SettingEnum.Appointment]: [
        ...configData['Patient'].map((x) => ({
          ...x,
          SettingDisplayName: `Patient - ${x.DisplayName}`,
        })),
        ...configData['Appointment'].map((x) => ({
          ...x,
          SettingDisplayName: `Appointment - ${x.DisplayName}`,
        })),
        {
          id: 'hasflaggedforms',
          DisplayName: 'Flagged To Move',
          FieldName: 'hasflaggedforms',
          SettingDisplayName: 'Appointment - Flagged To Move',
        },
        {
          id: 'appointmentformsflagged',
          DisplayName: 'Ready To Move',
          FieldName: 'appointmentformsflagged',
          SettingDisplayName: 'Appointment - Ready To Move',
        },
        {
          id: 'formstatus',
          DisplayName: 'Form Status',
          FieldName: 'formstatus',
          SettingDisplayName: 'Appointment - Form Status',
        },
        {
          id: 'updatedon',
          DisplayName: 'Updated On',
          FieldName: 'updatedon',
          SettingDisplayName: 'Appointment - Updated On',
        },
        ...configData['Facility']
          .filter((x) => ['name', 'shortname'].includes(x.FieldName))
          .map((x) => {
            return {
              ...x,
              ...{
                DisplayName: `Facility ${x.DisplayName}`,
                FieldName: `facility${x.FieldName}`,
                SettingDisplayName: `Facility - ${x.DisplayName}`,
              },
            };
          }),
        ...configData['Department']
          .filter((x) => ['name', 'shortname'].includes(x.FieldName))
          .map((x) => {
            return {
              ...x,
              ...{
                DisplayName: `Dept. ${x.DisplayName}`,
                FieldName: `department${x.FieldName}`,
                SettingDisplayName: `Dept. - ${x.DisplayName}`,
              },
            };
          }),
      ],
      [SettingEnum.Form]: [
        ...configData['Metadata']
          .filter((x) => ['tags'].includes(x.FieldName))
          .map((x) => ({
            ...x,
            SettingDisplayName: `Form - ${x.DisplayName}`,
          })),
        {
          id: 'status',
          DisplayName: 'Status',
          FieldName: 'status',
          SettingDisplayName: 'Form - Status',
        },
        {
          id: 'name',
          DisplayName: 'Name',
          FieldName: 'name',
          SettingDisplayName: 'Form - Name',
        },
        {
          id: 'category',
          DisplayName: 'Category',
          FieldName: 'category',
          SettingDisplayName: 'Form - Category',
        },
        {
          id: 'type',
          DisplayName: 'Type',
          FieldName: 'type',
          SettingDisplayName: 'Form - Type',
        },
        {
          id: 'facility',
          DisplayName: 'Facility',
          FieldName: 'facility',
          SettingDisplayName: 'Form - Facility',
        },
        {
          id: 'language',
          DisplayName: 'Language',
          FieldName: 'language',
          SettingDisplayName: 'Form - Language',
        },
      ],
    };
  }
}
