import { HttpClient, HttpEventType } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiService,
  FormFastConfig,
  FormFastConfigService,
  TokenService,
} from '@next/shared/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PDFService extends ApiService {

  constructor(
    @Inject(FormFastConfigService) config: FormFastConfig,
    tokenService: TokenService,
    private http: HttpClient
  ) {
    super(config, tokenService);
  }

  /**
   * getSchema()
   * **************************************************************************
   * *      POST that returns our processed PDF FormFields schema object.
   * @param urlPayload - Object of Blob Storage Signed Url.  Be mindful these can expire!
   * @return PDF Form scheme */
  getSchemaFromS3(urlPayload: { url: string }) {
    return this.protectedEndpoint<any>(
      this.http.post<any>(`${this.config.apiUrl}pdf/schema`, urlPayload, this.getHeaders())
    );
  }

  /**
   * getSchemaFromFile()
   * **************************************************************************
   * *      POST that returns our processed PDF FormFields schema object.
   * @param document - File object, to pass PDF multer middleware, .pdf suffix required
   * @return PDF Form scheme */
  getSchemaFromFile(document: File) {
    const formData = new FormData();
    formData.append('pdf-form', document);
    return this.protectedEndpoint<any>(
      this.http.post<any>(`${this.config.apiUrl}pdf/file`, formData, this.getUploadHeaders())
    );
  }

  /**
   * mergePdfs()
   * **************************************************************************
   * *      POST File IDs of PDFs to generate a merged, flattened PDF data-uri.
   * @param ids - IDs of files to retrieve minio S3 URLs from.
   * @return Data URI of mime-type application/pdf. */
  mergePdfs(ids: string[]): Observable<string> {
    return this.protectedEndpoint<string>(
      this.http.post<string>(`${this.config.apiUrl}pdf/merge`, ids, this.getHeaders())
    );
  }

  /**
   * stampPdfs()
   * **************************************************************************
   * *      POST Form-Data config of Destination PDF URL, and Sources object array to DynamicPDF.
   * *      The response will be a new PDF generated by DynamicPDF, stamping as config instructs.
   * @param config - Series PDF Urls and Page Numbers that instructs DynamicPDF where to stamp.
   * @return application/pdf response */
  stampPdfs(config: any): Observable<any> {
    return this.protectedEndpoint<any>(
      this.http.post<any>(`${this.config.apiUrl}pdf-fill/stampBulk`, config, this.getPDFGenerateHeaders()).pipe(
        map((result) => {
          switch (result.type) {
            case HttpEventType.Response:
              return new Blob([result.body], { type: result.body.type })
          }
        })
      ));
  }
}
