export interface Tag {
  id: string;
  tag: string;
  count: number;
  active: string | boolean;
  currentreferences?: string
}

export interface TagFilter {
  activeTags: Tag[];
  experienceIds: Set<string>;
}

export interface TagJunction {
  tagid: string;
  elementid: string;
  elementtype: string;
  createdOn: Date;
  updatedOn: Date;
}

export enum TagMode {
  search = 'search',
  edit = 'edit',
  add = 'add'
}

