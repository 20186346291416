<div class="d-block pt-3">
  <div class="p-3 border rounded form-group" [ngClass]="{
  'border-success' : isValid,
  'border-danger' : !isValid && hasInput }">
    <div *ngIf="field.title" class="section-title h3">
      {{ field.title }}
      <span *ngIf="!isValid && hasInput" class="required">*</span>
    </div>
    <hr *ngIf="field.title" class="mt-0">
    <div *ngFor="let subField of field.fields">
      <next-viewer-element
        [form]="form"
        [field]="subField"
        [initialState]="initialState"
        [needsValidation]="needsValidation"
        (statusChange)="childStatusReceived()"
        (valueChanged)="emitValueChanged($event)">
      </next-viewer-element>
    </div>
  </div>
</div>



