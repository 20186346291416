<div class="d-flex align-items-start justify-content-end pr-1">
  <button data-test="current-grid-name" id="btn-select-view-dropdown-button" class="btn ih-btn-primary-o d-flex" [disabled]="stateSvc.enterpriseSettings.explicitRecordSearch" (click)="onGridSettingModal()">
    <label class="current-grid-name pr-1">{{ currentGridName }}</label>
    <fa-icon [icon]="['fas', 'caret-down']" size="lg" ></fa-icon>
  </button>

  <div class="btn-group btn-group float-right" [isDisabled]="stateSvc.enterpriseSettings.explicitRecordSearch" dropdown container="body" placement="bottom right">
    <button
      data-test="grid-save-button"
      id="grid-save-button"
      type="button"
      dropdownToggle
      class="btn ih-btn-primary-o"
      aria-controls="dropdown-split">
      <fa-icon [icon]="['fas', 'save']" class="p-0 fa-icon" size="lg"></fa-icon>
    </button>

    <ul id="grid-list" *dropdownMenu class="dropdown-menu dropdown-menu-right list-group list-group-flush bg-light" role="menu" aria-labelledby="button-split">
      <li role="menuitem">
        <button data-test="grid-list-save-current" id="grid-list-save-current" type="button" class="btn btn-light w-100 h-100 text-left rounded-0" (click)="save()">
          {{ 'SHARED_GRID.SAVE_MENU.SAVE_TO_CURRENT' | translate }}
        </button>
      </li>
      <li role="menuitem">
        <button data-test="grid-list-save-new" id="grid-list-save-new" type="button" class="btn btn-light w-100 h-100 text-left rounded-0" (click)="saveAs()">
          {{ 'SHARED_GRID.SAVE_MENU.SAVE_AS_NEW' | translate }}
        </button>
      </li>
    </ul>
  </div>

  <button data-test="grid-column-modal" 
    class="btn ih-btn-primary-o d-inline-block float-right" 
    disabled="{{stateSvc.enterpriseSettings.explicitRecordSearch? 'disabled': null}}" 
    (click)="onGridColumnModal()">
    <fa-icon [icon]="['fas', 'cog']" class="p-0 fa-icon" size="lg"></fa-icon>
  </button>
</div>

