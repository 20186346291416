import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { filter } from 'rxjs/operators';
import { Subject, takeUntil, tap } from 'rxjs';
import { MainComponent } from '@next/gx-viewer/feature-shell';
import { StateViewerService } from '../../../state/state-viewer.service';
import { SelectedForms, WindowMessageEventName } from '@next/shared/common';
import { TranslateService } from '@ngx-translate/core';
import { NextAdminService } from '@next/shared/next-services';

@Component({
  selector: 'next-clinical-footer-pdf',
  templateUrl: './clinical-footer-pdf.component.html',
  styleUrls: ['./clinical-footer-pdf.component.css'],
})
export class ClinicalFooterPdfComponent implements OnChanges, OnDestroy {
  @Input() viewerComponent: ComponentRef<MainComponent> = null;
  @Output() nextFormEvent: EventEmitter<null> = new EventEmitter<null>();
  obsChange: Subject<void> = new Subject<void>();

  currentPage: number;
  totalPages: number;
  eventBus: any;
  time: number = 0;
  loading: boolean = true;
  selectedForms: SelectedForms;

  constructor(private zone: NgZone, private translateSvc: TranslateService, private viewerStateSvc: StateViewerService, private adminSvc: NextAdminService) {
    this.selectedForms = viewerStateSvc.selectedForms;
  }

  ngOnChanges(): void {
    this.loading = true;
    this.obsChange.next();

    this.viewerComponent.instance.nxtPdfViewer.pdfViewerEmitter.pipe(
      filter((message: any) => message.eventName === WindowMessageEventName.PagesLoaded),
      tap(data => {
        this.zone.run(() => {
          this.currentPage = data.pagesCurrent;
          this.totalPages = data.pagesCount;
          this.eventBus = data.viewerApp.eventBus;
          this.eventBus.on('pagechanging', (e) => this.currentPage = e.pageNumber);
          this.loading = false;
        });
      }),
      takeUntil(this.obsChange)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.obsChange.next();
    this.obsChange.complete();
  }

  previousPage(): void {
    this._createMetric(false);
    this.eventBus.dispatch('previouspage');
  }

  nextPage(): void {
    this._createMetric(true);
    this.eventBus.dispatch('nextpage');
  }

  nextForm(): void {
    this.nextFormEvent.emit();
  }

  getFinishButtonLabel(): string {
    if (this.selectedForms.isLastForm) {
      return this.viewerComponent.instance.nxtPdfViewer.isFormValidForCurrentView
        ? this.translateSvc.instant('FORM_VIEWER.FOOTER.FINISH_CLOSE')
        : this.translateSvc.instant('FORM_VIEWER.FOOTER.SAVE_CLOSE');
    }
    else {
      return this.viewerComponent.instance.nxtPdfViewer.isFormValidForCurrentView
        ? this.translateSvc.instant('FORM_VIEWER.FOOTER.FINISH_NEXT')
        : this.translateSvc.instant('FORM_VIEWER.FOOTER.SAVE_NEXT');
    }
  }

  private _createMetric(next: boolean): void {
    const dir: string = next ? 'Next' : 'Back';
    this.adminSvc.createMetric('TimeOnPagePDFViewer', {
      'Page': this.currentPage,
      'Time': this.time,
      'Direction': dir,
      'Experience': this.selectedForms.currentForm
    });
    this.time = 0;
  }

}
