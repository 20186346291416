<div [ngClass]="{'d-none' : field.hidden}">
    <div class="form-group" [formGroup]="valueFormGroup">
      <next-form-switch id="{{ field.name }}"
                        formControlName="Text"
                        [value]="toggleValue"
                        (valueChanged)="toggle($event)">
      </next-form-switch>
    </div>
    <div>
      <div *ngIf="getFieldValue(field)" [@subField]="animateState" (@subField.done)="turnAnimationsOn()">
        <div *ngFor="let subField of field.trueFields">
          <next-viewer-element [form]="form" [field]="subField" [initialState]="initialState" [needsValidation]="needsValidation" (statusChange)="statusChangeReceived($event)" (valueChanged)="emitValueChanged($event)">
          </next-viewer-element>
        </div>
      </div>
      <div *ngIf="!getFieldValue(field)" [@subField]="animateState" (@subField.done)="turnAnimationsOn()">
        <div *ngFor="let subField of field.falseFields">
          <next-viewer-element [form]="form" [field]="subField" [initialState]="initialState" [needsValidation]="needsValidation" (statusChange)="statusChangeReceived($event)" (valueChanged)="emitValueChanged($event)">
          </next-viewer-element>
        </div>
      </div>
    </div>
</div>
