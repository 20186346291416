<p-table
  [value]="dataset"
  [columns]="columnHeaders"
  [alwaysShowPaginator]="true"
  [paginator]="true"
  [rows]="pageSize"
  [totalRecords]="totalItems"
  [showCurrentPageReport]="true"
  responsiveLayout="scroll"
  columnResizeMode="expand"   
  [resizableColumns]="true"
  scrollHeight="calc(100vh - 325px)"
  autoLayout="true"
  sortMode="single"
  [scrollable]="true"
>
<ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [id]="col.field" class="text-overflow" pSortableColumn="{{ col.field }}">
        <div class="d-flex justify-content-between align-items-center">
          <span>{{ col.header }}</span>
          <p-sortIcon field="{{col['field']}}"></p-sortIcon>
          <p-columnFilter *ngIf="col['field'] === 'eventtype' || col['field'] === 'formname'" 
          oninput="filter($event)"
          matchMode="contains"  
          type="text" 
          class="float-right" 
          [field]="col['field']" 
          [showMatchModes]="false"
          display="menu"
          [showAddButton]="false"
          [showOperator]="false"
          ></p-columnFilter>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-columns="columns">
    <tr>
      <td *ngFor="let col of columns" class="text-overflow align-middle">
        <ng-container *ngIf="col.field !== 'eventdate'">
          {{ col?.nested_field ? rowData[col.field]?.[col.nested_field] ?? '' :rowData[col.field]}}
        </ng-container>
        <ng-container *ngIf="col.field === 'eventdate'">
          {{ rowData[col.field] | date: 'M/d/yyyy h:mm a' }}
        </ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td class="text-overflow align-middle"></td>
    </tr>
  </ng-template>
</p-table>
<!-- <div class="card flex justify-content-center">
  <p-paginator (onPageChange)="onPageChange($event)" [first]="0" [rows]="pageSize" [totalRecords]="totalItems"></p-paginator>
</div> -->