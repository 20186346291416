import { TextboxInputType } from "./viewer.model";

export type FieldSubmissionData = {
  Type: SubmissionDataType;
  Value: {
    Text?: string | string[];
    ImageSource?: any;  // or some other type
    Format?: TextboxInputType | string;
    SignedDate?: string;
    Strokes?: any[];
    FileID?: string;
    FormID?: string;
    OutputFormat?: string;
    DisplayText?: string;
    Timestamp?: {
      Location?: string;
      Value?: string;
    };
  }
}

type PrefillObject = { [key: string]: any } | undefined;

export type SubmissionData = {
  [key: string]: FieldSubmissionData & PrefillObject;
}

/**
 * FormSubmission model contains properties for both the Form and FormHistory table in our DB
 *
 * taskId is not in either tables, but if passed when creating or updating a form it will
 * update the Task table as well.
 */
export interface FormSubmission {
  id: string,
  experienceversionid: string;
  submissiontype: SubmissionType;
  updatedby: string;
  fileid: string;
  data: SubmissionData;
  metadata: SubmissionMetadata;
  taskId?: string;
  updatedon?: string;   // FHISTORY createdon
  lastupdated: any;     // FORM updatedon formatted
  createdon?: string;
  personid?: string;
  appointmentid?: string;
}

export interface SubmissionMetadata {
  page: number;
}

export interface Attachment {
  attachmentUrl: string;
  contenttype: string;
  fieldid: string;
  name: string;
  signedUrl: string;
}

export enum SubmissionType {
  Saved = 'saved',
  Submitted = 'submitted',
  Initialized = 'initialized'
}

export enum SubmissionDataType {
  Text = 'Text',
  Signature = 'Signature',
  TypedSignature = 'TypedSignature',
  Drawing = 'Drawing',
  File = 'File'
}
