import { Component, Input } from '@angular/core';
import { ActivityProcessType } from '@next/shared/common';
import { UserResolverService } from '@next/shared/next-services';

@Component({
  selector: 'next-processes-tab',
  templateUrl: './processes-tab.component.html',
  styleUrls: ['./processes-tab.component.scss']
})
export class ProcessesTabComponent {

  @Input() workflows: any;
  @Input() patientId: string;
  @Input() appointmentId: string;
  @Input() eventType: ActivityProcessType;
  
  constructor (
    public usrSvc: UserResolverService
  ) { }

}
