import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  IntegrationType,
  AuthToken,
  AzureActiveDirectoryGroup,
  IhPermissions,
  PreferenceType,
  TokenService,
  User,
} from '@next/shared/common';
import { NextAuthService } from '../auth.service';
import { map, tap } from 'rxjs/operators';
import { NextAdminService } from '../admin.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UserResolverService {

  jwt: any;
  permissions: any[];
  jwtHelper = new JwtHelperService();
  _usesPatients = true;
  _usesAuthorization = true;
  private _integrationType: string;
  _isTestEnvironment: boolean = false;

  azGroups$: BehaviorSubject<AzureActiveDirectoryGroup[]> = new BehaviorSubject<AzureActiveDirectoryGroup[]>([]);
  user$: BehaviorSubject<User> = new BehaviorSubject<User>({
    firstName: '',
    lastName: '',
    oid: '',
    integration: '',
    groups: []
  });

  constructor (
    private translateSvc: TranslateService,
    private tokenSvc: TokenService,
    private authSvc: NextAuthService,
    private adminSvc: NextAdminService
  ) { }

  getCustomPermission(permission: typeof IhPermissions[keyof typeof IhPermissions]): boolean {
    return this.permissions[permission];
  }

  get hasDemonstrationLicense(): boolean {
    const licenses= JSON.parse(sessionStorage.getItem('licenses'));
    return licenses && licenses.includes('demonstration');
  }

  get usesPatients(): boolean {
    return this._usesPatients;
  }

  set usesPatients(value: boolean) {
    this._usesPatients = value;
  }

  get usesAuthorization(): boolean {
    return this._usesAuthorization;
  }

  set usesAuthorization(value: boolean) {
    this._usesAuthorization = value;
  }

  get integrationType(): string {
    return this._integrationType;
  }

  private set integrationType(type: string) {
    this._integrationType = type;
  }

  get user(): User {
    return this.user$.getValue();
  }

  set isTestEnvironment(val: boolean) {
    this._isTestEnvironment = val;
  }

  get isTestEnvironment(): boolean {
    return this._isTestEnvironment;
  }

  resolve(): Observable<User> | Observable<never> {
    const fallback: Observable<User> = of({
      firstName: null,
      lastName: null,
      oid: null,
      groups: []
    });
    try {
      const jwt = this.tokenSvc.getAccessToken();
      if (!jwt) return fallback;

      const authToken: AuthToken = this.jwtHelper.decodeToken(jwt);
      this.permissions = authToken.permissions ?? [];
      this.integrationType = authToken.integration;
      switch (this.integrationType) {
        case (IntegrationType.SALESFORCE) : {
          this.usesAuthorization = false;
          this.usesPatients = false;

          const user: User = {
            firstName: '',
            lastName: '',
            oid: authToken.sub,
            email: authToken.username, // TODO: User should store username not email
            groups: null,
            photo: null,
            thumbnail: null
          } as User;

          this.user$.next(user);
          return of(user);
        }
        case (IntegrationType.AZURE): {
          const identity = this.tokenSvc.getIdentity();

          // Process integration result Access Token
          const user: User = {
            firstName: identity.firstname,
            lastName: identity.lastname,
            oid: identity.id,
            email: identity.email,
            groups: null,
            photo: null,
            thumbnail: null
          } as User;

          const azureGroups$ = Object.keys(this.permissions).some(p => p === IhPermissions.ADMINISTRATOR) ? this.authSvc.getAllAzureActiveDirectoryGroups() : of([]);
          const preferences$ = this.adminSvc.getAllPreferenceForUser(user.oid);
          const allFacilities$ = this.adminSvc.getAllFacilities().pipe(map(res => Array.isArray(res) ? res.filter(f => f.isactive) : []));
          const allDepartments$ = this.adminSvc.getDepartments().pipe(map(res => Array.isArray(res) ? res : []));
          const tenantSettings$ = this.adminSvc.getTenantSettings();
          const integrationToken = this.tokenSvc.getIntegrationToken(); // Deprecated.

          return forkJoin([azureGroups$, preferences$, allFacilities$, allDepartments$, tenantSettings$]).pipe(
            tap(([azureGroups,,,,]) => this.azGroups$.next(azureGroups)),
            tap(([, preferences, allFacilities, allDepartments, tenantSettings]) => this._setupBrowserMetric(user, tenantSettings, allFacilities, allDepartments, preferences)),
            tap(([,,,,tenantSettings]) => this._setEnvironment(tenantSettings)),
            map(([azureGroups, preferences,,,]) => this._formatUser(user, preferences, authToken, azureGroups)),
            tap(user => this._setAvatar(user, integrationToken)),
            tap(user => this.user$.next(user)));
        }
      }
    }
    catch {
      return fallback;
    }
  }

  private _setAvatar(user, token): void {
    // Use window.fetch() to bypass NG auth interceptors, if invalid access token, just 401 with no redirect/reauth attempt
    // This call is not critical for the application, let the 401 be thrown and move on
    const photo: Promise<any> = window.fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
      method: 'GET',
      headers: {
        'content-type': 'image/*',
        'Authorization': `bearer ${token}`,
      }
    });
    photo.then(async response => {
      const reader = new FileReader();
      reader.readAsDataURL(await response.blob());
      reader.onloadend = () => {
        user.photo = reader.result as string;
        const stateUser = this.user;
        this.user$.next({ ...stateUser, photo: reader.result as string, ...user });
      }
    });
  }

  private _formatUser(user, preferences, token, azGroups): User {
    user.preferences = preferences;
    if (this.getCustomPermission(IhPermissions.ADMINISTRATOR)) {
      user.groups = ((token.groups || []).map(id => (azGroups || []).find(group => group.id === id))|| []).filter(el => !!el);
      return user;
    }
    else {
      user.groups = (token.groups || []).map(id => { return { id: id, displayName: '' }});
      return user;
    }
  }

  private _setEnvironment(tenantSettings: any): void {
    this.isTestEnvironment = tenantSettings?.generalsettings?.environment === 'Test'
  }

  private _setupBrowserMetric(user: User, tenantSettings, facilities, departments, userPreferences): void {
    const metricInstanceName = tenantSettings?.generalsettings?.gainsight;
    if (!metricInstanceName) return;
    let metricLicense: string = '';
    switch (metricInstanceName) {
      case 'Production':
        metricLicense = 'AP-PWTP9QHVH1PQ-2';
        break;
      case 'Stage':
        metricLicense = 'AP-PWTP9QHVH1PQ-2-2';
        break;
      case 'QA':
        metricLicense = 'AP-PWTP9QHVH1PQ-2-3';
        break;
      case 'Integration':
        metricLicense = 'AP-PWTP9QHVH1PQ-2-4';
        break;
    }

    const script = document.createElement('script');
    script.async = true;
    script.innerText = `(function(n,t,a,e,co){var i="aptrinsic";n[i]=n[i]||function(){ (n[i].q=n[i].q||[]).push(arguments)},n[i].p=e;n[i].c=co; var r=t.createElement("script");r.async=!0,r.src=a+"?a="+e; var c=t.getElementsByTagName("script")[0];c.parentNode.insertBefore(r,c) })(window,document,"https://web-sdk.aptrinsic.com/api/aptrinsic.js","${metricLicense}");`;
    document.head.append(script);

    const facilityPreference = userPreferences.find(x => x.type === PreferenceType.Facility);
    const facilityId = facilityPreference ? facilityPreference.data[PreferenceType.Facility] : '-1';
    const departmentPreference = userPreferences.find(x => x.type === PreferenceType.Department);
    const departmentId = departmentPreference ? departmentPreference.data[PreferenceType.Department] : '-1';
    const facilityName = (facilityId === '-1')
      ? this.translateSvc.instant('LANDING_PAGE.ALL_DEPARTMENTS')
      : facilities.find(facility => facility.id === facilityId)?.name;
    const departmentName: string = (departmentId === '-1')
      ? this.translateSvc.instant('LANDING_PAGE.ALL_DEPARTMENTS')
      : departments.find(department => department.id === departmentId)?.name;
    const userInfoForGainSight = {
      'id': user.oid,
      'firstName': user.firstName,
      'lastName': user.lastName,
      'email': user.email,
      "facility": facilityName,
      "department": departmentName,
    };
    const accountInfoForGainSight = {
      'id': tenantSettings?.generalsettings?.sfcid || '0018000000Otz7W',
      'sfdcId': tenantSettings?.generalsettings?.sfcid || '0018000000Otz7W',
    };
    (window as any).aptrinsic('identify', userInfoForGainSight, accountInfoForGainSight);
  }
}
