import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'next-form-switch',
  template: `
    <ng-container *ngIf="!form">
      <label class="toggle">
        <input
          [id]="id"
          [name]="name"
          type="checkbox"
          (change)="toggleValue($event)"
          [checked]="value"/>
        <span class="handle"></span>
      </label>
    </ng-container>
    <ng-container *ngIf="form" [formGroup]="form">
      <label class="toggle">
        <input
          [id]="id"
          [name]="name"
          formControlName="{{controlName}}"
          type="checkbox"
          (ngModelChange)="toggleValue($event)"/>
        <span class="handle"></span>
      </label>
    </ng-container>

  `,
  styleUrls: ['./form-switch.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FormSwitchComponent {
  @Input() id;
  @Input() name;
  @Input() value: boolean;
  @Input() form: UntypedFormGroup;
  @Input() controlName: string;
  @Output() valueChanged = new EventEmitter<boolean>();

  toggleValue(event: any): void {
    this.value = event?.target?.checked ?? false;
    this.valueChanged.emit(this.value);
  }
}
