import { DateRangeSelection } from "./patient.model"

export interface ConfigTableInterface {
  id: string,
  label: string,
  type: ConfigType,
  data: any,
  updatedon: string,
  createdon: string
}

export enum ConfigType {
  Hl7 = 'HL7 Configuration',
  Archival = 'Archival',
  DataMapping = 'Data Mapping',
  MetadataMapping = 'Metadata Mapping',
  TemplateMapping = 'Template Mapping',
  FacilityMapping = 'Facility Mapping',
  DepartmentMapping = 'Department Mapping',
  EnterpriseSettings = 'Enterprise Settings',
  AutoSign = 'Auto Sign',
  Notification = 'Notification',
  NotificationBackup = 'Notification Backup',
  SystemSettings = 'System Settings',
  BarcodeScanningProfiles = 'Barcode Scanning Profiles',
  PdfTemplateMappings = 'Pdf Template Mappings',
  SmsSettings = 'Sms Settings',
  PatientRemoteAccess = "Patient Remote Access"
}

export enum EnterpriseLabels {
  Dashboard = 'dashboard',
  Patients = 'patients',
  Appointments = 'appointments',
  Forms = 'forms',
  Retention = 'retention'
}


export enum ContactAssociation {
  Facility = 'Facility',
}

export interface EnterpriseSettings {
  dashboard: string,
  patients: string,
  appointments: string,
  forms: string,
  timeoutValue: number,
  explicitRecordSearch: boolean,
  retention: EnterpriseRetentionConfig,
  appointmentHeaders: EnterpriseAppointmentAccordionHeaderConfig
}

export interface EnterpriseRetentionConfig {
  LOG: { daysUser: number, daysSystem: number },
  PATIENT: { formsNone: boolean, appointmentsNone: boolean },
  APPOINTMENT: { dateAdmit: number, dateDischarge: number },
  FREQUENCY: { days: boolean[], time: string }
}

export interface EnterpriseAppointmentAccordionHeaderConfig {
  A1: { LABEL: string, VALUE: string },
  A2: { LABEL: string, VALUE: string },
  A3: { LABEL: string, VALUE: string },
  B1: { LABEL: string, VALUE: string },
  B2: { LABEL: string, VALUE: string },
  B3: { LABEL: string, VALUE: string },
  B4: { LABEL: string, VALUE: string },
}

export interface SmsSettings {
  FREQUENCY: { days: boolean[], time: string },
  APPOINTMENT: { dateAdmit: number }
}

export interface PreferenceSubmission {
  id: string,
  userid: string,
  type: string,
  data: any
}

export interface DefaultSignatures {
  INITIALS: string,
  STROKES: unknown[],
  TEXT: string
}

export type DisplayColumn = Omit<DataMappingConfig, 'isMeta'>

export interface GridSetting {
  id?: string,
  userid: string,
  isdefault?: boolean,
  settingname: string,
  displaycolumn: {data: DisplayColumn[]},
  sortby: any,
  filterby: any,
  screen: string,
  updatedon?: Date,
  createdon?: Date,
  daterangefilter?: Partial<DateRangeSelection>
}

export interface DataMapping {
  [SettingEnum.Patient]: (DataMappingConfig & { SettingDisplayName?: string })[],
  [SettingEnum.Appointment]: (DataMappingConfig & { SettingDisplayName?: string })[],
  [SettingEnum.Form]: (DataMappingConfig & { SettingDisplayName?: string })[]
}

export interface DataMappingConfig {
  id: string,
  isMeta: boolean,
  FieldName: string ,
  DisplayName: string
}

export interface NotificationSettings {
  id: string,
  loginscreentext: string,
  loginscreenstart: Date,
  loginscreenend: Date,
  inapptext: string,
  inappstart: Date,
  inappend: Date
}

export enum SettingEnum {
  Appointment = 'Appointments',
  Patient  = 'Patients',
  Form = 'Forms',
  FormManagement = 'FormManagementTab'
}

export enum ViewType {
  Menu = 'Menu',
  Table = 'Table'
}

export enum ConfigEnum {
  Id = 'id',
  Label = 'label',
  Type = 'type',
  Data = 'data',
  UpdatedOn = 'updatedon',
  CreatedOn = 'createdon'
}

export enum Hl7ConfigEnum {
  Id = 'id',
  Label = 'label',
  Type = 'type',
  Event = 'event',
  Namespace = 'namespace',
  Mapping = 'mapping',
  Data = 'data'
}

export enum Hl7ConfigMappingEnum {
  Label = 'label',
  Name = 'name',
  ReferenceId = 'referenceId',
  Action = 'action',
  Fields = 'fields',
  Type = 'type'
}

export enum Hl7ConfigActionsEnum {
  Create = 'create',
  Upsert = 'upsert',
  Read = 'read',
  Update = 'update'
}

export enum Hl7ConfigMappingFieldEnum {
  Source = 'src',
  Lit = 'lit',
  Destination = 'dest',
  Type = 'type',
  IsExternalId = 'isExternalId'
}

export enum HL7ConfigMappingFieldFormatEnum {
  Date = 'date',
  Datetime = 'datetime',
  Boolean = 'boolean',
  Phone = 'phone',
  Email = 'email',
  String = 'string',
  Reference = 'reference'
}

export enum IhObjectNameEnum {
  Patient = 'patientdata',
  Appointment = 'appointment'
}

export interface License {
  id: string,
  licensekey: any,
  updatedon: string,
  createdon: string
}

export interface Facility {
  id?: string,
  logoid?: string,
  name?: string,
  address1?: string,
  address2?: string,
  city?: string,
  state?: string,
  groups?: string[],
  departments?: Department[],
  postalcode?: string,
  website?: string,
  facilityid?: string,
  shortname?: string,
  isactive: boolean,
  updatedon?: Date,
  createdon?: Date
}

export interface FacilityGroup {
  id: string,
  facilityid: string,
  groupid: string,
  updatedon?: Date,
  createdon?: Date
}

export interface Contact {
  id?: string,
  associationid?: string,
  association?: string,
  displayname?: string,
  datatype?: string,
  data?: string,
  updatedon?: Date,
  createdon?: Date
}

export interface Dataset {
  id: string,
  name: string,
  columnnames: string[],
  searchparameters: string[],
  datasets: any[],
  createdon: string,
  updatedon: string
}

export interface ActivityLog {
  id: string,
  activityname: string,
  status: string,
  rundate: string,
  results : string,
  updatedon?: Date,
  createdon?: Date
}

export interface HardCodeColumn {
  id: string,
  name: string
}

export interface Dashboard {
  id: number;
  name: string;
  sortorder: number;
  type: string;
}

export enum LogLevel {
  Warn = 'warn',
  Debug = 'debug',
  Info = 'info',
  Error = 'error'
}

export class Department
{
  id: string;
  name: string;
  shortname: string;
  departmentid: string;

  constructor(id, name = '') {
    this.id = id;
    this.name = name;
  }
}
