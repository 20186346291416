import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  CanActivateChild,
} from '@angular/router';
import { IhPermissions, TokenService } from '@next/shared/common';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate, CanActivateChild {

  jwtHelper = new JwtHelperService();

  constructor(private tokenService: TokenService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return of(this.hasPermissions(route));
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return of(this.hasPermissions(route));
  }

  hasPermissions(route: ActivatedRouteSnapshot): boolean {
    const token = this.jwtHelper.decodeToken(this.tokenService.getAccessToken());
    const requiredPermission: IhPermissions = route.data.requiredPermission;

    if (token && requiredPermission) {
      if (token?.permissions[requiredPermission]) {
        return true;
      }
    }

    this.router.navigateByUrl('/error');
    return false;
  }
}
