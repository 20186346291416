import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ApiService, FormFastConfig, FormFastConfigService, TokenService } from "@next/shared/common";
import { Observable } from "rxjs";


@Injectable({
    providedIn: 'root'
  })
export class ArchiveService extends ApiService {

    constructor(@Inject(FormFastConfigService) config: FormFastConfig, tokenService: TokenService, private http: HttpClient) {
        super(config, tokenService);
    }

    archive(formId: string): Observable<any> {
        return this.protectedEndpoint<any>(
            this.http.post<any>(`${this.config.apiUrl}form/${formId}/archive`, null, this.getHeaders())
          );
      }
}
