import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TaskDTO } from '@next/shared/common';
import { Injectable } from '@angular/core';
import { NextFormService } from '../form.service';

@Injectable({
  providedIn: 'root',
})
export class NextTaskResolverService {

  constructor(
    private formSvc: NextFormService
  ) { }

  resolve(route: ActivatedRouteSnapshot):  Observable<TaskDTO> | Observable<never> {
    const taskId = route.paramMap.get('taskId');
    if (taskId) {
      return this.formSvc.getTask(taskId);
    }
    else {
      return of(null);
    }
  }
}
