import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UrlTrackerService {
  private firstLevelUrls = ['dashboard', 'patients', 'appointments', 'library'];
  private secondLevelUrls = ['form', 'designer'];
  private pointOfOriginFirstLevel = 'dashboard/view';
  private pointOfOriginSecondLevel = '';

  constructor(private router: Router) {}

  private _isFirstLevelURL(url: string): boolean {
    const split: string[] = url.toLocaleLowerCase().split('/').filter(el => !!el);
    return split.some(el => this.firstLevelUrls.includes(el));
  }

  private _isSecondLevelURL(url: string): boolean {
    const split: string[] = url.toLocaleLowerCase().split('/').filter(el => !!el);
    return this.secondLevelUrls.every(el => split.includes(el))
  }

  public setOrigin(rootURL: string): void {
    this.pointOfOriginFirstLevel = rootURL;
  }

  init(): void {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(({ urlAfterRedirects }: NavigationEnd) => {
      if (this._isFirstLevelURL(urlAfterRedirects)) {
        this.pointOfOriginFirstLevel = urlAfterRedirects;
        this.pointOfOriginSecondLevel = '';
      } else if (this._isSecondLevelURL(urlAfterRedirects)) {
        this.pointOfOriginSecondLevel = urlAfterRedirects;
      }
    });
  }

  navigateToPointOfOrigin(): Promise<boolean> {
    if (this._isSecondLevelURL(this.router.url)) {
      return this.router.navigateByUrl(this.pointOfOriginFirstLevel);
    }

    const pointOfOrigin = this.pointOfOriginSecondLevel
      ? this.pointOfOriginSecondLevel
      : this.pointOfOriginFirstLevel;
    return this.router.navigateByUrl(pointOfOrigin);
  }
}
