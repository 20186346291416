<div class="spinner-container" *ngIf='loading | async' [@fadeInOut]>
  <img src='assets/images/interlace.gif' alt='Loading In Progress' class='spinner mb-2'>
  <div class='container-fluid justify-content-center'>
    <div class='row justify-content-center'>
      <div class='col-md-6 col-10'>
        <h4 class='w-100 text-center mt-1'>{{ loadingMessage }}</h4>
        <progressbar class='align-items-center ih-progress-bar' [animate]="false" [value]="loadingProgress" [striped]='true' [max]='100' type="success">
        </progressbar>
      </div>
    </div>
  </div>
</div>

<div
  id="mainTop"
  *ngIf='(loading | async) === false'
  class="container-fluid overflow-auto m-0 p-0"
  [ngClass]="{
    'viewer-scroll-area-embedded' : integration !== ViewerIntegrationMode.RAW,
    'viewer-scroll-area' : integration === ViewerIntegrationMode.RAW
  }"
  (scroll)="onScroll()">
  <next-viewer-main
    [integration]="integration"
    [formId]='formId'
    [experience]="experience"
    [attachments]="attachments"
    [initialState]="formData"
    [needsValidation]="needsValidation"
    (valueChanged)="valueChanged($event)">
  </next-viewer-main>
  <next-viewer-footer
    #viewerFooter
    [class.invisible]="integration !== ViewerIntegrationMode.RAW"
    [experience]="experience"
    [selectedPage]="selectedPage"
    [allowSave]="true"
    [submitDisabled]="btnDisabled"
    (nextPageEvent)="goNextPage()"
    (prevPageEvent)="goPrevPage()"
    (saveEvent)="submit(SubmissionType.Saved)"
    (cancelEvent)="cancel()"
    (submitEvent)="submit(SubmissionType.Submitted)"
    (needsValidationEvent)="validateRequiredFields()">
  </next-viewer-footer>
  <next-viewer-warning-ribbon [integration]="integration"></next-viewer-warning-ribbon>
</div>
