import { ValidatorFn } from "@angular/forms";

export class DynamicFormField {
  fieldName: string;
  validators: ValidatorFn[];
  defaultValue: string = null;

  public constructor(init?:Partial<DynamicFormField>) {
    Object.assign(this, init);
  }
}
