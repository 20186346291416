<div class="font-sm" *ngIf="formField?.errors && formField?.touched">
  <span class="text-danger" id="error-required" *ngIf="formField?.errors?.required">
    {{errorPrefix}} required.
  </span>
  <span class="text-danger" id="error-pattern" *ngIf="formField?.errors?.pattern">
    Invalid format.
  </span>
  <span class="text-danger" id="error-min-length" *ngIf="formField?.errors?.minlength">
    Must be at least {{formField?.errors?.minlength.requiredLength}} chars.
  </span>
  <span class="text-danger" id="error-max-length" *ngIf="formField?.errors?.maxlength">
    Must be less than {{formField?.errors?.maxlength.requiredLength}} chars.
  </span>
  <span class="text-danger" id="error-min" *ngIf="formField?.errors?.min">
    Must be greater than or equal to {{formField?.errors?.min.min}}.
  </span>
  <span class="text-danger" id="error-max" *ngIf="formField?.errors?.max">
    Must be less than or equal to {{formField?.errors?.max.max}}.
  </span>
  <span class="text-danger" id="error-email" *ngIf="formField?.errors?.email">
    Invalid format.
  </span>
  <span class="text-danger" id="error-encryption-key" *ngIf="formField?.errors?.encryptionkey">
    Must contain {{formField?.errors?.encryptionkey.requiredLength}} characters.
  </span>
  <span class="text-danger" id="error-numeric" *ngIf="formField?.errors?.numeric">
    Only digits are allowed.
  </span>
</div>
