import { Component, Input } from '@angular/core';
import { FieldBaseComponent } from '../field-base/field-base.component';

@Component({
  selector: 'next-column-layout',
  templateUrl: './column-layout.component.html',
  styleUrls: ['./column-layout.component.css']
})
export class ColumnLayoutComponent extends FieldBaseComponent {

  @Input() needsValidation: boolean;
}
