import { Injectable } from '@angular/core';
import { Logout } from "../models/authorization.model";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { defer, Observable, Subject, Subscription } from "rxjs";
import { delay, repeatWhen } from "rxjs/operators";
import { TokenService } from "./token.service";

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  isLoggedOut: boolean;
  readonly refreshAccessToken$: Subject<void> = new Subject();
  readonly sessionTimedOut$: Subject<void> = new Subject();
  private _isSubscribedToDomEvents: boolean;
  private _warningIsVisible: boolean;
  private _keepaliveDomEvents: string[] = [ "click", "mouseover", "mouseout", "keyup", "wheel", "scroll", "touchstart" ];
  private _inactivityTimeout$: Subscription;

  private _accessTokenKeepaliveInterval$: Observable<any> =
    defer(async () => this.checkAccessTokenExpirationTime())
      .pipe(
        repeatWhen(notifications => notifications.pipe(delay(Logout.CHECK_INTERVAL)))
      );

  private _inactivityTimeoutInterval$: Observable<any> =
    defer(async () => this.checkInactivityTimeout())
      .pipe(
        repeatWhen(notifications => notifications.pipe(delay(Logout.CHECK_INTERVAL)))
      );

  get timeoutMinutes(): number {
    return Number(localStorage.getItem(Logout.TIMEOUT_STORE_KEY));
  }

  /**
   * The number of minutes of inactivity before the user is logged out.
   * Setting this to 0 will prevent the inactivity logout and allow the user to remain logged in until their token expires.
   * @param value Minutes to timeout.
   */
  set timeoutMinutes(value: number) {
    if (isNaN(value) || this.timeoutMinutes === value) {
      return;
    }

    localStorage.setItem(Logout.TIMEOUT_STORE_KEY, value.toString());

    if (value === 0) {
      this.stopInactivityTimeoutJob();
      return;
    }

    this.startInactivityTimeoutJob();
  }

  constructor(
    private toastSvc: ToastrService,
    private tokenSvc: TokenService,
    private translateSvc: TranslateService) {
    if (this.timeoutMinutes > 0) {
      this.startInactivityTimeoutJob();
    }
    this._accessTokenKeepaliveInterval$.subscribe()
  }

  private startInactivityTimeoutJob(): void {
    this.updateLastActiveTimeLocalStorage();
    this.addEventListeners();
    this._inactivityTimeout$ = this._inactivityTimeoutInterval$.subscribe();
  }

  private stopInactivityTimeoutJob(): void {
    if (this._inactivityTimeout$) {
      this._inactivityTimeout$.unsubscribe();
    }
    this.removeEventListeners();
    this.removeLastActiveTimeLocalStorage();
  }

  private checkInactivityTimeout(): void {
    try {
      if (this.isLoggedOut || this.timeoutMinutes === 0) {
        return;
      }

      const lastActionTime = parseInt(localStorage.getItem(Logout.LAST_ACTION_TIME_STORE_KEY), null);
      const warningTime = this.minutesToMilliseconds(Logout.WARNING_TIME);
      const expirationTime = lastActionTime + this.minutesToMilliseconds(this.timeoutMinutes);
      const remainingTime = expirationTime - Date.now();

      if (remainingTime <= 0) {
        this.toastSvc.clear();
        this.isLoggedOut = true;
        this.sessionTimedOut$.next();
        return;
      }

      if (remainingTime <= warningTime && !this._warningIsVisible) {
        const warningTitle = this.translateSvc.instant('TOASTR_MESSAGE.LOGOUT_WARNING_TITLE');
        const warningMsg = this.translateSvc.instant('TOASTR_MESSAGE.LOGOUT_WARNING', { time: Logout.WARNING_TIME });

        this.toastSvc.clear();

        const warningToast = this.toastSvc.warning(warningMsg, warningTitle, {
          timeOut: warningTime,
          progressBar: true,
          progressAnimation: 'increasing',
          closeButton: true
        });

        warningToast.onShown.subscribe(() => this._warningIsVisible = true);
        warningToast.onHidden.subscribe(() => this._warningIsVisible = false);
      }
    }
    // eslint-disable-next-line no-empty
    catch {
      // Ignore errors to keep the timeout job running.
    }
  }

  private checkAccessTokenExpirationTime(): void {
    try {
      const millisecondsBeforeExpiration = (this.tokenSvc.getSessionTokenExpirationTime() * 1000) - Date.now();

      if (millisecondsBeforeExpiration <= this.minutesToMilliseconds(Logout.REFRESH_TOKEN_TIME)) {
        this.refreshAccessToken$.next();
      }
    }
    // eslint-disable-next-line no-empty
    catch {
      // Ignore errors to keep the access token keepalive job running.
    }
  }

  private updateLastActiveTimeLocalStorage = () =>
    localStorage.setItem(Logout.LAST_ACTION_TIME_STORE_KEY, Date.now().toString());

  private removeLastActiveTimeLocalStorage = () =>
    localStorage.removeItem(Logout.LAST_ACTION_TIME_STORE_KEY);

  private minutesToMilliseconds = (minutes: number): number => isNaN(minutes) ? 0 : minutes * 60 * 1000;

  private addEventListeners() {
    if (this._isSubscribedToDomEvents) {
      return;
    }

    this._keepaliveDomEvents.forEach(e => document.body.addEventListener(e, this.updateLastActiveTimeLocalStorage));
    this._isSubscribedToDomEvents = true;
  }

  private removeEventListeners() {
    this._keepaliveDomEvents.forEach(e => document.body.removeEventListener(e, this.updateLastActiveTimeLocalStorage));
    this._isSubscribedToDomEvents = false;
  }
}
