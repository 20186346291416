import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'next-form-error',
  templateUrl: './form-validation-error.component.html',
  styleUrls: ['./form-validation-error.component.scss']
})
export class FormValidationErrorComponent implements OnInit {

  @Input() formField: UntypedFormControl | any;
  @Input() fieldName: string;
  @Input() minLength: number;
  errorPrefix = 'This field';

  ngOnInit() {
    this.errorPrefix = `${this.fieldName || this.errorPrefix} is`
  }

}
