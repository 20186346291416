import {
  ApiService,
  Attachment,
  FormFastConfig,
  FormFastConfigService,
  FormSubmission,
  TokenService
} from '@next/shared/common';
import { Inject, Injectable } from '@angular/core';
import {HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class NextSubmissionService extends ApiService {

  constructor(@Inject(FormFastConfigService) config: FormFastConfig,
              tokenService: TokenService, private http: HttpClient) { super(config, tokenService); }

  getSubmission(id: string): Observable<FormSubmission> {
    return this.protectedEndpoint<FormSubmission>(
      this.http.get<FormSubmission>(`${this.config.apiUrl}form/${id}`, this.getHeaders())
    );
  }

  create(data: FormSubmission): Observable<any> {
    const request: HttpRequest<FormSubmission> = new HttpRequest('POST', `${this.config.apiUrl}form`, data, this.getHeadersReportProgress());
    return this.protectedEndpoint<any>(this.http.request(request));
  }

  /**
   * Sends a form submission POST request without HTTP Progress headers.
   * @param data Form submission
   */
  createWithoutProgress(data: FormSubmission): Observable<any> {
    return this.protectedEndpoint<FormSubmission>(
      this.http.post<FormSubmission>(`${this.config.apiUrl}form`, data, this.getHeaders())
    );
  }

  update(data: FormSubmission): Observable<any> {
    const request: HttpRequest<FormSubmission> = new HttpRequest('PATCH', `${this.config.apiUrl}form/${data.id}`, data, this.getHeadersReportProgress());
    return this.protectedEndpoint<any>(this.http.request(request));
  }

  /**
   * Sends a form submission PATCH request without HTTP Progress headers.
   * @param data Form submission
   */
  updateWithoutProgress(data: FormSubmission): Observable<any> {
    return this.protectedEndpoint<FormSubmission>(
      this.http.patch<FormSubmission>(`${this.config.apiUrl}form/${data.id}`, data, this.getHeaders())
    );
  }

  /**
   *
   * @param {string} formId = get back all attachments connected to this form id
   */
  getAttachments(formId: string): Observable<Attachment[]> {
    return this.protectedEndpoint<Attachment[]>(
      this.http.get<Attachment[]>(`${this.config.apiUrl}form/${formId}/attachments?includesignedurls=true`, this.getHeaders())
    );
  }

  /**
   *
   * @param {string} formId - the form id associated with this attachment
   * @param {string} fileId - the file id of the attachment in file repo
   */
  getAttachment(formId: string, fileId: string): Observable<Attachment> {
    return this.protectedEndpoint<Attachment>(
      this.http.get<Attachment>(`${this.config.apiUrl}form/${formId}/attachments/${fileId}?includesignedurls=true`, this.getHeaders())
    );
  }

  /**
   *
   * @param {string} formId - form id of the submission
   * @param {string} name - field name of attachment
   * @param {string} vid - experience version id
   * @param {Object} data - data to submit
   * @param {File} data.file - the file to upload
   * @param {Object} data.meta - the metadata to include with the form history insertion
   * @param {number} data.meta.page - the selected page at time of upload
   */
  uploadAttachment(formId: string, name: string, vid: string, data: {file: File, meta: any}): Observable<HttpEvent<unknown>> {
    const fd: FormData = new FormData();
    fd.append('name', name);
    fd.append('fieldid', name);
    fd.append('attachment', data.file);
    fd.append('experienceversion', vid);
    fd.append('metadata', JSON.stringify(data.meta));
    const request = new HttpRequest('POST', `${this.config.apiUrl}form/${formId}/attachments`, fd, this.getUploadHeaders());
    return this.http.request(request);
  }

  /**
   *
   * @param formId - form id associated to attachment
   * @param attachmentId - file id of the attachment to remove
   */
  deleteAttachment(formId: string, attachmentId: string) : Observable<any> {
    return this.protectedEndpoint(
      this.http.delete(`${this.config.apiUrl}form/${formId}/attachments/${attachmentId}`,
      {
        responseType: 'text',
        headers: this.getHeaders().headers
      }));
    }
}
