import {Component, Input} from '@angular/core';
import { StateViewerService } from '../..';

@Component({
  selector: 'next-no-selection',
  templateUrl: './no-selection.component.html',
  styleUrls: ['./no-selection.component.scss']
})
export class NoSelectionComponent {
  constructor(public stateViewerSvc: StateViewerService) { }
  @Input() closure: string = '';

}
