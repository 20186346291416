import {
  ApiService,
  FormFastConfig,
  FormFastConfigService,
  TokenService,
  UhiResponse,
} from '@next/shared/common';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NextUhiService extends ApiService {

  constructor(
    @Inject(FormFastConfigService) config: FormFastConfig,
    private tokenSvc: TokenService,
    private http: HttpClient) { super(config, tokenSvc); }
    
  /**
   * Send session string to verify session
   * @param session
   */
  authorizeSession(session: string, verification?: string): Observable<UhiResponse> {

    const body = {
      session: session,
      verification: verification
    };

    return this.protectedEndpoint<UhiResponse>(
      this.http.post<any>(`${this.config.apiUrl}uhi`, body, this.getHeaders())
    );
  }

}
