import { Injectable, OnDestroy } from '@angular/core';
import { Event, NavigationExtras, NavigationStart, Router, UrlTree } from '@angular/router';
import { Observable, Subject, filter, takeUntil } from 'rxjs';

export interface NextQueryParams {
  uhiEmbedded?: string;
}

export interface NextRouterState {
  sessionUrl?: string;
}

export interface NextRouterNavigationExtras extends NavigationExtras {
  queryParams?: NavigationExtras['queryParams'] & NextQueryParams;
  state?: NavigationExtras['state'] & NextRouterState;
}


@Injectable()
export class NextRouterService extends Router implements OnDestroy {
  private _uhiEmbedded = false;
  private _sessionUrl: string;
  private obsCleanup: Subject<void> = new Subject<void>();

  constructor() {
    super();
    this.uhiRestoredStateEvent().subscribe(async (event: NavigationStart) => {
        // Navigate to the sessionUrl
        await this.navigateByUrl(event.restoredState.sessionUrl, {
          replaceUrl: true,
        });
      });
  }

  ngOnDestroy(): void {
    this.obsCleanup.next();
    this.obsCleanup.complete();
    super.ngOnDestroy();
  }

  // This is set only in the uhi component
  set sessionUrl(url: string) {
    this._sessionUrl = url;
  }

  get sessionUrl(): string {
    return this._sessionUrl;
  }

  // This is set only in the uhi component
  set uhiEmbedded(value: boolean) {
    this._uhiEmbedded = value;
  }

  get uhiEmbedded(): boolean {
    return this._uhiEmbedded;
  }

  /**
   * Returns the first NavigationStart event that has a restoredState.sessionUrl
   * This is used in the case where the user refreshes the page or the iframe is reloaded while in UHI embedded mode
   */
  private uhiRestoredStateEvent(): Observable<Event> {
    return this.events
      .pipe(
        takeUntil(this.obsCleanup),
        filter(
          (event) =>
            event instanceof NavigationStart 
            && !!event.restoredState
            && !!event.restoredState?.sessionUrl
        )
      );
  }

  /**
   * Merges the extras with the embedded query params if necessary
   */
  private mergeExtras(extras: NextRouterNavigationExtras = {}): NavigationExtras {   
    if (this._uhiEmbedded && !!this._sessionUrl) {
      if (!extras?.queryParams?.uhiEmbedded) {
        // Update the query params to include 'embedded=true'
        extras.queryParams = { ...extras?.queryParams, uhiEmbedded: 'true' };
      }

      if (!extras?.state || !extras?.state?.sessionUrl) {
        extras.state = { ...extras?.state, sessionUrl: this._sessionUrl };
      }
    }
    

    return extras;
  }

  navigate(commands: any[], extras: NextRouterNavigationExtras = {}): Promise<boolean> {
    const mergedExtras = this.mergeExtras(extras);

    return super.navigate(commands, mergedExtras);
  }

  navigateByUrl(url: string | UrlTree, extras: NextRouterNavigationExtras = {}): Promise<boolean> {
    const mergedExtras = this.mergeExtras(extras);

    return super.navigateByUrl(url, mergedExtras);
  }
}
