import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ViewerService } from '../state/viewer.service';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import {
  Attachment,
  FieldDTO,
  FormSubmission,
  GuidedExperienceDTO,
  GuidedExperienceInstanceDTO,
  GxProcessing,
  SubmissionData,
  SubmissionType,
  ViewerFieldType,
  ViewerIntegrationMode,
  WindowMessageEventName,
} from '@next/shared/common';
import { UntypedFormGroup } from '@angular/forms';
import {
  NextAdminService,
  NextSubmissionService,
} from '@next/shared/next-services';
import { ErrorRibbonService } from '../state/error-ribbon.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';
import { ViewerFooterComponent } from './components/viewer-footer/viewer-footer.component';

@Component({
  selector: 'next-web-viewer',
  templateUrl: './viewer-web.component.html',
  styleUrls: ['./viewer-web.component.css'],
  animations: [
    trigger('fadeInOut',[
      transition(':enter',
        [ style({opacity: 0 }), animate('400ms ease-in-out', style({opacity: '*' }))]),
      transition(':leave',
        [ style({opacity: '*' }), animate('400ms ease-in-out', style({opacity: 0 }))])
    ])
  ],
  /* This will cause a viewer service to instantiate every time this Component is created.
   * We do this to reset the internal state fresh each time you navigate to the tool */
  providers: [ViewerService, ErrorRibbonService],
})

export class ViewerWebComponent implements OnInit, OnDestroy {
  @ViewChild('viewerFooter', { static: false }) viewerFooter: ViewerFooterComponent;
  SubmissionType: typeof SubmissionType = SubmissionType;                       // allow this enum to be used in the template
  ViewerIntegrationMode: typeof ViewerIntegrationMode = ViewerIntegrationMode;  // allow this enum to be used in the template
  obsCleanup: Subject<void> = new Subject<void>();
  @Input() instance$: BehaviorSubject<GuidedExperienceInstanceDTO> = new BehaviorSubject<GuidedExperienceInstanceDTO>(null);
  @Input() integration: ViewerIntegrationMode = ViewerIntegrationMode.RAW;

  formId: string;
  selectedPage: number;
  needsValidation: boolean;
  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  loadingMessage: string;
  loadingProgress: number = 0;
  btnDisabled: boolean;
  scrolledToBottom = false;
  time: number = 0;

  // Properties defined from instance
  formData: SubmissionData = { };
  form: UntypedFormGroup;
  experience: GuidedExperienceDTO;
  experienceForm: GuidedExperienceDTO;
  prefill: unknown = { };
  submission: FormSubmission;
  attachments: Attachment[];

  constructor(
    public errorSvc: ErrorRibbonService,
    private viewerSvc: ViewerService,
    private submissionSvc: NextSubmissionService,
    private toastSvc: ToastrService,
    private translateSvc: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private adminSvc: NextAdminService,
    private location: Location,
    private gxProcessing: GxProcessing,
  ) { }

  ngOnInit(): void {
    this.form = this.viewerSvc.form = new UntypedFormGroup({});
    this.activatedRoute.queryParams.pipe(
      tap(params => this.formId = params.formId),
      takeUntil(this.obsCleanup)
    ).subscribe();
    this.instance$.pipe(
      filter(instance => !!instance),
      filter(instance => !!instance.experience),
      map(instance => this.setInstanceMobile(instance)),
      tap(instance => {
        this.experience = instance.experience;
        this.prefill = instance.prefill;
        this.submission = instance.submission;
        this.attachments = instance.attachments ?? [];
      }),
      tap(instance => {
        this.viewerSvc.initialize(instance).then(result => {
          this.formData = result.initialData;
          this.loadingProgress = 100;
          setTimeout(() => {
            this.loading.next(false);
          }, 800);
        }, reason => console.dir(reason));
      }),
      takeUntil(this.obsCleanup)
    ).subscribe();

    this.viewerSvc.getSelectedPage().pipe(
      tap(pageNum => this.selectedPage = pageNum),
      takeUntil(this.obsCleanup)
    ).subscribe();

    timer(0, 1000).pipe(
      tap(() => this.time++),
      takeUntil(this.obsCleanup)
    ).subscribe();
  }

  /**
   * setInstanceMobile()
   * - Modifies provided experienceDTO to present only the mobile pages (web viewer)
   * - Hold onto the PDF Experience Form in memory, for cases where its need (calculations)
   * @param instance */
  private setInstanceMobile(instance: GuidedExperienceInstanceDTO): GuidedExperienceInstanceDTO {
    this.experienceForm = {
      ...instance.experience,
      pages: [ instance.experience.pages.find(p => p.pageType === 'FORM') ]
    };
    return {
      ...instance,
      experience: {
        ...instance.experience,
        pages: instance.experience.pages.filter(p => p.pageType !== 'FORM')
      }
    };
  }

  ngOnDestroy(): void {
    this.obsCleanup.next();
    this.obsCleanup.complete();
  }

  async goNextPage(): Promise<void> {
    this.errorSvc.clearMessage();

    if (this.viewerSvc.pageViewed(this.selectedPage) || this.scrolledToBottom || this.isViewingBottom()) {
      // Save the form data if dirty
      if (this.viewerSvc.form.dirty) {
        await this.submit(SubmissionType.Saved, '', false);
      }

      this.resetScroll();
      this.errorSvc.clearMessage();
      this.viewerSvc.nextPage();
      this.needsValidation = false;
      this.createMetric(true);
    }
    else {
      this.showNotScrolledError();
    }
  }

  goNextPageEmbedded(): void {
    this.errorSvc.clearMessage();
    if (this.viewerSvc.pageViewed(this.selectedPage) || this.scrolledToBottom || this.isViewingBottom()) {
      this.resetScroll();
      this.viewerSvc.nextPage();
      this.needsValidation = false;
    }
    else {
      this.showNotScrolledError();
    }
  }

  goPreviousPageEmbedded(): void {
    this.errorSvc.clearMessage();
    this.resetScroll();
    this.viewerSvc.prevPage();
  }

  saveFormEmbedded(): void {
    this.submit(SubmissionType.Saved, '', false, false);
  }

  async goPrevPage(): Promise<void> {
    if (this.viewerSvc.form.dirty) {
      await this.submit(SubmissionType.Saved, '', false);
    }

    this.resetScroll();
    this.errorSvc.clearMessage();
    this.viewerSvc.prevPage();
    this.createMetric(false);
  }

  async submit(submissionType: SubmissionType, taskId: string = '', postMessage: boolean = true, doCreate: boolean = true): Promise<void> {
    if (submissionType === SubmissionType.Submitted) {
      if (!this.viewerSvc.pageViewed(this.selectedPage) && !this.scrolledToBottom && !this.isViewingBottom()) {
        this.showNotScrolledError();
        return; // back out of submit if invalid state
      }
      this.btnDisabled = true;
    }

    const submitData = await this.gxProcessing.postProcessing(this.experience, this.formId, this.selectedPage, this.viewerSvc.form.value, this.submissionSvc);
    const fd = await this.viewerSvc.processCalculations(this.experience, submissionType, this.formData, submitData, this.prefill);

    const metadata = {
      client: null,
      page: this.selectedPage
    };

    if (this.prefill && Object.keys(this.prefill).length) {
      Object.assign(fd, { _prefill_c4af0d49_e948_4737_8c12_8d64511faeec: this.prefill });
    }

    const payload: FormSubmission = {
      id: this.formId,
      experienceversionid: this.experience.vid,
      submissiontype: submissionType,
      updatedby: '',
      fileid: null,
      data: fd,
      metadata: metadata,
      taskId: taskId,
      lastupdated: this.submission?.lastupdated || null,
      personid: this.submission?.personid,
      appointmentid: this.submission?.appointmentid
    };

    // If there is an existing submission then update, else create a new form
    const upsert = this.submission || !doCreate
      ? this.submissionSvc.update(payload)
      : this.submissionSvc.create(payload);

    this.loadingProgress = 0;
    this.loadingMessage = (submissionType === SubmissionType.Submitted)
      ? this.translateSvc.instant("GX_OVERLAY.PROGRESS_SUBMIT")
      : this.translateSvc.instant("GX_OVERLAY.PROGRESS_SAVE");
    this.loading.next(true);

    upsert.subscribe(result => {
      switch (result.type) {
        case HttpEventType.Sent: {
          this.loadingProgress = 0;
          break;
        }
        case HttpEventType.UploadProgress:
        case HttpEventType.DownloadProgress: {
          this.loadingProgress = Math.floor(result.loaded / result.total);
          break;
        }
        case HttpEventType.Response: {
          this.loadingProgress = 100;
          this.loadingMessage = (submissionType === SubmissionType.Submitted)
            ? this.translateSvc.instant("GX_OVERLAY.DONE_SUBMIT")
            : this.translateSvc.instant("GX_OVERLAY.DONE_SAVE");
          this.submission = result.body; // Store last submission
          this.formId = this.viewerSvc.formId = this.submission.id; // Update form id
          this.viewerSvc.form.markAsPristine();

          setTimeout(() => {
            this.loading.next(false);
            this.loadingMessage = '';
            if (this.integration === ViewerIntegrationMode.CLINICAL) {
              this.adminSvc.createMetric("CompleteFormViewer", {
                "Time": this.time,
                "Experience": this.experience
              });
            }
            this.submitSuccess(submissionType, postMessage);
          }, 2100);
          break;
        }
      }
    }, (err: HttpErrorResponse) => {
      this.loading.next(false);
      this.loadingProgress = 0;
      this.errorSvc.showErrorMessage(err.message);
      setTimeout(() => {
        this.submitFailure(err);
      }, 2100);
    });
  }

  submitSuccess(submissionType: SubmissionType, postMessage: boolean = true): void {
    const redirect: string = this.router.createUrlTree([], {
      relativeTo: this.activatedRoute,
      queryParams: {formId: this.formId},
      queryParamsHandling: 'merge'
    }).toString();
    switch (submissionType) {
      case SubmissionType.Saved: {
        this.toastSvc.success(this.translateSvc.instant('TOASTR_MESSAGE.SAVE_SUCCESS'), this.translateSvc.instant('TOASTR_MESSAGE.SAVE_TITLE'));
        this.location.go(redirect);
        break;
      }
      case SubmissionType.Submitted: {
        this.toastSvc.success(
          this.translateSvc.instant('TOASTR_MESSAGE.SUBMIT_SUCCESS'),
          this.translateSvc.instant('TOASTR_MESSAGE.SUBMIT_TITLE'));
        this.btnDisabled = false;
        if (this.experience.sendToSuccessPage) {
          this.router.navigateByUrl(`/viewer/${this.experience.id}/${this.experience.vid}/success`);
        } else {
          this.location.go(redirect);
        }
        break;
      }
    }
    if (postMessage && window.parent) {
      window.parent.postMessage({
        eventName: (submissionType === SubmissionType.Saved) ? WindowMessageEventName.ExperienceSave : WindowMessageEventName.ExperienceSubmit,
        formId: this.formId,
      }, document.referrer || '*');
    }
    if (postMessage && window.opener) {
      const payload = { eventName: (submissionType === SubmissionType.Saved) ? WindowMessageEventName.ExperienceSave : WindowMessageEventName.ExperienceSubmit, formId: this.formId };
      (window.opener as Window).postMessage(payload, window.opener?.location?.origin || '*');
    }
  }

  submitFailure(err): void {
    console.log('Error: ', err);
    this.btnDisabled = false;
  }

  cancel(): void {
    if (window.parent) {
      window.parent.postMessage({ eventName: WindowMessageEventName.ExperienceCanceled },document.referrer || '*');
    }
    if (window.opener) {
      window.parent.postMessage({ eventName: WindowMessageEventName.ExperienceCanceled },window.opener.location?.origin || '*');
    }
  }

  async valueChanged(field: FieldDTO): Promise<void> {
    if (field?.calculations?.onChange) {
      // TODO: Do we still need this timeout in Spring2021+ to avoid the calculations getting old values?
      setTimeout(async () => {
        const updatedData = await this.viewerSvc.getCalculationData(
          field.calculations.onChange,
          field.name,
          this.experience,
          { ...this.viewerSvc.form.value, ...this.formData },
          this.prefill);

        if (updatedData) {
          Object.entries(updatedData.fields).forEach(kvp => {
            const key = kvp[0];
            const val = kvp[1];
            if (this.viewerSvc.form.contains(key)) {
              this.viewerSvc.form.controls[key].setValue(val);
            }
            else {
              this.formData[key] = val;
            }
          });
          this.experience = this.gxProcessing.getUpdatedConfig(this.experience, updatedData.configs);
        }
      });
    }
    // If a Photo Element form value changed, have the Viewer submit a form
    if (field.type === ViewerFieldType.PHOTO) {
      this.formId = this.viewerSvc.formId = field['formId'];
      const submitData = await this.gxProcessing.postProcessing(this.experience, this.formId, this.selectedPage, this.viewerSvc.form.value, this.submissionSvc);
      const fd = await this.viewerSvc.processCalculations(this.experience, SubmissionType.Saved, this.formData, submitData, this.prefill);

      const metadata = {
        client: null,
        page: this.selectedPage
      };

      if (this.prefill && Object.keys(this.prefill).length) {
        Object.assign(fd, { _prefill_c4af0d49_e948_4737_8c12_8d64511faeec: this.prefill });
      }
      const payload: FormSubmission = {
        id: this.formId,
        experienceversionid: this.experience.vid,
        submissiontype: SubmissionType.Saved,
        updatedby: '',
        fileid: null,
        data: fd,
        metadata: metadata,
        lastupdated: this.submission?.lastupdated || null
      };
      this.submission = (await this.submissionSvc.update(payload).toPromise()) as FormSubmission;
      // END Photo Element fork
    }
    this.viewerSvc.removeViewedPage(this.selectedPage);
  }

  createMetric(next: boolean): void {
    if (this.integration === ViewerIntegrationMode.CLINICAL) {
      const dir = next ? "Next" : "Back"
      this.adminSvc.createMetric("TimeOnPageGXViewer", { "Page": this.selectedPage,
        "Time": this.time, "Direction": dir, "Experience": this.experience });
    }
  }

  onScroll(): void {
    if (this.isViewingBottom()) {
      this.scrolledToBottom = true;
      this.viewerSvc.addViewedPage(this.selectedPage);
    }
  }

  isViewingBottom(): boolean {
    const element = document.getElementById("mainTop");
    return Math.ceil(element.scrollHeight - element.scrollTop) - element.clientHeight <= 50;
  }

  resetScroll(): void {
    document.getElementById("mainTop").style.scrollBehavior = 'auto';
    document.getElementById("mainTop").scrollTo(0,0);
    document.getElementById("mainTop").style.scrollBehavior = 'smooth';
    this.scrolledToBottom = false;
  }

  showNotScrolledError(): void {
    this.errorSvc.showErrorMessage(
      this.translateSvc.instant('WARNING_RIBBON.SCROLL_TO_BOTTOM_MSG')
    );
  }

  validateRequiredFields(): void {
    this.needsValidation = true;
  }

  async setFormParameters() {
    const updatedFieldSubmissionsWEB = this.gxProcessing.getParameterFields(this.experience, this.prefill);
    const updatedFieldSubmissionsPDF = this.gxProcessing.getParameterFields(this.experienceForm, this.prefill);
    for (const name in updatedFieldSubmissionsPDF) {
      if (!this.form.get(name)) {
        this.formData[name] = updatedFieldSubmissionsPDF[name];
        const fieldDTO = this.experienceForm.pages[0].fields.find(f => f.name === name);
        if (fieldDTO)
          await this.valueChanged(fieldDTO);
      }
    }
    for (const name in updatedFieldSubmissionsWEB) {
      this.formData[name] = updatedFieldSubmissionsWEB[name];
      this.form.get(name).patchValue(updatedFieldSubmissionsWEB[name]);
      const fieldDTO = this.gxProcessing.getAllFields(this.experience).find(f => f.name === name);
      if (fieldDTO)
        await this.valueChanged(fieldDTO);
    }
  }
}
