export interface Authorization {
  accessToken: string;
  integrationToken: string;
  identity: object;
  preferences: Array<object>;
}

export enum Logout {
  LAST_ACTION_TIME_STORE_KEY =  'lastAction',
  TIMEOUT_STORE_KEY = 'timeout',
  CHECK_INTERVAL = 15000, // in ms
  WARNING_TIME = 5, // minutes
  TIMEOUT_VALUE = 10, //minutes
  REFRESH_TOKEN_TIME = 1 // minutes
}

// OAuth2.0 specific response errors - https://www.oauth.com/oauth2-servers/access-tokens/access-token-response/
export const oAuthErrors = {
  invalid_client: 'invalid_client',
  invalid_grant: 'invalid_grant',
  invalid_request:  'invalid_request',
  invalid_scope: 'invalid_scope',
  unauthorized_client:    'unauthorized_client',
  unsupported_grant_type: 'unsupported_grant_type',
} as const;

// These are the errors that can be returned from our middleware when a user is not authorized
export const tokenErrors = {
  missing_token: 'missing_token',
  invalid_token: 'invalid_token',
  expired_token: 'expired_token',
  insufficient_permissions: 'insufficient_permissions',
} as const;