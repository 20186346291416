<div id="nova-clinical-search-form-group" [formGroup]="form" class="mb-3">
  <div class="row">
    <div [ngClass]="{'col-10 pr-0' : this.searchType === searchByTypeEnum.Barcode, 'col-12' : this.searchType !== searchByTypeEnum.Barcode}">
      <select id="select-search-criteria" class="prepend-input form-control mb-1" formControlName="searchType">
        <option *ngIf="stateSvc.barcodeScanningProfiles.length" [value]="this.searchByTypeEnum.Barcode">
          {{ "PATIENT_SEARCH.BARCODE_LABEL" | translate }}
        </option>
        <option [value]="this.searchByTypeEnum.AccountNumber">
          {{ "PATIENT_SEARCH.ACCOUNT_LABEL" | translate }}
        </option>
        <option [value]="this.searchByTypeEnum.DOB">
          {{ "PATIENT_SEARCH.DOB_LABEL" | translate }}
        </option>
        <option [value]="this.searchByTypeEnum.Patient">
          {{ "PATIENT_SEARCH.NAME_LABEL" | translate }}
        </option>
        <option [value]="this.searchByTypeEnum.MRN">
          {{ "PATIENT_SEARCH.MRN_LABEL" | translate }}
        </option>
      </select>
    </div>
    <div *ngIf="this.searchType === searchByTypeEnum.Barcode" class="input-group-append barcode-icon col-2">
      <span>
        <fa-icon [icon]="['fas', 'barcode']" (click)="showScanner()"></fa-icon>
      </span>
    </div>
  </div>

  <div id="patient-search-group" *ngIf="this.searchType !== searchByTypeEnum.Barcode" class="input-group" role='search' aria-label="patient-search-group">
    <div class="input-group-prepend">
      <div class="input-group-text prepend-input">
        <fa-icon [icon]="['fas', 'search']"></fa-icon>
      </div>
    </div>
    <label for="patient-search"></label>
    <input
      id="patient-search"
      name="patient-search"
      type="text"
      role="searchbox"
      class="form-control is-invalid"
      autocomplete="off"
      aria-label="patient-search"
      nextAutoFocusElement
      formControlName="searchInput"
      [placeholder]="searchPlaceHolder"
      (keydown.enter)="this.facilityDepartmentFilteringSvc.search(this.searchType); this.stateSvc.searchedAppointments.isNewSearch = true;"
      [shouldFocus]="!this.stateSvc.searchedPatients.filter" />
    <div class="input-group-append">
      <span class="input-group-text append-input">
        <fa-icon
          *ngIf="this.stateSvc.searchedPatients.filter"
          [icon]="['far', 'times-circle']" class="p-0"
          (click)="resetSearchInput()">
        </fa-icon>
      </span>
    </div>
  </div>
</div>
