<div class="h-100">
  <div class="d-flex justify-content-between align-items-center header py-2">
    <div class="col-3">
      <button id="btn-cancel" class="no-border text-nowrap" (click)="modalClose.emit()">
        {{ 'PATIENT_FORMS.MOVE_FLAGGED_FORM.HEADER.CANCEL' | translate }}
      </button>
    </div>
    <div class="col-5 text-center">
      <h5 class="m-0 p-1 text-truncate">{{ 'PATIENT_FORMS.MOVE_FLAGGED_FORM.HEADER.TITLE' | translate: { patientLabel : labelPatient } }}</h5>
    </div>
    <div class="col-4 d-flex justify-content-end">
      <button id="not-now" class="btn rounded-pill mr-1 text-nowrap" (click)="modalClose.emit()">
        {{ 'PATIENT_FORMS.MOVE_FLAGGED_FORM.HEADER.NOT_NOW' | translate }}
      </button>
      <button id="move-form" class="btn rounded-pill text-nowrap" [disabled]="stateViewerSvc.selectedFormsFromMoveFormsModal.length < 1" (click)="emitMoveForms()">
        {{ 'PATIENT_FORMS.MOVE_FLAGGED_FORM.HEADER.MOVE' | translate }}
      </button>
    </div>
  </div>
  <div class="overflow-auto scroll-area">
    <div class="mx-2 my-3">
      <h6>
        {{ 'PATIENT_FORMS.MOVE_FLAGGED_FORM.INFORMATION_LABEL_1' | translate: { patientLabel : labelPatient.toLowerCase(), typeLabel : typeLabel.toLowerCase() } }}
      </h6>
      <div class="mt-1" *ngIf="flaggedPatientForms.length">
        <h6 class="m-0">
          {{ 'PATIENT_FORMS.MOVE_FLAGGED_FORM.IN_PATIENT_RECORD_LABEL' | translate: { patientLabel : labelPatients } | uppercase }}
        </h6>
        <next-flagged-forms-table-inline
          [openedFrom]="openedFrom"
          [fromMoveFormsModal]="true"
          [forms]="flaggedPatientForms"
          [patient]=""
          [displayEllipsisMenu]="false">
        </next-flagged-forms-table-inline>
      </div>
    </div>
    <div *ngIf="flaggedAppointmentForms.length > 0" class="mx-2 my-3">
      <h6 class="m-0">
        {{ 'PATIENT_FORMS.MOVE_FLAGGED_FORM.IN_APPOINTMENT_RECORD_LABEL' | translate: { appointmentLabel : labelAppointments } | uppercase }}
      </h6>
      <next-move-flagged-forms-appointment-accordion
        [fromMoveFormsModal]="true"
        [appointmentForms]="flaggedAppointmentForms">
      </next-move-flagged-forms-appointment-accordion>
    </div>
  </div>
</div>
