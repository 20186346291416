<div *ngIf="experience" class="h-100">
  <div class="container">
    <div class="header-container">
      <div *ngIf="experience.header.logo.url" [ngClass]="'logo-' + experience.header.logo.alignment" [style.height.px]="experience.header.logo.height">
        <img class="header-image" [attr.src]="getSecureLogoUrl() | secure | async" alt="" [style.minHeight.px]="experience.header.logo.height" />
      </div>
    </div>
    <div *ngFor="let page of experience.pages; let pageIndex = index">
      <next-viewer-page
        *ngIf="pageIndex === (selectedPage | async)"
        [form]="form"
        [formId]="formId"
        [experience]="experience"
        [page]="page"
        [initialState]="initialState"
        [attachments]="attachments"
        [needsValidation]="needsValidation"
        (valueChanged)="emitValueChanged($event)">
      </next-viewer-page>
    </div>
  </div>
</div>
