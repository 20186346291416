import { Injectable } from '@angular/core';
import { IDService } from './id.service';
import { FieldType } from '../models/form.model';
import { GuidedExperienceDTO } from '../models/guided-experience.model';

@Injectable({
  providedIn: 'root'
})
export class ImportHelperService {
  constructor(protected idSvc: IDService) { }

  convertFields(fields, pdfTemplateID) {
    const pageFields = [];
    fields.pages.map(page => pageFields.push(...page.annotations.filter(anno => anno)));
    const filtered = pageFields.filter(anno => this.isSupportedAnnotationType(anno));
    return filtered.map(f => Object.assign({}, { id: this.idSvc.generate() }, { name: f.name }, { source: pdfTemplateID } ));
  }

  filterFieldTypes(fields) {
    return fields.filter(
      field => field.fieldType !== 'BUTTON' && field.fieldType !== 'HYPERLINK'
    );
  }

  isSupportedAnnotationType(type) {
    switch (type) {
      case 'textbox':
      case 'checkbox':
      case 'radio':
      case 'combobox':
      case 'button':
      case 'signature':
        return true;
      default:
        return false
    }
  }

  nextAnnotationTypetoLegacy(type) {
    switch (type) {
      case 'textbox':
        return FieldType.TextBox;
      case 'checkbox':
        return FieldType.CheckBox;
      case 'radio':
        return FieldType.Radio;
      case 'combobox':
        return FieldType.DropDown;
      case 'button':
        return FieldType.Button;
      case 'signature':
        return FieldType.Signature;
    }
  }

  nextSelectOptionsToLegacy(annot) {
    switch (annot.type) {
      case 'radio':
        return annot.options.map(option => {
          return {
            id: this.idSvc.generate(),
            text: option.value,
            value: option.value,
            selected: false
          };
        });

      case 'combobox':
        return annot.options.map(option => {
          return {
            id: this.idSvc.generate(),
            text: option,
            value: option,
            selected: false
          };
        });
    }

    return [];
  }

  flattenSources(experience) {
    const sources = experience.metadata.sources;
    const activeSource = sources[sources.length - 1];
    experience.metadata.sources = [ activeSource ];
    return experience.metadata;
  }

  generateMetaData(gx: GuidedExperienceDTO): string {
    const metadata = this.flattenSources(gx);
    const pages = (gx.pages === undefined ? '' : JSON.stringify(gx.pages));
    const datasets = JSON.stringify(gx.datasets);
    return `{
        "name": "${gx.name}",
        "description": "${gx.description}",
        "version": "${gx.version}",
        "configversion": 1,
        "status": "draft",
        "pdftemplateid": "@pdftemplateid",
        "datasets": ${datasets},
        "metadata": ${JSON.stringify(metadata)},
        "data": {
          "header": {
            "logo": {
              "url": "${gx.header.logo.url}",
              "height": ${gx.header.logo.height},
              "alignment": "${gx.header.logo.alignment}"
            }
          },
          "calculations": {
            "onLoad": "${gx.onLoad}",
            "onSave": "${gx.onSave}",
            "onSubmit": "${gx.onSubmit}"
          },
          "pages": ${(pages)}
      }}`;
  }

  formatFileName(name: string, id: string) {
    if (name && id) {
      return (id + '_' + name.replace('/', '_').replace(/[^a-z0-9]/gi, '_')).substr(0, 79);
    }
    return '';
  }

}
