<div id='iop-signature-prompt'>
  <div *ngIf="!batchSign" class="container-fluid w-100">
    <div class='row bg-light border-bottom py-2'>
      <div class='col-4'></div>
      <div class='col-4 d-flex justify-content-center align-self-center text-truncate'>
        <h5>{{ signatureProperties.title || (signatureProperties.signatureFor === 'patient' ? 'SIGNATURE_PROMPT.PATIENT_TITLE' : 'SIGNATURE_PROMPT.TITLE') | translate }}</h5>
      </div>
      <div class='col-4 d-flex justify-content-end align-items-start'>
        <button
          id='cancel-btn-no-batch'
          type='reset'
          class='btn btn-plain rounded-circle pull-right'
          aria-label='close'
          (click)="cancel()">
          <fa-icon [icon]='["fas", "times"]' size='lg'></fa-icon>
        </button>
      </div>
    </div>
    <div #container class="modal-body minh-300-px w-100 d-flex">
      <ng-container>
        <!--ACCESS CODE VIEW-->
        <div [hidden]="showCanvas" class="container-fluid">
          <div class="row form-group p-0" [formGroup]='accessCode'>
            <div class="col-12 col-lg-10 my-2">
              <label for="user-select" class="mb-0">
                <h5 class='mb-1'>
                  {{ "SIGNATURE_PROMPT.PERSON_SIGN" | translate}}
                </h5>
              </label>
              <div class="input-group mw-500-px">
                <ng-select
                  id='user-select'
                  aria-label='user-select'
                  class='form-control'
                  aria-autocomplete='none'
                  [clearable]="false"
                  [searchable]="true"
                  [(ngModel)]="signerID"
                  [ngModelOptions]='{ standalone : true }'>
                  <ng-option *ngFor="let user of users | async" [value]="user.id">
                    {{ user.fullName }}
                  </ng-option>
                </ng-select>
                <div class="input-group-append">
                  <button
                    id="btn-assign"
                    class="btn btn-outline-dark h-auto"
                    type="button"
                    (click)="onAssign()">
                    {{ "SIGNATURE_PROMPT.ASSIGN" | translate}}
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-10 my-2" [formGroup]="accessCode">
              <label for="access-code-input" class='mb-0'>
                <h5 class='mb-1'>
                  {{ "SIGNATURE_PROMPT.CODE" | translate}}
                </h5>
              </label>
              <div class='input-group mw-500-px'>
                <input
                  #codeElement
                  id="access-code-input"
                  type='text'
                  aria-label="access-code-input"
                  autocomplete='off'
                  aria-autocomplete='none'
                  class="form-control"
                  formControlName="code"
                  nextAutoFocusElement
                  [mask]='masking'
                  [patterns]='pattern'
                  [hiddenInput]='true'
                  [maxLength]='24'
                  [ngClass]='{ "is-invalid" : invalidMatch }'
                  (blur)="hideCodeCb()"
                  (keydown.enter)="onEnterKeyPressSubmit()"/>
                <div class='input-group-append'>
                  <button
                    id='toggle-code-mask'
                    type='button'
                    class='btn btn-outline-dark h-auto'
                    style='min-width: 54px'
                    (click)='toggleHiddenCode()'>
                    <fa-icon [icon]='hideCode ? ["fas", "eye"] : ["fas", "eye-slash"]' size='lg'></fa-icon>
                  </button>
                </div>
                <div class="invalid-feedback">
                  {{ "SIGNATURE_PROMPT.INVALID_MATCH" | translate:attemptsObject }}
                </div>
              </div>
            </div>
          </div>
          <div class="row p-0 mt-4">
            <div class='col-12 d-flex p-0 justify-content-between align-content-center'>
              <button id="cancel-btn-staff-prompt" class="btn btn-sign text-truncate" type="button" (click)="cancel()">
                {{ "SIGNATURE_PROMPT.CANCEL" | translate }}
              </button>
              <button id="sign-no-code-btn-staff-prompt" class="btn btn-sign text-truncate" type="button" (click)="signWithoutCode()">
                {{ "SIGNATURE_PROMPT.SIGN_NO_CODE" | translate }}
              </button>
              <button id="sign-btn-staff-prompt" class="btn btn-sign text-truncate" type="button" (click)="sign()" [disabled]="accessCodeDisabled || !codeValue">
                {{ "SIGNATURE_PROMPT.SIGN" | translate }}
              </button>
            </div>
          </div>
        </div>

        <div [hidden]="!showCanvas" class="d-flex flex-column">

          <div class="d-flex justify-content-between align-items-center my-2">
            <div *ngIf="signatureProperties.signatureFor !== 'staff' && signatureProperties.enableTypedSignature && !patientUnableToSign" class="btn-group btn-group-sm bordered-btn-group" role="group">
              <button
                type="button"
                class="btn"
                [ngClass]="{'btn-primary': !isTypedSignature, 'btn-primary-outline': isTypedSignature}"
                (click)="toggleIsTypedSignature(false)">
                {{ "SIGNATURE_PROMPT.DRAW_SIGNATURE_BUTTON" | translate }}
              </button>
              <button
                type="button"
                class="btn"
                [ngClass]="{'btn-primary': isTypedSignature, 'btn-primary-outline': !isTypedSignature}"
                (click)="toggleIsTypedSignature(true)">
                {{ "SIGNATURE_PROMPT.TYPE_SIGNATURE_BUTTON" | translate }}
              </button>
            </div>

            <button
              *ngIf="!signatureProperties.signatureFor || signatureProperties.signatureFor === 'other'"
              id="btn-self-sign-signature"
              type="submit"
              class="btn btn-sm btn-primary col-4 col-xs-6"
              (click)="selfSign()">
              {{ "SIGNATURE_PROMPT.SELF_SIGN" | translate }}
            </button>

            <div *ngIf="signatureProperties.signatureFor === 'patient' && !isPatientView" class="form-check form-check-inline ml-auto">
              <input
                id="patient-unable-to-sign"
                type="checkbox"
                class="form-check-input"
                (click)="togglePatientUnableToSign()"
                [ngModel]="patientUnableToSign">
              <label for="patient-unable-to-sign" class="form-check-label">
                {{ "SIGNATURE_PROMPT.PATIENT_UNABLE_TO_SIGN" | translate }}
              </label>
            </div>
          </div>

          <canvas id="canvas-signature-area" #drawCanvas [hidden]="isTypedSignature || patientUnableToSign" class="border rounded draw-canvas"></canvas>

          <div class="d-flex flex-column my-2">
            <div *ngIf="signatureProperties.captureRelationship" class="w-100 input-group input-group-sm mb-1">
              <div class="input-group-prepend">
                <span id="signature-capture-relationship-input" class="input-group-text" style="min-width: 95px">
                  {{ 'SIGNATURE_PROMPT.RELATIONSHIP' | translate }}
                </span>
              </div>
              <select
                id="relationship-select"
                class="form-control"
                [disabled]="patientUnableToSign"
                [ngClass]='{ "required" : isRelationshipRequired() }'
                [(ngModel)]="selectedRelationship"
                (ngModelChange)="signatureRelationshipChangeNg($event)"
                (change)="onRelationshipChange($event)">
                <ng-container *ngIf="signatureProperties.relationshipSource === RelationshipListSource.UsePDFSettings">
                  <option *ngFor="let item of relationshipListSource" [value]="item.value">{{ item.text }}</option>
                </ng-container>
                <ng-container *ngIf="signatureProperties.relationshipSource !== RelationshipListSource.UsePDFSettings">
                  <option *ngFor="let item of relationshipListSource" [value]="item">{{ item }}</option>
                </ng-container>
              </select>
            </div>

            <div *ngIf="(signatureProperties.captureSignerName && !patientUnableToSign) || isTypedSignature" class="w-100 input-group input-group-sm mb-1">
              <div class="input-group-prepend">
                <span id="signature-type-name-label" class="input-group-text" style="min-width: 95px">
                  {{ "SIGNATURE_PROMPT.TYPE_NAME" | translate }}
                </span>
              </div>
              <input
                id="signature-type-name"
                type="text"
                class="form-control"
                autocomplete="off"
                aria-describedby="signature-type-name-label"
                [ngClass]="{'not-valid': isTypeNameRequired()}"
                (ngModelChange)="signatureTypeNameChange($event)"
                [(ngModel)]="sigName">
            </div>

            <div *ngIf="patientUnableToSign">
              <span>{{ "SIGNATURE_PROMPT.PATIENT_UNABLE_TO_SIGN_REASON" | translate }}</span>
              <input
                id="unable-to-sign"
                type="text"
                class="form-control w-100"
                autocomplete="off"
                aria-describedby="signature-type-name-label"
                [(ngModel)]="sigName">
            </div>
          </div>

          <div class="d-flex justify-content-start align-items-center my-2">
            <button id="btn-clear-signature-strokes" *ngIf="!patientUnableToSign && !isTypedSignature && sigValue" class="btn btn-outline-danger" (click)="onClearSignature()">
              <fa-icon class="mr-2" [icon]="['fas', 'times']"></fa-icon>
              <span>{{ "SIGNATURE_PROMPT.CLEAR_SIG" | translate }}</span>
            </button>
            <button
              id="cancel-btn-sig-canvas"
              class="btn btn-outline-secondary ml-auto px-4"
              type="reset"
              (click)="cancel()">
              {{ "SIGNATURE_PROMPT.CANCEL" | translate }}
            </button>
            <button
              id="apply-btn-sig-canvas"
              class="btn btn-primary ml-2 px-4"
              type="submit"
              (click)="applySignatureAsync()"
              [disabled]="!enableApply()">
              {{ "SIGNATURE_PROMPT.APPLY" | translate }}
            </button>
          </div>
          <span class="my-2 small">{{ signatureProperties.defaultStatement }}</span>
        </div>
      </ng-container>

    </div>
  </div>

  <!--BATCH-SIGN VIEW-->
  <div *ngIf="batchSign">
    <next-view-batch-sign
      [codeFG]='accessCode'
      [invalid]='invalidMatch'
      [accessCodeDisabled]='accessCodeDisabled'
      (submitModal)='sign($event)'
      (exitModal)='exitModal.emit()'>
    </next-view-batch-sign>
  </div>
</div>
