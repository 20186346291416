import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TokenService, SavedScreenStates, SettingEnum, ViewType } from '@next/shared/common';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from "ngx-toastr";
import { NextAuthService } from '@next/shared/next-services';

@Component({
  selector: 'next-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  logoutModal: BsModalRef;
  returningUrl: string;
  logoutInactivity: any;
  isMainComponent: any;
  isLoggedOut: boolean;
  logoutUrl = '/logout/sessionended';

  @Output() isLoggedOutEvent: EventEmitter<boolean> = new EventEmitter();

  constructor (
    private translateSvc: TranslateService,
    private toastr: ToastrService,
    private tokenService: TokenService,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    private authSvc: NextAuthService
  ) { }

  ngOnInit(): void {
    this.isMainComponent = this.activatedRoute.snapshot.queryParamMap.get('isMainComponent');
    this.logoutInactivity = this.activatedRoute.snapshot.queryParamMap.get('logoutInactivity');
    this.returningUrl = this.activatedRoute.snapshot.queryParamMap.get('currentUrl');

    // logged out came from the logout redirect
    if (window.location.pathname === this.logoutUrl) {
      this.isLoggedOut = true;
      this.modalService.hide(1);
      this.toastr.clear();
      return;
    }

    if (!this.activatedRoute.snapshot.paramMap.get("auto")) {
      this.processModalLogout();
    } else {
      this.logout();
    }
  }

  processModalLogout() {
    const config = {
      backdrop: true,
      ignoreBackdropClick: true,
      initialState: {
        title: this.translateSvc.get('LOGOUT.TITLE'),
        message: this.translateSvc.get('LOGOUT.CONFIRM_MESSAGE'),
        cancelButton: this.translateSvc.get('LOGOUT.CANCEL_BUTTON'),
        confirmButton: this.translateSvc.get('LOGOUT.OK_BUTTON')
      }
    }
    this.logoutModal = this.modalService.show(ConfirmationDialogComponent, config);
    this.logoutModal.content.onClose.subscribe(result => {
      if (result) {
        this.isMainComponent = true;
        this.logout();
      } else {
        // send isLoggedOut event to the router
        this.isLoggedOutEvent.emit(false);
      }
    });
  }

  logout() {

    // Logout the authenticator
    const logoutURl = `?redirectUrl=${window.location.origin}${this.logoutUrl}`;

    // Get the logout url from the server and redirect the user to the logout auth provider page
    this.authSvc.Logout(logoutURl).subscribe({
      next: result => {
        this.isLoggedOut = true;
        this.modalService.hide(1);
        this.toastr.clear();

        // Clear the token
        this.tokenService.clear();
        this.tokenService.clearLastAction();
        
        this.resetScreenViews();

        // This is artificially added so when the user hits the back button, it will stay on this page.
        history.pushState(history.state, '', this.logoutUrl);

        if (this.isMainComponent) {
          // send isLoggedOut event to the router
          this.isLoggedOutEvent.emit(true);
        }

        const url = new URL(result.logoutUrl);
        window.location.href = url.href;
      },
      error: () => {
        this.toastr.error(this.translateSvc.instant('LOGOUT.ERROR_MESSAGE'),'',{ disableTimeOut: false });
        this.isLoggedOut = false;
        this.modalService.hide(1);
        this.isLoggedOutEvent.emit(false);

      }
    });
  }

  login() {
    // This will navigate the user to the base url
    window.location.href = this.returningUrl && this.returningUrl !== this.logoutUrl
      ? window.location.origin + this.returningUrl
      : window.location.origin;
  }

  private resetScreenViews() {
    // Reset screen states / views back to Table view (updates sessionStorage)
    const savedScreenStates = new SavedScreenStates();
    
    savedScreenStates.setView(SettingEnum.Patient, ViewType.Table);
    savedScreenStates.setView(SettingEnum.Appointment, ViewType.Table);
    savedScreenStates.setView(SettingEnum.Form, ViewType.Table);
  }
}
