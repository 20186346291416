<div *ngIf="loading$ | async" class="h-100 w-100 position-relative">
  <fa-icon [icon]="['fas', 'spinner']" [pulse]="true" size="4x" class="position-absolute spinner mx-auto align-middle"></fa-icon>
</div>
<iframe
  #iframe
  title="pdf"
  (load)="onLoadEnd()"
  [ngClass]="{
    'iframe-embedded' : integration === ViewerIntegrationMode.CLINICAL,
    'iframe-raw'      : integration !== ViewerIntegrationMode.CLINICAL,
    'invisible'       : loading$ | async
  }"></iframe>
