<p-table
  [value]="forms"
  [columns]="columnHeadersFlaggedFormsInline"
  [selection]="selectedForms"
  (selectionChange)="selectionChanged($event)"
  sortField="appointmentid"
  responsiveLayout="scroll"
  [resizableColumns]="true"
  sortMode="single"
  rowHover="true"
  columnResizeMode="expand">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th id="checkbox-header" class="min-btn-width">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th *ngFor="let column of columns" [id]="column.field" pResizableColumn [pSortableColumn]="column['field'] === 'status' ? 'statusorder' : column['field']" class="text-overflow" [ngStyle]="{'display': column.field === 'statusorder'? 'none':''}">
        <div class="d-flex justify-content-start align-items-center">
          {{ column.header }}
          <p-sortIcon field="{{column['field'] === 'status' ? 'statusorder' : column['field'] }}"></p-sortIcon>
          <p-columnFilter type="text" [field]="column.field" display="menu"></p-columnFilter>
        </div>
      </th>
      <th *ngIf="displayEllipsisMenu" id="additional">
        {{ 'PATIENT_FORMS.COLUMN_HEADERS.ADDITIONAL' | translate }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-form let-rowIndex="rowIndex" let-columns="columns">
    <tr id='{{ "assigned-form_" + rowIndex }}' data-test='assigned-form-row-inline'>
      <td class='min-btn-width'>
        <p-tableCheckbox (click)="$event?.stopPropagation(); menu.hide();" [value]="form"></p-tableCheckbox>
      </td>
      <td *ngFor="let column of columns" class="text-overflow align-middle" [ngClass]="{'wrap-column-text': column.field === 'name'}" (click)="rowClicked(form)"  [ngStyle]="{'display': column.field === 'statusorder'? 'none':''}">
        <ng-container *ngIf="column.field === 'name' && form['tomovelater']">
          <fa-icon [icon]="['fas', 'history']" flip="horizontal" class="text-muted"></fa-icon>
        </ng-container>
        <ng-container *ngIf="column.field !== 'updatedon' && column.field !== 'lastarchivedon' && column.field !== 'formType'">
          {{column.field === 'status' ? formatStatus(form[column.field]): form[column.field]}}
        </ng-container>
        <ng-container *ngIf="column.field === 'lastarchivedon'">
          {{ form["lastarchivedon"] | date:'M/d/yyyy h:mm a'}}
        </ng-container>
        <ng-container *ngIf="column.field === 'updatedon'">
          {{( form["updatedon"] | date:'M/d/yyyy h:mm a') }}
        </ng-container>
      </td>
      <td *ngIf="displayEllipsisMenu">
        <button class="d-inline-block p-0 options" (click)="rowMenuClicked(form); menu.toggle($event);">
          <fa-icon [icon]="['fas', 'ellipsis-h']" size="lg"></fa-icon>
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
