<div class="d-flex justify-content-between align-items-center w-100 bg-white position-relative" style="height: 68px; z-index:11;">
  <div class="next-page-section text-truncate d-flex flex-row justify-content-center align-items-center w-75">
    <div class="container-fluid text-left px-0">
      <button id="btn-web-prev-page" class="btn-navigate pl-3" (click)="previousPage()" [disabled]="loading || currentPage === 0">
        <fa-icon class="pr-2" [icon]="['fas', 'chevron-left']"></fa-icon>
        <span class="d-none d-xl-inline-block">{{ "FORM_VIEWER.FOOTER.PREV_PAGE" | translate }}</span>
      </button>
    </div>
    <div class="container-fluid text-center p-2">
    <span *ngIf="!loading">
      {{ "FORM_VIEWER.PAGES" | translate: { current: currentPage + 1, total: totalPages } }}
    </span>
      <span *ngIf="loading">
      <fa-icon [icon]="['fas', 'spinner']" [pulse]="true"></fa-icon>
    </span>
    </div>
    <div class="container-fluid text-right px-0">
      <button id="btn-web-next-page" class="btn-navigate pr-3" (click)="nextPage()" [disabled]="loading || (currentPage + 1) === totalPages">
        <span class="d-none d-xl-inline-block">{{ "FORM_VIEWER.FOOTER.NEXT_PAGE" | translate }}</span>
        <fa-icon class="pl-2" [icon]="['fas', 'chevron-right']"></fa-icon>
      </button>
    </div>
  </div>
  <div class="next-form-btn pl-1 w-25">
    <ng-container *ngIf="(currentPage + 1) !== totalPages">
      <button id="btn-save-web-viewer" class="btn btn-primary rounded-pill p-0 w-100 text-truncate text-lg" (click)="saveForm()" [disabled]="loading">
        <span *ngIf="!loading" class="h5 mb-0">{{ 'FORM_VIEWER.FOOTER.SAVE' | translate }}</span>
        <span *ngIf="loading"><fa-icon [icon]="['fas', 'spinner']" [pulse]="true"></fa-icon></span>
      </button>
    </ng-container>
    <ng-container *ngIf="(currentPage + 1) === totalPages">
      <button id="btn-finish-web-viewer" class="btn btn-primary rounded-pill p-0 w-100 text-truncate" (click)="finalize()" [disabled]="loading">
        <span *ngIf="!loading" class="h5 mb-0">{{ 'FORM_VIEWER.FOOTER.FINALIZE' | translate }}</span>
        <span *ngIf="loading"><fa-icon [icon]="['fas', 'spinner']" [pulse]="true"></fa-icon></span>
      </button>
    </ng-container>
  </div>
</div>
