import { SettingEnum } from "./admin.model";

/**
 * This class persists information about given screens for the Clinical application.
 * Data includes the active view modes (Table/Menu), and Table grid configurations
 * - View mode (Table/Menu) is held in local storage.
 * - Selected Table configurations are stored in memory.
 */
export class SavedScreenStates {
  private _tableConfigurations = { };

  private get _screenConfigDictionary(): ScreenStateDictionary {
    return JSON.parse(sessionStorage.getItem("ScreenStates") ?? "{}");
  }

  private set _screenConfigDictionary(value: ScreenStateDictionary) {
    sessionStorage.setItem("ScreenStates", JSON.stringify(value ?? {}));
  }

  private setScreenState(screenName: SettingEnum, screenConfig: ScreenState) {
    const currentConfig = this._screenConfigDictionary;
    currentConfig[screenName] = screenConfig;
    this._screenConfigDictionary = currentConfig;
    this._tableConfigurations[screenName] = screenConfig.selectedTableConfig;
  }

  getScreenState(screenName: SettingEnum): ScreenState {
    const screenState = this._screenConfigDictionary[screenName] ?? new ScreenState();
    screenState.selectedTableConfig = this._tableConfigurations[screenName];
    return screenState;
  }

  setTableConfig(screenName: SettingEnum, tableConfig: TableConfig): void {
    this._tableConfigurations[screenName] = tableConfig;
  }

  setView(screenName: SettingEnum, view: "Menu" | "Table"): void {
    const screenConfig = this.getScreenState(screenName);
    screenConfig.selectedView = view;
    this.setScreenState(screenName, screenConfig);
  }
}

type ScreenStateDictionary = Record<string, ScreenState>;

export class ScreenState {
  selectedView: "Menu" | "Table";
  selectedTableConfig: TableConfig;

  public constructor(init?: Partial<ScreenState>) {
    Object.assign(this, init);
  }
}

export interface TableConfig {
  name: string;
  gridId: string;
}
