import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivityHistory, ActivityProcessType } from '@next/shared/common';
import { ActivityLogService } from '@next/shared/next-services';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs';

@Component({
  selector: 'next-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit, OnChanges {

  @Input() patientId: string;
  @Input() appointmentId: string;
  @Input() eventType: ActivityProcessType;

  dataset: Array<ActivityHistory> = [];
  totalItems: number = 0;
  page: number = 0;
  pageSize: number = 10;

  columnHeaders: any[] = [];

  constructor(
    private activityLogSvc: ActivityLogService, 
    private translateSvc: TranslateService,
    private cd: ChangeDetectorRef  
  ) {}

  ngOnInit() {
    this.translateSvc
      .get(['ACTIVITYLOG.COLUMN_HEADERS', 'ACTIVITYLOG.COLUMN_FIELDS'])
      .subscribe((val) => {
        const headers = val['ACTIVITYLOG.COLUMN_HEADERS'];
        this.columnHeaders = [
          { field: 'eventtype', header: headers.EVENT_NAME },
          { field: 'formname', header: headers.FORM },
          { field: 'data', nested_field: 'status', header: headers.STATUS },
          { field: 'eventdate', header: headers.EVENT_DATE },
          { field: 'useremail', header: headers.USER },
          { field: 'data', nested_field: 'signature_field', header: headers.SIGNATURE }
        ];
      });

    this.loadData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.patientId || changes.appointmentId) {
      this.loadData();
    }
  }

  loadData() {
    if (this.patientId) {
      this.activityLogSvc
        .getActivityHistoryByPatientId(this.patientId, this.appointmentId).pipe(tap((response: any) => {
          this.totalItems = response.totalItems;
          this.dataset = response.items;
          this.cd.detectChanges();
        }))
        .subscribe();
    }
  }
}