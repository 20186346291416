<div class="container-fluid h-100 bg-dark">
  <div class='row bg-light rounded-top justify-content-between py-1'>
    <div class='col-2 justify-content-start'>
      <button id='btn-view-experience-close' type='reset' class='btn btn-text text-left py-0 my-0' (click)='closeModal.emit()'>
        {{ 'MANAGE_FORMS.FS_TABLE.VIEW_CLOSE' | translate }}
      </button>
    </div>
    <div class='col-8 justify-content-center align-self-center text-truncate'>
      <h5 class='text-center m-0 p-0'>{{ title }}</h5>
    </div>
    <div class='col-2'></div>
  </div>
  <div class='row iframe-row overflow-hidden'>
    <iframe #iframe title="pdf"></iframe>
  </div>
</div>
