import { FunctionDTO } from "./functiondto.model";
import { BehaviorSubject } from 'rxjs';

export class SearchedFunctionDTOs {
    private _functionDTOs: FunctionDTO[] = [];
    private _selectedFunctionDTO = {} as FunctionDTO;
    public filter: string = '';
    public selectedFunctionDTO$: BehaviorSubject<FunctionDTO>;

    constructor() {
        this.selectedFunctionDTO$ = new BehaviorSubject<FunctionDTO>(this._selectedFunctionDTO);
        this.clearSelectedFunctionDTO();        
    }
    
    get functionDTOs(): FunctionDTO[] {
        return this._functionDTOs;
    }

    set functionDTOs(functionDTOs: FunctionDTO[]) {
        this._functionDTOs = [... functionDTOs];
    }

    get selectedFunctionDTO(): FunctionDTO {
        return this._selectedFunctionDTO;
    }

    set selectedFunctionDTO(functionDTO: FunctionDTO) {
        this._selectedFunctionDTO = {... functionDTO};
        this.selectedFunctionDTO$.next(this._selectedFunctionDTO);
    }

    clearSelectedFunctionDTO() {
        this._selectedFunctionDTO = {name: null, formula: null, helptext: null} as FunctionDTO;
        this.selectedFunctionDTO$.next(this._selectedFunctionDTO);
    }
}
