import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ViewerService } from '../state/viewer.service';
import {
  NextSubmissionService,
  SignatureService,
} from '@next/shared/next-services';
import {
  Attachment,
  FieldDTO,
  FieldType,
  FormSubmission,
  GuidedExperienceDTO,
  GuidedExperienceInstanceDTO,
  GxProcessing,
  Logo,
  Logout,
  RelationshipListSource,
  SignatureFor,
  SignatureType,
  SubmissionData,
  SubmissionMetadata,
  SubmissionType,
  TokenService,
  ViewerFieldType,
  ViewerIntegrationMode,
  WindowMessageEventName,
  WrittenSigDTO,
} from '@next/shared/common';
import { filter, first, map, takeUntil, tap } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { Location } from '@angular/common';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'next-pdf-viewer',
  templateUrl: './viewer-pdf.component.html',
  styleUrls: ['./viewer-pdf.component.css'],
  /* This will cause a viewer service to instantiate every time this Component is created.
   * We do this to reset the internal state fresh each time you navigate to the tool */
  providers: [ViewerService]
})

export class ViewerPdfComponent implements OnInit, OnDestroy {
  @ViewChild('iframe', { static: true }) iframe: ElementRef;
  obsCleanup: Subject<void> = new Subject<void>();
  @Output() pdfViewerEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() instance$: BehaviorSubject<GuidedExperienceInstanceDTO> = new BehaviorSubject<GuidedExperienceInstanceDTO>(null);
  @Input() integration: ViewerIntegrationMode = ViewerIntegrationMode.RAW;

  ViewerIntegrationMode: typeof ViewerIntegrationMode = ViewerIntegrationMode;      // Allow this enum to be used in the template
  isPatientView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isFormValidForAll: boolean = true;
  isFormValidForCurrentView: boolean = true;
  facilityLogo: Logo;

  RequiredClinicianSignatures: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  signQueueSignatures: string[] = [];

  formId: string;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  // Properties defined from instance
  formData: SubmissionData = { };
  experience: GuidedExperienceDTO;
  fields: FieldDTO[] = [];
  prefill: any = { };
  submission: FormSubmission;
  attachments: Attachment[];

  constructor(
    private viewerSvc: ViewerService,
    private submissionSvc: NextSubmissionService,
    private signatureSvc: SignatureService,
    private zone: NgZone,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private gxProcessing: GxProcessing,
    private tokenService: TokenService,
    private location: Location,
  ) {
    // Bindings for direct iFrame access
    (<any>window).loadAnnotationState = this.loadAnnotationState.bind(this);
    (<any>window).loadGXData = this.loadGXData.bind(this);
    (<any>window).integration = this.integration;
  }

  ngOnInit(): void {
    this.viewerSvc.form = new FormGroup({});
    this.activatedRoute.queryParams.pipe(
      tap(params => this.formId = params.formId),
      takeUntil(this.obsCleanup)
    ).subscribe();
    this.instance$.pipe(
      filter(instance => !!instance),
      filter(instance => !!instance.experience),
      map(instance => this.setInstanceForm(instance)),
      tap(instance => {
        this.experience = instance.experience;
        this.fields = instance.experience.pages[0].fields;
        this.prefill = instance.prefill;
        this.submission = instance.submission;
        this.attachments = instance.attachments ?? [];
      }),
      tap(instance => {
        this.viewerSvc.initialize(instance, this.facilityLogo).then(result => {
          this.formData = result.initialData;
          if (this.integration === ViewerIntegrationMode.CLINICAL) this.novaLoadPdf();
          else if (this.integration === ViewerIntegrationMode.MULTI_MONITOR) this.multiMonitorLoadPdf();
          else this.loadPdf();
        }, reason => console.error(reason));
      }),
      takeUntil(this.obsCleanup)
    ).subscribe();
  }

  /**
   * setInstanceForm()
   * - Modifies provided experienceDTO to present only the form page (pdf viewer)
   * @param instance */
  private setInstanceForm(instance: GuidedExperienceInstanceDTO): GuidedExperienceInstanceDTO {
    return {
      ...instance,
      experience: {
        ...instance.experience,
        pages: instance.experience.pages.filter(p => p.pageType === 'FORM')
      }
    };
  }

  ngOnDestroy(): void {
    (<any>window).integration = undefined;
    (<any>window).isPatientView = undefined;
    (<any>window).RequiredClinicianSignatures = undefined;
    (<any>window).SignQueueTaskSignatures = undefined;
    (<any>window).loadAnnotationState = undefined;
    (<any>window).loadGXData = undefined;
    this.obsCleanup.next();
    this.obsCleanup.complete();
  }

  multiMonitorLoadPdf(): void {
    (<any>window).integration = ViewerIntegrationMode.MULTI_MONITOR;
    (<any>window).SignQueueTaskSignatures = undefined;
    (<any>window).isPatientView = undefined;
    (<any>window).RequiredClinicianSignatures = undefined;
    this.loadPdf();
  }

  novaLoadPdf(): void {
    (<any>window).integration = ViewerIntegrationMode.CLINICAL;
    (<any>window).SignQueueTaskSignatures = this.signQueueSignatures;
    // Subscribe to clinical app view state
    this.isPatientView.pipe(
      tap(isPatientView => (<any>window).isPatientView = isPatientView),
      tap(() => {
        const viewerApp = this.iframe?.nativeElement?.contentWindow?.PDFViewerApplication;
        if (viewerApp) {
          const windowApp = this.iframe?.nativeElement?.contentWindow;
          windowApp.requiredElementsForCurrentView = [];
          windowApp.allRequiredElements = [];
          this.isFormValidForCurrentView = true;
          this.isFormValidForAll = true;
          if (viewerApp.eventBus) {
            viewerApp.eventBus.dispatch('toggleView', null);
          }
        }
      }),
      tap(async isPatientView => {
        if (!isPatientView) {
          await this.getFormsSignatures(null);
        }
      }),
      takeUntil(this.obsCleanup)
    ).subscribe();

    // Subscribe to clinical app required signatures (used for form status)
    this.RequiredClinicianSignatures.pipe(
      tap(result => (<any>window).RequiredClinicianSignatures = result),
      takeUntil(this.obsCleanup)
    ).subscribe();
    this.loadPdf();
  }

  private loadPdf(): void {
    const windowApp = this.iframe?.nativeElement?.contentWindow || {};
    windowApp.requiredElementsForCurrentView = [];
    windowApp.allRequiredElements = [];
    this.isFormValidForCurrentView = true;
    this.isFormValidForAll = true;
    this.iframe.nativeElement.contentWindow?.location?.replace(`assets/pdfjs/web/viewer.html?file=${encodeURIComponent(this.experience.pdftemplate.url)}`);
  }

  public async onViewerLoad(): Promise<void> {
    const viewerApp = this.iframe.nativeElement.contentWindow?.PDFViewerApplication;
    if (!viewerApp) return;

    // Wait for initialization to complete so that we can access the Event Bus
    viewerApp?.initializedPromise.then(() => {
      this.zone.run(() => {
        this.loading$.next(false);
      });

      viewerApp.eventBus.on('submitform', this.submit.bind(this, SubmissionType.Submitted, true));
      viewerApp.eventBus.on('fieldchanged', this.onFieldChange.bind(this));
      viewerApp.eventBus.on('staff-signature', this.onSignature.bind(this));
      viewerApp.eventBus.on('validationchanged', this.onValidationChanged.bind(this));
      viewerApp.eventBus.on('allrequiredfieldschanged', this.onAllRequiredFieldsChanged.bind(this));
      // Hook additional Event Listeners for integration
      switch (this.integration) {
        case ViewerIntegrationMode.CLINICAL:
          this.setClinicalEventHooks(viewerApp);
          break;
        case ViewerIntegrationMode.MULTI_MONITOR:
          this.setMMEventHooks(viewerApp);
          break;
      }
    });
  }

  async getFormsSignatures(callback) {
    const isCurrentUserRequiredToSign = (fieldName, assignedSignatures): boolean => {
      const assignedSignature = assignedSignatures.find(a => a.fieldname === fieldName);
      return assignedSignature
        ? assignedSignature.assigntoid === this.tokenService.getIdentity().id || this.tokenService.isIdInCurrentUsersGroups(assignedSignature.assigntoid)
        : false;
    };

    this.signatureSvc.getAssignedSignatures(this.experience.vid, this.formId).subscribe((assignedSignatures) => {
      const sigs: string[] = [];
      const signatureFields = this.fields.filter(f => f.type === FieldType.Signature);
      for (const field of signatureFields) {
        const signatureFieldDTO: WrittenSigDTO = field as WrittenSigDTO;
        if (signatureFieldDTO.signatureFor === SignatureFor.Staff && signatureFieldDTO.required && isCurrentUserRequiredToSign(field.name, assignedSignatures)) {
          sigs.push(field.name);
        }
      }
      this.RequiredClinicianSignatures.next(sigs);
      if (callback) callback();
    });
  }

  /**
   * Sets a global annotation variable that the Viewer will access for loading the initial state.
   * This is called directly by the Viewer and will block until complete.
   * This required a modification to the viewer.
   */
  public loadAnnotationState(): unknown {
    let data = {};
    if (this.formData) {
      data = this.formData;
    }
    return data;
  }

  /**
   * Sets a global annotation variable that the Viewer will access for loading the initial state.
   * This is called directly by the Viewer and will block until complete.
   * This required a modification to the viewer.
   */
  public loadGXData(): any[] {
    return this.gxProcessing.getAllFields(this.experience);
  }

  private async prepareSubmissionPayload(submissionType: SubmissionType): Promise<FormSubmission> {
    const processedData: any = await this.gxProcessing.postProcessing(this.experience, this.formId, 0, this.formData, this.submissionSvc);
    const submissionData: any = await this.viewerSvc.processCalculations(this.experience, submissionType, this.submission?.data || { }, processedData, this.prefill || '');

    if (this.prefill && Object.keys(this.prefill).length) {
      Object.assign(submissionData, { _prefill_c4af0d49_e948_4737_8c12_8d64511faeec: this.prefill });
    }

    return {
      id: this.formId || this.submission?.id || null,
      experienceversionid: this.experience.vid,
      submissiontype: submissionType,
      updatedby: '',
      fileid: null,
      data: submissionData,
      metadata: {
        client: null,
        page: 0
      } as SubmissionMetadata,
      lastupdated: this.submission?.lastupdated || null
    };
  }

  public async submit(submissionType: SubmissionType, postMessage: boolean = true, doCreate = true): Promise<void> {

    const payload: FormSubmission = await this.prepareSubmissionPayload(submissionType);

    const upsert = this.submission?.updatedon || !doCreate
      ? this.submissionSvc.update(payload)
      : this.submissionSvc.create(payload);

    // If there is an existing submission, update it
    // Else, create a new form. A formId may or may not have been provided.
    upsert.pipe(
      first()
    ).subscribe({
      next: result => {
        if (result.type === HttpEventType.Response) {
          this.submission = result.body;
          this.formId = this.viewerSvc.formId = result.body.id;

          this.location.go(
            this.router.createUrlTree([], {
              relativeTo: this.activatedRoute,
              queryParams: {formId: result.body.id},
              queryParamsHandling: 'merge'
            }).toString());

          const submitResultMessage: { eventName: WindowMessageEventName, formId: string } = {
            eventName: (submissionType === SubmissionType.Saved)
              ? WindowMessageEventName.ExperienceSave
              : WindowMessageEventName.ExperienceSubmit,
            formId: this.submission.id,
          }

          if (this.integration === ViewerIntegrationMode.CLINICAL && this.pdfViewerEmitter) {
            this.pdfViewerEmitter.emit(submitResultMessage);
          }

          if (postMessage && window.parent) {
            window.parent.postMessage(submitResultMessage, document.referrer || '*');
          }

          if (postMessage && window.opener) {
            (window.opener as Window).postMessage(submitResultMessage, window.opener?.location?.origin || '*');
          }
        }
      },
      error: e => {
        if (this.integration === ViewerIntegrationMode.CLINICAL && e.status === 409) {
          this.pdfViewerEmitter.emit({ eventName: WindowMessageEventName.SubmitError, formId: payload.id });
        }
      }
    });
  }

  /**
   * Submits a form without HTTP progress events.
   * @param submissionType
   * @param doCreate
   */
  public async submitWithoutProgress(submissionType: SubmissionType, doCreate: boolean = false): Promise<void> {

    const payload: FormSubmission = await this.prepareSubmissionPayload(submissionType);

    const upsert = this.submission?.updatedon || !doCreate
      ? this.submissionSvc.updateWithoutProgress(payload)
      : this.submissionSvc.createWithoutProgress(payload);

    upsert.subscribe({
      next: (submission: FormSubmission) => {
        this.submission = submission;

        const submitResultMessage: {eventName: WindowMessageEventName, formId: string, status: string} = {
          eventName: (submissionType === SubmissionType.Saved)
            ? WindowMessageEventName.ExperienceSave
            : WindowMessageEventName.ExperienceSubmit,
          formId: this.submission.id,
          status: this.submission['status']
        };

        if (window.parent) {
          window.parent.postMessage(submitResultMessage, '*');
        }

        if (window.opener) {
          (window.opener as Window).postMessage(submitResultMessage, '*');
        }
      },
      error: e => {
        if (this.integration === ViewerIntegrationMode.CLINICAL && e.status === 409) {
          this.pdfViewerEmitter.emit({ eventName: WindowMessageEventName.SubmitError, formId: payload.id });
        }
      }
    });
  }

  private onFieldChange(evt: any): void {

    // The input element that triggered the change
    const viewerDocument = this.iframe.nativeElement.contentWindow?.document || document.getElementsByTagName('iframe')[0].contentDocument;
    const inputEls = viewerDocument.getElementsByName(evt.source.data.fieldName);

    // The element that was updated
    // If element is a group, get the one that is checked
    const inputEl = inputEls.length === 1
      ? inputEls[0]
      : [].find.call(inputEls, el => el.checked === true);

    // The current state of our Form Data - This will be updated
    const state = this.formData;
    this.updateStateFromElement(state, inputEl);

    // The field object that contains an optional Calculation function
    // This may be null if a field exists in the PDF that does not in the Exp.
    const field = this.fields.find(obj => obj.name === evt.source.data.fieldName);

    // If the field exists, we want to run its calc function and update all affected fields in the Viewer
    if (field?.calculations) {
      this.runFieldCalculation(field, state);
    }

    switch (this.integration) {
      case ViewerIntegrationMode.CLINICAL:
      case ViewerIntegrationMode.MULTI_MONITOR:
        this.pdfViewerEmitter.emit({
          eventName: WindowMessageEventName.FieldChanged,
          fieldConfig: field,
          fieldElement: inputEl,
          experience: this.experience,
          formData: this.formData,
          submission: this.submission
        });
        break;
    }
  }

  /** onSelfSign()
   * ---
   * Similar to onSignature(), but no access code is necessary.
   * Update field and associated fields, instruct pdf.js to rerender the signature annotation
   * and then emit field change. */
  public async onSelfSign(event: any): Promise<void> {
    if (event.Value.Strokes || event.Value.Text) {
      const signatureConfig: WrittenSigDTO = this.fields.find(x => x.type === ViewerFieldType.WRITTENSIG && x.name === event.Name) as WrittenSigDTO;
      const associatedFields = this.setSignatureAssociatedFields(event, signatureConfig);
      const signatureSubmission = {
        Value: event.Value,
        Type: event.Value.Strokes ? 'Signature' : 'TypedSignature'
      };
      const updatedFields = { [signatureConfig.name]: signatureSubmission, ...associatedFields };
      this.updateFormFields(updatedFields, this.formData);

      const viewerApp = this.iframe.nativeElement.contentWindow.PDFViewerApplication;
      viewerApp.eventBus.dispatch('signature-signed', signatureSubmission);

      this.pdfViewerEmitter.emit({
        eventName: WindowMessageEventName.FieldChanged,
        fieldConfig: signatureConfig,
        fieldElement: null,
        experience: this.experience,
        formData: this.formData
      });
    }
  }

  /**
   * The viewer requests for signature, take the correctly formatted signature
   * submission object and update formData with sig and associated fields.
   * Also request pdf.js re-render this signature annotation via eventBus.
   * @param event
   * @private */
  public async onSignature(event: any): Promise<void> {
    const signatureConfig: WrittenSigDTO = this.fields.find(f => f.name === event.Name) as WrittenSigDTO;
    let signatureValue = null;
    let allUpdatedFieldValues = null;
    try {
      signatureValue = { Value: event.Value, Type: event.Value.Strokes ? 'Signature' : 'TypedSignature' };
      allUpdatedFieldValues = this.setSignatureAssociatedFields(event, signatureConfig);
      const updatedFields = { [signatureConfig.name]: signatureValue, ...allUpdatedFieldValues };
      this.updateFormFields(updatedFields, this.formData);
    }
    catch (err) {
      signatureValue = null;
    }
    finally {
      // Instruct pdf.js to render the changed signature annotation
      const viewerApp = this.iframe.nativeElement.contentWindow.PDFViewerApplication;
      viewerApp.eventBus.dispatch('signature-signed', signatureValue);
      this.pdfViewerEmitter.emit({
        eventName: WindowMessageEventName.FieldChanged,
        fieldConfig: signatureConfig,
        fieldElement: null,
        experience: this.experience,
        formData: this.formData
      });
    }
  }

  setSignatureAssociatedFields(event, field: WrittenSigDTO): any {
    const updatedFields: SubmissionData = { };
    if (field) {
      if (field.signatureTimeStampFieldName) {
        // Note: PDF.jS signature_prompt.js itself is handing the timestamp, but ideally we would move it
        // into this routine, so all is in one location
      }

      if (field.captureSignerNameApplyTo && event?.CaptureSignerName) {
        const signerNameValue = this.formData[field.captureSignerNameApplyTo];
        updatedFields[field.captureSignerNameApplyTo] = {
          ...signerNameValue,
          ...{
            Value: {
              Text: event.Value.Name || event.Value.Text || ''
            }
          }
        };
      }

      if (field.captureRelationshipApplyTo && event?.CaptureRelationship) {
        const relationshipValue = this.formData[field.captureRelationshipApplyTo];
        updatedFields[field.captureRelationshipApplyTo] = {
          ...relationshipValue,
          ...{
            Value: {
              Text: event.Value.Relationship || ''
            }
          }
        };
      }
    }
    return updatedFields;
  }

  /** ___setFormParameters()___
   *
   * After the viewer has been initialized, setFormParameters() can be called to update
   * the viewer with parameter information.  This also takes into account calculations.
   *
   * Parameters must leverage the existing Prefill system.
   * Update this.prefill and you can call this routine as often as you'd like. */
  setFormParameters() {
    /** ___hightlight()___
     *
     * Highlight an array of fields in the PDF Viewer for a period of time
     *
     * @param fields An array of fields that need to be highlighted
     * @param seconds The number of seconds for the fields to stay highlighted
     */
    const highlight = (fields: SubmissionData, seconds: number) => {

      // If there is nothing to highlight, ignore this routine!
      if (Array(fields).length === 0) return;

      // Get the document of our PDF Viewer
      const viewerDocument = this.iframe.nativeElement.contentWindow?.document || document.getElementsByTagName('iframe')[0].contentDocument;

      // Iterate through our fields, looking them up in the DOM, and assigning our class
      for (const name in fields) {

        const updatedEls = viewerDocument.getElementsByName(name);
        if (!updatedEls.length) continue; // Protect against a bad script field

        // Add highlight
        for (const el of updatedEls) {
          el.classList.add('highlight-off', 'highlight-on');
        }
      }

      // Remove highlight, after N seconds
      // This is done in steps because we need our final transition to run unto completion.
      // Two classes are used here so that our base elements do not have to cary the transition code.
      setTimeout(function () {
        // Remove our highlight class, which will trigger the "-off" version's transition animation
        Array.from(viewerDocument.querySelectorAll('.highlight-on')).forEach((el: HTMLElement) => el.classList.remove('highlight-on'));

        // Keep this class active just long enough for it to finish its animation
        setTimeout(function () {
          Array.from(viewerDocument.querySelectorAll('.highlight-off')).forEach((el: HTMLElement) => el.classList.remove('highlight-off'));
        }, 1000);

      }, seconds * 1000); // Hold the highlight for N seconds
    }

    // Step 1 - Get all fields that have been updated with parameter data
    const updatedFieldSubmissions = this.gxProcessing.getParameterFields(this.experience, this.prefill);
    // Step 2 - Update those fields in the PDF Viewer for display, and our State Store
    this.updateFormFields(updatedFieldSubmissions, this.formData);
    // Step 3 - Run Calculations all at once!
    // Note: By setting the fields first, then running calculations, we can ensure that
    //       calculations that reference fields instead of prefill will work.
    for (const name in updatedFieldSubmissions) {
      const field = this.fields.find((fld) => fld.name === name);
      if (!field || !field.calculations.onChange) continue;

      this.runFieldCalculation(field, this.formData);
    }

    // Step 4 - Make the updated fields glow for 3 seconds
    highlight(updatedFieldSubmissions, 3);
  }


  public async onValidationChanged(event) {
    this.isFormValidForCurrentView = event.source.isValid;
  }


  public async onAllRequiredFieldsChanged(event) {
    this.isFormValidForAll = event.source.isEntireFormValid;
  }

  /**
   * Run the optional OnChange calculation for a specific field.
   * This will do three things:
   *    1. Run the calculation resulting in field changes
   *    2. Update our form data state with the new field values
   *    3. Update the Viewer elements with that same information
   *
   * A calculation will NOT trigger another OnChange calculation.
   * @param field
   * @param state
   */
  private runFieldCalculation(field: FieldDTO, state: SubmissionData) {
    // Run Change Calculation on Field
    this.viewerSvc.getCalculationData(field.calculations.onChange, field.name, this.experience, state, this.prefill)
      .then((updatedValues) => {
        if (updatedValues) {
          this.updateFormFields(updatedValues.fields, state);
          this.experience = this.gxProcessing.getUpdatedConfig(this.experience, updatedValues.configs);
          const viewerApp = this.iframe.nativeElement.contentWindow.PDFViewerApplication;
          viewerApp.eventBus.dispatch('updateconfigs', Object.keys(updatedValues.configs).map((key) => { return updatedValues.configs[key]; }));
        }
      }).catch(err => console.error(err));
  }

  updateFormFields(updatedFields: SubmissionData, state: any = {}) {
    const viewerDocument = this.iframe.nativeElement.contentWindow?.document || document.getElementsByTagName('iframe')[0].contentDocument;

    // Iterated all fields that were updated as part of this calculation
    for (const key in updatedFields) {
      if (!Object.prototype.hasOwnProperty.call(updatedFields, key)) continue;

      const updatedField = updatedFields[key];

      // Skip on no change
      if (JSON.stringify(state[key]) === JSON.stringify(updatedField)) continue;

      state[key] = updatedField;

      // Update the affected field in the PDF Viewer
      const updatedEls = viewerDocument.getElementsByName(key);
      if (!updatedEls.length) continue; // Protect against a bad script field

      // Some elements are grouped, like radio buttons, and need to be processed specially.
      if (updatedEls.length > 1) {
        const elements: any[] = Array.from(updatedEls);
        const selectRadio = elements.find(obj => obj.type === 'radio' && obj.value === updatedField.Value.Text);

        if (selectRadio) {
          selectRadio.checked = true;
          selectRadio.onclick();
        }
      }

      else {
        const updateEl = updatedEls[0];
        // Signature annotations have their init() routine stubbed into their container element
        // If this function is found, call it to rerender the signature annotation
        if (updateEl.parentNode?.initFn) {
          updateEl.parentNode.initFn();
        }
        else {
          switch (updateEl.type) {
            case 'checkbox': {
              updateEl.checked = updatedField.Value.Text !== '';
              if (updateEl.checked) {
                updateEl.onclick();
              }
              break;
            }
            case 'button': {
              break;
            }
            default: {
              updateEl.value = updatedField.Value.Text;
              if (updateEl.onchange) {
                updateEl.onchange();
              }
              if (updateEl.onblur) {
                updateEl.onblur();
              }
            }
          }
        }
      }
    }
  }


  /**
   * Update the form data state with form element data found in each annotation layer
   * NOTE: Currently not used, but could be useful in the future.
   * @param state
   */
  private updateStateFromElements(state) {

    // Get all annotation layers that have been rendered
    const viewerDocument = this.iframe.nativeElement.contentWindow?.document || document.getElementsByTagName('iframe')[0].contentDocument;
    const annotLayers = viewerDocument.getElementsByClassName('annotationLayer');

    // Iterate through each annotation layer
    for (const annotLayer of annotLayers) {

      // Note: For radio, we are only getting back the specific element that was selected
      const inputEls = annotLayer.querySelectorAll("input[type='text'], input[type='checkbox'], input[type='radio']:checked, select, textarea");
      for (const inputEl of inputEls) {
        this.updateStateFromElement(state, inputEl);
      }
    } // For all annotation layers
  }


  /**
   * Update the form data state with data stored in a specific element
   * @param state
   * @param inputEl
   */
  private updateStateFromElement(state, inputEl) {

    // Get field object that represents the field data.  This includes Type, Value, etc.
    const fieldValue = state[inputEl.name];
    if (!fieldValue) return; // Field state should be defaulted by pdfjs

    // Update the field value directly from the input element.
    // Different types of elements need to be processed differently.
    switch (inputEl.type) {
      case 'checkbox':
        fieldValue.Value.Text = inputEl.checked ? inputEl.value : '';
        break;
      case 'button':
        break;
      default:  // text, select, textarea, radio
        fieldValue.Value.Text = inputEl.options ? inputEl.options[inputEl.selectedIndex].value : inputEl.value;
    } // switch
  }

  focusOnField(name: string): void {
    const viewerDocument = this.iframe.nativeElement.contentWindow?.document || document.getElementsByTagName('iframe')[0].contentDocument;
    const fields = viewerDocument.getElementsByName(name);
    if (fields.length) {
      fields[0].focus();
    }
  }

  isRequiredField(name: string): boolean {
    const viewerDocument = this.iframe.nativeElement.contentWindow?.document || document.getElementsByTagName('iframe')[0].contentDocument;
    const fields = viewerDocument.getElementsByName(name);
    if (fields.length) {
       return fields[0].className === 'required';
    }
    else {
      return false;
    }
  }

  /**
   * CB that updates local storage session timer.
   * iFrames do not inherit DOM events from their parent document */
  onLoadEnd(): void {
    const domEventTypes = [ 'click', 'mouseover', 'mouseout', 'keyup', 'wheel', 'scroll', 'touchstart' ];
    for (const eventType of domEventTypes) {
      this.iframe.nativeElement.contentWindow.document.addEventListener(eventType, () => localStorage.setItem(Logout.LAST_ACTION_TIME_STORE_KEY, Date.now().toString()));
    }
  }

  private setClinicalEventHooks(PDFJSViewerApplication): void {
    PDFJSViewerApplication.eventBus?.on('pagesloaded', (e) => {
      this.pdfViewerEmitter.emit({
        eventName: WindowMessageEventName.PagesLoaded,
        viewerApp: PDFJSViewerApplication,
        pagesCurrent: PDFJSViewerApplication.page,
        pagesCount: e.pagesCount,
      })
    });

    let pagesRendered = [];
    PDFJSViewerApplication.eventBus?.on('pagerendered', (e) => {
      this.zone.run(() => {
        pagesRendered.push(e);
        if (pagesRendered.length) {
          this.pdfViewerEmitter.emit({
            eventName: WindowMessageEventName.PageRendered,
            viewerApp: PDFJSViewerApplication
          });
          pagesRendered = [];
        }
      });
    });

    PDFJSViewerApplication.eventBus?.on('documentinit', () => {
      this.zone.run(() => {
        PDFJSViewerApplication.eventBus.dispatch('firstpage');
      });
    });

    PDFJSViewerApplication.eventBus.on('sign', (e) => {
      this.zone.run(() => {
        const signatureDTO: WrittenSigDTO = e.data.GXField || { };
        let signerName: string = '';
        if (signatureDTO.captureSignerName && signatureDTO.signatureFor === SignatureFor.Patient && this.prefill.Patient) {
          signerName = `${this.prefill?.Patient?.firstname} ${this.prefill?.Patient?.lastname}`;
        }
        this.pdfViewerEmitter.emit({
          eventName: WindowMessageEventName.Signature,
          signatureFieldConfig: signatureDTO,
          signatureProperties: {
            signatureFor: signatureDTO.signatureFor || SignatureFor.Other,
            signatureType: signatureDTO.signatureType || SignatureType.DrawnSignature,
            signatureName: signatureDTO.name || e.data.fieldName,
            enableTypedSignature: signatureDTO.enableTypedSignature,
            captureSignerName: signatureDTO.captureSignerName,
            captureRelationship: signatureDTO.captureRelationship,
            defaultStatement: signatureDTO.statementText,
            relationshipSource: signatureDTO.captureRelationshipListSource,
            pdfSettings: signatureDTO.captureRelationshipListSource === RelationshipListSource.UsePDFSettings
              ? (this.fields.find(f => f.name === signatureDTO.captureRelationshipApplyTo) as any).options
              : [],
            patientName: signerName,
            title: signatureDTO.title,
            lockedFieldNames: e.data.fieldLocking?.fields,
          }
        });
      })
    });
  }

  private setMMEventHooks(PDFJSViewerApplication): void {
    PDFJSViewerApplication.eventBus.on('sign', (e) => {
      this.zone.run(() => {
        const signatureDTO = e.data.GXField || { };
        let signerName: string = '';
        if (signatureDTO.captureSignerName && signatureDTO.signatureFor === SignatureFor.Patient && this.prefill.Patient) {
          signerName = `${this.prefill?.Patient?.firstname} ${this.prefill?.Patient?.lastname}`;
        }
        this.pdfViewerEmitter.emit({
          eventName: WindowMessageEventName.Signature,
          signatureFieldConfig: signatureDTO,
          signatureProperties: {
            signatureFor: signatureDTO.signatureFor || 'other',
            signatureType: signatureDTO.signatureType || 'drawn',
            signatureName: signatureDTO.name || e.data.fieldName,
            enableTypedSignature: signatureDTO.enableTypedSignature,
            captureSignerName: signatureDTO.captureSignerName,
            captureRelationship: signatureDTO.captureRelationship,
            defaultStatement: signatureDTO.statementText,
            relationshipSource: signatureDTO.captureRelationshipListSource,
            pdfSettings: signatureDTO.captureRelationshipListSource === RelationshipListSource.UsePDFSettings
              ? (this.fields.find(f => f.name === signatureDTO.captureRelationshipApplyTo) as any).options
              : [],
            patientName: signerName,
            title: signatureDTO.title,
            lockedFieldNames: e.data.fieldLocking?.fields,
          }
        });
      })
    });
  }

  @HostListener('document:webviewerloaded', ['$event'])
  webViewerLoaded(event) {
    if (event.type === 'webviewerloaded') {
      this.onViewerLoad();
    }
  }
}
