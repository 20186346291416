import { Observable } from 'rxjs';
import { LayerExperienceDTO } from '@next/shared/common';
import { Injectable } from '@angular/core';
import { NextExperienceService } from '../../experience.service';

@Injectable({
  providedIn: 'root',
})
export class NextLayersResolverService {
  constructor(private experienceSvc: NextExperienceService) { }

  resolve(): Observable<LayerExperienceDTO[]> | Observable<never> {
    return this.experienceSvc.getLayerExperiencesPublished();
  }
}
