import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AddFormsModalComponent } from '../add-forms-modal/add-forms-modal.component';
import {
  NextExperienceService,
  NextFormService,
  NextImageService,
  NextSubmissionService,
} from '@next/shared/next-services';
import {
  Appointment,
  Facility,
  FormDTO,
  FormEmbedded,
  FormGenerateDTO,
  FormStatus,
  GuidedExperienceDTO,
  GxProcessing,
  Logo,
  LogoService,
  Patient,
  SelectedForms,
  SubmissionData,
} from '@next/shared/common';
import { StoreService } from '../state/store.service';
import { FormViewComponent } from '../forms-table/components/form-view/form-view.component';
import { App, ConfirmationDialogComponent, Utility } from '@next/shared/ui';
import { TranslateService } from '@ngx-translate/core';
import { StateViewerService } from '../state/state-viewer.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormsUtilityService {
  modalRef: BsModalRef;
  private pdfUrl: string;
  private pdfIframeId: string = 'PrintPdfIframe';

  constructor (
    private experienceSvc: NextExperienceService,
    private formSvc: NextFormService,
    private gxProcessing: GxProcessing,
    private submissionSvc: NextSubmissionService,
    private imageSvc: NextImageService,
    private logoSvc: LogoService,
    private modalSvc: BsModalService,
    private router: Router,
    private stateViewerSvc: StateViewerService,
    private storeSvc: StoreService,
    private translateSvc: TranslateService,
    private spinnerSvc: NgxSpinnerService) { }

  showAddFormsModal(modalConfig: AddFormsModalConfig, modalOptions?: ModalOptions): void {
    this.modalRef = this.modalSvc.show(AddFormsModalComponent, modalOptions ?? {
      ignoreBackdropClick: false,
      keyboard: true,
      class: 'modal-lg',
      initialState: {
        treeMode: true,
        showPackets: true,
        flattenPacketOutput: true,
        showTitle: false,
        hideActionPrint: false,
        hideActionSign: false,
        hideActionAdd: false
      }
    });
    // On Cancel
    this.modalRef.content.modalClose.subscribe(() => this.modalRef.hide());
    // On Add
    this.modalRef.content.modalAdd.subscribe(async results => {
      this.modalRef.hide();
      const appointmentArray = Utility.convertToArray(modalConfig.appointments, modalConfig.appointment);
      const forms = this.convertAddFormsModalResultsToFormDTOs(results, modalConfig.patient, appointmentArray);
      const newForms = await this.formSvc.bulkFormInsert(forms).toPromise();
      newForms.forEach(f => {
        f.patientdataid = modalConfig.patient.id;
        f.patientdata = modalConfig.patient;
        f.appointment = f.appointmentid ? appointmentArray.find(appointment => appointment.id === f.appointmentid) : null;
      });
      if (modalConfig.onAdd) {
        modalConfig.onAdd(newForms);
      }
    });
    // On Print
    this.modalRef.content.modalPrint.subscribe(async results => {
      this.modalRef.hide();
      const appointmentArray = Utility.convertToArray(modalConfig.appointments, modalConfig.appointment);
      const forms = this.convertAddFormsModalResultsToFormDTOs(results, modalConfig.patient, appointmentArray);
      await this.printFormsAsync(forms);
    });
    // On Fill Out
    this.modalRef.content.modalSign.subscribe(async results => {
      this.modalRef.hide();
      await this.spinnerSvc.show(App.SPINNERS.MAIN_SPINNER);
      const appointmentArray = Utility.convertToArray(modalConfig.appointments, modalConfig.appointment);
      const forms = this.convertAddFormsModalResultsToFormDTOs(results, modalConfig.patient, appointmentArray);
      const newForms = await this.formSvc.bulkFormInsert(forms).toPromise();
      newForms.forEach(f => {
        f.patientdataid = modalConfig.patient.id;
        f.patientdata = modalConfig.patient;
        f.appointment = f.appointmentid ? appointmentArray.find(appointment => appointment.id === f.appointmentid) : null;
      });
      if (modalConfig.onSign) {
        modalConfig.onSign(newForms);
      }
      this.stateViewerSvc.selectedForms = new SelectedForms(newForms);
      await this.fillOutFormsAsync();
      await this.spinnerSvc.hide(App.SPINNERS.MAIN_SPINNER);
    });
  }

  async fillOutFormsAsync(): Promise<void> {
    this.stateViewerSvc.disableParameterModal = false;
    await this.router.navigate(['form/viewer']);
  }

  async printFormsAsync(forms: FormDTO[], submissionData?: SubmissionData, guidedExperienceDTO?: GuidedExperienceDTO): Promise<void> {
    if (!forms) {
      return;
    }
    await this.spinnerSvc.show(App.SPINNERS.MAIN_SPINNER);
    const payload: FormGenerateDTO[] = await Promise.all(forms.map(async form => {
      let submission = null;
      let facilityLogo: Logo = { id: '',  src: '' };
      if (submissionData || !form.status || form.status.toLowerCase() === FormStatus.NotStarted.toLowerCase()) {

        const experience: GuidedExperienceDTO = guidedExperienceDTO ?? await firstValueFrom(this.experienceSvc.getExperience(form?.experienceid))
        const facilityReference = this.getFacilityReference(form);
        if (facilityReference?.logoid) {
          const image = await this.imageSvc.getImageById(facilityReference.logoid).toPromise()
          if (image) {
            facilityLogo = {
              id: facilityReference.logoid,
              src: await this.logoSvc.getBase64Logo(image.secureUrl)
            };
          }
        }
        const prefill: any = this.getFormPrefill(form);

        prefill.Metadata = {
          formid: form.id,
          formtemplatename: form.name,
          status: form.status,
          archivedon: form.lastarchivedon,
        };
        prefill.Template = {
          templatename: experience.name,
          templateid: experience.id,
          version: experience.version,
          description: experience.description,
          tags: form.tags,
          metadataformid: experience.metadataformid,
          metadataoldformid: experience.metadataoldformid,
          metadataarchiveenabled: experience.metadataarchiveenabled,
          metadataprintshopid: experience.metadataprintshopid,
          metadatadocumenttype: experience.metadatadocumenttype,
          metadatadepartments: experience.metadatadepartments?.map(d => this.storeSvc.departments.find(x => x.id === d).name).join(', '),
          metadatalanguage: experience.metadatalanguage,
          metadatapolicy: experience.metadatapolicy,
          metadatapublicurl: experience.metadatapublicurl,
          metadataformowner: experience.metadataformowner,
          metadataapprovedby: experience.metadataapprovedby,
          metadataapproveddate: experience.metadataapproveddate,
          metadatafacilities: experience.metadatafacilities?.map(f => this.storeSvc.facilitiesByUserGroup.find(x => x.id === f).name).join(', '),
          modifiedby: experience.modifiedby,
          updatedon: experience.updatedon,
          publishedby: experience.publishedby,
          publishedon: experience.publishedon
        };
        prefill.Department = { name: prefill.Appointment.departmentlongname, shortname: prefill.Appointment.departmentshortname, departmentid: prefill.Appointment.department };
        prefill.Facility = facilityReference;
        const preprocessedSubmissionData = await this.gxProcessing.preProcessing(experience, { id: form.id, data: submissionData }, prefill, facilityLogo);
        submission = await this.gxProcessing.postProcessing(experience, form.id, 0, preprocessedSubmissionData?.data ?? submissionData, this.submissionSvc);
      }
      return {
        pdftemplateid: form.pdftemplateid,
        formid: form.id,
        status: form.status || FormStatus.NotStarted,
        submissionData: submission
      };
    }));
    await this.printAndDisplayFormsAsync(payload);
    await this.spinnerSvc.hide(App.SPINNERS.MAIN_SPINNER);
  }

  async viewFormAsync(form: FormDTO): Promise<void> {
    const formEmbedded: FormEmbedded = form as FormEmbedded;
    let facilityLogo: Logo = { id: '',  src: ''};
    const facilityReference = this.getFacilityReference(form);
    if (facilityReference?.logoid) {
      const image = await this.imageSvc.getImageById(facilityReference.logoid).toPromise()
      if (image) {
        facilityLogo = {
          id: facilityReference.logoid,
          src: await this.logoSvc.getBase64Logo(image.secureUrl)
        };
      }
    }
    formEmbedded.prefill = this.getFormPrefill(form);

    const modal = this.modalSvc.show(FormViewComponent, {
      ignoreBackdropClick: false,
      keyboard: true,
      class: 'modal-form-view h-100',
      initialState: {
        embeddedForm: formEmbedded,
        logoUri: facilityLogo
      }
    });
    modal.content.closeModal.subscribe(() => modal.hide());
  }

  showDeleteFormsModal(callbacks: ModalCallbacks, modalOptions?: ModalOptions): void {
    const modalRef = this.modalSvc.show(ConfirmationDialogComponent, modalOptions ?? {
      initialState: {
        title: this.translateSvc.get('CONFIRMATION_DEL.TITLE'),
        message: this.translateSvc.get('CONFIRMATION_DEL.TASK'),
        cancelButton: this.translateSvc.get('CONFIRMATION_DEL.CANCEL'),
        confirmButton: this.translateSvc.get('CONFIRMATION_DEL.CONFIRM')
      },
      class: 'modal-confirmation',
      ignoreBackdropClick: true,
      keyboard: false
    });
    modalRef.content.onClose.subscribe((confirmed: boolean) => {
      if (confirmed && callbacks.onConfirm) {
        callbacks.onConfirm();
      }
      if (!confirmed && callbacks.onCancel) {
        callbacks.onCancel();
      }
    });
  }

  convertAddFormsModalResultsToFormDTOs(results: any[], patient: Patient, appointments: Appointment[]): FormDTO[] {
    const formDTOArray: FormDTO[] = [];
    appointments = Utility.convertToArray(appointments);
    appointments.forEach((appointment) => {
      (results || []).forEach((form) => {
        formDTOArray.push(({
          ...form,
          formid: form.id,
          personid: appointment?.patientdata?.id ?? patient.id,
          appointmentid: appointment?.id ?? null,
          firstname: appointment?.patientdata?.firstname ?? patient.firstname,
          lastname: appointment?.patientdata?.lastname ?? patient.lastname,
          mrn: appointment?.patientdata?.mrn ?? patient.mrn,
          servicingfacility: appointment?.servicingfacility
        }))
      })
    });
    return formDTOArray;
  }

  getFacilityReference(form: FormDTO): Facility {

    if (!form) {
        return null;
    }
    const activeFacilityList = this.storeSvc.allFacilities?.filter(x => !!x.isactive);
    if (!form.appointmentid) {
        if (activeFacilityList.length === 1) {
            return activeFacilityList[0];
        } else {
            return this.storeSvc.selectedFacility;
        }
    } else {
        return activeFacilityList.find(facility => facility.facilityid?.toLowerCase() === form.servicingfacility?.toLowerCase());
    }
  }

  public async printAndDisplayFormsAsync(payload: FormGenerateDTO[], dataUri: string = ''): Promise<void> {
    const base64 = dataUri.length > 0 ? dataUri : await firstValueFrom(this.formSvc.generateForm(payload))

    if (base64?.length <= 0) {
      throw new Error();
    }

    if (window['invokeCSharpAction']) {
      window['invokeCSharpAction'](JSON.stringify({command: 'print', data: base64}));
      return;
    }

    const blob = await (await fetch(base64)).blob();

    if (this.pdfUrl) {
      // Best practices, URLs should be removed if they are no longer needed.
      // https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL_static#usage_notes
      window.URL.revokeObjectURL(this.pdfUrl);
    }

    this.pdfUrl = window.URL.createObjectURL(blob);

    this.printPdfIframe(this.pdfUrl);
  }

  private printPdfIframe(url: string): void {
    let pdfIFrame: HTMLIFrameElement;

    pdfIFrame = document.getElementById(this.pdfIframeId) as HTMLIFrameElement;

    if (!pdfIFrame) {
      pdfIFrame = document.createElement('iframe');
      document.body.appendChild(pdfIFrame);
      pdfIFrame.id = this.pdfIframeId;
      pdfIFrame.style.display = 'none';
    }

    pdfIFrame.src = url;
    pdfIFrame.onload = () => {
      setTimeout(() => {
        pdfIFrame.contentWindow.focus();
        pdfIFrame.contentWindow.print();
      }, 100);
    };
  }

  private getFormPrefill(form: FormDTO): any {
    const metadata = {
      formid: form.id,
      formtemplatename: form.name,
      version: form.version,
      status: form.status,
      tags: form.tags,
      updatedon: form.updatedon,
      archivedon: form.lastarchivedon,
    };

    const prefill = form.appointmentid
      ? this.getAppointmentFormPrefill(form.appointmentid)
      : this.getPatientFormPrefill(form.patientdataid);
    prefill.Metadata = metadata;
    return prefill;
  }

  private getAppointmentFormPrefill(appointmentId: string): any {
    let appointment = this.storeSvc.searchedAppointments.appointments.find(app => app.id === appointmentId);
    if (!appointment) {
      appointment = this.storeSvc.todaysAppointments.appointments.find(app => app.id === appointmentId);
    }
    return {
      Patient: appointment?.patientdata ?? {},
      Appointment: appointment ?? {}
    };
  }

  private getPatientFormPrefill(patientId: string): any {
    const patient = this.storeSvc.searchedPatients.patients.find(f => f.id === patientId);
    return {
      Patient: patient ?? {},
      Appointment: {},
    };
  }
}

export interface ModalCallbacks {
  onCancel?: () => void;
  onConfirm?: () => void;
}

export interface AddFormsModalConfig extends ModalCallbacks {
  appointment?: Appointment;
  appointments?: Appointment[];
  patient: Patient;
  onAdd?: (forms: FormDTO[]) => void,
  onPrint?: (forms: FormDTO[]) => void,
  onSign?: (forms: FormDTO[]) => void;
}
