import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NgswEventsService {

  // TODO Update Angular Service Worker to version 13 or greater to take advantage of the new Version Update observable.
  // https://www.npmjs.com/package/@angular/service-worker
  // https://angular.io/guide/service-worker-communications#version-updates
  constructor(private swUpdates: SwUpdate, private toastSvc: ToastrService) { }

  subscribeToServiceWorkerEvents(): void {
    this.swUpdates.available.subscribe(() => {
      this.swUpdates.activateUpdate().then(() => document.location.reload());
    });

    this.swUpdates.unrecoverable.subscribe(() => {
      this.toastSvc.error('Failed to download update. Please refresh the page.', 'App Update',{ disableTimeOut: true });
    });
  }
}
