import { Component, HostListener, Input, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  DateRangeSelection,
  DisplayColumn,
  GridSetting,
  SettingEnum,
} from '@next/shared/common';
import { NextAdminService } from '@next/shared/next-services';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { Observable, Subject, first } from 'rxjs';
import { StoreService } from '../state/store.service';

@Component({
  selector: 'next-grid-setting-save-modal',
  templateUrl: './grid-setting-save-modal.component.html',
  styleUrls: ['./grid-setting-save-modal.component.scss']
})
export class GridSettingSaveModalComponent implements OnInit {
  @Input() sortby: Observable<[string]>;
  @Input() filterby: Observable<any>;
  @Input() displaycolumn: DisplayColumn[];
  @Input() userid: string;
  @Input() screen: SettingEnum;
  @Input() datefilterrange: DateRangeSelection | null;

  onClose: Subject<boolean> = new Subject<boolean>();
  formArray: UntypedFormArray = new UntypedFormArray([]);
  gridview: UntypedFormGroup = new UntypedFormGroup({
    newgridsetting: new UntypedFormControl('', Validators.required)
  });
  canSave = false;

  @HostListener('document:keydown.enter', ['$event'])
  onEnterPressed() {
    if (!this.gridview.valid) {
      return;
    }

    this.createSetting();
  }

  constructor(
    public stateSvc: StoreService,
    public modalRef: BsModalRef,
    private adminSvc: NextAdminService,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private translateSvc: TranslateService
  ) {  }

  ngOnInit() {
    this.loadData();
  }

  loadData(): void {
    this.adminSvc.getAllGridSettings(this.screen, this.userid).pipe(first()).subscribe(data => {
      this.formArray = new UntypedFormArray([]);
      for (const setting of data) {
        const record: UntypedFormGroup = this.formBuilder.group({
          id: setting.id,
          settingname: setting.settingname,
        });
        this.formArray.push(record);
      }
    });
  }

  async createSetting() {
    const config = this.gridview.value;
     const data: GridSetting = {
      isdefault: false,
      userid: this.userid,
      settingname: config.newgridsetting,
      displaycolumn: {data: this.displaycolumn ?? null},
      sortby: this.sortby,
      filterby: this.filterby,
      screen: this.screen,
      daterangefilter: this.datefilterrange ?? null
     };

     const settings = await this.adminSvc.getGridSettingByName(config.newgridsetting).toPromise()
     if(settings.length > 0){
      const config: Partial<GlobalConfig> = { preventDuplicates: false, disableTimeOut: true };
      this.toastr.clear();
       this.toastr.error(this.translateSvc.instant('SHARED_GRID.SAVE_MENU.UNIQUE_VIEW_NAME'),'' ,config)
       this.canSave = false;
     } else {
      this.adminSvc.saveSetting(data).subscribe(result => {
        this.close({
          id: result[0]?.id,
          name: config.newgridsetting
        });
        this.toastr.success(this.translateSvc.instant('SHARED_GRID.SAVE_MENU.SAVE_MESSAGE'));
      });
     }
  }

  close(setting) {
    this.onClose.next(setting);
    this.onClose.complete();
    this.modalRef.hide();
  }

}
