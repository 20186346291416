import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  SubmissionDataType,
  DisplayStatus, DateTimeDefaults,
} from '@next/shared/common';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import SignaturePad from 'signature_pad';
import { FieldBaseComponent } from '../field-base/field-base.component';
import { Utility } from "@next/shared/ui";

@Component({
  selector: 'next-written-sig',
  templateUrl: './written-sig.component.html',
  styleUrls: ['./written-sig.component.css'],
  animations: [
    trigger('slide', [
      state(DisplayStatus.CLOSED, style({ transform: 'translateY(0)'})),
      state(DisplayStatus.OPENED, style({ transform: 'translateY(-100%)' })),
      transition('* <=> *', animate('800ms ease-in-out'))
    ])
  ]
})
export class WrittenSigComponent extends FieldBaseComponent implements OnInit, AfterViewInit {

  display: string = DisplayStatus.CLOSED;
  canvasMaxWidth: number = 1110;
  sigValue: any;
  sigPad: SignaturePad;
  previewPad: SignaturePad;
  isStandardSig: boolean;

  @ViewChild('sigCanvas') sigCanvas: ElementRef;
  @ViewChild('sigPreviewCanvas') sigPreviewCanvas: ElementRef;

  ngOnInit(): void {
    const initValue: any = this.initialState[this.field.name];
    this.setTempValueControlSwitching(this.field.name);

    if (!this.form.contains(this.field.name)) {
      const options: any = this.field.required ? { validators: [Validators.required] } : { };
      this.valueFormGroup = new UntypedFormGroup({
        SignedDate: new UntypedFormControl(''),
        Strokes: new UntypedFormControl('', options),
        Text: new UntypedFormControl('', options)
      });

      this.form.addControl(this.field.name, new UntypedFormGroup({
        Type: new UntypedFormControl(SubmissionDataType.Signature),
        Value: this.valueFormGroup
      }));
    } else {
      this.valueFormGroup = this.form.get(`${this.field.name}.Value`) as UntypedFormGroup;
    }

    this.isStandardSig = initValue?.Type !== SubmissionDataType.TypedSignature;
    this.sigValue = this.isStandardSig ? this.form.get(`${this.field.name}.Value.Strokes`)?.value || [] : this.form.get(`${this.field.name}.Value.Text`)?.value || '';
  }

  ngAfterViewInit(): void {
    if (this.sigValue && this.sigValue.length !== 0) {
      this.setupPreviewCanvas();
      if (this.isStandardSig) {
        this.sigPadFillStroke();
      }
      else {
        this.sigPadFillText(this.sigPreviewCanvas);
      }
    }
  }

  toggleSig(toggleTypedSig: boolean): void {
    this.form.patchValue({[this.field.name]: { Type: toggleTypedSig ? SubmissionDataType.TypedSignature : SubmissionDataType.Signature }});
    this.isStandardSig = !toggleTypedSig;
  }

  private setupSigCanvas(resize: boolean = false): void {
    const canvas: HTMLCanvasElement = <HTMLCanvasElement>this.sigCanvas.nativeElement;
    const prevWidth = canvas.width;
    const prevHeight = canvas.height;
    const newWidth = (window.document.body.offsetWidth - 48 < this.canvasMaxWidth)
      ? window.document.body.offsetWidth - 48
      : this.canvasMaxWidth;
    const newHeight = newWidth / 5 < 100 ? 100 : newWidth / 5;
    canvas.width = newWidth;
    canvas.height = newHeight;
    canvas.style.width = newWidth + 'px';
    canvas.style.height = newHeight + 'px';
    this.sigPad = new SignaturePad(canvas);

    this.sigPad.addEventListener('beginStroke', () => {
      this.setCSSUserSelect('none');
    }, { once: true });

    this.sigPad.addEventListener('endStroke', () => {
      this.updateSigValue();
      this.setCSSUserSelect('auto');
    }, { once: false });

    if (this.sigValue && resize) {
      const xRatio: number = canvas.width / prevWidth;
      const yRatio: number = canvas.height / prevHeight;
      this.resizeData(this.sigValue, xRatio, yRatio);
      this.sigPad.fromData(this.sigValue);
    }
  }

  private setupPreviewCanvas(): void {
    const previewCanvas = <HTMLCanvasElement>this.sigPreviewCanvas.nativeElement;
    const newWidth = (window.document.body.offsetWidth - 48 < this.canvasMaxWidth)
      ? window.document.body.offsetWidth - 48
      : this.canvasMaxWidth;
    const newHeight = newWidth / 5 < 100 ? 100 : newWidth / 5;

    previewCanvas.width = newWidth;
    previewCanvas.height = newHeight;
    this.previewPad = new SignaturePad(previewCanvas,  { minWidth: 1, maxWidth: 3 });
    this.previewPad.off();
  }

  openDialog(): void {
    this.display = DisplayStatus.OPENED;
    if (this.sigCanvas) this.setupSigCanvas();
  }

  closeDialog(): void {
    this.updateSigValue();
    this.setupPreviewCanvas();
    if (this.isStandardSig) {
      this.sigPadFillStroke();
    }
    else {
      this.sigPadFillText(this.sigPreviewCanvas);
    }
    this.display = DisplayStatus.CLOSED;
    this.updateStatus(this.form.get(this.field.name).status);
    this.valueChanged.emit(this.field);
  }

  clearSignature(): void {
    this.sigPad.clear();
    this.sigValue = '';
  }

  discardSignature(): void {
    this.sigValue = '';
    this.valueFormGroup.patchValue({ SignedDate: '', Strokes: '', Text: '' });
    if (this.field.signatureTimeStampEnabled && this.field.signatureTimeStampFieldName) {
      delete this.initialState[this.field.signatureTimeStampFieldName];
    }
    this.updateStatus(this.valueFormGroup.status);
    this.valueChanged.emit(this.field);
  }

  private sigPadFillText(canvas: ElementRef): void {
    canvas.nativeElement.width = document.getElementById('popupTitle').offsetWidth;
    canvas.nativeElement.height = 120;
    const ctx = canvas.nativeElement.getContext('2d');
    if (canvas.nativeElement.offsetWidth <= 400) {
      ctx.font = "normal normal 36px 'Brush Script MT', cursive";
    } else {
      ctx.font = "normal normal 48px 'Brush Script MT', cursive";
    }
    ctx.fillText(this.sigValue, 15, 75, canvas.nativeElement.width - 20);
  }

  private sigPadFillStroke(): void {
    if (this.sigValue.length) {

      if (this.isStandardSig) {
        const size = this.getSigSize(this.sigValue);

        //create deep copy of signature
        const sigCopy = JSON.parse(JSON.stringify(this.sigValue));
        this.adjustSig(sigCopy, size);
        this.previewPad.fromData(sigCopy);
      } else {
        this.previewPad.fromData(this.sigValue);
      }
    }
  }

  private adjustSig(strokes: any, sigSize: any): void {
    const xPadding = 3;
    const width = this.sigPreviewCanvas.nativeElement.width;
    const height = this.sigPreviewCanvas.nativeElement.height;

    const deltaWidth = sigSize.width - (width - 2 * xPadding);
    const deltaHeight = (sigSize.height - height);
    let multiplier = 1;
    if (deltaWidth > 0 || deltaHeight > 0) {
      if (deltaWidth > deltaHeight) {
        multiplier = (width - 2 * xPadding) / sigSize.width;
      }
      else {
        multiplier = height / sigSize.height;
      }
    }
    const yCenterPadding = (height - (sigSize.height * multiplier)) / 2;
    if (strokes) {
      strokes.forEach(function (stroke) {
      stroke.points.forEach(function (point: any) {
            point.x = ((point.x - sigSize.minX) * multiplier) + xPadding;
            point.y = ((point.y - sigSize.minY) * multiplier) + yCenterPadding;
        });
      });
    }
  }

  private getSigSize(strokes) {
      let minX = Number.MAX_VALUE;
      let minY = Number.MAX_VALUE;
      let maxX = 0;
      let maxY = 0;
      strokes.forEach(function (stroke) {
        stroke.points.forEach(function (point) {
          point.x +=10;
          point.y +=10;
          if (point.x < minX) {
            minX = point.x;
          }

          if (point.x > maxX) {
            maxX = point.x;
          }

          if (point.y < minY) {
            minY = point.y;
          }

          if (point.y > maxY) {
            maxY = point.y;
          }
        });
      });
      return {
        width: maxX - minX,
        height: maxY - minY,
        maxX: maxX,
        maxY: maxY,
        minX: minX,
        minY: minY
      };
  }

  private updateSigValue(): void {
    if (this.isStandardSig) {
      this.sigValue = this.sigPad.toData();
      this.valueFormGroup.patchValue({ ['Strokes']: this.sigValue });
      this.valueFormGroup.patchValue({ ['SignedDate']: new Date() });
      this.form.get(this.field.name).patchValue({['Type']: SubmissionDataType.Signature})
    }
    else {
      this.sigValue = this.form.get(this.field.name + '.Value.Text').value;
      this.valueFormGroup.patchValue({ ['SignedDate']: new Date() });
      this.form.get(this.field.name).patchValue({['Type']: SubmissionDataType.TypedSignature});
    }

    // If signature-timestamp enabled, apply timestamp to target field
    if (this.field.signatureTimeStampEnabled && this.field.signatureTimeStampFieldName) {
      const format: string = this.field.signatureTimeStampFormat || DateTimeDefaults['signature'].value;
      const formattedStamp: string = Utility.toFormattedDateStr(format, this.valueFormGroup.value.SignedDate);
      this.initialState[this.field.signatureTimeStampFieldName] = {
        Type: 'Text',
        Value: {
          Text: formattedStamp,
          Format: 'alphanumeric'
        }
      }
    }
  }

  // scale draw data by ratio
  private resizeData(data: any, ratioX: number, ratioY: number): void {
    if (data) {
      data.forEach(function (stroke) {
        stroke.points.forEach(function (point: any) {
          point.x *= ratioX;
          point.y *= ratioY;
        });
      });
    }
  }

  @HostListener("window:resize") orientation(): void {
    if (this.display === DisplayStatus.OPENED && this.sigCanvas) {
      this.sigPad.off();
      this.setupSigCanvas(true);
    }
  }
}
