import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import bwipjs from 'bwip-js';
import { JSONPath } from 'jsonpath-plus';
import { AsyncSubject, from, Observable } from 'rxjs';
import { Calculation, CalculationResult } from '../models/calculation.model';
import { PatientFormStateDisplay } from '../models/form.model';
import {
  AnnotationDTO,
  BarcodeFormat,
  CheckboxFieldDTO,
  DisplayFormat,
  DropdownFieldDTO,
  FieldDTO,
  GuidedExperienceDTO,
  InputType,
  LayerExperienceDTO,
  TextboxFieldDTO,
  WrittenSigDTO,
} from '../models/guided-experience.model';
import { FieldSubmissionData, FormSubmission, SubmissionData, SubmissionDataType } from '../models/submit.model';
import {
  SignatureType,
  TextboxInputType,
  ViewerFieldType,
} from '../models/viewer.model';
import { IDService } from './id.service';
import { FunctionJSFileLoader } from './jsFileLoader.service';

declare let formCalculations: any;

@Injectable({
  providedIn: 'root'
})
export class GxProcessing {
  listOfFunctions: Calculation[] = [];

  constructor (
    private activatedRoute: ActivatedRoute,
    private idSvc: IDService,
    private http: HttpClient,
    private functionJSFileLoader: FunctionJSFileLoader
  ) {
    this.getCalculations()
  }



  doEvaluate(data: any, expression: string) {
    return (JSONPath({path: expression, json: data}) || [])[0];
  }

  generateEmptyFieldValue(field: FieldDTO, facilityLogo: { id: string, src: string | ArrayBuffer } = null): FieldSubmissionData | null {
    switch (field.type) {
      case ViewerFieldType.TEXTBOX: {
        return this.generateTextFieldSubmission(field as TextboxFieldDTO, facilityLogo)
      }
      case ViewerFieldType.BARCODE:
      case ViewerFieldType.CHECKBOX:
      case ViewerFieldType.DROPDOWN:
      case ViewerFieldType.RADIOGROUP:
      case ViewerFieldType.DYNAMICARRAYFIELD: {
        return {
          Type: SubmissionDataType.Text,
          Value: {
            Text: '',
            Format: TextboxInputType.ALPHANUM
          }
        };
      }
      case ViewerFieldType.WRITTENSIG: {
        const signatureFieldDTO: WrittenSigDTO = field as WrittenSigDTO;
        switch (signatureFieldDTO.signatureType) {
          case SignatureType.Initials:
          case SignatureType.TypedSignature:
            return {
              Type: SubmissionDataType.TypedSignature,
              Value: {
                SignedDate: '',
                Text: ''
              }
            };
          default:
            return {
              Type: SubmissionDataType.Signature,
              Value: {
                SignedDate: '',
                Strokes: []
              }
            };
        }
      }
      case ViewerFieldType.PHOTO: {
        return {
          Type: SubmissionDataType.File,
          Value: {
            FileID: '',
            FormID: '',
          }
        }
      }
      case ViewerFieldType.ANNOTATION: {
        const annotationField: AnnotationDTO = field as AnnotationDTO;
        const value: any = {
          Strokes: [],
          ImageSource: annotationField.imageSource
        };
        return {
          Type: SubmissionDataType.Drawing,
          Value: value,
        };
      }
      default:
        return null;
    }
  }

  generateTextFieldSubmission(field: TextboxFieldDTO, facilityLogo: { id: string, src: string | ArrayBuffer } = null): FieldSubmissionData {
    const textFieldDTO: TextboxFieldDTO = field;
    const textFieldSubmission: FieldSubmissionData = { Type: SubmissionDataType.Text, Value: { } };
    switch (textFieldDTO.displayFormat) {
      case DisplayFormat.Barcode: {
        textFieldSubmission.Value = { Text: '', ImageSource: '' };
        break;
      }
      case DisplayFormat.FacilityLogo: {
        textFieldSubmission.Value = { Text: '', ImageSource: facilityLogo?.src, FileID: facilityLogo?.id };
        break;
      }
      default: {
        textFieldSubmission.Value = { Text: '', Format: textFieldDTO.inputType || 'alphanumeric' };
        switch (textFieldDTO.inputType) {
          case InputType.Date:
          case InputType.Time:
          case InputType.DateTime: {
            if (textFieldDTO.dateFormat) {
              textFieldSubmission.Value.OutputFormat = textFieldDTO.dateFormat;
            }
          }
        }
      }
    }
    return textFieldSubmission;
  }

  private applyFormMetadata(prefill: any, experience: GuidedExperienceDTO, submission = null) {
    const FormMetadata = {
      formid: submission?.id ?? this.activatedRoute.snapshot.queryParams.formId ?? '',
      formtemplatename: submission?.name ?? experience.name,
      version: experience.version,  // Note: This may be more appropriate as a Template property
      status: submission?.status ?? 'not_started',
      updatedon: submission?.updatedon,
      modified: submission?.updatedon,
      archivedon: submission?.lastarchivedon,
      tags: experience.tags,        // Note: This may be more appropriate as a Template property
      ...prefill.Metadata,
    }
    Object.assign(prefill, { Metadata: FormMetadata });
  }

  private applyTemplateProperties(prefill: any, experience: GuidedExperienceDTO) {
    const TemplateProperties = {
      templatename: experience.name,
      templateid: experience.id,
      version: experience.version,
      description: experience.description,
      tags: experience.tags,
      metadataformid: experience.metadataformid,
      metadataoldformid: experience.metadataoldformid,
      metadataprintshopid: experience.metadataprintshopid,
      metadatadocumenttype: experience.metadatadocumenttype,
      metadatafacilities: experience.metadatafacilities,
      metadatadepartments: experience.metadatadepartments,
      metadatarequireslegalreview: experience.metadatarequireslegalreview,
      metadatalanguage: experience.metadatalanguage,
      metadatapolicy: experience.metadatapolicy,
      metadatapublicurl: experience.metadatapublicurl,
      metadataformowner: experience.metadataformowner,
      metadatastaffreviewrequired: experience.metadatastaffreviewrequired,
      metadatadefaultremotecccessto: experience.metadatadefaultremotecccessto,
      metadataapprovedby: experience.metadataapprovedby,
      metadataapproveddate: experience.metadataapproveddate,
      modifiedby: experience.modifiedby,
      updatedon: experience.updatedon,
      publishedby: experience.publishedby,
      publishedon: experience.publishedon,
      ...prefill.Template,
    };
    Object.assign(prefill, { Template: TemplateProperties });
  }

  getParameterFields(experience: GuidedExperienceDTO, prefill: any): SubmissionData {
    if (!experience) return { };

    const datasets: string[] = experience.datasets ?? [];
    const data: SubmissionData = { };

    // Filter the results to only fillable fields (has .name property)
    const fields: FieldDTO[] = (this.getAllFields(experience)|| []).filter(f => f.name);

    // Loot at every field.  If it has prefill and that prefill is parameter based, fill it out
    for (const field of fields) {
      // If the field is prefilling off of a parameter, attempt to process it.
      if (field['FHIRPath']) {
        const fhirPathSource = field['FHIRPath'].split('.')[0];
        if (datasets.includes(fhirPathSource)) {
          const value: string = this.doEvaluate(prefill, field['FHIRPath']);
          if (value) {
            data[field.name] = Object.assign({}, this.generateEmptyFieldValue(field), {
              Type: SubmissionDataType.Text,
              Value: {
                Text: value
              }
            });
          }
          else {
            data[field.name] = this.generateEmptyFieldValue(field);
          }
        }
      }
    }
    return data;
  }

  /** preProcessing()
   * ---
   * ___ Generates an initial submission data object from parameters:                           ___
   * - experience :   _ The guided experience from which to generate form submission fields from  _
   * - savedState :   _ Existing form history data                                                _
   * - prefill    :   _ Prefill data                                                              _
   * - facilityLogo : _ Optional base64 encoded image to embed                                    _
   * @param experience
   * @param submission
   * @param prefill
   * @param facilityLogo
   */
  async preProcessing(experience: GuidedExperienceDTO, submission: Partial<FormSubmission>, prefill: any, facilityLogo: { id: string, src: string | ArrayBuffer } = null): Promise<{experience: GuidedExperienceDTO, data: SubmissionData}> {
    let data: SubmissionData = submission?.data || { };
    this.applyFormMetadata(prefill, experience, submission);
    this.applyTemplateProperties(prefill, experience);

    // Run through all fields setting up the initial data
    // Filter the results to only fillable fields (has .name property)
    const fields: FieldDTO[] = (this.getAllFields(experience)).filter(f => f.name);
    for (const field of fields) {
      // Is there a saved state for this field, use it.
      // Exceptions to this rule are:
      //  - Form-Metadata prefill : since it's dynamic and updates on its own,
      //  - TextBoxes of Barcode and Facility Logo DisplayFormat : since they are base64 encoded strings that are not retained in submission payload
      if (Object.hasOwnProperty.call(data, field.name) && field['displayFormat'] !== 'facilitylogo' && field['displayFormat'] !== 'barcode' && field['FHIRPath'] !== 'Metadata.status') {
        continue;
      }

      // If field has prefill and there is a value for the prefill property, apply it.
      if (Object.hasOwnProperty.call(field, 'FHIRPath') && field['FHIRPath']) {
        const value: string = field['FHIRPath'] !== 'Metadata.status'
          ? this.doEvaluate(prefill, field['FHIRPath'])
          : PatientFormStateDisplay[this.doEvaluate(prefill, field['FHIRPath'])?.toUpperCase()];
        const fieldSubmission: FieldSubmissionData = this.generateEmptyFieldValue(field, facilityLogo);
        if (value) {
          const textBoxFieldDTO: TextboxFieldDTO = field as TextboxFieldDTO;
          switch (textBoxFieldDTO.displayFormat) {
            case DisplayFormat.Barcode: {
              fieldSubmission.Value.ImageSource = this.generateBarcode(field as TextboxFieldDTO, value);
              break;
            }
            case DisplayFormat.FacilityLogo: {
              break;
            }
            default: {
              fieldSubmission.Value.Text = value;
              break;
            }
          }
        }
        data[field.name] = fieldSubmission;
      }

      // Else if no prefill, generate empty value.
      else {
        data[field.name] = this.generateEmptyFieldValue(field, facilityLogo);
      }

      // add timestamp for signature if needed
      if ((field as WrittenSigDTO).signatureTimeStampFieldName) {
        data[(field as WrittenSigDTO).signatureTimeStampFieldName] = {
          Type: SubmissionDataType.Text,
          Value: {
            Text: '',
            Format: TextboxInputType.ALPHANUM
          }
        }
      }
    }

    // Build out a list of calculations that need to be run
    const calculationsToRun = [];
    if (experience.onLoad) {
      calculationsToRun.push({
        name: experience.onLoad,
      });
    }

    // Add all field calculations
    for (const field of fields) {
      if (Object.prototype.hasOwnProperty.call(data, field.name)&& field?.calculations?.onChange) {
        calculationsToRun.push({
          name:  field.calculations.onChange,
          fieldName: field.name
        });
      }
    }

    // process calculations sequentially
    for (const calculation of calculationsToRun) {
      const calculationResults = await this.runCalculation(calculation.name, calculation.fieldName, experience, data, prefill).toPromise();
      data = Object.assign({}, data, calculationResults.fields);
      experience = this.getUpdatedConfig(experience, calculationResults.configs)
    }
    return {experience, data};
  }

  /** postProcessing()
   * ---
   * ___ Generates the final submission data for a form before sending to APIs:                             ___
   * - experience :     _ The guided experience from which to generate form submission fields from            _
   * - formId :         _ Existing form id if one exists                                                      _
   * - selectedPage :   _ Mobile web viewer page of submission (so on load can return directly to this page)  _
   * - submissionData : _ Existing submission data that was retrieved on viewer load, if one exists           _
   * - submissionSvc :  _ The submission service, which can be substituted out based on integration           -
   * @param experience
   * @param formId
   * @param selectedPage
   * @param submissionData
   * @param submissionSvc
   */
  async postProcessing(experience: GuidedExperienceDTO, formId: string, selectedPage: number, submissionData: SubmissionData, submissionSvc: any): Promise<SubmissionData> {
    const data = submissionData;
    const fields: FieldDTO[] = this.getAllFields(experience);

    for (const fieldDTO of fields) {
      if (!Object.prototype.hasOwnProperty.call(fieldDTO, 'name')) continue;

      switch (fieldDTO.type) {
        case ViewerFieldType.TEXTBOX: {
          const textBoxField: TextboxFieldDTO = fieldDTO as TextboxFieldDTO;
          switch (textBoxField.displayFormat) {
            case DisplayFormat.Barcode:
              data[textBoxField.name].Value.Text = '';
              break;
            case DisplayFormat.FacilityLogo:
              data[textBoxField.name].Value.Text = '';
              data[textBoxField.name].Value.ImageSource = '';
              break;
          }
          break;
        }
        /** Process Dropdown */
        case ViewerFieldType.DROPDOWN: {
          // Convert dropdown value to delimited concatenated string when multiselect is enabled
          // Note: Add picklist field type to PDF/GX Viewer so this multiselect dropdown is no longer required
          const dropDownField = fieldDTO as DropdownFieldDTO;
          const fieldValue = data[fieldDTO.name].Value;
          if (dropDownField.dropDownMultiSelect && dropDownField.dropDownDelimiter !== '' && Array.isArray(fieldValue.Text)) {
            data[fieldDTO.name].Value.Text = fieldValue.Text.join(dropDownField.dropDownDelimiter);
          }
          break;
        }
        /** Process Checkbox */
        case ViewerFieldType.CHECKBOX: {
          // Convert checkbox if truthy to the export value configured by the pdf, otherwise empty string
          const checkboxField = fieldDTO as CheckboxFieldDTO;
          if (data[checkboxField.name]) {
            data[checkboxField.name].Value.Text = data[checkboxField.name].Value.Text ? checkboxField.onValue : '';
          }
          break;
        }
        /** Process Written Signature */
        case ViewerFieldType.WRITTENSIG: {
          const signatureDTO = fieldDTO as WrittenSigDTO;
          // Remove extraneous Text or Strokes property based on SubmissionDataType
          if (data[signatureDTO.name]?.Type === SubmissionDataType.Signature) {
            delete data[signatureDTO.name].Value.Text;
          } else if (data[signatureDTO.name]?.Type === SubmissionDataType.TypedSignature) {
            delete data[signatureDTO.name].Value.Strokes;
          }
          break;
        }
        /** Process Drawing Annotation */
        case ViewerFieldType.ANNOTATION: {
          const typedField: AnnotationDTO = fieldDTO as AnnotationDTO;
          // Convert HTMLImageElement into File for Embedded Annotations
          if (typedField.pdfBacked && data[typedField.name].Value.ImageSource.src) {
            fetch(data[typedField.name].Value.ImageSource.src).then(res => res.blob()).then(blob => {
              data[typedField.name].ImageSource = new File([blob], `${typedField.name}.jpeg`, blob);
            });
          }
          // Upload Attachment if this Annotation Drawing is an Attachment type
          else if (!typedField.pdfBacked && data[typedField.name].Value.ImageSource.length) {
            const uri: string = data[typedField.name].Value.ImageSource;
            const header: string = uri.split(',')[0];
            const mime: string = header.substring(header.indexOf(':') + 1, header.length + 1).split(';')[0];
            const f: Response = await fetch(uri);
            const a: ArrayBuffer = await f.arrayBuffer();
            const file: File = new File([a], typedField.name + '.jpeg', { type: mime });
            await submissionSvc.uploadAttachment(formId, typedField.name, experience.vid, {
                file: file,
                meta: { platform: null, page: selectedPage }}
            ).toPromise();
          }
          break;
        }
      }
    }
    return data;
  }

  getCalculations(): Observable<any> {
    const getCalculationsPromise = new Promise((resolve, reject) => {
      const functionFile = this.functionJSFileLoader.loadJsScript();
      functionFile.onload = () => {
        this.listOfFunctions = Object.getOwnPropertyNames(formCalculations).filter(prop => typeof formCalculations[prop] === 'function').map(calc => {
          return {
            id: this.idSvc.generate(),
            name: calc,
            helptext: formCalculations.toolTips[calc]
          }
        });
        resolve(this.listOfFunctions);
      }

      functionFile.onerror = reject;
      functionFile.onabort = reject;
    });
    return from(getCalculationsPromise);
  }

  runCalculation(calculationName: string, fieldName: string, config: GuidedExperienceDTO, state: any, prefill: any): AsyncSubject<CalculationResult> {
    const results = new AsyncSubject<CalculationResult>();
    const calculationLookup = this.listOfFunctions.find(c => c.name === calculationName);
    const sdk = this.getFormSDK(results, config, state, prefill);
    if (calculationLookup) {
      formCalculations[calculationName](sdk, fieldName);
      // if no async calls were made, go ahead and save.
      if (!sdk.async) {
        sdk.save(false);
      }
    }
    else {
      if (calculationName !== '') {
        console.warn(`Calculation '${calculationName}' not found.`);
      }

      sdk.save(false);
    }

    return results;
  }

  containsInputFields(fields: FieldDTO[]) {
    return fields.some(f => f.name);
  }

  getAllFields(experience: GuidedExperienceDTO | LayerExperienceDTO): FieldDTO[] {
    const formPage = experience?.pages?.find(p => p?.pageType === 'FORM');
    if (formPage) {
      return [...formPage.fields] as FieldDTO[];
    }
    else {
      return (experience?.pages?.reduce((acc, val) => acc.concat(this.getAllSubFields(val.fields)), []).filter(e => e) as FieldDTO[]) || [] as FieldDTO[];
    }
  }

  getAllSubFields(fields: any[]): any[] {
    if (!fields) return [];

    let ret = [...fields];
    for (const f of fields) {
      switch (f.type) {
        case ViewerFieldType.RADIOGROUP:
        case ViewerFieldType.DROPDOWN:
          f.switch.forEach(s => ret = ret.concat(this.getAllSubFields(s.fields)));
          break;
        case ViewerFieldType.CHECKBOX:
          ret = ret.concat(this.getAllSubFields(f.trueFields));
          ret = ret.concat(this.getAllSubFields(f.falseFields));
          break;
        case ViewerFieldType.COLUMNLAYOUT:
          for (const column of f.columns) {
            ret = ret.concat(this.getAllSubFields(column.fields));
          }
          break;
        default:
          ret = ret.concat(this.getAllSubFields(f.fields || (f.popupField && f.popupField.fields)));
          break;
      }
    }
    return ret;
  }

  getUpdatedConfig(oldConfig: GuidedExperienceDTO, updatedConfigs): GuidedExperienceDTO {
    // If no configs were updated, return the old one
    if (Object.keys(updatedConfigs).length === 0) {
      return oldConfig;
    }

    const newConfig = Object.assign({}, oldConfig);

    // Go through all the pages
    for (const page of newConfig.pages) {
      this.updateConfig(page.fields, updatedConfigs);
    }

    return newConfig;
  }

  updateConfig(fieldConfigs: any[], updatedConfigs) {
    for (let i = 0; i < fieldConfigs.length; i++) {
      if (updatedConfigs[fieldConfigs[i].name]) {
        fieldConfigs[i] = Object.assign({}, updatedConfigs[fieldConfigs[i].name]);
      }

      // we have to check for nested controls as well
    }
  }

  private getFormSDK(resultStream: AsyncSubject<CalculationResult>, config: GuidedExperienceDTO, currentState: SubmissionData, prefill: any = null) {

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    //deep clone current state so any changes after calc is not updating current state
    const clonedState = JSON.parse(JSON.stringify(currentState));

    // Have to do a deep copy of fields here so calculations can't modify them directly
    // This let's us control when we update the config -- at the end of the calculation
    const fields: FieldDTO[] = [];
    (config.pages.reduce((acc, val) => acc.concat(this.getAllSubFields(val.fields)), []).filter(e => e) as FieldDTO[]).forEach(field => {
      fields.push(Object.assign({}, field));
    });

    return {
      resultStream: resultStream,
      http: this.http,
      fieldConfigs: fields,
      prefill: prefill,
      updatedFields: { },
      updatedConfigs: { },
      async: false,
      saved: false,

      /**
       * Update the required state of the field and field config based on given object
       * @param impactedFields | {fieldName: isRequired}
       */
      updateRequiredStateOfFields: function(impactedFields) {
        for (const [fieldName, isRequired] of Object.entries(impactedFields)) {
          const field = this.getField(fieldName);
          const fieldConfig = this.getFieldConfig(fieldName);

          if (field && fieldConfig) {
            field['Required'] = isRequired;
            fieldConfig['required'] = isRequired;
            this.setFieldConfig(fieldName, fieldConfig);
          }
        }
      },

      /**
       * Directly sets a field's value.  If the value is in an invalid format, it is not set.
       * @param fieldName
       * @param fieldValue
       */
      setFieldValue: function(fieldName, fieldValue) {
        const field = this.getField(fieldName);
        const config = this.getFieldConfig(fieldName);
        if (field) {
          // Process Text Fields - This keeps existing Formatting
          if (field.Type === SubmissionDataType.Text) {
            // TODO: Do not set if the field.Value.Format and field.Value.Text are not compatible.
            field.Value = { Format: field.Format || 'alphanumeric', Text: fieldValue };
            if (config && config.displayFormat === DisplayFormat.Barcode) {
              const barcodeURI = that.generateBarcode(config, fieldValue);
              field.Value.Text = barcodeURI;
              field.Value.ImageSource = barcodeURI;
            }
            this.updatedFields[fieldName] = field;
          }
        }
        else {
          // When a field does not exist, create one dynamically.
          // This allows for two opportunities:
          //   1. Someone can add "hidden" field values w/o having to create an actual field.
          //   2. Not all fields are always known.  Ex: PDF with more fields than experience.
          this.updatedFields[fieldName] = {
            Type: SubmissionDataType.Text,
            Value: {
              Format: 'alphanumeric',
              Text: fieldValue
            }
          }
        } // If field exists or not
      },

      /**
       * Returns the value of the field.  The response type will be determined by the field type and format.
       * For a Text type, if you always need a raw string, call getField(...).Value.Text instead.
       * @param fieldName
       */
      getFieldValue: function(fieldName) {
        const field = this.getField(fieldName);

        if (field) {
          return field.Value;
        }

        console.warn('Unknown field ' + fieldName);
        return '';
      },

      /**
       * Returns the specified field object containing Type and Value.  The structure of Value will vary based on Type.
       * @param fieldName
       */
      getField: function(fieldName) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.updatedFields.hasOwnProperty(fieldName)) {
          return this.updatedFields[fieldName];
        }

        if (clonedState[fieldName]) {
          return clonedState[fieldName];
        }

        console.warn('Unknown field ' + fieldName);
        return '';
      },

      /**
       * Set the specified fieldName's GX config
       * @param fieldName
       * @param fieldConfig
       */
      setFieldConfig: function(fieldName, fieldConfig) {
        // Note, this lets you essentially 'create' fields, because we don't know if the field config already exists
        this.updatedConfigs[fieldName] = fieldConfig;
      },

      /**
       * Returns the specified field config by fieldName.  If the GX config hasn't had the field added, it returns null.
       * @param fieldName
       */
      getFieldConfig: function(fieldName) {
        const field = this.fieldConfigs.find(f => f.name === fieldName);

        if (field) {
          return field;
        }

        console.warn('Unknown field ' + fieldName);
        return null;
      },

      /**
       * Returns a value from the prefill service.  The response type will be a string
       * @param expression expression
       */
      getPrefill: function(expression: string) {
        const exp = expression || '';

        return that.doEvaluate(prefill, exp) || '';
      },

      /**
       * Calls the specified url with GET.  Then executes the provided callback with the result
       * @param url
       * @param callback
       */
      getHttp: function(url, callback) {
        this.async = true;

        this.http.get(url).subscribe(result => {
          callback(result);
        });
      },

      /**
       *  Saves the current updated form state.  This is needed if you have any async (http) calls.
       *  This can only be called once, and if not done by a calculation, the form will not load
       */
      save: function(displayWarnings: boolean = true) {
        if (!this.async && displayWarnings) {
          console.warn('Call to formSdk.save() on a synchronous calculation detected.');
        }

        if (this.saved && displayWarnings) {
          console.warn('Second call to formSdk.save() detected.');
        }

        this.resultStream.next({ fields: this.updatedFields, configs: this.updatedConfigs });
        this.resultStream.complete();

        this.saved = true;
      }
    }
  }

  /** generateBarcode()
   * ---
   * _Return a base64 Encoded Data URI of barcode_
   * @param field
   * @param value */
  generateBarcode(field: TextboxFieldDTO, value: string): string {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const options = { bcid: '', text: value, scale: 10, height: 20, includetext: true, textxalign: 'center' };
    switch (field.barcodeFormat) {
      case BarcodeFormat.Code39:
        options.bcid = 'code39';
        break;
      case BarcodeFormat.Code128:
        options.bcid = 'code128';
        break;
      case BarcodeFormat.QR:
        options.bcid = 'qrcode'
        Object.assign(options, { width : 20 });
        break;
      case BarcodeFormat.PDF417:
        options.bcid = 'pdf417'
        break;
    }
    let uri;
    if (options.bcid) {
      const canvasElement: HTMLCanvasElement = bwipjs.toCanvas(canvas, options);
      uri = canvasElement.toDataURL('image/png', 0.1);
    }
    return uri;
  }

}
