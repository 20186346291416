import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  ApiService,
  FormFastConfig,
  FormFastConfigService,
  Patient,
  TaskDTO,
  TokenService,
  Appointment,
  SearchByType,
  PatientExtended,
  AppointmentExtended,
  EncryptedSession
} from '@next/shared/common';

@Injectable({
  providedIn: 'root'
})

export class NextClinicalService extends ApiService {
  constructor (
    @Inject(FormFastConfigService) config: FormFastConfig,
    private tokenSvc: TokenService,
    private http: HttpClient
  ) {
    super(config, tokenSvc);
  }

  getPatients(searchByType: SearchByType, searchString: any, facility: string, department: string): Observable<PatientExtended<Patient>[]> {
    if (searchByType === SearchByType.DOB) {
      searchString = searchString.replaceAll('/', '-');
    }
    const facilityValue = this.getDefaultValueIfInvalid(facility);
    const departmentValue = this.getDefaultValueIfInvalid(department);
    return this.protectedEndpoint<PatientExtended<Patient>[]>(
      this.http.get<PatientExtended<Patient>[]>(`${this.config.apiUrl}patient/search/${searchByType}/${searchString}?facility=${facilityValue}&department=${departmentValue}`, this.getHeaders()));
  }

  getAppointments(searchByType: SearchByType, searchString: any, facility: string, department: string, lastRefreshedAt = 0): Observable<AppointmentExtended<Appointment>[]> {
    searchString = this.getDefaultValueIfInvalid(searchString);
    const searchByTypeValue = (searchByType === undefined || searchByType === null || searchString === "-1") ? "-1" : searchByType;
    const facilityValue = this.getDefaultValueIfInvalid(facility);
    const departmentValue = this.getDefaultValueIfInvalid(department);
    if (searchByType === SearchByType.DOB) {
      searchString = searchString.replaceAll('/', '-');
    }
    return this.protectedEndpoint<AppointmentExtended<Appointment>[]>(
      this.http.get<AppointmentExtended<Appointment>[]>(`${this.config.apiUrl}appointment/patient/search/${searchByTypeValue}/${encodeURIComponent(searchString)}`
      + `?facility=${facilityValue}`
      + `&department=${departmentValue}`
      + `&lastRefreshedAt=${lastRefreshedAt}`, this.getHeaders()));
  }

  getPatientsByBarcode(barcodeProfileId: string, searchString: any, facility: string, department: string, lastRefreshedAt = 0): Observable<PatientExtended<Patient>[]> {
    const facilityValue = this.getDefaultValueIfInvalid(facility);
    const departmentValue = this.getDefaultValueIfInvalid(department);
    searchString = encodeURIComponent(searchString);

    return this.protectedEndpoint<PatientExtended<Patient>[]>(
      this.http.get<PatientExtended<Patient>[]>(`${this.config.apiUrl}patient/barcode/${barcodeProfileId}/search/${searchString}`
      + `?facility=${facilityValue}`
      + `&department=${departmentValue}`
      + `&lastRefreshedAt=${lastRefreshedAt}`, this.getHeaders()));
  }

  getAppointmentsByBarcode(barcodeProfileId: string, searchString: string, facility: string, department: string, lastRefreshedAt = 0): Observable<AppointmentExtended<Appointment>[]> {
    const facilityValue = this.getDefaultValueIfInvalid(facility);
    const departmentValue = this.getDefaultValueIfInvalid(department);
    searchString = encodeURIComponent(searchString);

    return this.protectedEndpoint<AppointmentExtended<Appointment>[]>(
      this.http.get<AppointmentExtended<Appointment>[]>(`${this.config.apiUrl}appointment/patient/barcode/${barcodeProfileId}/search/${searchString}`
      + `?facility=${facilityValue}`
      + `&department=${departmentValue}`
      + `&lastRefreshedAt=${lastRefreshedAt}`, this.getHeaders()));
  }

  getAppointmentsByDateRange(startDate: Date, endDate: Date, facility: string, department: string, lastRefreshedAt = 0): Observable<AppointmentExtended<Appointment>[]> {
    const facilityValue = this.getDefaultValueIfInvalid(facility);
    const departmentValue = this.getDefaultValueIfInvalid(department);
    const url = `${this.config.apiUrl}appointment/daterange/search`
      + `?startDate=${this.parseDateString(startDate)}`
      + `&endDate=${this.parseDateString(endDate)}`
      + `&facility=${facilityValue}`
      + `&department=${departmentValue}`
      + `&lastRefreshedAt=${lastRefreshedAt}`;

    return this.protectedEndpoint<AppointmentExtended<Appointment>[]>(this.http.get<AppointmentExtended<Appointment>[]>(url, this.getHeaders()));
  }

  getPatient(patientId: string): Observable<PatientExtended<Patient>> {
    return this.protectedEndpoint<PatientExtended<Patient>>(
      this.http.get<PatientExtended<Patient>>(`${this.config.apiUrl}patient/${patientId}`, this.getHeaders()));
  }

  getTasksForUser(id: string): Observable<TaskDTO[]> {
    return this.protectedEndpoint<TaskDTO[]>(
      this.http.get<TaskDTO[]>(`${this.config.apiUrl}task/person/${id}`, this.getHeaders()));
  }

  getIndexFromPatient(patientId: string) {
    return this.protectedEndpoint<PatientExtended<Patient>>(
      this.http.get<PatientExtended<Patient>>(`${this.config.apiUrl}patient/${patientId}/index`, this.getHeaders()));
  }

  getRemoteTransferSession(data) {
    return this.protectedEndpoint<EncryptedSession>(
      this.http.post<EncryptedSession>(`${this.config.apiUrl}appointment/remote/transfer/session`, data, this.getHeaders())
    );
  }

  getDefaultValueIfInvalid(strVal: string) {
    return (strVal === undefined || strVal === null || strVal === '') ? '-1' : strVal;
  }

  parseDateString(date: Date): string  {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  handleError(error) {
    if (error.error instanceof ErrorEvent) {
      return throwError(error.error.message);
    }
  }
}
