import { Injectable, Inject } from '@angular/core';
import { FormFastConfig, FormFastConfigService } from '../config/formfast.config';
import { TokenService } from '../services/token.service';

@Injectable({
    providedIn: 'root'
})
export class JSFileLoader {

    public loadJsScript(src: string, id: string, referrerPolicy: string): HTMLScriptElement {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = src;
        script.id = id;
        script.referrerPolicy = referrerPolicy;
        document.body.appendChild(script);
        return script;
    }
  }

@Injectable({
    providedIn: 'root'
})
export class FunctionJSFileLoader {
    private url: string;

    constructor(@Inject(FormFastConfigService) config: FormFastConfig, private tokenSvc: TokenService, private jsFileLoader: JSFileLoader) {
        this.url = config.apiUrl + 'function/getfunctionfile';
    }

    public loadJsScript(): HTMLScriptElement {
        const token = this.tokenSvc.getAccessToken();
        const finalUrl = `${this.url}?token=${token}`;
        return this.jsFileLoader.loadJsScript(finalUrl, 'dynamic_function', 'origin');
    }
}
