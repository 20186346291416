import { TaskDTO } from './form.model';
import { FormSubmission } from './submit.model';
import { SignatureType } from './viewer.model';

export interface GuidedExperienceDTO {
  id: string;
  vid: string;
  configversion: number;
  formId: string;
  type: string;
  name: string;
  description: string;
  datasets: string[];
  header: HeaderDTO;
  pages: PageDTO[];
  annotations: GuidedExperienceAnnotation[];
  pdftemplateid: string;
  pdftemplatedescription?: string;
  pdftemplate: FileDTO;
  onLoad: string;
  onSave: string;
  onSubmit: string;
  embedAttachments: boolean,
  sendToSuccessPage: boolean,
  signAllStaff: boolean,
  metadata: NextGuidedExperienceMetaData;
  experiencecreatedon?: string;
  publishedon?: string;
  lastpublishedon?: string;
  status?: string;
  tags?: string;
  version?: string;
  createdon?: string;
  updatedon?: string;
  designerversionid?: string;
  metadatadefaultdisplaymode?: string;
  metadatatemplatename?: string;
  metadataformid?: string;
  metadataoldformid?: string;
  metadataarchiveenabled?: boolean;
  metadataprintshopid?: string;
  metadatadocumenttype?: string;
  metadatafacilities?: string[];
  metadatadepartments?: string[];
  metadatarequireslegalreview?: boolean;
  metadatalanguage?: string;
  metadatapolicy?: string;
  metadatapublicurl?: string;
  metadataformowner?: string;
  metadatastaffreviewrequired?: boolean;
  metadatadefaultremotecccessto?: boolean;
  metadatashowreviewandfinalize?: boolean;
  metadataapproveddate?: string;
  metadataapprovedby?: string;
  modifiedby?: string;
  publishedby?: string;
}

export interface LayerExperienceDTO {
  configversion: number;
  id: string;
  type: string;
  name: string;
  description: string;
  datasets: string;
  pages: PageDTO[],
  fields: any[],
  sources: NextGuidedExperienceSource[],
  pdftemplateid: string;
  pdftemplateurl: string;
  pdftemplatedescription: string;
  onLoad: string;
  onSave: string;
  onSubmit: string;
}

export interface FileDTO {
  id: string;
  name: string;
  description: string;
  createdon: string;
  config: any;
  url: string;
  isdeleted: boolean
}

export interface GuidedExperienceInstanceDTO {
  experience: GuidedExperienceDTO,
  submission: FormSubmission,
  attachments: any[],
  prefill: any,
  task?: TaskDTO
}

export interface NextGuidedExperienceMetaData {
  sources: NextGuidedExperienceSource[];
}

export interface NextGuidedExperienceSource {
  id: string;
  type: NextGuidedExperienceExternalType;
}

export enum NextGuidedExperienceExternalType {
  PDF = 'pdf',
  LAYER = 'pdf-layer'
}

export interface BaseElement {
  id: string;
  type: ElementType;
  settings: any;
  errors: any;
  field?: any;
}

export enum ElementType {
  Experience = 'EXPERIENCE',
  Page = 'PAGE',
  Field = 'FIELD',
  Header = 'HEADER',
  Text = 'TEXT',
  Photo = 'PHOTO',
  Link = 'LINK',
  WrittenSignature = 'WRITTENSIGNATURE',
  ImageField = 'IMAGEFIELD',
  FieldDivider = 'FIELDDIVIDER',
  FieldSpacer = 'FIELDSPACER',
  Section = 'SECTION',
  Popup = 'POPUP',
  Annotation = 'ANNOTATION',
  Image = 'IMAGE',
  RichText = 'RICHTEXT',
  PaymentGateway = 'PAYMENTGATEWAY',
  ColumnLayout = 'COLUMNLAYOUT'
}

export enum DisplayFormat {
  FacilityLogo = 'facilitylogo',
  Barcode = 'barcode',
  Textbox = 'text'
}

export enum BarcodeFormat {
  QR = 'qr',
  Code128 = '128',
  Code39 = '39',
  PDF417 = 'pdf417'
}

export interface NextGuidedExperienceSourcePDF extends NextGuidedExperienceSource {
  formFields: any[];              // Form Fields in this Source
  schema: SourcePDFPage[];        // Schema structure of the PDF
  selectedSchema: string;         // The currently selected page of the PDF (Designer only)
}

export interface NextGuidedExperienceSourceLayer extends NextGuidedExperienceSourcePDF {
  pdfTemplate: string;      // ID of PDF File record
  sourceExperience: string; // The Experience ID for this Layer
  sourceLayer: string;      // The ID generated of this Layer instance
  appliedPages: number[];
}

export interface SourcePDFPage extends BaseElement {
  source?: string;
  id: string;
  index: number;          // Index of page in the PDF
  height: number;         // Height of the page
  width: number;          // Width of the page
  formFields: string[];   // Array of Form-Field IDs that are on this page
}

export interface HeaderDTO {
  logo: {
    url: string,
    height: number,
    alignment: string
  }
}

export interface PageDTO {
  name: string;
  title: string;
  titleTag: string;
  condition: string;
  fields: FieldDTO[];
  fieldNames: string[];
  pageType: string;
}

export interface FieldDTO {
  name: string;
  type: string;
  calculations: FieldCalculationDTO;
  dynamicFieldArrayType?: string;
}

export interface FieldCalculationDTO {
  onChange: string;
}

export interface TextboxFieldDTO extends FieldDTO {
  text: string;
  displayFormat: DisplayFormat;
  barcodeFormat: BarcodeFormat;
  required: boolean;
  hidden: boolean;
  placeholder: string;
  regularExpression: string;
  inputType: string;
  maskSocial?: boolean;
  maxChar: number;
  minValue: number;
  maxValue: number;
  dateFormat?: string;
  futureDates?: boolean;
  pastDates?: boolean;
  readonly: boolean;
  FHIRPath: string;
  multiLine: boolean;
}

export interface CheckboxFieldDTO extends FieldDTO {
  text: string;
  required: boolean;
  readonly: boolean;
  hidden: boolean;
  onValue: string;
  trueFields: FieldDTO[];
  falseFields: FieldDTO[];
  FHIRPath: string;
}

export interface DropdownFieldDTO extends FieldDTO {
  text: string;
  options: DropdownOptionDTO[];
  required: boolean;
  readonly: boolean;
  hidden: boolean;
  switch: SwitchDTO[];
  FHIRPath: string;
  dropDownFilter: boolean;
  dropDownMultiSelect: boolean;
  dropDownDelimiter: string;
  dropDownAllowText: boolean;
}

export interface DropdownOptionDTO {
  text: string;
  value: string;
}

export interface RadiogroupFieldDTO extends FieldDTO {
  text: string;
  options: RadiogroupOptionDTO[];
  readonly: boolean;
  required: boolean;
  hidden: boolean;
  switch: SwitchDTO[];
  FHIRPath: string;
}

export interface RadiogroupOptionDTO {
  text: string;
  value: string;
}

export interface SwitchDTO {
  when: string;
  fields: FieldDTO[];
}

export interface WrittenSigDTO extends FieldDTO {
  title: string;
  buttonText: string;
  statementText: string;
  required: boolean;
  disableTypedSignature: boolean;
  signatureFor: string;
  signatureForSource: string;
  signatureForSourceField: string;
  signatureTimeStampEnabled: boolean;
  signatureTimeStampLocation: string;
  signatureTimeStampFieldName: string;
  signatureTimeStampFormat: string;
  signatureType: SignatureType;
  enableTypedSignature: boolean;

  captureRelationship: boolean;
  captureRelationshipApplyTo: string;
  captureRelationshipListSource: string;

  captureSignerName: boolean;
  captureSignerNameApplyTo: string;
}

export interface GuidedExperienceAnnotation {
  name: string;
  width: number;
  height: number;
  annotationData: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FieldDivider extends FieldDTO { }

export interface FieldSpacer extends FieldDTO {
  size: number;
}

export interface Link extends FieldDTO {
  text: string;
  value: string;
}

export interface Header extends FieldDTO {
  text: string;
  headerTag: string;
  underline: boolean;
  italics: boolean;
  bold: boolean;
  alignment: string;
}

export interface TextLabel extends FieldDTO {
  text: string;
  size: number;
  underline: boolean;
  italics: boolean;
  bold: boolean;
  alignment: string;
}

export interface Popup extends FieldDTO {
  text: string;
  class: any;
  popupField: PopupField;
}

export interface PopupField extends FieldDTO {
  type: string;
  name: string;
  title: string;
  validateOneField: boolean;
  fields: FieldDTO[];
}

export interface Section extends FieldDTO {
  title: string;
  fields: any;
}

export interface PhotoDTO extends FieldDTO {
  title: string;
  preButtonText: string;
  postButtonText: string;
  preHeaderText: string;
  postHeaderText: string;
  required: boolean;
}

export interface AnnotationDTO extends FieldDTO {
  attachmentName: string;
  imageUrl: string;
  required: boolean;
  text: string;
  imageSource?: string;
  pdfBacked: boolean;
}

export interface RichTextDTO extends FieldDTO {
  text: string;
  format: string;
}

export interface ColumnLayout extends FieldDTO {
  columns: ColumnLayoutColumn[];
}

export interface ColumnLayoutColumn {
  width: number;
  fields: FieldDTO[];
}

export enum InputType {
  AlphaNumeric = 'alphanumeric',
  Alpha = 'alpha',
  Numeric = 'numeric',
  Date = 'date',
  Time = 'time',
  DateTime = 'date-time',
  Currency = 'currency',
  Social = 'ssn',
  Phone = 'phone',
  Email = 'email',
  PostalCode = 'postalcode',
  CreditCardDigits = 'ccdigits',
  RegularExpression = 'regularexpression',
  ShortDate2 = 'shortdate2',
  ShortDate4 = 'shortdate4',
  Signature = 'signature'
}

export const DateTimeDefaults = {
  "date": {
    value: '%m/%d/%Y'
  },
  "time": {
    value: '%I:%M %P'
  },
  "date-time": {
    value: '%m/%d/%Y %I:%M %P'
  },
  "signature": {
    value: '%m/%d/%Y %I:%M %P'
  }
}

export const DateTimePreFormats = [
  {
    formatFor: 'date',
    display: 'MM/DD/YYYY',
    value: '%m/%d/%Y'
  },
  {
    formatFor: 'date',
    display: 'DD/MM/YYYY',
    value: '%d/%m/%Y'
  },
  {
    formatFor: 'date-time',
    display: 'MM/DD/YYYY HH:MM AM',
    value: '%m/%d/%Y %I:%M %P'
  },
  {
    formatFor: 'date-time',
    display: 'DD/MM/YYYY HH:MM AM',
    value: '%d/%m/%Y %I:%M %P'
  }
]

export enum WindowMessageEventName {
  ExperienceSubmit = 'Submit',
  ExperienceSave = 'Save',
  ExperienceCanceled = 'Cancel',
  ExperienceEdited = 'ExperienceEdited',
  ExperienceTemplateEdited = 'ExperienceTemplateEdited',
  ExperienceLayerEdited = 'ExperienceLayerEdited',
  FieldChanged = 'FieldChanged',
  RefreshPreviewWindow = 'refresh',
  PageRendered = 'pagerendered',
  PagesLoaded = 'pagesloaded',
  Signature = 'signature',
  SubmitError = 'SubmitError',
  ViewerInitialized = 'viewerInitialized',
  EmbeddedPrefill = 'embeddedPrefill'
}

export enum PrefillSource {
  Patient = 'Patient',
  Appointment = 'Appointment',
  Metadata = 'Metadata',
  Template = 'Template',
  Facility = 'Facility',
  Department = 'Department'
}

export enum PrefillSourceDescription {
  Patient = 'Patient',
  Appointment = 'Appointment',
  Metadata = 'Form Properties',
  Template = 'Form Template Properties',
  Facility = 'Facility',
  Department = 'Department'
}

export enum RelationshipListSource {
  UsePDFSettings = "USEPDFSETTINGS"
}
