import { Component, Input, OnChanges } from '@angular/core';
import { FormDTO, FormEmbedded, FormStatus } from '@next/shared/common';
import { StateViewerService } from '../state/state-viewer.service';
import { StoreService } from '../state/store.service';
import { TranslateService } from '@ngx-translate/core';
import {
  ArchiveService,
  NextFormService,
  UserResolverService,
} from '@next/shared/next-services';
import { Router } from '@angular/router';
import { FormsUtilityService } from '../services/forms-utility.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { FormsTableInlineComponent } from '../forms-table-inline/forms-table-inline.component';

@Component({
  selector: 'next-flagged-forms-table-inline',
  templateUrl: './flagged-forms-table-inline.component.html',
  styleUrls: ['../forms-table/forms-table.component.scss'],
})
export class FlaggedFormsTableInlineComponent extends FormsTableInlineComponent implements OnChanges {
  @Input() displayEllipsisMenu: boolean = true;

  flagToMoveLaterLabel: string;
  removeFlagToMoveLabel: string;
  columnHeadersFlaggedFormsInline: any[] =[];

  constructor(
    protected archiveSvc: ArchiveService,
    protected formSvc: NextFormService,
    protected formsUtilSvc: FormsUtilityService,
    protected router: Router,
    public stateViewerSvc: StateViewerService,
    protected storeSvc: StoreService,
    protected toastSvc: ToastrService,
    protected translateSvc: TranslateService,
    protected userSvc: UserResolverService,
    protected spinnerSvc: NgxSpinnerService
  ) {
    super(
      archiveSvc,
      formSvc,
      formsUtilSvc,
      router,
      stateViewerSvc,
      storeSvc,
      toastSvc,
      translateSvc,
      userSvc,
      spinnerSvc
    );
    this.columnHeadersFlaggedFormsInline = [
      { field: "name", header: this.translateSvc.instant('PATIENT_FORMS.COLUMN_HEADERS.NAME') },
      { field: "mrn", header: this.translateSvc.instant('PATIENT_FORMS.COLUMN_HEADERS.MRN') },
      { field: "status", header: this.translateSvc.instant('PATIENT_FORMS.COLUMN_HEADERS.STATUS') },
      { field: "lastarchivedon", header: this.translateSvc.instant('PATIENT_FORMS.COLUMN_HEADERS.ARCHIVED') },
      { field: "updatedon", header: this.translateSvc.instant('PATIENT_FORMS.COLUMN_HEADERS.MODIFIED') },
      { field: "tags", header: this.translateSvc.instant('PATIENT_FORMS.COLUMN_HEADERS.TAG') }
    ];
  }

  ngOnChanges(): void {
    this.selectedForms = [];
  }

  private removeFlagToMoveMenuItem(): void {
    this.menuItems = this.menuItems.filter(
      (item) =>
        item.label !== this.flagToMoveLaterLabel &&
        item.label !== this.removeFlagToMoveLabel &&
        item.label !== this.archiveLabel
    );
  }

  private populateMenuWithFlagToMoveLabel(): void {
    if (this.stateViewerSvc?.selectedForms?.forms?.length) {
      this.removeFlagToMoveMenuItem();

      const hasOnlyFlaggedForms = this.stateViewerSvc.selectedForms.forms.map(form => form.tomovelater)
        .reduce((accumulator, currentValue) => accumulator && currentValue, true);

      const flagLabel = hasOnlyFlaggedForms ? this.removeFlagToMoveLabel : this.flagToMoveLaterLabel;

      this.menuItems.push({
        label: flagLabel,
        command: () => this.commandToggleToMoveLaterValues(),
      });
      this.menuItems.push({
        label: this.archiveLabel,
        command: () => this.commandArchive(),
      });
    }
  }

  protected updateStateServiceSelectedForms(): void {
    super.updateStateServiceSelectedForms();
    this.populateMenuWithFlagToMoveLabel();
  }

  protected initRowMenu(menuConfig: any): void {
    super.initRowMenu(menuConfig);
    this.flagToMoveLaterLabel = menuConfig.FLAG_TO_MOVE_LATER;
    this.removeFlagToMoveLabel = menuConfig.REMOVE_FLAG_TO_MOVE;
  }

  rowMenuClicked(form: FormEmbedded): void {
    if (!this.selectedForms.some((f) => f.id === form.id)) {
      this.selectedForms = [form];
    }

    this.updateStateServiceSelectedForms();
    this.selectedForm = form;
    this.menuItems.find((x) => x.label === this.archiveLabel).disabled = form.status.toLowerCase() === FormStatus.NotStarted;
  }

  private commandToggleToMoveLaterValues(): void {
    if (this.stateViewerSvc?.selectedForms?.forms?.length) {
      const hasOnlyFlaggedForms = this.stateViewerSvc.selectedForms.forms.map(form => form.tomovelater)
        .reduce((accumulator, currentValue) => accumulator && currentValue, true);

      const personID: string = this.stateViewerSvc.selectedForms.forms[0].patientdataid;
      const formIDs: string[] = this.stateViewerSvc.selectedForms.forms.map((form) => form.id);
      this.formSvc.bulkUpdateToMoveLater(personID, formIDs, !hasOnlyFlaggedForms).pipe(
        first()
      ).subscribe({
        next: (forms: FormDTO[]) => {
          const updatedForms = this.stateViewerSvc.selectedForms;
          forms.forEach((form) => updatedForms.update(form));
          this.stateViewerSvc.selectedForms = updatedForms;
        },
        error: () => {
          this.toastSvc.error(
            this.translateSvc.instant('TOASTR_MESSAGE.TO_MOVE_LATER_TOGGLE_VALUE_ERROR'), '', { disableTimeOut: true });
          },
      });
    }
  }
}
