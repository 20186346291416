import { Injectable, NgZone, OnDestroy } from '@angular/core';
import {
  distinctUntilChanged,
  fromEvent,
  Observable,
  Subject,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FormDTO } from '../models/form.model';
import { GuidedExperienceInstanceDTO } from '../models/guided-experience.model';
import SignaturePad from 'signature_pad';

export enum MM_EVENTNAME {
  WINDOW_INIT = 'mm_init',
  SELECTED_FORMS = 'mm_selected_forms',
  FORM_REQUEST = 'mm_form_request',
  CURRENT_FORM = 'mm_current_form',
  FORM_CHANGE = 'mm_form_change',
  FORM_CHANGE_REQUEST = 'mm_form_change_request',
  FORM_NEXT_REQUEST = 'mm_form_next_request',
  FORM_COMPLETE = 'mm_form_complete',
  DATA_CHANGE_REQUEST = 'mm_data_change_request',
  SIG_DEVICE_CHANGE = 'mm_sig_device_change',
  SIG_SUBMIT_REQUEST = 'mm_sig_submit_request',
  SIG_PROMPT_ACTION = 'mm_sig_prompt_action',
}

export interface MMPayloadWindowInit {
  eventName: MM_EVENTNAME.WINDOW_INIT,
  data: null
}

export interface MMPayloadSelectedForms {
  eventName: MM_EVENTNAME.SELECTED_FORMS,
  data: {
    token: string,
    forms: FormDTO[],
    currentID: string
  }
}

export interface MMPayloadCurrentForm {
  eventName: MM_EVENTNAME.CURRENT_FORM,
  data: {
    id: string,
    viewMode: string,
    instance: GuidedExperienceInstanceDTO,
    conditionalImage: { src: string | ArrayBuffer, id: string },
    usesTopaz: boolean,
  }
}

export interface MMPayloadCurrentFormChange {
  eventName: MM_EVENTNAME.FORM_CHANGE,
  data: {
    id: string,
  }
}

export interface MMPayloadFormChangeRequest {
  eventName: MM_EVENTNAME.FORM_CHANGE_REQUEST,
  data: {
    id: string
  }
}

export interface MMPayloadNextFormRequest {
  eventName: MM_EVENTNAME.FORM_NEXT_REQUEST,
  data: null
}

export interface MMPayloadFormComplete {
  eventName: MM_EVENTNAME.FORM_COMPLETE,
  data: null
}

export interface MMPayloadFormDataChangeRequest {
  eventName: MM_EVENTNAME.DATA_CHANGE_REQUEST,
  data: any
}

export interface MMPayloadSignatureDeviceToggled {
  eventName: MM_EVENTNAME.SIG_DEVICE_CHANGE,
  data: boolean
}

export interface MMPayloadSignatureSubmitRequest {
  eventName: MM_EVENTNAME.SIG_SUBMIT_REQUEST,
  data: any
}

export interface MMPayloadSignaturePromptAction {
  eventName: MM_EVENTNAME.SIG_PROMPT_ACTION,
  type: SignaturePromptAction
  data: any
}

export enum SignaturePromptAction {
  OPEN = 'OPEN',
  CANCEL = 'CANCEL',
  SIGN = 'SIGN',
  CLEAR = 'CLEAR',
  APPLY = 'APPLY',
  RELATION_CHANGE = "RELATION",
  SIGNER_NAME_CHANGE = "SIGNER_NAME",
  PATIENT_CANNOT_SIGN = 'PATIENT_CANNOT_SIGN',
  TYPED_SIGNATURE = 'TYPED_SIGNATURE',
}

export enum WindowType {
  CLINICAL = 'CLINICAL',
  REMOTE = 'REMOTE'
}

export interface WindowPosition {
  left: number,
  top: number,
  width: number,
  height: number
}

export enum WindowActionType {
  Scroll = 'Scroll'
}

export interface IScrollPayloadData {
  scrollIndex: number
}

export interface IActionPayload {
  type: WindowActionType,
  data: IScrollPayloadData
}

export interface IFrameObject {
  iframe: HTMLIFrameElement,
  document: Document,
  window: Window
}

@Injectable({
  providedIn: 'root'
})
export class MultiMonitorService implements OnDestroy {
  private observerCleanup$: Subject<void> = new Subject();
  public isSecondWindowOpen: boolean = false;
  public isSecondWindowOpen$: Subject<boolean> = new Subject<boolean>();

  public readonly KEY_SECOND_WINDOW_REF: string = "ilh_secondWindowRef";
  public readonly KEY_SECOND_WINDOW_POSITION_SPECS: string = "ilh_secondWindowPositionSpecs";
  public readonly KEY_ON_SECOND_WINDOW_CLOSE_FN: string = "ilh_onSecondWindowClose";
  public readonly KEY_PDF_CONTAINER_ID: string = "viewerContainer";
  public readonly KEY_SECOND_WINDOW_ACTION: string = "ilh_secondWindowAction";
  public readonly KEY_IGNORE_PDF_SCROLL_EVENT: string = "ilh_ignorePdfScrollEvent";

  public isTrackingWindowActions: boolean = false;

  constructor(private zone: NgZone) {
  }

  ngOnDestroy(): void {
    this.observerCleanup$.next();
    this.observerCleanup$.complete();
  }

  async launchMultiMonitor(screen: Screen, mmLaunchedPayload: MMPayloadSelectedForms): Promise<void> {
    const windowURL: string = window.location.origin + `/remote/monitor`;

    const windowOptions: string = await this.getWindowOptions(screen);
    const childWindow: Window = window.open(windowURL, '_nova-multi-monitor', windowOptions);
    this.setSecondWindowRef(childWindow);
    this.isSecondWindowOpen$.next(true);
    this.isSecondWindowOpen = true;

    // Listen for mm init message
    this.sync.receiveMonitorInit$().pipe(
      tap(() => {
        window[this.KEY_ON_SECOND_WINDOW_CLOSE_FN] = () => this.zone.run(() => {
          this.isSecondWindowOpen$.next(false);
          this.isSecondWindowOpen = false;
        });
        this.onClinicalWindowCloseListener();
        this.trackWindowActions();
      }),
      tap(() => {
        this.sync.sendSelectedForms(mmLaunchedPayload.data.token, mmLaunchedPayload.data.forms,mmLaunchedPayload.data.currentID);
      }),
      // NOTE: need observer cleanup
    ).subscribe();
  }

  private async getWindowOptions(screen: Screen): Promise<string> {
    // Stub the Window Mgmt API if browser support is not available
    if (!('getScreenDetails' in window) || !('isExtended' in screen)) {
      window['getScreenDetails'] = async () => [window.screen];
      Object.defineProperty(window.screen, 'isExtended', { value: false });
    }
    // Request access from Window Mgmt API; Stub the API on error
    await (window as any).getScreenDetails().catch(() => {
      window['getScreenDetails'] = async () => [window.screen];
      Object.defineProperty(window.screen, 'isExtended', { value: false });
    });

    screen = screen ?? window.screen;

    let windowPosition: WindowPosition;
    if (this.getSecondWindowPositionSpecs()) {
      windowPosition = this.getSecondWindowPositionSpecs();
    } else {
      windowPosition = this.getWindowPositionSpecs(screen);
    }
    let windowOptions: string = `toolbar=0,location=0,menubar=0,resizable=1,status=1`;
    windowOptions += `,left=${windowPosition.left},top=${windowPosition.top},width=${windowPosition.width},height=${windowPosition.height},fullscreen`;
    return windowOptions;
  }

  trackWindowActions(): void {
    const pdfViewerContainer: HTMLElement = this.getPdfViewerContainer();
    if (pdfViewerContainer) {
      fromEvent(pdfViewerContainer, 'scroll').pipe(
        takeUntil(this.observerCleanup$),
        distinctUntilChanged()
      ).subscribe((event: Event) => {
        const windowScrollSpecs: IScrollPayloadData = this.getWindowScrollSpecs(event.target);
        this.triggerWindowAction(WindowActionType.Scroll, windowScrollSpecs);
      });

      if (!this.isTrackingWindowActions) {
        this.isTrackingWindowActions = true;
        fromEvent(window, 'storage').pipe(
          takeUntil(this.observerCleanup$),
          distinctUntilChanged()
        ).subscribe((event: StorageEvent) => {
          if (event.key === this.KEY_SECOND_WINDOW_ACTION) {
            this.updateWindowContent();
          }
        });
      }
    }
  }

  listenToSignaturePoints(signaturePad: SignaturePad, canvas: HTMLCanvasElement, windowType: WindowType): void {
    fromEvent(signaturePad, 'afterUpdateStroke').pipe(
      filter(() => this.isSecondWindowOpen || windowType === WindowType.REMOTE),
      distinctUntilChanged(),
      tap(() => {
        if (windowType === WindowType.CLINICAL) {
          this.sync.sendRequestSignaturePromptAction.toRemote({
            type: SignaturePromptAction.SIGN, data: {
              sigValue: signaturePad.toData(),
              canvasWidth: canvas.width,
              canvasHeight: canvas.height
            }
          });
        } else if (windowType === WindowType.REMOTE) {
          this.sync.sendRequestSignaturePromptAction.toClinical({
            type: SignaturePromptAction.SIGN, data: {
              sigValue: signaturePad.toData(),
              canvasWidth: canvas.width,
              canvasHeight: canvas.height
            }
          });
        }
      }),
      takeUntil(this.observerCleanup$),
    ).subscribe();
  }

  triggerWindowAction(type: WindowActionType, data: IScrollPayloadData): void {
    const actionPayload: IActionPayload = {type: type, data: data};
    this.setStringifiedStorageItem(this.KEY_SECOND_WINDOW_ACTION, actionPayload);
  }

  updateWindowContent(): void {
    const actionPayload: IActionPayload = this.getParsedStorageItem(this.KEY_SECOND_WINDOW_ACTION);
    let payloadData: IScrollPayloadData;

    if (actionPayload.type === WindowActionType.Scroll) {
      setTimeout(() => {
        const ignoreScrollEvent: boolean = this.getIgnoreScrollStatus();
        this.setIgnoreScrollStatus(false);

        if (!ignoreScrollEvent) {
          payloadData = actionPayload.data;
          const pdfViewerContainer: HTMLElement = this.getPdfViewerContainer();
          const scrollValueFromTop: number = payloadData.scrollIndex * this.getElementMaxHeight(pdfViewerContainer);
          this.scrollElementTo(pdfViewerContainer, scrollValueFromTop);
        }
      }, 5);
    }
  }

  scrollElementTo(element: any, scrollPosition: any): void {
    this.setIgnoreScrollStatus(true);
    this.setStringifiedStorageItem(this.KEY_IGNORE_PDF_SCROLL_EVENT, true);
    element.scrollTo(0, scrollPosition);
  }

  getPdfViewerContainer(): HTMLElement {
    return this.getIFrameObj().document.getElementById(this.KEY_PDF_CONTAINER_ID);
  }

  getIFrameObj(): IFrameObject {
    const iframeElement: HTMLIFrameElement = document.getElementsByTagName('iframe')[0];
    return {
      iframe: iframeElement,
      document: iframeElement.contentDocument || iframeElement.contentWindow.document,
      window: iframeElement.contentWindow
    }
  }

  isFullScreen(): boolean {
    return !!(document['fullscreenElement'] || document['webkitFullscreenElement'] || document['msFullscreenElement']);
  }

  onClinicalWindowCloseListener(): void {
    window.addEventListener('beforeunload', () => {
      this.closeSecondWindowFromParent();
    });
  }

  onRemoteWindowCloseListener(): void {
    window.addEventListener('beforeunload', () => {
      this.setSecondWindowPositionSpecs(this.getWindowPositionSpecs());
      window.opener?.[this.KEY_ON_SECOND_WINDOW_CLOSE_FN]();
      this.setSecondWindowRef(null);
    });
  }

  closeSecondWindowFromParent(): void {
    const secondWindow: Window = this.getSecondWindowRef();
    if (secondWindow && !secondWindow.closed) {
      this.setSecondWindowPositionSpecs(this.getWindowPositionSpecs(secondWindow.screen));
      secondWindow.close();
      this.setSecondWindowRef(null);
    }
  }

  setSecondWindowRef(secondWindow: Window): void {
    window[this.KEY_SECOND_WINDOW_REF] = secondWindow;
  }

  getSecondWindowRef(): Window {
    return window[this.KEY_SECOND_WINDOW_REF];
  }

  getSecondWindowPositionSpecs(): WindowPosition {
    return this.getParsedStorageItem(this.KEY_SECOND_WINDOW_POSITION_SPECS);
  }

  setSecondWindowPositionSpecs(positionSpecs: WindowPosition): void {
    this.setStringifiedStorageItem(this.KEY_SECOND_WINDOW_POSITION_SPECS, positionSpecs);
  }

  getWindowPositionSpecs(windowScreen: Screen = null): WindowPosition {
    windowScreen = windowScreen ?? window.screen;
    return {
      left: windowScreen['availLeft'],
      top: windowScreen['availTop'],
      width: windowScreen['availWidth'],
      height: windowScreen['availHeight']
    }
  }

  getWindowScrollSpecs(pdfViewerContainer: any): IScrollPayloadData {
    return { scrollIndex: pdfViewerContainer.scrollTop / this.getElementMaxHeight(pdfViewerContainer) };
  }

  getElementMaxHeight(pdfContainer: HTMLElement): number {
    return Math.max(pdfContainer?.scrollHeight, pdfContainer?.offsetHeight, pdfContainer?.clientHeight, 0);
  }

  setIgnoreScrollStatus(shouldIgnore: boolean = false): void {
    this.setStringifiedStorageItem(this.KEY_IGNORE_PDF_SCROLL_EVENT, shouldIgnore);
  }

  getIgnoreScrollStatus(): boolean {
    return this.getParsedStorageItem(this.KEY_IGNORE_PDF_SCROLL_EVENT) ?? false;
  }

  setStringifiedStorageItem(key: string, value: any): void {
    localStorage.setItem(key, value ? JSON.stringify(value) : value);
  }

  getParsedStorageItem(storageKey: string): any {
    const stringValue: string = localStorage.getItem(storageKey);
    return stringValue ? JSON.parse(stringValue) : stringValue;
  }

  /***************************
   ** SELECTED FORM SYNCING **
   ***************************/
  // eslint-disable-next-line @typescript-eslint/member-ordering
  sync = {
    sendMonitorInit: (): void => {
      const initMessage: MMPayloadWindowInit = { eventName: MM_EVENTNAME.WINDOW_INIT, data: null };
      if (window.opener) window.opener.postMessage(initMessage, window.opener.location.origin || "*");
      else if (window.parent) window.parent.postMessage(initMessage, document.referrer || "*");
    },
    receiveMonitorInit$: (): Observable<MMPayloadWindowInit> => {
      return fromEvent(window, 'message').pipe(
        map((message: MessageEvent) => message.data),
        filter((messageData) => messageData.eventName === MM_EVENTNAME.WINDOW_INIT),
        take(1));
    },
    sendSelectedForms: (token: string, forms: FormDTO[], currentID: string): void => {
      const window: Window = this.getSecondWindowRef();
      if (window) {
        const sendSelectedFormsMessage: MMPayloadSelectedForms = { eventName: MM_EVENTNAME.SELECTED_FORMS, data: { token: token, currentID: currentID, forms: forms } };
        window.postMessage(sendSelectedFormsMessage, window.origin);
      }
    },
    sendCurrentFormRequest: (): void => {
      const sendCurrentFormRequestMessage = { eventName: MM_EVENTNAME.FORM_REQUEST, data: null };
      if (window.opener) window.opener.postMessage(sendCurrentFormRequestMessage, window.opener.location.origin || "*");
      else if (window.parent) window.parent.postMessage(sendCurrentFormRequestMessage, document.referrer || "*");
    },
    receiveCurrentFormRequest$: (): Observable<void> => {
      return fromEvent(window, 'message').pipe(
        map((message: MessageEvent) => message.data),
        filter((messageData) => messageData.eventName === MM_EVENTNAME.FORM_REQUEST));
    },
    sendRequestedCurrentForm: (id: string, viewMode: string, instance: GuidedExperienceInstanceDTO, formData: any, usesTopaz: boolean, conditionalImage = null): void => {
      const window: Window = this.getSecondWindowRef();
      if (window) {
        if (!instance.submission) {
          // If no existing submission state then
          // stub in the submission data we already know about
          instance.submission = { data: formData, id: id, experienceversionid: instance.experience.vid } as any;
        }
        const mmPayload: MMPayloadCurrentForm = { eventName: MM_EVENTNAME.CURRENT_FORM, data: { id: id, viewMode: viewMode, instance: instance, usesTopaz: usesTopaz, conditionalImage: conditionalImage } };
        window.postMessage(mmPayload, window.origin);
      }
    },
    receiveRequestedCurrentForm$: (): Observable<MMPayloadCurrentForm>  => {
      return fromEvent(window, 'message').pipe(
        map((message: MessageEvent) => message.data),
        filter((messageData) => messageData.eventName === MM_EVENTNAME.CURRENT_FORM),
        take(1));
    },
    sendCurrentFormChange: (form: FormDTO): void => {
      const window: Window = this.getSecondWindowRef();
      if (window) {
        const mmPayload: MMPayloadCurrentFormChange = { eventName: MM_EVENTNAME.FORM_CHANGE, data: { id: form.id } };
        window.postMessage(mmPayload, window.origin);
      }
    },
    receiveCurrentFormChange$: (): Observable<MMPayloadCurrentFormChange> => {
      return fromEvent(window, 'message').pipe(
        map((message: MessageEvent) => message.data),
        filter((messageData) => messageData.eventName === MM_EVENTNAME.FORM_CHANGE));
    },
    sendRequestFormChange: (form: FormDTO): void => {
      const requestFormChangeMessage: MMPayloadFormChangeRequest = { eventName: MM_EVENTNAME.FORM_CHANGE_REQUEST, data: { id: form.id } };
      if (window.opener) window.opener.postMessage(requestFormChangeMessage, window.opener.location.origin || "*");
      else if (window.parent) window.parent.postMessage(requestFormChangeMessage, document.referrer || "*");
    },
    receiveRequestFormChange$: (): Observable<MMPayloadFormChangeRequest> => {
      return fromEvent(window, 'message').pipe(
        distinctUntilChanged(),
        map((message: MessageEvent) => message.data),
        filter((messageData) => messageData.eventName === MM_EVENTNAME.FORM_CHANGE_REQUEST));
    },
    requestFormChangeCB: (id: string, forms: FormDTO[] = []): FormDTO | null => {
      return forms.find(f => f.id === id) ?? null;
    },
    sendRequestNextForm: (): void => {
      const requestFormChangeMessage: MMPayloadNextFormRequest = { eventName: MM_EVENTNAME.FORM_NEXT_REQUEST, data: null };
      if (window.opener) window.opener.postMessage(requestFormChangeMessage, window.opener.location.origin || "*");
      else if (window.parent) window.parent.postMessage(requestFormChangeMessage, document.referrer || "*");
    },
    receiveRequestNextForm$: (): Observable<MMPayloadNextFormRequest> => {
      return fromEvent(window, 'message').pipe(
        map((message: MessageEvent) => message.data),
        filter((messageData) => messageData.eventName === MM_EVENTNAME.FORM_NEXT_REQUEST));
    },
    sendSelectedFormsComplete: (): void => {
      const window: Window = this.getSecondWindowRef();
      if (window) {
        const mmPayload: MMPayloadFormComplete = { eventName: MM_EVENTNAME.FORM_COMPLETE, data: null };
        window.postMessage(mmPayload, window.origin);
      }
    },
    receiveSelectedFormsComplete$: (): Observable<MMPayloadFormComplete> => {
      return fromEvent(window, 'message').pipe(
        map((message: MessageEvent) => message.data),
        filter((messageData) => messageData.eventName === MM_EVENTNAME.FORM_COMPLETE));
    },
    sendSignatureDeviceToggled: (usingSignatureDevice: boolean): void => {
      const window: Window = this.getSecondWindowRef();
      if (window) {
        const mmPayload: MMPayloadSignatureDeviceToggled = { eventName: MM_EVENTNAME.SIG_DEVICE_CHANGE, data: usingSignatureDevice };
        window.postMessage(mmPayload, window.origin);
      }
    },
    receiveSignatureDeviceChange$: (): Observable<MMPayloadSignatureDeviceToggled> => {
      return fromEvent(window, 'message').pipe(
        map((message: MessageEvent) => message.data),
        filter((messageData) => messageData.eventName === MM_EVENTNAME.SIG_DEVICE_CHANGE));
    },
    sendRequestFieldChange: {
      toRemote: (data: any): void => {
        const window: Window = this.getSecondWindowRef();
        if (window) {
          const requestDataChangeMessage: MMPayloadFormDataChangeRequest = { eventName: MM_EVENTNAME.DATA_CHANGE_REQUEST, data: data };
          window.postMessage(requestDataChangeMessage, window.origin);
        }
      },
      toClinical: (data: any): void => {
        const requestDataChangeMessage: MMPayloadFormDataChangeRequest = { eventName: MM_EVENTNAME.DATA_CHANGE_REQUEST, data: data };
        if (window.opener) window.opener.postMessage(requestDataChangeMessage, window.opener.location.origin || "*");
        else if (window.parent) window.parent.postMessage(requestDataChangeMessage, document.referrer || "*");
      },
    },
    receiveRequestFieldChange$: {
      fromRemote: (): Observable<any> => {
        return fromEvent(window, 'message').pipe(
          distinctUntilChanged(),
          map((message: MessageEvent) => message.data),
          filter((messageData) => messageData.eventName === MM_EVENTNAME.DATA_CHANGE_REQUEST));
      },
      fromClinical: (): Observable<any> => {
        return fromEvent(window, 'message').pipe(
          distinctUntilChanged(),
          map((message: MessageEvent) => message.data),
          filter((messageData) => messageData.eventName === MM_EVENTNAME.DATA_CHANGE_REQUEST));
      },
    },
    sendRequestSignaturePromptAction: {
      toRemote: (payload: { type: SignaturePromptAction, data: any }): void => {
        const window: Window = this.getSecondWindowRef();
        if (window) {
          const requestMessage: MMPayloadSignaturePromptAction = { eventName: MM_EVENTNAME.SIG_PROMPT_ACTION, type: payload.type, data: payload.data };
          window.postMessage(requestMessage, window.origin);
        }
      },
      toClinical: (payload: { type: SignaturePromptAction, data: any }): void => {
        const requestMessage: MMPayloadSignaturePromptAction = { eventName: MM_EVENTNAME.SIG_PROMPT_ACTION, type: payload.type, data: payload.data };
        if (window.opener) window.opener.postMessage(requestMessage, window.opener.location.origin || "*");
        else if (window.parent) window.parent.postMessage(requestMessage, document.referrer || "*");
      },
    },
    receiveRequestSignaturePromptAction$: (): Observable<MMPayloadSignaturePromptAction> => {
      // TODO: MultiMonitor Check
      return fromEvent(window, 'message').pipe(
        map((message: MessageEvent) => message.data),
        filter((msgPayload): boolean => msgPayload.eventName === MM_EVENTNAME.SIG_PROMPT_ACTION));
    }
  }
}
