import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FieldDTO } from '@next/shared/common';
import { FieldBaseComponent } from '../field-base/field-base.component';

@Component({
  selector: 'next-section',
  templateUrl: './section.component.html',
})

export class SectionComponent extends FieldBaseComponent implements OnInit, AfterViewInit {

  @Input() needsValidation: boolean;
  private dynamicChildren: FieldDTO[] = [];

  isValid: boolean = false;
  hasInput: boolean = false;

  ngOnInit(): void {
    this.dynamicChildren = this.getAllSubFields(this.field.fields) || [];
    this.hasInput = this.dynamicChildren.filter(c => c.name).length > 0;
  }

  ngAfterViewInit(): void {
    this.updateIsValid();
  }

  childStatusReceived(): void {
    this.updateIsValid();
  }

  private updateIsValid(): void {
    setTimeout(() => {
      this.isValid = this.hasAtLeastOneValidField(this.dynamicChildren);
    });
  }

}
