import { Component, Input } from '@angular/core';
import { FieldDivider } from '@next/shared/common';

@Component({
  selector: 'next-field-divider',
  templateUrl: './field-divider.component.html',
})
export class FieldDividerComponent {
  @Input() field: FieldDivider;
}
