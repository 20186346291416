<div class="form-group rounded w-100">
  <button id='{{ field.name + "_open-signature" }}' [hidden]="sigValue && sigValue.length > 0" type="button" class="btn btn-primary btn-block gci-button" (click)="openDialog()" [ngClass]="{'rounded border-danger' : field.required }">
    <div class="position-relative">
      {{ field.buttonText }}
      <span *ngIf="field.required" class="position-absolute right-0">
        <fa-icon [icon]="['fas', 'exclamation-triangle']" class="exclamation-triangle"></fa-icon>
      </span> 
    </div>
  </button>
  <!-- need this html for fonts to work -->
  <span class="font"></span> 
  <div [@slide]="display" [hidden]="!sigValue || sigValue.length === 0" class="w-100">
    <canvas #sigPreviewCanvas id="sig-preview-canvas" class="signature-box rounded"></canvas>
    <button id='{{ field.name + "_clear_written_sig" }}' type="button" class="btn btn-danger" (click)="discardSignature()">
      <fa-icon [icon]="['fas', 'ban']"></fa-icon>
      <span class="font-weight-bolder">&nbsp;&nbsp;{{ 'WRITTENSIG.DISCARD_SIG' | translate }}</span>
    </button>
  </div>
</div>

<div class="position-fixed p-2 w-100 h-100 popup" [@slide]="display" [formGroup]="form">

  <div class="container-fluid popup-scroll-area" [formGroup]='form'>
    <div class="popup-header page-title" id="popupTitle">
      <h2>{{ field.title }}</h2>
    </div>
    <hr>
    <div *ngIf='!field.signatureFor || field.signatureFor === "other"'>
      <ng-container *ngTemplateOutlet='defaultSignature'></ng-container>
    </div>
    <div *ngIf='field.signatureFor === "patient" && field.signatureType !== "initialsSignature"'>
      <ng-container *ngTemplateOutlet='defaultSignature'></ng-container>
    </div>
    <div *ngIf='field.signatureFor === "patient" && field.signatureType === "initialsSignature"'>
      <ng-container *ngTemplateOutlet='typedSignature'></ng-container>
    </div>
    <div *ngIf='field.signatureFor === "staff" && field.signatureType === "drawnSignature"'>
      <ng-container *ngTemplateOutlet='wetSignatureStaff'></ng-container>
    </div>
    <div *ngIf='field.signatureFor === "staff" && (field.signatureType === "typedSignature" || field.signatureType === "initialsSignature")'>
      <ng-container *ngTemplateOutlet='typedSignature'></ng-container>
    </div>
  </div>

</div>

<ng-template #defaultSignature>
  <div [formGroup]='form'>
    <div [hidden]="!field.enableTypedSignature" [formGroupName]="field.name">
      <label class="typed-sig">{{ 'WRITTENSIG.TYPEDSIG_DESCRIPTION' | translate }}</label>
      <next-form-switch class="float-right" [value]="!isStandardSig" (valueChanged)="toggleSig($event)"></next-form-switch>
      <div [hidden]="isStandardSig" formGroupName="Value">
        <label for="{{ field.name }}">{{ 'WRITTENSIG.TYPEDSIG_LABEL' | translate }}</label>
        <input type="text" class="form-control" id="{{ field.name }}" formControlName="Text" autocomplete="off">
        <button id='{{ field.name + "_apply_default_typed_sig" }}' type='button' class="btn btn-primary my-2 float-right w-40" (click)="closeDialog()">
          <fa-icon [icon]="['fas', 'check']" class='mr-2'></fa-icon>
          <span class="font-weight-bolder">{{ 'WRITTENSIG.SAVE_SIG' | translate }}</span>
        </button>
      </div>
    </div>
    <div [hidden]="!isStandardSig" class="w-100">
      <canvas #sigCanvas id="sig-canvas-default" class="signature-box rounded disabled-touch-action"></canvas>
      <button id='default-clear-signature' class="btn btn-danger my-2 w-40" (click)="clearSignature()">
        <fa-icon [icon]="['fas', 'times']" class='mr-2'></fa-icon>
        <span class="font-weight-bolder">{{ 'WRITTENSIG.CLEAR_SIG' | translate }}</span>
      </button>
      <button id='{{ field.name + "_apply_default_written_sig" }}' type='button' class="btn btn-primary my-2 w-40 float-right" (click)="closeDialog()">
        <fa-icon [icon]="['fas', 'check']" class='mr-2'></fa-icon>
        <span class="font-weight-bolder">{{ 'WRITTENSIG.SAVE_SIG' | translate }}</span>
      </button>
    </div>
    <br><label>{{ field.statementText }}</label>
  </div>
</ng-template>

<ng-template #wetSignaturePatient>
  <div class="w-100">
    <canvas #sigCanvas id="sig-canvas-patient" class="signature-box rounded disabled-touch-action"></canvas>
    <button id='wet-sig-patient-clear-signature' class="btn btn-danger my-2 w-40" (click)="clearSignature()">
      <fa-icon [icon]="['fas', 'times']"></fa-icon>
      <span class="font-weight-bolder">&nbsp;&nbsp;{{ 'WRITTENSIG.CLEAR_SIG' | translate }}</span>
    </button>
    <button id='{{ field.name + "_apply_drawn_patient_sig" }}' type='button' class="btn btn-primary my-2 w-40 float-right" (click)="closeDialog()">
      <fa-icon [icon]="['fas', 'check']" class='mr-2'></fa-icon>
      <span class="font-weight-bolder">{{ 'WRITTENSIG.SAVE_SIG' | translate }}</span>
    </button>
  </div>
  <br><label>{{ field.statementText }}</label>
</ng-template>

<ng-template #wetSignatureStaff>
  <div class="w-100">
    <canvas #sigCanvas id="sig-canvas-staff" class="signature-box rounded disabled-touch-action"></canvas>
    <button id='wetsig-staff-clear-signature' class="btn btn-danger my-2 w-40" (click)="clearSignature()">
      <fa-icon [icon]="['fas', 'times']"></fa-icon>
      <span class="font-weight-bolder">&nbsp;&nbsp;{{ 'WRITTENSIG.CLEAR_SIG' | translate }}</span>
    </button>
    <button id='{{ field.name + "_apply_drawn_staff_sig" }}' type='button' class="btn btn-primary my-2 w-40 float-right" (click)="closeDialog()">
      <fa-icon [icon]="['fas', 'check']" class='mr-2'></fa-icon>
      <span class="font-weight-bolder">{{ 'WRITTENSIG.SAVE_SIG' | translate }}</span>
    </button>
  </div>
  <br><label>{{ field.statementText }}</label>
</ng-template>

<ng-template #typedSignature>
  <div [formGroup]='form'>
    <div [formGroupName]="field.name">
      <div formGroupName="Value">
        <label for="{{ field.name + '_Value' }}">{{ 'WRITTENSIG.TYPEDSIG_LABEL' | translate }}</label>
        <div class='input-group'>
          <div class='input-group-prepend'>
            <span class='input-group-text'>
              <fa-icon [icon]='["fas", "font"]'></fa-icon>
              <fa-icon *ngIf='field.signatureType === "initialsSignature"' [icon]='["fas", "font"]'></fa-icon>
            </span>
          </div>
          <input type="text" class="form-control" id="{{ field.name + '_Value' }}" formControlName="Text">
        </div>
        <button id='{{ field.name + "_apply_typed_staff_sig" }}' type='button' class="btn btn-primary my-2 w-40 float-right" (click)="closeDialog()">
          <fa-icon [icon]="['fas', 'check']" class='mr-2'></fa-icon>
          <span class="font-weight-bolder">{{ 'WRITTENSIG.SAVE_SIG' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
  <br><label>{{ field.statementText }}</label>
</ng-template>
