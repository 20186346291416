<div class='h-100 w-100 p-4 text-center' [formGroup]="inputForm">
    <h4 class='font-weight-bold'>{{ title | async }}</h4>
    <div class='py-2'>
      {{ message | async }}
    </div>
    <div class='py-2'>
      <input nextAutoFocusElement id="new-value" type="text" class="form-control" [maxLength]='50' [formControlName]='"newvalue"'/>
    </div>
    <div>
      <div class='d-flex justify-content-between'>
        <button role='button' type='reset' id='cancel' class='btn-cancel px-1' (click)='decline()'>{{ cancelButton | async }}</button>
        <button role='button' type='submit' id='confirm' class='btn-ih px-1' (click)='confirm()'>{{ confirmButton | async }}</button>
      </div>
    </div>
  </div>

