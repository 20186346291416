import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  Observable,
  of,
  Subject,
  timeout,
} from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import {
  FormSubmission,
  GuidedExperienceInstanceDTO,
  ViewerIntegrationMode,
  WindowMessageEventName,
} from '@next/shared/common';
import { ViewerPdfComponent } from '../viewer-pdf/viewer-pdf.component';
import { ViewerWebComponent } from '../viewer-web/viewer-web.component';

enum ViewMode {
  WEB = 'web',
  PDF = 'pdf'
}

@Component({
  selector: 'next-main',
  templateUrl: './main.component.html',
})
export class MainComponent implements OnInit, OnDestroy {
  @ViewChild('NxtPdfViewer', { static: false }) nxtPdfViewer: ViewerPdfComponent;
  @ViewChild('NxtWebViewer', { static: false }) nxtWebViewer: ViewerWebComponent;

  obsCleanup: Subject<void> = new Subject<void>();
  viewMode$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  instance$: BehaviorSubject<GuidedExperienceInstanceDTO> = new BehaviorSubject<GuidedExperienceInstanceDTO>(null);
  integration: ViewerIntegrationMode = ViewerIntegrationMode.RAW;

  constructor(private route: ActivatedRoute) {
    this.onPreviewWindowRefresh().subscribe();
    this.onResolvers().subscribe();
    this.emitViewerInit();
  }

  ngOnInit(): void {
    if (!this.viewMode$.getValue()) {
      this.viewMode$ = new BehaviorSubject<string>((this.route.snapshot.routeConfig.path === ViewMode.PDF) || this.route.snapshot.routeConfig.path.endsWith(ViewMode.PDF) ? ViewMode.PDF : ViewMode.WEB);
    }
  }

  ngOnDestroy(): void {
    this.obsCleanup.next();
    this.obsCleanup.complete();
  }

  private onPreviewWindowRefresh(): Observable<void> {
    return fromEvent(window, "message").pipe(
      map((messageEvent: MessageEvent) => messageEvent.data),
      filter(message => message === WindowMessageEventName.RefreshPreviewWindow),
      filter(() => !!location),
      tap(() => location.reload()),
      takeUntil(this.obsCleanup));
  }

  private onResolvers(): Observable<GuidedExperienceInstanceDTO> {
    if (this.hasEmbeddedPrefill()) {
      const embeddedPrefill$ = fromEvent(window, "message").pipe(
        map((message: MessageEvent) => message.data),
        filter((message: any) => message.eventName === WindowMessageEventName.EmbeddedPrefill),
        timeout({ each: 2000, with: () => {
          console.warn(`${window.origin} expected an 'embeddedPrefill' message but one was not received.`);
          return of({ data: null });
            // If a post message isn't provided within seconds of request, return without prefill.
            // This prevents the viewer from just endlessly waiting for a prefill message
        }}),
        take(1));

      return combineLatest([this.route.data, embeddedPrefill$]).pipe(
        map(([instance, prefillMessage]) => Object.assign(instance, { prefill: prefillMessage.data }) as GuidedExperienceInstanceDTO),
        tap(instance => this.instance$.next(instance)),
        takeUntil(this.obsCleanup));
    }
    else {
      return this.route.data.pipe(
        map((data) => data as GuidedExperienceInstanceDTO),
        tap(instance => this.instance$.next(instance)),
        takeUntil(this.obsCleanup));
    }
  }

  toggleView(mode: string): void {
    if (mode === this.viewMode$.getValue()) return;
    switch (mode) {
      case ViewMode.PDF: {
        const instanceVal = this.instance$.getValue();
        instanceVal.submission = { ...instanceVal.submission ?? { data: null }} as FormSubmission;
        instanceVal.submission.data = { ...this.nxtWebViewer.formData, ...this.nxtWebViewer.form.value };
        this.instance$.next(instanceVal);
        this.viewMode$.next(ViewMode.PDF);
        break;
      }
      case ViewMode.WEB: {
        const instanceVal = this.instance$.getValue();
        instanceVal.submission = { ...instanceVal.submission ?? { data: null }} as FormSubmission;
        instanceVal.submission.data = this.nxtPdfViewer.formData;
        this.instance$.next(instanceVal);
        this.viewMode$.next(ViewMode.WEB);
        break;
      }
      default:
        break;
    }
  }

  private emitViewerInit(): void {
    const initMessage = { eventName: WindowMessageEventName.ViewerInitialized, key: null, value: null, error: null };
    if (window.parent) window.parent.postMessage(initMessage, document.referrer || "*");
    if (window.opener) window.opener.postMessage(initMessage, document.referrer || "*");
  }

  private hasEmbeddedPrefill(): boolean {
    const query: unknown = this.route.snapshot.queryParamMap.get('embeddedPrefill');
    return !!query;
  }

  // Listen for refresh events, from the designer
  @HostListener('window:message', ['$event']) onPostMessage(event) {
    if (event.data === WindowMessageEventName.RefreshPreviewWindow) {
      location.reload();
    }
  }

}
