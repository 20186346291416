import { Appointment } from "./patient.model";
import { BehaviorSubject } from "rxjs";

export class SearchedAppointments {
  readonly searchedAppointments$ = new BehaviorSubject<Appointment[]>([]);
  private _searchedAppointments: SearchedAppointment[] = [];
  readonly selectedAppointment$: BehaviorSubject<Appointment> = new BehaviorSubject<Appointment>(null);
  private _dateFilter: Date[] = [];
  private _isAutoSearch = null;
  public error: string = '';
  private firstTimeSelectedTracker = {}
  private _isNewSearch = false;

  get dateFilter(): Date[] { return this._dateFilter ?? []; }
  set dateFilter(value: Date[]) { this._dateFilter = value ?? []; }

  get isAutoSearch(): boolean | null { return this._isAutoSearch; }
  set isAutoSearch(value: boolean) { this._isAutoSearch = value; }

  set appointments(appointments: Appointment[]) {
    this.setAppointmentsWithLimit(appointments);
  }

  get appointments(): Appointment[] {
    return this._searchedAppointments.map(a => a.appointment);
  }

  setAppointmentsWithLimit(appointments: Appointment[] = []) {
    const selectedAppointmentsIdSet = new Set();
    this.selectedAppointments.forEach(appointment => selectedAppointmentsIdSet.add(appointment.id));
    this._searchedAppointments = [];

    if (appointments) {
      appointments.forEach((appointment) => {
        this._searchedAppointments.push({
          appointment: appointment,
          isSelected: selectedAppointmentsIdSet.has(appointment.id) ?? false
        });
      });
    }
    this._searchedAppointments.sort((a, b) => a.appointment.patientdata.lastname.localeCompare(b.appointment.patientdata.lastname));
    this.searchedAppointments$.next(this.appointments);
  }

  toggleAppointmentForSelect(appointment: Appointment) {
    const appointmentToSelect = this._searchedAppointments.filter(a => a.appointment === appointment);
    if (appointmentToSelect && appointmentToSelect.length > 0) {
      appointmentToSelect[0].isSelected = !appointmentToSelect[0].isSelected;
    }
  }

  selectAppointments(selectedAppointments: Appointment[]) {
    const selectedAppointmentsIdSet = new Set();
    selectedAppointments.forEach(appointment => selectedAppointmentsIdSet.add(appointment.id));
    this._searchedAppointments.forEach((searchedAppointment: SearchedAppointment) => {
      searchedAppointment.isSelected = selectedAppointmentsIdSet.has(searchedAppointment.appointment.id);
    });
  }

  isSelected(appointment: Appointment) {
    const foundRecord = this._searchedAppointments.filter(a => a.appointment.id === appointment.id);
    return foundRecord.length > 0 ? foundRecord[0].isSelected : false;
  }

  get selectedAppointments() {
    return this._searchedAppointments.filter(a => a.isSelected).map(a => a.appointment);
  }

  makeSelected(appointment: Appointment) {
    const selected: SearchedAppointment[] = this._searchedAppointments.filter(sa => sa.appointment.accountnumber === appointment.accountnumber);
    selected[0].isSelected = true;
    this.selectedAppointment$.next(selected[0].appointment);
  }

  unselectAllAppointments() {
    this._searchedAppointments.map(sa => sa.isSelected = false);
  }

  getSelectedRecordCount() {
    return this._searchedAppointments.filter(a => a.isSelected).length;
  }

  isFirstTimeSelectingRecord(recordId: string) {
    if (!this.firstTimeSelectedTracker[recordId]) {
      this.firstTimeSelectedTracker[recordId] = true;
      return true;
    }
    return false;
  }

  set isNewSearch(isNewSearch: boolean) {
    this._isNewSearch = isNewSearch;
  }

  get isNewSearch(): boolean {
    return this._isNewSearch;
  }
}

export interface SearchedAppointment {
    appointment: Appointment,
    isSelected: boolean
}
