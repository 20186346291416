import { Facility } from "../..";
import { FormDTO } from "./form.model";

type Extension = {
  [key: string]: any;
};


export interface Patient {
  id: string;
  lastname: string;
  firstname: string;
  middlename: string;
  mrn: string;
  mpi: string;
  birthdate: string;
  gender: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalcode: string;
  phone: string;
  email: string;
  maritalstatus: string;
  primarylanguage: string;
  languages: string;
  ssn: string;
  assignedclinicianfirstname: string;
  assignedclinicianlastname: string;
  assignedclinicianid: string;
  updatedon: string;
  createdon: string;
}



export type PatientExtended<T extends Patient> = T & Extension;

export interface Appointment {
  id: string;
  patientdataid: string;
  accountnumber: string;
  location: string;
  servicingfacility: string;
  hospitalservice: string;
  patienttype: string;
  admittingphysicianfirstname: string;
  admittingphysicianlastname: string;
  admittingphysicianid: string;
  physician: string;
  eventdatetime: string;
  admitdatetime: string;
  dischargedatetime: string;
  calendarstartdatetime: string;
  calendarenddatetime: string;
  formstatus: string;
  name: string;
  type: string;
  notes: string;
  arrivalnotes: string;
  updatedon: string;
  createdon: string;
  orders: string[];
  patientdata: PatientExtended<Patient>;
  department: string;
  hasflaggedforms: boolean | string;
}

export type AppointmentExtended<T extends Appointment> = T & Extension;

export class AppointmentForms {
  appointment: Appointment;
  forms: FormDTO[];

  public constructor(init?:Partial<AppointmentForms>) {
    Object.assign(this, init);
  }
}

export interface VerificationResponse {
  authorized: boolean;
  lockedout: boolean;
  wrongcode: boolean;
  verificationcodeexpired: boolean;
  newemailsent: boolean;
  numberpatients: number;
  accesstoken: string;
}

export interface VerificationResponse {
  authorized: boolean;
  lockedout: boolean;
  wrongcode: boolean;
  verificationcodeexpired: boolean;
  newemailsent: boolean;
  numberpatients: number;
  accesstoken: string;
}

export enum AppointmentStateTranslationPath {
  none = "APPOINTMENTS.FORM_STATUS.NONE",
  not_started = "APPOINTMENTS.FORM_STATUS.NOT_STARTED",
  in_progress = "APPOINTMENTS.FORM_STATUS.IN_PROGRESS",
  action_required = "APPOINTMENTS.FORM_STATUS.ACTION_REQUIRED",
  complete = "APPOINTMENTS.FORM_STATUS.COMPLETE"
}

export enum AppointmentFormStatus {
  none = 'none',
  not_started = 'not_started',
  in_progress = 'in_progress',
  action_required = 'action_required',
  complete = 'complete'
}

export enum AppointmentStatus {
  checked_in = "Checked-in",
  not_checked_in = "Check-in Needed",
  complete = "Complete",
  Unknown = "Unknown"
}

export enum AppointmentType {
  Surgery = "Surgery",
  Unknown = "Unknown"
}

export enum ProcedureDataset {
  surgeonId = 'surgeonId',
  surgeon = 'surgeon',
  specialty = 'specialty',
  procedure = 'procedure',
  surgicalCenter = 'surgicalcenter',
  laterality = 'laterality',
  diagnosis = 'diagnosis',
  icd = 'icd',
  cpt = 'cpt',
  procedureDesc = 'proceduredescription'
}

export enum ProcedureColumns {
  PROCEDURE = 'Procedure',
  SURGEONID = 'Provider ID',
  LATERALITY = 'Laterality',
  DIAGNOSIS = 'Diagnosis',
  ICD = 'ICD',
  CPT = 'CPT',
  PROCEDURE_DESC = 'Procedure Description'
}

export enum FacilityColumns {
  SURGEON = 'Doctor ID',
  SURGEONID = 'Provider ID',
  SPECIALTY = 'Specialty',
  FACILITIES = 'Facilities',
  FACILITY_DELIMITER = ';'
}

export function mapDatasetTypes(type: ProcedureColumns | FacilityColumns) {
  switch(type) {
    case FacilityColumns.FACILITIES:
      return ProcedureDataset.surgicalCenter;
    case FacilityColumns.SPECIALTY:
      return ProcedureDataset.specialty;
    case FacilityColumns.SURGEON:
      return ProcedureDataset.surgeon;
    case ProcedureColumns.PROCEDURE:
      return ProcedureDataset.procedure;
    case ProcedureColumns.PROCEDURE_DESC:
      return ProcedureDataset.procedureDesc;
    case ProcedureColumns.LATERALITY:
      return ProcedureDataset.laterality;
    case ProcedureColumns.DIAGNOSIS:
      return ProcedureDataset.diagnosis;
    case ProcedureColumns.ICD:
      return ProcedureDataset.icd;
    case ProcedureColumns.CPT:
      return ProcedureDataset.cpt;
    default:
      break;
  }
}

export type DateRangeSelection = {
  dates: Date[],
  offSetDays: number,
  numberOfDays: number 
}

export interface IRange {
  value: Date[];
  label: string;
}

export type AppointmentWithFacility = {
  appointment: Appointment;
  facility: Facility;
}

export type RemotePatientResponse = PatientExtended<Patient> & {
  appointment_facilities?: AppointmentWithFacility[]}