import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorRibbonService  {

  private message: string;

  showErrorMessage(message: string) {
    this.message = message;
    setTimeout(() => {
      this.clearMessage();
    }, 5000);
  }

  getMessage(){
    return this.message;
  }

  clearMessage(){
    this.message = null;
  }

}
