import { Component, Input, OnInit } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ErrorRibbonService } from '../../../state/error-ribbon.service';
import { ViewerIntegrationMode } from '@next/shared/common';

@Component({
  selector: 'next-viewer-warning-ribbon',
  templateUrl: './viewer-warning-ribbon.component.html',
  styleUrls: ['./viewer-warning-ribbon.component.css'],
  animations: [
    trigger('errorRibbon', [
      state('in', style({ transform: 'translateY(0px)' })),
      state('in-embedded', style({ transform: 'translateY(0px)' })),
      state('out', style({ transform: 'translateY(-68px)' })),
      state('out-embedded', style({ transform: 'translateY(-40px)' })),
      transition('* => *', animate('900ms ease-in-out'))
    ])
  ]
})

export class ViewerWarningRibbonComponent implements OnInit {
  @Input() integration: ViewerIntegrationMode = ViewerIntegrationMode.RAW;
  display: string;
  ViewerIntegrationMode: typeof ViewerIntegrationMode = ViewerIntegrationMode;      // Allow this enum to be used in the template

  constructor(public errorSvc: ErrorRibbonService) { }

  ngOnInit() {
    this.display = this.integration !== ViewerIntegrationMode.RAW ? 'out-embedded' : 'out';
  }

  showWarning() {
    this.display = this.integration !== ViewerIntegrationMode.RAW ? 'in-embedded' : 'in';
  }

  closeWarning() {
    this.display = this.integration !== ViewerIntegrationMode.RAW ? 'out-embedded' : 'out';
  }
}
