import { BehaviorSubject, Subject } from "rxjs";
import { Patient } from "./patient.model";

export class SearchedPatients {
  readonly searchedPatients$ = new BehaviorSubject<Patient[]>([]);
  private _searchedPatients: SearchedPatient[] = [];
  private _filter: string = '';
  public searchedPatientsChanged = new BehaviorSubject(null); // used by the full screen for patient view
  public error: string;

  readonly patientFilterCleared$: Subject<void> = new Subject();
  readonly selectedPatient$: BehaviorSubject<Patient> = new BehaviorSubject<Patient>(null);
  private firstTimeSelectedTracker = {};

  get filter(): string {
    return this._filter;
  }

  set filter(value: string) {
    this._filter = value;
    if (!value) {
      this.patientFilterCleared$.next();
    }
  }

  get patients(): Patient[] {
    return this._searchedPatients.map(p => p.patient);
  }

  set patients(patients: Patient[]) {
    const previouslySelectedPatients = this.selectedPatients;
    this._searchedPatients = [];

    if (patients) {
      patients.forEach((patient) => {
        this._searchedPatients.push({
          patient: patient,
          isSelected: previouslySelectedPatients?.some(p => p.id === patient.id) ?? false
        });
      });
    }
    this._searchedPatients.sort((a, b) => a.patient.lastname.localeCompare(b.patient.lastname));
    this.searchedPatients$.next(this.patients);
    this.searchedPatientsChanged.next(null);
  }

  get selectedPatients(): Patient[] {
    return this._searchedPatients.filter(p => p.isSelected).map(p => p.patient);
  }

  selectPatient(patient: Patient) {
    const patientToSelect = this._searchedPatients.filter(p => p.patient === patient);
    if (patientToSelect && patientToSelect.length > 0) {
      patientToSelect[0].isSelected = true;
      this.selectedPatient$.next(patientToSelect[0].patient);
    }
  }

  togglePatientForMultiSelect(patient: Patient) {
    const patientToSelect = this._searchedPatients.filter(p => p.patient === patient);
    if (patientToSelect && patientToSelect.length > 0) {
      patientToSelect[0].isSelected = !patientToSelect[0].isSelected;
    }

    if (this.selectedPatients.length === 1) {
      this.selectedPatient$.next(this.selectedPatients[0]);
    }
  }

  unSelectAllPatients(): void {
    this._searchedPatients.map(p => p.isSelected = false);
  }

  isPatientSelected(patient: Patient): boolean {
    const foundRecord = this._searchedPatients.filter(p => p.patient === patient);
    return foundRecord.length > 0 ? foundRecord[0].isSelected : false;
  }

  getSelectedRecordCount(): number {
    return this._searchedPatients.filter(p => p.isSelected).length;
  }

  isFirstTimeSelectingRecord(recordId: string): boolean {
    if (!this.firstTimeSelectedTracker[recordId]) {
      this.firstTimeSelectedTracker[recordId] = true;
      return true;
    }
    return false;
  }
}

export interface SearchedPatient {
    patient: Patient,
    isSelected: boolean
}
