import { Injectable } from '@angular/core';
import { StateViewerService } from '../state/state-viewer.service';
import { StoreService } from '../state/store.service';
import {
  Appointment,
  AppointmentForms,
  FormDTO,
  Patient,
} from '@next/shared/common';
import { NextFormService, PatientService } from '@next/shared/next-services';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { concatAll, filter, map, mergeMap, tap, toArray } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FlaggedFormsService {
  public flaggedFormsForPatient$: BehaviorSubject<FormDTO[]> = new BehaviorSubject<FormDTO[]>({ } as FormDTO[]);
  public appointmentsWithFlaggedForms$: BehaviorSubject<AppointmentForms[]> = new BehaviorSubject<AppointmentForms[]>({ } as AppointmentForms[]);

  constructor (
    private formSvc: NextFormService,
    private patientSvc: PatientService,
    public stateViewerSvc: StateViewerService,
    public storeSvc: StoreService
  ) { }

  getFlaggedFormsForPatient(patient: Patient): Observable<FormDTO[]> {
    const getForms$ = this.formSvc.getFormsByPatientIndex(patient.id);
    return getForms$.pipe(
      filter((forms: FormDTO[]) => !!forms),
      concatAll(),
      filter((form: FormDTO) => form.tomovelater),
      toArray(),
      tap((forms: FormDTO[]) => this.setFlaggedFormsForPatient(forms)));
  }

  getFlaggedFormsForAppointments(patient: Patient, skipAccessibilityFilter: boolean = false): Observable<AppointmentForms[]> {
    return this.patientSvc.getPatientIndexAppointmentsWithFlaggedForms(patient.id).pipe(
      concatAll(),
      filter((appointment: Appointment) => this._isAccessibleFacility(appointment, skipAccessibilityFilter)),
      filter((appointment: Appointment) => this._isAccessibleDepartment(appointment, skipAccessibilityFilter)),
      mergeMap((appointment: Appointment) => {
        return forkJoin([of(appointment), this.formSvc.getFormsByAppointments(appointment.patientdataid, appointment.id)]);
      }),
      map(([appointment, forms]) => {
        appointment.patientdata = patient;
        return new AppointmentForms({ appointment: appointment, forms: (forms || []).filter(form => form.tomovelater) });
      }),
      toArray(),
      tap((appointmentsWithFlaggedForms: AppointmentForms[]) => this.appointmentsWithFlaggedForms$.next(appointmentsWithFlaggedForms)));
  }

  checkHasFlaggedFormsToMove(flaggedFormsForPatient: FormDTO[], appointmentsWithFlaggedForms: AppointmentForms[], selectedPatient: Patient = null, selectedAppointment: Appointment = null) {
    const filteredFlaggedPatientForms: FormDTO[] = this.excludeFormsForPatientId(flaggedFormsForPatient, selectedPatient?.id);
    const filteredFlaggedAppointmentForms: AppointmentForms[] = this.excludeFormsForAppointmentId(appointmentsWithFlaggedForms, selectedAppointment?.id, selectedAppointment?.patientdataid, selectedAppointment?.patientdata?.mpi);
    return filteredFlaggedPatientForms.length > 0 || filteredFlaggedAppointmentForms.length > 0;
  }

  excludeFormsForPatientId(flaggedFormsForPatient: FormDTO[], patientId: string = '') {
    patientId = patientId ?? '';
    if (patientId) {
      return flaggedFormsForPatient.filter(form => form.patientdataid !== patientId);
    }
    return flaggedFormsForPatient;
  }

  excludeFormsForAppointmentId(appointmentsWithFlaggedForms: AppointmentForms[], appointmentId = '', patientId = '', patientMpi = '') {
    appointmentId = appointmentId ?? '';
    patientId = patientId ?? '';
    patientMpi = patientMpi ?? '';
    if (appointmentId && patientId) {
      return appointmentsWithFlaggedForms.filter(appointmentForm => {
        const isSamePatient = appointmentForm.appointment.id !== appointmentId && appointmentForm.appointment.patientdataid === patientId;
        const isLikePatient = appointmentForm.appointment.id !== appointmentId && (appointmentForm.appointment.patientdata.mpi === patientMpi);
        return isSamePatient || isLikePatient;
      });
    }
    return appointmentsWithFlaggedForms;
  }

  setFlaggedFormsForPatient(forms: FormDTO[]): void {
    this.flaggedFormsForPatient$.next(forms);
  }

  private _isAccessibleFacility(appointment: Appointment, skipFilter: boolean = false): boolean {
    const skipFacilityFilter: boolean = skipFilter || !appointment.servicingfacility || !this.storeSvc.allFacilities.length || !this.storeSvc.allFacilities.some(f => f.id !== '-1') || !this.storeSvc.facilitiesByUserGroup.some(f => f.id !== '-1');
    const isAccessibleFacility: boolean = (this.storeSvc.selectedFacility.id === '-1')
      ? this.storeSvc.facilitiesByUserGroup.some(facility => facility.facilityid === appointment.servicingfacility)
      : this.storeSvc.selectedFacility.facilityid === appointment.servicingfacility;
    return skipFacilityFilter || isAccessibleFacility;
  }

  private _isAccessibleDepartment(appointment: Appointment, skipFilter: boolean = false): boolean {
    const skipDepartmentFilter: boolean = skipFilter || !appointment.department ||  !this.storeSvc.allDepartments.length || !this.storeSvc.allDepartments.some(d => d.id !== '-1') || !this.storeSvc.departments.some(d => d.id !== '-1');
    const isAccessibleDepartment: boolean = (this.storeSvc.selectedFacility.id === '-1')
      ? this.storeSvc.departments.some(d => d.departmentid === appointment.department)
      : this.storeSvc.selectedFacility.departments.some(d => d.departmentid === appointment.department);
    return skipDepartmentFilter || isAccessibleDepartment;
  }
}
