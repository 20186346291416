<p-accordion *ngFor="let appointmentForm of appointmentFormsSelected; index as i" styleClass="appointments-accordion shadow-sm mb-3">
  <ng-container *ngIf="appointmentForm.forms?.length">
    <p-accordionTab [(selected)]="appointmentForm.isOpen">
      <ng-template pTemplate="header">
        <!--------------------
        -- ACCORDION HEADER --
        --------------------->
        <ng-container>
          <div class="d-flex w-100 p-0">
            <div class="d-inline-flex flex-column align-items-center justify-content-around pt-2 pb-1 border-right" style="width: 50px">
              <p-badge [value]="appointmentForm.forms.length.toString() ?? '0'" size="medium"></p-badge>
              <fa-icon [icon]="(appointmentForm.isOpen ? ['fas', 'chevron-up'] : ['fas', 'chevron-down'])" size="lg" class="text-muted"></fa-icon>
            </div>

            <div *ngIf="appointmentForm.appointment" class="container-fluid" style="width: calc(100% - 50px)">
              <div class="row justify-content-between align-items-center h-100">
                <div class="col-12 col-lg-4 col-xl-3 order-1 p-1 text-truncate">
                  <small *ngIf="appointmentHeaderConfiguration.A1.LABEL">{{ appointmentHeaderConfiguration.A1.LABEL + ': ' }}</small>
                  <span nextDisplayModelProperty [propertyPath]="appointmentHeaderConfiguration.A1.VALUE" [appointment]="appointmentForm.appointment"></span>
                </div>
                <div class="col-12 col-lg-4 col-xl-3 order-2 p-1 text-truncate">
                  <small *ngIf="appointmentHeaderConfiguration.A2.LABEL">{{ appointmentHeaderConfiguration.A2.LABEL + ': ' }}</small>
                  <span nextDisplayModelProperty [propertyPath]="appointmentHeaderConfiguration.A2.VALUE" [appointment]="appointmentForm.appointment"></span>
                </div>
                <div class="col-12 col-lg-4 col-xl-3 order-4 order-xl-3 p-1 text-truncate">
                  <small *ngIf="appointmentHeaderConfiguration.A3.LABEL">{{ appointmentHeaderConfiguration.A3.LABEL + ': ' }}</small>
                  <span nextDisplayModelProperty [propertyPath]="appointmentHeaderConfiguration.A3.VALUE" [appointment]="appointmentForm.appointment"></span>
                </div>
                <div class="col-12 col-lg-4 col-xl-3 order-0 order-lg-3 order-xl-4 py-1 pl-1 pr-2 text-right text-truncate"></div>
                <div class="col-12 col-lg-4 col-xl-3 order-5 p-1 text-truncate">
                  <small *ngIf="appointmentHeaderConfiguration.B1.LABEL">{{ appointmentHeaderConfiguration.B1.LABEL + ': ' }}</small>
                  <span nextDisplayModelProperty [propertyPath]="appointmentHeaderConfiguration.B1.VALUE" [appointment]="appointmentForm.appointment"></span>
                </div>
                <div class="col-12 col-lg-4 col-xl-3 order-6 p-1 text-truncate">
                  <small *ngIf="appointmentHeaderConfiguration.B2.LABEL">{{ appointmentHeaderConfiguration.B2.LABEL + ': ' }}</small>
                  <span nextDisplayModelProperty [propertyPath]="appointmentHeaderConfiguration.B2.VALUE" [appointment]="appointmentForm.appointment"></span>
                </div>
                <div class="col-12 col-lg-4 col-xl-3 order-7 p-1 text-truncate">
                  <small *ngIf="appointmentHeaderConfiguration.B3.LABEL">{{ appointmentHeaderConfiguration.B3.LABEL + ': ' }}</small>
                  <span nextDisplayModelProperty [propertyPath]="appointmentHeaderConfiguration.B3.VALUE" [appointment]="appointmentForm.appointment"></span>
                </div>
                <div class="col-12 col-lg-4 col-xl-3 order-8 p-1 text-truncate">
                  <small *ngIf="appointmentHeaderConfiguration.B4.LABEL">{{ appointmentHeaderConfiguration.B4.LABEL + ': ' }}</small>
                  <span nextDisplayModelProperty [propertyPath]="appointmentHeaderConfiguration.B4.VALUE" [appointment]="appointmentForm.appointment"></span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-template>
      <ng-template pTemplate="body">
        <next-flagged-forms-table-inline
          [openedFrom]="FormType.Appointment"
          [fromMoveFormsModal]="true"
          [forms]="appointmentForm.forms"
          [patient]="appointmentForm.appointment.patientdata"
          [appointment]="appointmentForm.appointment"
          [displayEllipsisMenu]="false">
        </next-flagged-forms-table-inline>
      </ng-template>
    </p-accordionTab>
  </ng-container>
</p-accordion>
