import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FieldBaseComponent } from '../field-base/field-base.component';

@Component({
  selector: 'next-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css'],
  animations: [
    trigger('subField', [
      state('in', style({ opacity: 1 })),
      transition('void => in', [
        style({
          opacity: 0,
          transform: 'translateY(1rem)'
        }),
        animate('300ms ease-in-out')
      ]),
    ]),
  ],
})

export class CheckboxComponent extends FieldBaseComponent implements OnInit, AfterViewInit {
  @Input() attachments: any;
  @Input() needsValidation: boolean;

  ngOnInit(): void {
    super.processOnInit(this.field);
  }

  ngAfterViewInit(): void {
    super.processAfterViewInit(this.field);
    this.valueChangeSub();
  }

  onChange() {
    if (this.field.trueFields.length || this.field.falseFields.length) {
      super.resetDynamicChildren(this.field);
    }
  }

  statusChangeReceived(event): any {
    this.statusChange.emit(event);
  }
}
