import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NextFormService } from '@next/shared/next-services';
import {
  Appointment,
  AppointmentForms,
  EnterpriseAppointmentAccordionHeaderConfig,
  FormDTO,
  FormStatusSortOrder,
  FormType
} from '@next/shared/common';
import { FormsUtilityService } from '../services/forms-utility.service';
import { Router } from '@angular/router';
import { StoreService } from '../state/store.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'next-appointment-forms-accordion',
  templateUrl: './appointment-forms-accordion.component.html',
  styleUrls: ['./appointment-forms-accordion.component.scss']
})
export class AppointmentFormsAccordionComponent implements OnChanges, OnDestroy, OnInit {

  protected readonly FormType = FormType;
  @Input() appointments: Appointment[] = [];
  appointmentForms: AppointmentFormsSelected[] = [];
  observerCleanup$: Subject<void> = new Subject();
  triggerFlaggedFormsCheckForAppointments: EventEmitter<void> = new EventEmitter<void>();
  appointmentHeaderConfiguration: EnterpriseAppointmentAccordionHeaderConfig;

  constructor (
    protected formSvc: NextFormService,
    protected formsUtilSvc: FormsUtilityService,
    protected router: Router,
    protected storeSvc: StoreService,
    protected translateSvc: TranslateService) {
    this.appointmentHeaderConfiguration = this.storeSvc.enterpriseSettings.appointmentHeaders;
  }

  ngOnInit(): void {
    this.storeSvc.searchedForms.formsDeleted$.pipe(
      takeUntil(this.observerCleanup$)
    ).subscribe(async () => {
      // Update appointment forms in place.
      await Promise.all(this.appointmentForms.map(async a => {
        a.forms = await this.getAppointmentFormsAsync(a.appointment);
      }));
    });
  }

  ngOnDestroy(): void {
    this.storeSvc.patientFormOpenedAccordions = this.appointmentForms.filter(x => x.isOpen).map(x => x.appointment.id);
    this.observerCleanup$.next();
    this.observerCleanup$.complete();
  }

  ngOnChanges(): void {
    this.initAppointmentFormsAsync().then();
  }

  protected async initAppointmentFormsAsync(): Promise<void> {
    this.appointmentForms = await Promise.all(this.appointments.map(async a =>
      new AppointmentFormsSelected(
        a,
        await this.getAppointmentFormsAsync(a),
        this.storeSvc.patientFormOpenedAccordions.includes(a.id)
      )));
    this.triggerFlaggedFormsCheckForAppointments.emit();
  }

  protected async getAppointmentFormsAsync(appointment: Appointment): Promise<FormDTO[]> {
    const forms = (await this.formSvc.getFormsByAppointments(appointment.patientdataid, appointment.id).toPromise()) ?? [];
    forms.forEach(form => {
      form.appointment = appointment;
      form.patientdata = appointment.patientdata;
      form.statusorder = this.statusOrder(form.status); });
    forms.sort((a, b) => { return a.statusorder - b.statusorder || (a.name > b.name ? 1:- 1) }); // sort by status and then name
    return forms;
  }

  statusOrder(statusString: string): number {
    return FormStatusSortOrder[statusString.toUpperCase()];
  }

  addForm(event: any, appointmentForm: AppointmentFormsSelected): void {
    if (event) {
      event.stopPropagation();
    }

    this.formsUtilSvc.showAddFormsModal({
      appointment: appointmentForm.appointment,
      patient: appointmentForm.appointment.patientdata,
      onAdd: forms => appointmentForm.forms = appointmentForm.forms.concat(forms),
      onSign: forms => appointmentForm.forms = appointmentForm.forms.concat(forms)
    });
  }
}

export class AppointmentFormsSelected {
  appointmentForms: AppointmentForms;
  isOpen: boolean;

  public constructor(appointment: Appointment, forms: FormDTO[], isOpen: boolean) {
    this.appointmentForms = new AppointmentForms({appointment: appointment, forms: forms});
    this.isOpen = isOpen;
  }

  set appointment(appointment: Appointment) {
    this.appointmentForms.appointment = appointment;
  }

  get appointment(): Appointment {
    return this.appointmentForms.appointment;
  }

  set forms(forms: FormDTO[]) {
    this.appointmentForms.forms = forms;
  }

  get forms(): FormDTO[] {
    return this.appointmentForms.forms;
  }
}
