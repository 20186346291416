import { Injectable } from '@angular/core';
import {
  ConfigTableInterface,
  ConfigType
} from '@next/shared/common';
import { map } from 'rxjs';
import { NextAdminService } from '../admin.service';

@Injectable({
  providedIn: 'root',
})
export class NextEnterpriseResolverService {
  constructor(
    private nextAdminService: NextAdminService
  ) {}

  resolve() {
    return this.nextAdminService
      .getConfigsByTypes([
        ConfigType.EnterpriseSettings,
        ConfigType.BarcodeScanningProfiles,
        ConfigType.DataMapping,
      ])
      .pipe(
        map((res: ConfigTableInterface[]) => {
          const enterpriseSettings = res.find(
            (x) => x.type === ConfigType.EnterpriseSettings
          );
          const dataMappings = res.find(
            (x) => x.type === ConfigType.DataMapping
          );
          const barcodeProfiles = res.find(
            (x) => x.type === ConfigType.BarcodeScanningProfiles
          );
            return {
                enterpriseSettings: enterpriseSettings,
                dataMappings: dataMappings,
                barcodeProfiles: barcodeProfiles
            }
        })
      );
  }
}
