export enum SetupSignatureOptions {
  Id = 'ID',
  Text = 'TEXT',
  Strokes = 'STROKES',
  Initials = 'INITIALS',
  AccessCode = 'CODE',
  NPI = 'NPI'
}

export enum PreferenceType {
  DefaultSignatures = 'DEFAULTSIGNATURES',
  AccessCode = 'ACCESSCODE',
  Facility = 'FACILITY',
  Department = 'DEPARTMENT',
  DEA = 'DEA',
  NPI = 'NPI',
  Dashboard = 'DASHBOARD',
  DashboardDisplay = 'DASHBOARDDISPLAY',
  DefaultBarcode = "DEFAULTBARCODEID",
  SearchByType = "SEARCHBYTYPE",
  FavoriteExperiences = "FAVORITEEXPERIENCES",
  DesignerTimeStampFormat = "DESIGNER_FORMAT_TIMESTAMP",
  DesignerDateFormat = "DESIGNER_FORMAT_DATE",
  DesignerTimeFormat = "DESIGNER_FORMAT_TIME",
  DesignerDateTimeFormat = "DESIGNER_FORMAT_DATETIME"
}

export enum Relationship {
  Self = 'SELF',
  Patient = 'PATIENT'
}
