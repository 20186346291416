import { Pipe, PipeTransform } from '@angular/core';
import { SearchByType } from "@next/shared/common";

@Pipe({
  name: 'patientSearchHighlight'
})
export class PatientSearchHighlightPipe implements PipeTransform {

  transform(value: string, searchByType: SearchByType, currentSearchType: SearchByType, searchTerm: string): any {

    if (currentSearchType !== searchByType || searchTerm?.length < 2) {
      return value;
    }

    return value.replace(new RegExp(searchTerm, 'gi'), "<mark class='search-text-highlight'>$&</mark>");
  }

}
