import { Injectable } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChartBar as lightfaChartBar } from '@fortawesome/pro-light-svg-icons';
import {
  faCalendar as regularfaCalendar,
  faCalendarAlt as regularfaCalendarAlt,
  faClock as regularfaClock,
  faCalendarClock as regularfaCalendarClock,
  faCheckCircle as regularfaCheckCircle,
  faCheckSquare as regularfaCheckSquare,
  faCircle as regularfaCircle,
  faDotCircle as regularfaDotCircle,
  faEdit as regularfaEdit,
  faEnvelope as regularfaEnvelope,
  faEye as regularfaEye,
  faFile as regularfaFile,
  faFileAlt as regularfaFileAlt,
  faFileImage as regularfaFileImage,
  faFilePdf as regularfaFilePdf,
  faHeart as regularfaHeart,
  faPlayCircle as regularfaPlayCircle,
  faTimesCircle as regularfaTimesCircle,
  faTrashAlt as regularfaTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faAlignCenter as solidfaAlignCenter,
  faAlignLeft as solidfaAlignLeft,
  faAlignRight as solidfaAlignRight,
  faAngleDown as solidfaAngleDown,
  faAngleUp as solidfaAngleUp,
  faArchive as solidfaArchive,
  faArrowLeft as solidfaArrowLeft,
  faArrowsAltV as solidfaArrowsAltV,
  faAsterisk as solidfaAsterisk,
  faBan as solidfaBan,
  faBarcode as solidfaBarcode,
  faBars as solidfaBars,
  faBell as solidfaBell,
  faBold as solidfaBold,
  faBookmark as solidfaBookmark,
  faCalculator as solidfaCalculator,
  faCalendarAlt as solidfaCalendarAlt,
  faCamera as solidfaCamera,
  faCaretDown as solidfaCaretDown,
  faCaretLeft as solidfaCaretLeft,
  faCaretRight as solidfaCaretRight,
  faCaretUp as solidfaCaretUp,
  faCheck as solidfaCheck,
  faCheckCircle as solidfaCheckCircle,
  faCheckSquare as solidfaCheckSquare,
  faChevronDoubleLeft as solidfaChevronDoubleLeft,
  faChevronDoubleRight as solidfaChevronDoubleRight,
  faChevronDown as solidfaChevronDown,
  faChevronLeft as solidfaChevronLeft,
  faChevronRight as solidfaChevronRight,
  faChevronUp as solidfaChevronUp,
  faCircle as solidfaCircle,
  faClipboardList as solidfaClipboardList,
  faCloud as solidfaCloud,
  faCodeBranch as solidfaCodeBranch,
  faCog as solidfaCog,
  faCogs as solidfaCogs,
  faColumns as solidfaColumns,
  faCopy as solidfaCopy,
  faCreditCard as solidfaCreditCard,
  faCrop as solidfaCrop,
  faCrosshairs as solidfaCrosshairs,
  faDatabase as solidfaDatabase,
  faDesktop as solidfaDesktop,
  faDisplay as solidfaDisplay,
  faDisplaySlash as solidfaDisplaySlash,
  faDollarSign as solidfaDollarSign,
  faDownload as solidfaDownload,
  faEdit as solidfaEdit,
  faEllipsisH as solidfaEllipsisH,
  faEllipsisV as solidfaEllipsisV,
  faEnvelope as solidfaEnvelope,
  faEraser as solidfaEraser,
  faExclamation as solidfaExclamation,
  faExclamationCircle as solidfaExclamationCircle,
  faExclamationTriangle as solidfaExclamationTriangle,
  faEye as solidfaEye,
  faEyeSlash as solidfaEyeSlash,
  faFeatherAlt as solidfaFeatherAlt,
  faFile as solidfaFile,
  faFileAlt as solidfaFileAlt,
  faFileExport as solidfaFileExport,
  faFileImage as solidfaFileImage,
  faFileImport as solidfaFileImport,
  faFilePdf as solidfaFilePdf,
  faFileUpload as solidfaFileUpload,
  faFolder as solidfaFolder,
  faFolderOpen as solidfaFolderOpen,
  faFont as solidfaFont,
  faGripLines as solidfaGripLines,
  faHeading as solidfaHeading,
  faHeart as solidfaHeart,
  faHistory as solidfaHistory,
  faHome as solidfaHome,
  faHospital as solidfaHospital,
  faHospitalUser as solidfaHospitalUser,
  faIdBadge as solidfaIdBadge,
  faIdCard as solidfaIdCard,
  faImage as solidfaImage,
  faIndustry as solidfaIndustry,
  faInfoCircle as solidfaInfoCircle,
  faItalic as solidfaItalic,
  faLink as solidfaLink,
  faListAlt as solidfaListAlt,
  faLock as solidfaLock,
  faLongArrowAltLeft as solidfaLongArrowAltLeft,
  faMagic as solidfaMagic,
  faMapMarkerAlt as solidfaMapMarkerAlt,
  faMapPin as solidfaMapPin,
  faMedkit as solidfaMedkit,
  faMinus as solidfaMinus,
  faMinusCircle as solidfaMinusCircle,
  faMobileAlt as solidfaMobileAlt,
  faParagraph as solidfaParagraph,
  faPen as solidfaPen,
  faPencilAlt as solidfaPencilAlt,
  faPenFancy as solidfaPenFancy,
  faPercent as solidfaPercent,
  faPhone as solidfaPhone,
  faPlus as solidfaPlus,
  faPlusCircle as solidfaPlusCircle,
  faPrint as solidfaPrint,
  faQuestionCircle as solidfaQuestionCircle,
  faSave as solidfaSave,
  faSearch as solidfaSearch,
  faSearchMinus as solidfaSearchMinus,
  faSearchPlus as solidfaSearchPlus,
  faShieldAlt as solidfaShieldAlt,
  faSignature as solidfaSignature,
  faSitemap as solidfaSitemap,
  faSpinner as solidfaSpinner,
  faSyncAlt as solidfaSyncAlt,
  faTable as solidfaTable,
  faTabletAlt as solidfaTabletAlt,
  faTag as solidfaTag,
  faTags as solidfaTags,
  faTerminal as solidfaTerminal,
  faThList as solidfaThList,
  faTimes as solidfaTimes,
  faTimesCircle as solidfaTimesCircle,
  faToggleOff as solidfaToggleOff,
  faToggleOn as solidfaToggleOn,
  faTrash as solidfaTrash,
  faUnderline as solidfaUnderline,
  faUpload as solidfaUpload,
  faUser as solidfaUser,
  faUsers as solidfaUsers,
  faUserCircle as solidfaUserCircle,
  faUserFriends as solidfaUserFriends,
  faUserPlus as solidfaUserPlus,
  faWindowRestore as solidfaWindowRestore,
  faWrench as solidfaWrench,
  faDesktopAlt as solidfaDesktopAlt,
  faUserMd as solidfaUserMd,
  faHouseLaptop as solidfaHouseLaptop
} from '@fortawesome/pro-solid-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  constructor(private library: FaIconLibrary) {}

  addIcons() {
    this.library.addIcons(
      regularfaClock,
      regularfaCalendar,
      regularfaCalendarAlt,
      regularfaClock,
      regularfaCalendarClock,
      regularfaCheckSquare,
      regularfaCircle,
      regularfaDotCircle,
      regularfaEdit,
      regularfaEnvelope,
      regularfaEye,
      regularfaFile,
      regularfaFileAlt,
      regularfaFileImage,
      regularfaFilePdf,
      regularfaPlayCircle,
      regularfaTimesCircle,
      regularfaTrashAlt,
      solidfaAlignCenter,
      solidfaAlignLeft,
      solidfaAlignRight,
      solidfaAngleDown,
      solidfaArchive,
      solidfaArrowLeft,
      solidfaArrowsAltV,
      solidfaAsterisk,
      solidfaBan,
      solidfaBarcode,
      solidfaBars,
      solidfaBell,
      solidfaBold,
      solidfaBookmark,
      solidfaCalculator,
      solidfaCalendarAlt,
      solidfaCamera,
      solidfaCaretDown,
      solidfaCaretLeft,
      solidfaCaretRight,
      solidfaCheck,
      solidfaCheckCircle,
      solidfaCheckSquare,
      solidfaChevronDown,
      solidfaChevronLeft,
      solidfaChevronRight,
      solidfaChevronUp,
      solidfaCircle,
      solidfaClipboardList,
      solidfaCloud,
      solidfaCodeBranch,
      solidfaCog,
      solidfaCopy,
      solidfaCreditCard,
      solidfaCrop,
      solidfaCrosshairs,
      solidfaDesktop,
      solidfaDisplay,
      solidfaDisplaySlash,
      solidfaDollarSign,
      solidfaDownload,
      solidfaEdit,
      solidfaEllipsisH,
      solidfaEnvelope,
      solidfaEraser,
      solidfaExclamation,
      solidfaExclamationCircle,
      solidfaExclamationTriangle,
      solidfaEye,
      solidfaEyeSlash,
      solidfaFeatherAlt,
      solidfaFile,
      solidfaFileAlt,
      solidfaFileExport,
      solidfaFileImage,
      solidfaFileImport,
      solidfaFilePdf,
      solidfaFileUpload,
      solidfaFolderOpen,
      solidfaFont,
      solidfaGripLines,
      solidfaHeading,
      solidfaHome,
      solidfaHospitalUser,
      solidfaIdCard,
      solidfaImage,
      solidfaInfoCircle,
      solidfaItalic,
      solidfaLock,
      solidfaLongArrowAltLeft,
      solidfaMapMarkerAlt,
      solidfaMapPin,
      solidfaMinus,
      solidfaMinusCircle,
      solidfaMobileAlt,
      solidfaPen,
      solidfaPencilAlt,
      solidfaPenFancy,
      solidfaPercent,
      solidfaPhone,
      solidfaPlus,
      solidfaPlusCircle,
      solidfaPrint,
      solidfaQuestionCircle,
      solidfaSave,
      solidfaSearch,
      solidfaSignature,
      solidfaSitemap,
      solidfaSpinner,
      solidfaSyncAlt,
      solidfaTable,
      solidfaTabletAlt,
      solidfaTag,
      solidfaTags,
      solidfaTerminal,
      solidfaThList,
      solidfaTimes,
      solidfaTimesCircle,
      solidfaToggleOff,
      solidfaToggleOn,
      solidfaTrash,
      solidfaUnderline,
      solidfaUpload,
      solidfaUser,
      solidfaUsers,
      solidfaUserCircle,
      solidfaUserFriends,
      solidfaUserPlus,
      solidfaMagic,
      solidfaParagraph,
      solidfaLink,
      solidfaWindowRestore,
      solidfaListAlt,
      solidfaColumns,
      solidfaIndustry,
      solidfaWrench,
      solidfaMedkit,
      solidfaIdBadge,
      solidfaShieldAlt,
      solidfaHospital,
      solidfaFolder,
      solidfaDatabase,
      solidfaCogs,
      solidfaHeart,
      regularfaHeart,
      solidfaCaretUp,
      solidfaEllipsisV,
      regularfaCheckCircle,
      solidfaAngleUp,
      solidfaHistory,
      lightfaChartBar,
      solidfaChevronDoubleLeft,
      solidfaChevronDoubleRight,
      solidfaSearchPlus,
      solidfaSearchMinus,
      solidfaDesktopAlt,
      solidfaUserMd,
      solidfaHouseLaptop
    );
  }
}
