import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ApiService,
  ConfigTableInterface,
  FormFastConfig,
  FormFastConfigService,
  License,
  PreferenceSubmission,
  TokenService,
  Dataset,
  GridSetting,
  Facility,
  Contact,
  Dashboard,
  FacilityGroup,
  Department,
  SettingEnum,
} from '@next/shared/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class NextAdminService extends ApiService {
  constructor(@Inject(FormFastConfigService) config: FormFastConfig, tokenSvc: TokenService, private http: HttpClient) {
    super(config, tokenSvc);
  }

  getConfigsByType(dataType: string): Observable<ConfigTableInterface[]> {
    return this.protectedEndpoint<ConfigTableInterface[]>(
      this.http.get<ConfigTableInterface[]>(`${this.config.apiUrl}config/type/${dataType}/config`, this.getHeaders())
    );
  }

  getConfigsByTypes(dataTypes: string[]): Observable<ConfigTableInterface[]> {
    return this.protectedEndpoint<ConfigTableInterface[]>(
      this.http.get<ConfigTableInterface[]>(`${this.config.apiUrl}config/type/config/multiple?types=${dataTypes}`, this.getHeaders())
    );
  }

  checkBeforeLoginNotification(): Observable<ConfigTableInterface[]> {
    return this.protectedEndpoint<ConfigTableInterface[]>(
      this.http.get<ConfigTableInterface[]>(`${this.config.apiUrl}config/login/notification`, this.getHeaders())
    );
  }

  saveConfig(data: any): Observable<ConfigTableInterface> {
    return this.protectedEndpoint<ConfigTableInterface>(
      this.http.post<ConfigTableInterface>(`${this.config.apiUrl}config`, data, this.getHeaders())
    );
  }

  updateConfig(id: string, data: any): Observable<ConfigTableInterface> {
    return this.protectedEndpoint<ConfigTableInterface>(
      this.http.put<ConfigTableInterface>(`${this.config.apiUrl}config/${id}`, data, this.getHeaders())
    );
  }

  updateDataMapConfig(id: string, config: any, changelog: any): Observable<ConfigTableInterface> {
    const data = { config: config, changelog: changelog };
    return this.protectedEndpoint<ConfigTableInterface>(
      this.http.put<ConfigTableInterface>(`${this.config.apiUrl}config/${id}`, data, this.getHeaders())
    );
  }

  deleteConfig(id: string): Observable<ConfigTableInterface> {
    return this.protectedEndpoint<ConfigTableInterface>(
      this.http.delete<ConfigTableInterface>(`${this.config.apiUrl}config/${id}`, this.getHeaders())
    );
  }

  getUsers(): Observable<any> { // ToDo: interface User API
    return this.protectedEndpoint<any>(
      this.http.get<any>(`${this.config.apiUrl}user/`, this.getHeaders())
    );
  }

  savePreference(data: PreferenceSubmission[]): Observable<PreferenceSubmission[]> {
    return this.protectedEndpoint<PreferenceSubmission[]>(
      this.http.post<PreferenceSubmission[]>(`${this.config.apiUrl}preference/`, data, this.getHeaders())
    );
  }

  getPreference(userId: string, type: string): Observable<PreferenceSubmission[]> {
    return this.protectedEndpoint<PreferenceSubmission[]>(
      this.http.get<PreferenceSubmission[]>(`${this.config.apiUrl}preference/user/${userId}/type/${type}`, this.getHeaders())
    );
  }

  getAllPreferenceForUser(userId: string): Observable<PreferenceSubmission[]> {
    return this.protectedEndpoint<PreferenceSubmission[]>(
      this.http.get<PreferenceSubmission[]>(`${this.config.apiUrl}preference/user/${userId}`, this.getHeaders())
    );
  }

  getAllPreferences(): Observable<PreferenceSubmission[]> {
    return this.protectedEndpoint<PreferenceSubmission[]>(
      this.http.get<PreferenceSubmission[]>(`${this.config.apiUrl}preference/`, this.getHeaders())
  )}

  getAllGridSettings(screen: SettingEnum, userid: string): Observable<GridSetting[]> {
    return this.protectedEndpoint<GridSetting[]>(
      this.http.get<GridSetting[]>(`${this.config.apiUrl}preference/gridsetting/screen/${screen}/userId/${userid}`, this.getHeaders())
    );
  }

  getGridSetting(id: string): Observable<GridSetting[]> {
    return this.protectedEndpoint<GridSetting[]>(
      this.http.get<GridSetting[]>(`${this.config.apiUrl}preference/gridsetting/id/${id}`, this.getHeaders())
    );
  }

  getGridSettingByName(name: string): Observable<GridSetting[]> {
    return this.protectedEndpoint<GridSetting[]>(
      this.http.get<GridSetting[]>(`${this.config.apiUrl}preference/gridsetting?name=${encodeURIComponent(name)}`, this.getHeaders())
    );
  }

  saveSetting(data: GridSetting): Observable<GridSetting> {
    return this.protectedEndpoint<GridSetting>(
      this.http.post<GridSetting>(`${this.config.apiUrl}preference/gridsetting/`, data, this.getHeaders())
    );
  }

  saveAllSetting(data: GridSetting[]): Observable<GridSetting[]> {
    return this.protectedEndpoint<GridSetting[]>(
      this.http.put<GridSetting[]>(`${this.config.apiUrl}preference/savegridsettings/`, data, this.getHeaders())
    );
  }

  updateSetting(data: GridSetting): Observable<GridSetting> {
    return this.protectedEndpoint<GridSetting>(
      this.http.put<GridSetting>(`${this.config.apiUrl}preference/gridsetting/`, data, this.getHeaders())
    );
  }

  deleteSetting(id: string) {
    return this.protectedEndpoint<boolean>(
      this.http.delete<boolean>(`${this.config.apiUrl}preference/gridsetting/${id}`, this.getHeaders())
    );
  }

  getDashboards(): Observable<Dashboard[]> {
    return this.protectedEndpoint<Dashboard[]>(
      this.http.get<Dashboard[]>(`${this.config.apiUrl}preference/dashboard/`, this.getHeaders())
    );
  }

  getAllLicense(): Observable<License[]> {
    return this.protectedEndpoint<License[]>(
      this.http.get<License[]>(`${this.config.apiUrl}license/`, this.getHeaders())
    );
  }

  getConsolidatedLicense(): Observable<License[]> {
    return this.protectedEndpoint<License[]>(
      this.http.get<License[]>(`${this.config.apiUrl}license/getConsolidated`, this.getHeaders())
    );
  }

  deleteLicense(id: string) {
    return this.protectedEndpoint<License>(
      this.http.delete<License>(`${this.config.apiUrl}license/${id}`, this.getHeaders())
    );
  }

  createLicense(data: any) {
    return this.protectedEndpoint<License>(
      this.http.post<License>(`${this.config.apiUrl}license`, data, this.getHeaders())
    );
  }

  createDataset(data: any): Observable<Dataset> {
    return this.protectedEndpoint<Dataset>(
      this.http.post<Dataset>(`${this.config.apiUrl}customerdataset`, data, this.getHeaders())
    );
  }

  getDatasets(id): Observable<Dataset> {
    return this.protectedEndpoint<Dataset>(
      this.http.get<Dataset>(`${this.config.apiUrl}customerdataset/${id}`, this.getHeaders())
    );
  }

  getAllDatasets(): Observable<Dataset[]> {
    return this.protectedEndpoint<Dataset[]>(
      this.http.get<Dataset[]>(`${this.config.apiUrl}customerdataset`, this.getHeaders())
    );
  }

  deleteDataset(id) {
    return this.protectedEndpoint<Dataset>(
      this.http.delete<Dataset>(`${this.config.apiUrl}customerdataset/${id}`, this.getHeaders())
    );
  }

  updateDataset(id, data): Observable<Dataset> {
    return this.protectedEndpoint<Dataset>(
      this.http.put<Dataset>(`${this.config.apiUrl}customerdataset/${id}`, data, this.getHeaders())
    );
  }

  downloadLogs(startDate: Date, endDate: Date) {
    return this.http.get(`${this.config.apiUrl}logger?StartTimestamp=${new Date(startDate).toISOString()}&EndTimestamp=${new Date(endDate).toISOString()}`,
     { responseType: 'blob' }).pipe(map((res: any) => {
      return new Blob([res], { type: 'application/zip', endings: 'native' });
    }));
  }

  getDisplayLogs() {
    return this.http.get(`${this.config.apiUrl}logger/unencrypted`,
    { responseType: 'blob' }).pipe(map((res: any) => {
     return new Blob([res], { type: 'text/plain', endings: 'native' });
   }));
  }

  createMetric(keyword, object) {
    if (!object || !keyword) {
      return;
    }
    const data = { "keyword" : keyword, "object": object }
    return this.protectedEndpoint<any>(
      this.http.post<any>(`${this.config.apiUrl}logger/metric`, data, this.getHeaders())
    ).subscribe();
  }

  getAllFacilities(): Observable<Facility[]> {
    return this.protectedEndpoint<Facility[]>(
      this.http.get<Facility[]>(`${this.config.apiUrl}facility/`, this.getHeaders())
    );
  }

  getFacility(id: string): Observable<Facility> {
    return this.protectedEndpoint<Facility>(
      this.http.get<Facility>(`${this.config.apiUrl}facility/${id}/config`, this.getHeaders())
    ).pipe(
      map(result => {
        return Array.isArray(result) ? result[0] : result;
      }));
  }

  createFacility(data: any) {
    return this.protectedEndpoint<Facility>(
      this.http.post<Facility>(`${this.config.apiUrl}facility`, data, this.getHeaders())
    );
  }

  editFacility(data: any) {
    return this.protectedEndpoint<Facility>(
      this.http.put<Facility>(`${this.config.apiUrl}facility`, data, this.getHeaders())
    ).pipe(
      map(response => {
        return Array.isArray(response) ? response[0] : response;
      }));
  }

  deleteFacility(data: any) {
    return this.protectedEndpoint<Facility>(
      this.http.put<Facility>(`${this.config.apiUrl}facility/${data.id}`, data, this.getHeaders())
    );
  }

  getFacilityGroupByUserGroups() {
    return this.protectedEndpoint<Facility[]>(
      this.http.get<Facility[]>(`${this.config.apiUrl}facility/filter`, this.getHeaders())
    );
  }

  getFacilityGroup(facilityId: string): Observable<FacilityGroup[]> {
    return this.protectedEndpoint<FacilityGroup[]>(
      this.http.get<FacilityGroup[]>(`${this.config.apiUrl}facility/${facilityId}/group`, this.getHeaders())
    );
  }

  updateFacilityGroup(facilityId: string, data: any) {
    return this.protectedEndpoint<FacilityGroup[]>(
      this.http.post<FacilityGroup[]>(`${this.config.apiUrl}facility/${facilityId}/group`, data, this.getHeaders())
    );
  }

  getAllContacts(facilityId): Observable<Contact[]> {
    return this.protectedEndpoint<Contact[]>(
      this.http.get<Contact[]>(`${this.config.apiUrl}contact/${facilityId}`, this.getHeaders())
    );
  }

  createContact(data: any) {
    return this.protectedEndpoint<Contact>(
      this.http.post<Contact>(`${this.config.apiUrl}contact`, data, this.getHeaders())
    );
  }

  editContact(data: any) {
    return this.protectedEndpoint<Contact>(
      this.http.put<Contact>(`${this.config.apiUrl}contact`, data, this.getHeaders())
    );
  }

  deleteContact(id) {
    return this.protectedEndpoint<Contact>(
      this.http.delete<Contact>(`${this.config.apiUrl}contact/${id}`, this.getHeaders())
    );
  }

  generateHelpUrl() {
    return this.protectedEndpoint<string>(
      this.http.get<string>(`${this.config.apiUrl}config/generateHelpUrl/InformedConsent`, this.getHeaders())
    );
  }

  getVersion(): Observable<string> {
    return this.http.get(`${this.config.apiUrl}version`, { responseType: "text" });
  }

  getParameterColumnData(id: string, ordinal:number, query: string): Observable<string[]> {
    return this.protectedEndpoint<string[]>(
      this.http.get<string[]>(`${this.config.apiUrl}customerdataset/${id}/parameters/${ordinal}?${query}`, this.getHeaders())
    );
  }

  getParameterDataSet(id: string, query: string): Observable<Dataset> {
    return this.protectedEndpoint<Dataset>(
      this.http.get<Dataset>(`${this.config.apiUrl}customerdataset/${id}?${query}`, this.getHeaders())
    );
  }

  getTypedSignatureRelationshipSource(id: string): Observable<string[]> {
    return this.protectedEndpoint<string[]>(
      this.http.get<string[]>(`${this.config.apiUrl}customerdataset/${id}/signaturerelationship`, this.getHeaders())
    );
  }

  getDepartments(): Observable<Department[]> {
    return this.protectedEndpoint<Department[]>(
      this.http.get<Department[]>(`${this.config.apiUrl}department`, this.getHeaders())
    );
  }

  saveDepartments(payload: Department[]) {
    return this.protectedEndpoint<void>(
      this.http.post<void>(`${this.config.apiUrl}department`, payload, this.getHeaders())
    );
  }

  getClientAppVersion() {
    return this.config.appVersion;
  }

  getTenantSettings() {
    return this.protectedEndpoint<any>(
      this.http.get<any>(`${this.config.apiUrl}tenant/settings`, this.getHeaders())
    );
  }
}
