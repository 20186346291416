<div class="container-fluid" style="height: 100vh">

    <div class="row d-flex modal-header justify-content-between align-content-end py-0 px-3">
      <button aria-label="Close" id="close" (click)="exit()">
        <fa-icon [icon]="['fas', 'chevron-left']"></fa-icon>&nbsp;&nbsp;Cancel
      </button>
      <h5 class="justify-content-center"> {{'PATIENT_SEARCH.SCAN_LABEL' | translate}}</h5>
      <span>&nbsp;</span>
    </div>
    <div *ngIf="stateSvc.barcodeScanningProfiles.length > 1" class="row modal-header justify-content-center pb-5">
      <div class="pr-4"> {{'PATIENT_SEARCH.PROFILE_LABEL' | translate}}</div>
      <select class="profile-select form-control" [(ngModel)]="selectedProfileId" (change)="profileSelected($event)">
        <option *ngFor="let profile of stateSvc.barcodeScanningProfiles" [value]="profile.id">
          {{ profile.profileName }}</option>
      </select>

    </div>

</div>
