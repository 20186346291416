import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { NextImageService } from '../image.service';
import { Image } from '@next/shared/common';

@Injectable({
  providedIn: 'root',
})
export class NextImageResolverService {
  constructor(private imageSvc: NextImageService) { }

  resolve(): Observable<Image[]> | Observable<never> {
    return this.imageSvc.getImages();
  }
}
