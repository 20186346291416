<div [nextUhiEmbedded]="true" id="btn-toolbar-tabs-dashboard" class="d-flex align-items-center text-center w-100 m-0 p-0 menu-icons">
    <button id="btn-dashboard-tab" *ngIf="userSvc.usesPatients" class="d-inline-block col-3 p-0 menu-item rounded" [class.active]="activeItem === MenuItems.Dashboard" (click)="openPage(MenuItems.Dashboard)">
      <fa-icon class="menu-icon" [icon]="['fas', 'clipboard-list']" ></fa-icon>
      <div class="item-text">{{ stateSvc.enterpriseSettings.dashboard || 'CLINICAL_MENU.DASHBOARD' | translate }}</div>
    </button>
    <button id="btn-patients-tab" *ngIf="userSvc.usesPatients" class="d-inline-block col-3 p-0 menu-item rounded" [class.active]="activeItem === MenuItems.Patients" (click)="openPage(MenuItems.Patients)">
      <fa-icon class="menu-icon" [icon]="['fas', 'user']" ></fa-icon>
      <div class="item-text">{{ stateSvc.enterpriseSettings.patients || 'CLINICAL_MENU.PATIENTS' | translate }}</div>
    </button>
    <button id="btn-appointments-tab" *ngIf="userSvc.usesPatients" class="d-inline-block col-3 p-0 menu-item rounded" [class.active]="activeItem === MenuItems.Appointments" (click)="openPage(MenuItems.Appointments)">
      <fa-icon class="menu-icon" [icon]="['fas', 'calendar-alt']" ></fa-icon>
      <div class="item-text">{{ stateSvc.enterpriseSettings.appointments || 'CLINICAL_MENU.APPOINTMENTS' | translate }}</div>
    </button>
    <button id="btn-forms-tab" *ngIf="userSvc.usesPatients" class="d-inline-block col-3 p-0 menu-item rounded" [class.active]="activeItem === MenuItems.Forms" (click)="openPage(MenuItems.Forms)">
      <fa-icon class="menu-icon" [icon]="['fas', 'file']" ></fa-icon>
      <div class="item-text">{{ stateSvc.enterpriseSettings.forms || 'CLINICAL_MENU.FORMS' | translate }}</div>
    </button>
</div>
