<div class="h-100" [formGroup]="manageTag">
  <div class="ilh-modal-header d-flex flex-column p-3 bg-header">
    <div class="d-flex align-content-center mb-3">
      <div class="col px-0">
        <button id="btn-modal-close" class="btn btn-sm btn-outline-secondary rounded-pill p-0 px-1" (click)="onManageTagsExit()">{{ 'TAGS.MANAGE.CANCEL' | translate }}</button>
      </div>
      <div class="center">
        <h5 id="modal-title" class="m-0 p-0">{{'TAGS.MANAGE.TITLE' | translate }}</h5>
      </div>
      <div class="col px-0 text-right">
        <button id="btn-manage-tags" *ngIf="mode === TagMode.search" (click)="updateMode(TagMode.edit)" class="btn btn-sm btn-primary rounded-pill p-0 px-1">{{ 'TAGS.MANAGE.EDIT_TAGS' | translate }}</button>
        <button id="btn-manage-tags-done" *ngIf="mode === TagMode.edit" (click)="updateMode(TagMode.search)" class="btn btn-sm btn-primary rounded-pill p-0 px-1">{{ 'TAGS.MANAGE.DONE' | translate }}</button>
      </div>
    </div>

    <div class="input-group ilh-input-group">
      <div class="input-group-prepend ilh-input-side">
        <span class="input-group-text">
          <fa-icon id="search-icon" class='ilh-bar-child text-muted' [icon]='["fas", "search"]'></fa-icon>
        </span>
      </div>

      <input id="input-search-tags" type="text" class="form-control ilh-input-field" [formControlName]='"search"'
             placeholder="{{'TAGS.MANAGE.SEARCH_INPUT_PLACE_HOLDER' | translate}}"
             [mask]='"Z{25}"' [pattern]='pattern["Z"].pattern' [patterns]='pattern' [maxLength]='25'>

      <div class="input-group-append ilh-input-side" *ngIf="manageTag.controls['search'].value">
        <span class="input-group-text">
          <fa-icon id="search-reset" class="text-muted cursor-pointer" [icon]="['far', 'times-circle']" (click)="manageTag.controls['search'].reset()" size="lg"></fa-icon>
        </span>
      </div>
    </div>
  </div>

  <div class="ilh-modal-body px-3 mt-2 ilh-modal-body-manage-tags">
    <div class='d-block row manage-tags-body'>
      <div class='container-fluid m-0 p-0 h-100'>
        <div *ngIf="mode === TagMode.edit" class="mx-3">
          <!-- Add New -->
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <span class="input-group-text"><fa-icon class='text-muted' [icon]='["fas", "tag"]'></fa-icon></span>
            </div>
            <input id="input-new-tag" type="text" class="form-control"
                   placeholder="{{'TAGS.MANAGE.ADD_INPUT_PLACE_HOLDER' | translate}}"
                   [formControlName]='"newtag"' (keyup.enter)="addTag()"
                   [mask]='"Z{25}"' [pattern]='pattern["Z"].pattern' [patterns]='pattern' [maxLength]='25'>
            <div class="input-group-append">
              <button id="btn-add-tag" class="btn btn-success" type="button" (click)="addTag();"
                      [attr.disabled]='manageTag.value["newtag"] ? null : true'>{{'TAGS.MANAGE.ADD' | translate | uppercase }}</button>
            </div>
          </div>
        </div>

        <div id="list-available-tags" class='mx-3 border-top border-bottom rounded scroll-area bg-light'>
          <div id="no-tags" *ngIf='!formArray.controls.length' class='container my-2 justify-content-center align-items-center h-100'>
            <div class='row h-25 justify-content-center align-items-end'>
              <fa-icon [icon]='["fas", "exclamation-circle"]' [size]='"2x"' class='text-muted'></fa-icon>
            </div>
            <div class='row justify-content-center text-muted'>{{ 'TAGS.MANAGE.NONE' | translate }}</div>
          </div>

          <div *ngIf='formArray.controls.length && mode === TagMode.search'>
            <div id="view-tags">
              <ng-container *ngFor='let tag of formArray.controls | filter: "value.name":manageTag.controls["search"].value | sortBy: "asc":"tag"; let i = index'>
                <div class="view-tag m-2 d-block text-left cursor-pointer no-select" id='{{ "tag-" + i }}' (click)='toggleActiveTag(tag.value)'>
                  <fa-icon [icon]='tag.value.active ? ["fas", "check-circle"] : ["far", "circle"]' size='lg'></fa-icon> {{ tag.value.name }}
                </div>
              </ng-container>
            </div>
          </div>

          <div *ngIf='formArray.controls.length && mode === TagMode.edit'>
            <div *ngFor='let tag of formArray.controls | filter: "value.name":manageTag.controls["search"].value; let i = index' [formGroup]='tag' class='row m-2'>
              <div class="input-group">
                <div class="input-group-prepend">
                  <button id='{{ "btn-delete-tag-" + i }}' class="btn btn-outline-danger p-1" nextConfirmClick (clickConfirmed)="deleteTag(tag.value, i)"></button>
                </div>
                <input id='{{ "input-edit-tag-" + i }}' class='form-control' type='text'
                       [formControlName]='"name"' (change)="saveTag(tag)" value='{{ tag.value.name }}'
                       [mask]='"Z{25}"' [pattern]='pattern["Z"].pattern' [patterns]='pattern' [maxLength]='25'>
                <div class="input-group-append">
                  <span id='{{ "tag-count-" + i }}' class="input-group-text">{{ tag.value.count | number }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="ilh-modal-footer px-3 mt-2 mb-3">
    <div *ngIf="formArray.controls.length && mode === TagMode.search" >
      <button id='btn-apply-tags' class="btn btn-block btn-primary rounded-pill" [disabled]="!this.formArray.dirty"
              (click)="submit()">{{'TAGS.MANAGE.APPLY_TAGS' | translate | uppercase }}</button>
    </div>
  </div>
</div>
