import { BehaviorSubject } from "rxjs";
import { Appointment } from "./patient.model";

export class TodaysAppointments {
  private todayAppointments: TodayAppointment[] = [];
  public selectedAppointmentsChanged = new BehaviorSubject(null);

  set appointments(value: Appointment[]) {
    this.todayAppointments = value?.map(a => new TodayAppointment({
      appointment: a, 
      isSelected: this.todayAppointments.some((pa) => pa.isSelected && pa.appointment.id === a.id)
    })) ?? [];
  }

  get appointments(): Appointment[] {
    return this.todayAppointments.map(a => a.appointment);
  }

  addSelectedTodayAppointment(appointment: Appointment): void {
    const foundAppointment = this.todayAppointments.find(a => a.appointment.id === appointment.id);

    if (foundAppointment) {
      foundAppointment.isSelected = true;
      this.selectedAppointmentsChanged.next(null);
    }
  }

  toggleSelection(appointment: Appointment): void {
    const foundAppointment = this.todayAppointments.find(a => a.appointment.id === appointment.id);

    if (foundAppointment) {
      foundAppointment.isSelected = !foundAppointment.isSelected;
      this.selectedAppointmentsChanged.next(null);
    }
  }

  unselectAll(): void {
    this.todayAppointments.forEach(a => a.isSelected = false);
  }

  get selectedAppointments(): Appointment[] {
    return this.todayAppointments.filter(a => a.isSelected).map(a => a.appointment);
  }
}

export class TodayAppointment {
  appointment: Appointment;
  isSelected: boolean = false;

  public constructor(init?: Partial<TodayAppointment>) {
    Object.assign(this, init);
  }
}
