import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  Attachment,
  DisplayStatus,
  FormFastConfig,
  FormFastConfigService,
  PhotoDTO,
} from '@next/shared/common';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { FieldBaseComponent } from '../field-base/field-base.component';
import {
  HttpProgressService,
  NextImageService,
  NextSubmissionService,
} from '@next/shared/next-services';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpEvent } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

/**
 * This component is reading the experience version id from the route, this is unique to this
 * field of the GX Viewer. */
@Component({
  selector: 'next-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.css'],
  animations: [
    trigger('popupState', [
      state(DisplayStatus.CLOSED, style({ transform: 'translateY(0)' })),
      state(DisplayStatus.OPENED, style({ transform: 'translateY(-100%)' })),
      transition('* => *', animate('800ms ease-in-out'))
    ]),
    trigger('fadeInOut',[
      transition(':enter',
        [ style({opacity: 0 }), animate('400ms ease-in-out', style({opacity: '*' }))]),
      transition(':leave',
        [ style({opacity: '*' }), animate('400ms ease-in-out', style({opacity: 0 }))])
    ])
  ]
})

export class PhotoComponent extends FieldBaseComponent implements OnInit, OnDestroy {
  DisplayStatus: typeof DisplayStatus = DisplayStatus; // Allow this enum to be used on the template

  constructor (
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private submissionSvc: NextSubmissionService,
    private toastSvc: ToastrService,
    private translateSvc: TranslateService,
    private progressSvc: HttpProgressService,
    private imageSVC: NextImageService,
    private router: Router,
    @Inject(FormFastConfigService) public config: FormFastConfig,
  ) {
    super();
  }
  cleanup: Subject<void> = new Subject();

  @Input() formId: string;

  @ViewChild('file') file: ElementRef;
  @ViewChild('image') image: HTMLImageElement;

  b64e: string | ArrayBuffer = '';
  experienceVersionId = '';
  dropzoneActive = false;
  display: DisplayStatus = DisplayStatus.CLOSED;

  loading: boolean = false;
  loadingProgress: number = 100;
  loadingMessage: string = this.translateSvc.instant('PHOTO.PROCESSING');

  async ngOnInit(): Promise<void> {
    this.setFormControl();
    if (this.valueFormGroup.value.FileID) {
      this.loading = true;
      this.b64e = await this.getFile();
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this.cleanup.next();
    this.cleanup.complete();
  }

  /**
   * On input file change, take the new file and
   * set it as this component's data-uri.
   *
   * @param {FileList} fList - The Input[type=file] value
   */
  async onChange(fList: FileList | File[]): Promise<void> {

    // If no file was provided, change the field to blank.
    if (!fList?.length) {
      this.b64e = '';
      this.file.nativeElement.files = null;
      this.file.nativeElement.value = null;
    }
    // If invalid file format, reject and change the field to blank.
    else if(!this.imageSVC.isValidFileType(fList[0])) {
      this.toastSvc.error(this.translateSvc.instant('PHOTO.INVALID_FORMAT'), '', {disableTimeOut: true});
      this.b64e = '';
      this.file.nativeElement.files = null;
      this.file.nativeElement.value = null;
    }

    // If file size exceeds environment limit, reject and change the field to blank.
    else if (super.bytesToMB(fList[0].size) > this.config.maxUploadFileSizeMB) {
      this.toastSvc.error(
        this.translateSvc.instant('PHOTO.EXCEEDS_PAYLOAD_MESSAGE', { max: this.config.maxUploadFileSizeMB} ),
        this.translateSvc.instant('PHOTO.EXCEEDS_PAYLOAD_TITLE') , {disableTimeOut: true}
      );
      this.b64e = '';
      this.file.nativeElement.files = null;
      this.file.nativeElement.value = null;
    }

    // Valid files are converted to JPEG and compressed.
    else {
      this.loadingMessage = this.translateSvc.instant('PHOTO.IMG_PROCESSING');
      this.loadingProgress = 1;
      this.loading = true;

      const compressed = await this.compressImage(fList[0], this.field);
      [ this.b64e ] = await this.fileToDataURI(compressed);

      this.loadingProgress = 100;
      this.loadingMessage = this.translateSvc.instant('PHOTO.IMG_COMPLETE');
      setTimeout(() => {
        this.loading = false
      }, 1800);
    }
    // In all circumstances mark the field as dirty.
    this.valueFormGroup.get('FileID').markAsDirty();
  }

  /**
   * If an existing file-id exists in the form then
   * delete that entry, then create the new file
   * and insert a new form history record using
   * the newly generated file-id.
   *
   */
  async onSubmit(): Promise<void> {
    if (this.valueFormGroup.value.FileID) {
      await this.deleteFile();
    }
    if (this.b64e) {
      await this.createFile();
    }
  }

  /**
   * Handler for rotate button click event.
   */
  async onRotate(): Promise<void> {
    await this.rotateFile();
    this.valueFormGroup.get('FileID').markAsDirty();
  }

  /**
   * Opens the popup dialog
   */
  openPopup(): void {
    this.display = DisplayStatus.OPENED;
  }

  /**
   * Submit data and close the popup.  Only submit if the form is dirty.
   */
  async onClosePopup(): Promise<void> {
    if (this.valueFormGroup.get('FileID').dirty) {
      await this.onSubmit();
    }
    else {
      this.display = DisplayStatus.CLOSED;
      this.valueFormGroup.markAsPristine();
    }
  }

  /**
   * Fetch the requested file using this form-id
   * and the file-id stored in the web form.
   *
   */
  async getFile(): Promise<string | ArrayBuffer> {
    const attachment: Attachment = await this.submissionSvc.getAttachment(this.valueFormGroup.value.FormID, this.valueFormGroup.value.FileID).toPromise();
    const response: Response = await fetch(attachment.signedUrl);
    const blob: Blob = await response.blob();
    const [ uri ]: (string | ArrayBuffer)[] = await this.fileToDataURI(blob);
    return uri;
  }

  /**
   * - Insert a new file into file API and
   * insert a new form history record
   * reflecting the change.
   *
   * ___UploadAttachment()___ returns request progress event
   * Monitor event for load bar display
   *
   * - When complete, Photo element emits event back to viewer-web.component.ts
   *   _The Callback for this event will then PATCH the form_
   * @returns - Attachment Response in promise */
  async createFile(): Promise<void> {

    /** Helper object to update this component's progress bar overlay
     * - setStart()
     * - setComplete()
     ************************************/
    const utility = {
      /** - Start a subscription to the http-progress service progress property
        * - Use this progress value to set the progress bar display, only subscribe
       * for the life of a single listener instance */
      setStart: () => {
        this.loading = true;
        this.loadingProgress = 0;
        this.loadingMessage = this.translateSvc.instant('PHOTO.UPLOADING');
        this.progressSvc.progress$.pipe(
          takeUntil(this.progressSvc.completed)
        ).subscribe((n: number) => {
          switch (n) {
            case 0:
              break;  // Swallow start event because no CB is necessary
            case 100:
              this.loadingProgress = 100;
              this.loadingMessage = this.translateSvc.instant('PHOTO.UPLOADED');
              break;
            default:
              this.loadingMessage = this.translateSvc.instant('PHOTO.UPLOADING');
              this.loadingProgress = Math.floor(n);
          }
        });
      },

      /** - Modify the component properties to present the completed progress bar for 2100ms
       *  - Update the route with the Form ID query parameter, and the Form Data from response */
      setComplete: (e) => {
        this.formId = this.formId || e.body.formid;

        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { formId: this.formId },
          queryParamsHandling: 'merge',
          skipLocationChange: false
        });

        this.valueFormGroup.patchValue({
          ['FileID']: e.body.fileid,
          ['FormID']: this.formId,
        }, { emitEvent: true, onlySelf: false });

        this.field['formId'] = this.formId;
        this.valueChanged.emit(this.field);

        setTimeout(() => {
          this.loading = false;
          this.display = DisplayStatus.CLOSED;
          this.valueFormGroup.markAsPristine();
          return e.body;
        }, 1800);
      }
    }

    // Start the utility
    utility.setStart();
    const file = await this.dataURIToFile(this.b64e);
    const body = { file: file, meta: { client: null, page: 0 } };
    const upload$: Observable<HttpEvent<any>> = this.submissionSvc.uploadAttachment(this.formId, this.field.name, this.experienceVersionId, body);
    upload$.pipe().subscribe((ev: HttpEvent<unknown>) => {
      utility.setComplete(ev);
    });
  }

  /**
   * Remove an attachment, this will delete the file from storage and
   * removing references to it in the current form-history. */
  async deleteFile(): Promise<void> {
    // TODO: Present spinner while deleting, and close photo popup on complete
    if (this.valueFormGroup.value.FileID) {
      const [ , deleteError ] = await this.promiseHandler(
        this.submissionSvc.deleteAttachment(this.valueFormGroup.value.FormID, this.valueFormGroup.value.FileID).toPromise()
      );
      if (deleteError) {
        this.toastSvc.error(deleteError.message, '', {disableTimeOut: true});
        return Promise.resolve(null);
      }
      this.valueFormGroup.patchValue({
        ['FileID']: '',
        ['FormID']: this.formId
      }, { emitEvent: true, onlySelf: false });
    }
    if (!this.b64e) {
      this.loading = false;
      this.display = DisplayStatus.CLOSED;
      this.valueFormGroup.markAsPristine();
      return null;
    }
  }

  /**
   * Simply remove the data uri, onSubmit() will
   * detect changes and delete the existing file
   * on the server if required.
   *
   * Remove FileList from file view-child */
  clearImage(): void {
    this.b64e = '';
    this.file.nativeElement.files = null;
    this.file.nativeElement.value = null;
  }

  /**
   * Take this component's data uri and rotate
   * it 90 degrees CW
   *
   * Apply the original uri mime-type to .toDataURL()
   * to keep consistent the file type. */
  async rotateFile(): Promise<void> {
    try {
      const mimeType = this.getDataUriMimeType(this.b64e as string);
      this.b64e = await new Promise((resolve, reject) => {
        const i: HTMLImageElement = new Image();
        i.src = this.b64e as string;
        i.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = i.height;
          canvas.height = i.width;
          canvas.style.position = 'absolute';
          const ctx = canvas.getContext('2d');
          ctx.translate(i.height, i.width / i.height);
          ctx.rotate(Math.PI / 2);
          ctx.drawImage(i, 0, 0);
          resolve(canvas.toDataURL(mimeType));
        }
        i.onerror = reject;
        i.onabort = reject;
      });
    }
    catch (error) {
      this.toastSvc.error(error.message, '', {disableTimeOut: true});
    }
  }

  /**
   *
   * @param f {File} - the image File to convert to jpg and lossy compress.
   * @param field {Object} - the field associated to this file.
   * @returns - Returns a compressed image File (jpg).
   *
   *                 - Jpeg is compressed below 3.5mb in size (lossy).
   *                 - Or image resolution has been reduced by half.
   *                 - Compression performed with HTML5Canvas.toDataURI().
   * @private */
  private async compressImage(f: File, field: PhotoDTO): Promise<File> {
    let out: File = f;
    let sizer = 1;
    do {
      const [ uri, w, h ] = await this.fileToDataURI(out, sizer);
      const compressedUri = await this.uriToCompressedUri(uri, w, h);
      const r: Response = await fetch(compressedUri);
      const a: ArrayBuffer = await r.arrayBuffer();
      out = new File([a], field.name + '.jpeg', { type: 'image/jpeg' });
      sizer += 0.125;
      this.loadingProgress = this.loadingProgress + 20;
    } while (out.size / 1000000 > 3.5 && sizer <= 1.5);
    return out;
  }

  /**
   * - Helper function for compressImage().
   *
   * - Creates a new data URI image using HTML5 Canvas.
   *
   * - Applies a white background in case the original image has an alpha,
   * and applies 50% image quality compression (lossy).
   *
   * @param {string} uri - data uri to compress
   * @param {number} width - dimensions : target width
   * @param {number} height - dimensions : target height
   * @returns {Promise<string>} - data uri promise
   * @private */
  private async uriToCompressedUri(uri, width, height): Promise<string> {
    return new Promise(((resolve, reject) => {
      const i = document.createElement('img');
      i.crossOrigin = 'anonymous';
      i.src = uri;
      i.onerror = () => reject(new Error());
      i.onabort = () => reject(new Error('Read aborted'));
      i.onload = async () => {
        const cvs = document.createElement('canvas');
        const ctx = cvs.getContext('2d');
        cvs.width = width;
        cvs.height = height;

        // Apply a white background to the canvas.
        // This is done just in case the original image has an alpha channel
        ctx.fillStyle = '#FFF';
        ctx.fillRect(0, 0, width, height);

        // Draw the original image onto the canvas
        ctx.drawImage(i, 0, 0, width, height);
        ctx.save();

        // Convert to jpeg and apply image quality reduction (lossy)
        const uriCompressed = cvs.toDataURL('image/jpeg', 0.5);
        resolve(uriCompressed);
      };
    }));
  }

  /**
   * Helper function for compressImage(), returns image with
   * new width and height.
   *
   * @param b - file or blob
   * @param sizer - resolution reduction factor
   * @returns Array - First element as content, second as content width, third as content height
   * @private */
  private async fileToDataURI(b: Blob | File, sizer: number = 1): Promise<any[]> {
    return new Promise(((resolve, reject) => {
      const r = new FileReader();
      r.readAsDataURL(b);
      r.onloadend = (e) => {
        const content = e.target.result;
        const img = document.createElement('img');
        img.src = content as string;
        img.onload = () => {
          const w = img.width / sizer;
          const h = img.height / sizer;
          resolve([content, w, h])
        }
      };
      r.onerror = () => reject(r.error);
      r.onabort = () => reject(new Error('Read aborted'));
    }));
  }

  /**
   * Convert data uri into File object
   *
   * @param {string | any} b - the data uri to convert
   * @returns {File} - The result file */
  async dataURIToFile(b: any): Promise<File> {
    const mimeType: string = this.getDataUriMimeType(b)
    const f: Response = await fetch(b);
    const a: ArrayBuffer = await f.arrayBuffer();
    return new File([a], this.field.name + super.getFileExtension(mimeType), { type: mimeType });
  }

  /** Get the experience version id, will need it to submit
   * the form data on upload event
   *
   * Define the form-group object if it was not created
   * on Viewer load, set component variables for this form
   * control and this value form-group control */
  private setFormControl(): void {
    this.route.data.pipe(
      takeUntil(this.cleanup),
      tap(data => {
        this.experienceVersionId = data.experience.vid;
      })
    ).subscribe();
    this.route.queryParams.pipe(
      takeUntil(this.cleanup),
      tap(params => {
        this.formId = params.formId;

        if (!this.form.contains(this.field.name)) {
          this.form.addControl(this.field.name, this.fb.group({
            Type: 'File',
            Value: this.fb.group({
              FileID: this.field.required ? ['', [Validators.required]] : [''],
              FormID: this.formId,
            })
          }));
        }
        this.valueFormGroup = this.form.get(this.field.name).get('Value') as UntypedFormGroup;
      })
    ).subscribe();
  }

  /** Wrapper for promises.  Will return resolved errors to drop the
   * promise rejection, such that only the original Error itself is
   * returned.  This allows more robust async/await Error handling.
   *
   * @param promise - The promise to extend.
   *
   * @returns - A two element array with the resolved value as the first element
   *            and undefined as the second, or undefined as the first element
   *            and the Error as the second.
   *          - Example: [any, undefined] or [undefined, Error] */
  private promiseHandler = (promise:Promise<any>) => {
    return promise
      .then(result => ([result, undefined]))
      .catch(error => Promise.resolve([undefined, error]))
  }

  /** Parse a data URI for its mime type.
   * @param {String} uri - A base 64 encoded data URI.
   * @returns {String} - mime type */
  private getDataUriMimeType = (uri: string): string => {
    const header: string = uri.split(',')[0];
    return header.substring(header.indexOf(':') + 1, header.length + 1).split(';')[0];
  };

  /**************************
   * Drag and Drop Handlers *
   **************************/
  onDrop = async (event) => {
    event.preventDefault();
    if (event.dataTransfer.items) {
      const file: File = event.dataTransfer.items[0].getAsFile();
      await this.onChange([file]);
    }
    this.dropzoneActive = false;
  }

  onDragOver = (event) => {
    event.preventDefault();
    this.dropzoneActive = true;
  }

  onDragLeave = () => {
    this.dropzoneActive = false;
  }
}
