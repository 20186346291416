import { Injectable } from '@angular/core';
import {
  FormDTO,
  FormSubmission,
  GuidedExperienceDTO,
  SelectedForms,
  SignQueue,
} from '@next/shared/common';
import { BehaviorSubject, Observable } from 'rxjs';

export interface RenderedForm {
  formData: any,
  experience: GuidedExperienceDTO,
  submission: FormSubmission,
  currentForm: FormDTO
}

export enum ColumnExpanded {
  Center = 'ColumnCENTER'
}

export interface AutoRefreshSettings {
  lastRefreshedAt: number,
  refreshInterval: number,
}

@Injectable({
  providedIn: 'root'
})
export class StateViewerService {
  public columnExpandedStates: { ColumnCENTER: boolean } = {
    ColumnCENTER: !((window.innerWidth < window.innerHeight) && (window.innerWidth < 1024)) // Default determined by screen size
  };

  private _formSavedFromDataSet = new BehaviorSubject(false);

  private readonly _facilityUpdated = new BehaviorSubject<boolean>(false);
  readonly facilityUpdated$ = this._facilityUpdated.asObservable();

  private readonly _autoRefreshSettings = new BehaviorSubject<AutoRefreshSettings>({
    refreshInterval: 5,// Default refresh interval
    lastRefreshedAt: 0,// full fetch
  });
  readonly autoRefreshSettings$ = this._autoRefreshSettings.asObservable();

  public moveToNextForm = new BehaviorSubject<FormDTO>(null);

  public readonly _signQueue = new BehaviorSubject<SignQueue>(new SignQueue([]));

  set signQueue(val: SignQueue) {
    this._signQueue.next(val);
  }

  get signQueue(): SignQueue {
    return this._signQueue.getValue();
  }

  private readonly _selectedForms = new BehaviorSubject<SelectedForms>(new SelectedForms([]));
  readonly selectedForms$ = this._selectedForms.asObservable();

  set selectedForms(selectedForms: SelectedForms) {
    this._selectedForms.next(selectedForms);
  }

  get selectedForms(): SelectedForms {
    return this._selectedForms.getValue();
  }

  private readonly _selectedFormsFromMoveFormsModal = new BehaviorSubject<FormDTO[]>([]);
  set selectedFormsFromMoveFormsModal(selectedForms: FormDTO[]) {
    this._selectedFormsFromMoveFormsModal.next(selectedForms);
  }
  get selectedFormsFromMoveFormsModal(): FormDTO[] {
    return this._selectedFormsFromMoveFormsModal.getValue();
  }

  public viewMode$ = new BehaviorSubject<string>('');

  public isPatientView$ = new BehaviorSubject(false);

  public RequiredClinicianSignatures = new BehaviorSubject([]);

  private readonly _allSignature = new BehaviorSubject<number>(null);
  readonly signature$ = this._allSignature.asObservable();

  set allSignature(val: number) {
    this._allSignature.next(val);
  }

  get allSignature(): number {
    return this._allSignature.getValue();
  }

  private readonly _dataset = new BehaviorSubject<any>(null);
  readonly dataset$ = this._dataset.asObservable();

  set dataset(val: any) {
    this._dataset.next(val);
  }

  get dataset(): any {
    return this._dataset.getValue();
  }

  set lockedMode(val: boolean) {
    val ? sessionStorage.setItem('lockedMode', 'true'): sessionStorage.removeItem('lockedMode') ;
  }

  get lockedMode(): boolean {
    return !!sessionStorage.getItem('lockedMode');
  }

  set viewMode(val: boolean) {
    val ? sessionStorage.setItem('viewMode', 'true'): sessionStorage.removeItem('viewMode') ;
  }

  get viewMode(): boolean {
    return !!sessionStorage.getItem('viewMode');
  }

  private readonly _showFacilityMenu = new BehaviorSubject(true);
  readonly showFacilityMenu$ = this._showFacilityMenu.asObservable();

  set showFacilityMenu(val: boolean) {
    this._showFacilityMenu.next(val);
  }

  get showFacilityMenu(): boolean {
    return this._showFacilityMenu.getValue();
  }

  private readonly _promptAccessCode = new BehaviorSubject(false);
  readonly promptAccessCode$ = this._promptAccessCode.asObservable();

  set promptAccessCode(val: boolean) {
    this._promptAccessCode.next(val);
  }

  get promptAccessCode(): boolean {
    return this._promptAccessCode.getValue();
  }

  private readonly _disableParameterModal = new BehaviorSubject(false);
  readonly disableParameterModal$ = this._disableParameterModal.asObservable();

  set disableParameterModal(val: boolean) {
    this._disableParameterModal.next(val);
  }

  get disableParameterModal(): boolean {
    return this._disableParameterModal.getValue();
  }

  private readonly _useTopazDevice = new BehaviorSubject(false);
  readonly useTopazDevice$: Observable<boolean> = this._useTopazDevice.asObservable();

  set useTopazDevice(val: boolean) {
    this._useTopazDevice.next(val);
  }

  get useTopazDevice(): boolean {
    return this._useTopazDevice.getValue();
  }

  private readonly _shouldAutoSaveRecord = new BehaviorSubject(false);
  readonly shouldAutoSaveRecord$ = this._shouldAutoSaveRecord.asObservable();

  set shouldAutoSaveRecord(val: boolean) {
    this._shouldAutoSaveRecord.next(val);
  }

  get shouldAutoSaveRecord(): boolean {
    return this._shouldAutoSaveRecord.getValue();
  }

  public readonly _renderedForm: BehaviorSubject<RenderedForm> = new BehaviorSubject(null);
  readonly renderedForm$: Observable<RenderedForm> = this._renderedForm.asObservable();

  set renderedForm(val: RenderedForm) {
    this._renderedForm.next(val);
  }

  get renderedForm(): RenderedForm {
    return this._renderedForm.getValue();
  }

  clearViewerState() {
    this.signQueue.tasksToSign = [];
    this.selectedForms.resetAllFormsToNotProcessed();
  }

  set favoriteFilter(val: boolean) {
    val ? sessionStorage.setItem('favoriteFilter', 'true'): sessionStorage.removeItem('favoriteFilter') ;
  }

  get favoriteFilter(): boolean {
    return !!sessionStorage.getItem('favoriteFilter');
  }

  set facilityUpdated(val: boolean) {
    this._facilityUpdated.next(val);
  }

  get facilityUpdated(): boolean {
    return this._facilityUpdated.getValue();
  }

  set formSavedFromDataSet(val: boolean) {
    this._formSavedFromDataSet.next(val);
  }

  get formSavedFromDataSet(): boolean {
    return this._formSavedFromDataSet.getValue();
  }

  set showAllForms(val: boolean) {
    val ? sessionStorage.setItem('showAllForms', 'true'): sessionStorage.removeItem('showAllForms') ;
  }

  get showAllForms(): boolean {
    return !!sessionStorage.getItem('showAllForms');
  }

  set autoRefreshSettings(autoRefreshSettings: AutoRefreshSettings) {
    this._autoRefreshSettings.next(autoRefreshSettings);
  }

  get autoRefreshSettings(): AutoRefreshSettings {
    return this._autoRefreshSettings.getValue();
  }
}
