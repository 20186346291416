<div
  class="btn-group"
  dropdown
  #dropdown='bs-dropdown'
  [container]='appendTo'
  (onShown)='dropdownOpen()'
  (onHidden)="onDropdownClose()"
  [insideClick]='true'
  [isAnimated]='true'
  [autoClose]='false'>
  <div role="button" id="btn-tags-dropdown" dropdownToggle tabindex="0" (keyup.enter)="dropdown.show()"
       class="cursor-pointer color-primary tab-focus-visible">
    <fa-icon [icon]='["fas", "tag"]' [size]="iconSize" class='tag-toggle-icon'></fa-icon>
  </div>
  <div *dropdownMenu class='dropdown-menu-tags dropdown-menu' [class.dropdown-menu-right]="placementLeft">
    <div class="h-100">
      <div class="ilh-modal-header d-flex flex-column p-3 bg-header">
        <div class="d-flex align-content-center mb-3">
          <div class="col px-0">
            <button id="btn-modal-close" class="btn btn-sm btn-outline-secondary rounded-pill p-0 px-1" (click)="close()">{{ 'TAGS.SEARCH.CANCEL' | translate }}</button>
          </div>
          <div class="center">
            <h5 id="modal-title" class="m-0 p-0">{{'TAGS.SEARCH.TITLE' | translate }}</h5>
          </div>
          <div class="col px-0 text-right"></div>
        </div>

        <div class="input-group input ilh-input-group">
          <div class="input-group-prepend ilh-input-side">
            <span class="input-group-text">
              <fa-icon id="search-icon" class='ilh-bar-child text-muted' [icon]='["fas", "search"]'></fa-icon>
            </span>
          </div>

          <input id="input-search-tags" type="text" class="form-control ilh-input-field" [(ngModel)]='searchFilter'
                 placeholder="{{'TAGS.SEARCH.INPUT_PLACEHOLDER' | translate}}" [maxLength]='25' nextAutoFocusElement>

          <div class="input-group-append ilh-input-side" *ngIf="searchFilter.length">
            <span class="input-group-text">
              <fa-icon id="search-reset" class="ilh-bar-child text-muted cursor-pointer"
                       [icon]="['far', 'times-circle']" (click)="clearQuery()"></fa-icon>
            </span>
          </div>
        </div>
      </div>

      <div class="ilh-modal-body px-3 mt-2 ilh-modal-body-search-tags">
        <div class='d-block row manage-tags-body'>
          <div class='container-fluid m-0 p-0 h-100'>
            <div id="list-available-tags" class='mx-3 border-top border-bottom rounded scroll-area bg-light'>
              <div id="no-tags" *ngIf='!allTags.length' class='container my-2 justify-content-center align-items-center h-100'>
                <div class='row h-25 justify-content-center align-items-end'>
                  <fa-icon [icon]='["fas", "exclamation-circle"]' [size]='"2x"' class='text-muted'></fa-icon>
                </div>
                <div class='row justify-content-center text-muted'>{{ 'TAGS.MANAGE.NONE' | translate }}</div>
              </div>

              <div *ngIf='allTags.length'>
                <div id="view-tags">
                  <ng-container *ngFor='let tag of allTags | filter: "tag":searchFilter | sortBy: "asc":"tag"; let i = index'>
                    <div class="view-tag m-2 d-block text-left cursor-pointer no-select" id='{{ "tag-" + i }}' (click)='onTagClick(tag)'>
                      <fa-icon [icon]='isActive(tag) ? ["fas", "check-circle"] : ["far", "circle"]' size='lg'></fa-icon> {{ tag.tag }}
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="ilh-modal-footer px-3 mt-2 mb-3">
        <div *ngIf='allTags.length'>
          <button id='btn-apply-tags' class="btn btn-block btn-primary rounded-pill" [disabled]="false"
                  (click)="submit()">{{'TAGS.SEARCH.SUBMIT' | translate | uppercase }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
