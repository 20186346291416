import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NextFormService } from '@next/shared/next-services';
import { FormsUtilityService } from "../services/forms-utility.service";
import { Router } from "@angular/router";
import { StoreService } from "../state/store.service";
import { TranslateService } from "@ngx-translate/core";
import { AppointmentFormsAccordionComponent, AppointmentFormsSelected } from "../appointment-forms-accordion/appointment-forms-accordion.component";
import { StateViewerService } from '../state/state-viewer.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AppointmentForms, FormDTO, FormType, Patient } from "@next/shared/common";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { FlaggedFormsService } from "../services/flagged-forms.service";
import { MoveFlaggedFormsModalComponent } from "../modals/move-flagged-forms-modal/move-flagged-forms-modal.component";
import { mergeMap, takeUntil } from "rxjs";

@Component({
  selector: 'next-appointment-flagged-forms-accordion',
  templateUrl: './appointment-flagged-forms-accordion.component.html',
  styleUrls: ['../appointment-forms-accordion/appointment-forms-accordion.component.scss',
    './appointment-flagged-forms-accordion.component.scss'
  ]
})
export class AppointmentFlaggedFormsAccordionComponent extends AppointmentFormsAccordionComponent implements OnInit, OnDestroy {

  protected readonly FormType = FormType;
  @Input() openedFrom: FormType;
  @Input() selectedPatient: Patient;
  @Input() flaggedFormsForPatient: FormDTO[];
  @Input() appointmentsWithFlaggedForms: AppointmentForms[];
  hasFlaggedFormsForAppointments = {};

  constructor (
    protected formSvc: NextFormService,
    protected formsUtilSvc: FormsUtilityService,
    protected router: Router,
    protected storeSvc: StoreService,
    protected translateSvc: TranslateService,
    protected stateViewerSvc: StateViewerService,
    private toastSvc: ToastrService,
    private modalSvc: BsModalService,
    private flaggedFormsSvc: FlaggedFormsService
  ) {
    super(
      formSvc,
      formsUtilSvc,
      router,
      storeSvc,
      translateSvc
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkFlaggedFormsForAppointments();
  }

  checkFlaggedFormsForAppointments() {
    this.triggerFlaggedFormsCheckForAppointments.pipe(
      takeUntil(this.observerCleanup$)
    ).subscribe(() => {
      this.hasFlaggedFormsForAppointments = {}
      this.appointments.forEach(appointment => {
        this.hasFlaggedFormsForAppointments[appointment.id] = this.flaggedFormsSvc.checkHasFlaggedFormsToMove(
          this.flaggedFormsForPatient, this.appointmentsWithFlaggedForms, this.selectedPatient, appointment
        );
      });
    });
  }

  showFlaggedForms(openedFrom: FormType, event: any, appointmentForm: AppointmentFormsSelected): void {
    event?.stopPropagation();
    const config: ModalOptions = {
      class: 'modal-xl',
      backdrop: true,
      ignoreBackdropClick: false,
      initialState: { openedFrom: openedFrom, selectedPatient: this.selectedPatient, excludeFormsForAppointmentId: appointmentForm.appointment.id }
    };

    const modalRef = this.modalSvc.show(MoveFlaggedFormsModalComponent, config);
    modalRef.content.modalClose.pipe(
      takeUntil(this.observerCleanup$)
    ).subscribe(() => modalRef.hide());

    modalRef.content.modalFormsToMove.pipe(
      takeUntil(this.observerCleanup$),
      mergeMap((formsToMove: FormDTO[]) => {
        const patientId = appointmentForm.appointment.patientdata.id;
        const appointmentId = appointmentForm.appointment.id;
        return this.formSvc.moveFlaggedForms(patientId, appointmentId, formsToMove.map((form) => form.id));
      })
    ).subscribe({
      next: () => {
        modalRef.hide();
        this.updateFormsForPatientAndAppointments();
      },
      error: () => {
        this.toastSvc.error(this.translateSvc.instant('APPOINTMENTS.TOASTR_MESSAGE.MOVE_FLAGGED_FORMS_ERROR'), '', {disableTimeOut: true});
      }
    });
  }

  displayMoveButton(appointmentForm: AppointmentFormsSelected): boolean {
    return this.stateViewerSvc.selectedForms.forms.length > 0 && !appointmentForm.forms.some(form =>
      this.stateViewerSvc.selectedForms.forms.some(selectedForm => selectedForm.id === form.id));
  }

  displayFlaggedIcon(appointmentForm: AppointmentFormsSelected): boolean {
    return appointmentForm.forms.some(form => form.tomovelater);
  }

  moveFlaggedFormsToAppointment(appointmentForm: AppointmentFormsSelected): void {
    const patientId = appointmentForm.appointment.patientdata.id;
    const appointmentId = appointmentForm.appointment.id;

    this.formSvc.moveFlaggedForms(patientId, appointmentId, this.stateViewerSvc.selectedForms.forms.map((form) => form.id)).pipe(
      first()).subscribe({
      next: () => {
        this.updateFormsForPatientAndAppointments();
      },
      error: () => {
        this.toastSvc.error(this.translateSvc.instant('APPOINTMENTS.TOASTR_MESSAGE.MOVE_FLAGGED_FORMS_ERROR'), '', {disableTimeOut: true});
      }
    });
  }

  private updateFormsForPatientAndAppointments(): void {
    const updatedForms = this.stateViewerSvc.selectedForms;
    updatedForms.EmptyAll();
    this.stateViewerSvc.selectedForms = updatedForms;
    this.stateViewerSvc.selectedFormsFromMoveFormsModal = [];
    this.storeSvc.searchedForms.flaggedFormsMovedFromAppointments$.next();
    this.storeSvc.searchedForms.flaggedFormsMovedFromPatientRecord$.next();
  }
}
