<div class="form-group rounded" [ngClass]="{'d-none' : field.hidden}" [formGroup]="valueFormGroup">
  <label for="{{field.name}}" class="m-0 field_text">
    {{field.text}}
    <span *ngIf="field.required" class="required">*</span>
  </label>
  <div [ngClass]="{'invalid' : needsValidation && valueFormGroup?.get('Text').errors?.required}" class='pb-1'>
    <fieldset [disabled]="field.readonly">
      <legend></legend>
      <div *ngFor="let option of field.options; let optionIndex = index; let lastItem=last" id="{{field.name}}">
        <label for="{{ field.name }}{{ optionIndex }}" class='d-inline-flex align-items-center py-2 w-100'>
          <input type="radio" id="{{ field.name }}{{ optionIndex }}" value="{{option.value}}" formControlName="Text" [required]="field.required" class='mr-2 mr-md-2' (change)='onChange()'>
          <span style='width:calc(100% - 80px)'>{{ option.text }}</span>
        </label>
        <span *ngIf="lastItem">{{turnAnimationsOn()}}</span>
      </div>
    </fieldset>
  </div>

  <!-- Validation Error Message -->
  <span *ngIf="needsValidation" class="float-right text-sizing color-danger">
    <span *ngIf="valueFormGroup?.get('Text').errors?.required">{{ 'REQUIRED_FIELD' | translate }}</span>
  </span>

  <div *ngIf="getFieldValue(field)">
    <div *ngFor="let subField of getSwitchFields(getFieldValue(field))" [@subField]="animateState">
      <next-viewer-element [form]="form" [field]="subField" [attachments]="attachments" [initialState]="initialState" [needsValidation]="needsValidation" (valueChanged)="emitValueChanged($event)"></next-viewer-element>
    </div>
  </div>

</div>
