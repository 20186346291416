import {Component, Input, Output, EventEmitter, ViewChild, HostListener} from '@angular/core';
import { TagService } from '@next/shared/next-services'
import { ToastrService } from "ngx-toastr";
import { Observable, forkJoin } from 'rxjs';
import { Tag, TagJunction } from '@next/shared/common';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

@Component({
  selector: 'next-tag-search',
  templateUrl: './tag-search.component.html',
  styleUrls: ['./tag-search.component.scss'],
})
export class TagSearchComponent {
  @ViewChild('dropdown') dropdown: BsDropdownDirective

  @Input() iconSize: SizeProp = '1x';
  @Input() appendTo: string = 'body';                       // The element to bind dropdown menu to, useful when dropdown is in a modal
  @Input() activeTags: Tag[] = [];                          // active tags provided by parent
  @Input() placementLeft: boolean = false;
  @Output() tagSearch: EventEmitter<{ activeTags: Tag[], experienceIds: Set<string> }> = new EventEmitter<{ activeTags: Tag[], experienceIds: Set<string> }>();

  searchFilter: string = '';    // The search filter 2-way data-bind
  allTags: Tag[] = [];          // All tags in the system
  selectedTags: Tag[] = [];     // Active tags scoped to only while dropdown is open
  shouldReactToKeyPressEvent = false;

  @HostListener('document:keyup.enter', ['$event'])
  onEnterPressed = () => {
    if (this.dropdown.isOpen && this.shouldReactToKeyPressEvent) {
      void this.submit();
    }
  }

  @HostListener('document:keyup.escape', ['$event'])
  onEscapePressed = () => {
    if (this.dropdown.isOpen && this.shouldReactToKeyPressEvent) {
      this.close();
    }
  }

  constructor (
    private tagSvc: TagService,
    private toastrSvc: ToastrService) { }

  /**
   * Dropdown event handler, called when
   * the dropdown element is opened.  Retrieves
   * all tags and marks those that are active
   *
   */
  dropdownOpen(): void {
    this.selectedTags = [];

    this.tagSvc.getAllTags().subscribe(tags => {
      this.allTags = tags;
      for (const tag of this.activeTags) {
        this.selectedTags.push(tag);
      }

      setTimeout(() => {
        // when enter-key is pressed on tag icon (from search), onEnterPressed is called twice (b/c onShown event is triggered first)
        this.shouldReactToKeyPressEvent = true;
      }, 250);
    });
  }

  isActive(t: Tag): boolean {
    return (this.selectedTags.find(s => s.id === t.id) !== undefined);
  }

  /**
   * Add or remove tag from selected tags
   *
   * @param t {Tag} - The tag that was clicked
   */
  onTagClick(t: Tag): void {
    const idxInSelectedTagsArray = this.selectedTags.findIndex(s => s.id === t.id);
    if (idxInSelectedTagsArray >= 0) {
      this.selectedTags.splice(idxInSelectedTagsArray, 1);
    } else {
      this.selectedTags.push(t);
    }
  }

  /**
   * Submit() next-tag-search two values
   * It emits the ids of the referenced items to view
   * and it emits the tags that are active
   *
   * @fires updatedActiveTags: {Object} - Tags applied and experience ids to show
   */
  async submit(): Promise<void> {
    try {
      if(this.selectedTags.length > 0) {
        this.activeTags = this.selectedTags;
      }

      const experiences = [];
      const obs: Observable<TagJunction[]>[] = [];
      for (const t of this.activeTags) {
        obs.push(this.tagSvc.getTagToElement(t.id));
      }

      const junctions = await forkJoin(obs).toPromise() || [[]];

      //when multiple tags are selected, element should be available in all response arrays
      if(this.activeTags.length > 1) {
        let res = junctions[0];
        for(let i = 1; i < junctions.length; i++) {
          res = this.intersection(res, junctions[i]);
        }

        for (const junction of res) {
          experiences.push(junction.elementid)
        }
      } else {
        for (const tags of junctions) {
          for (const junction of tags) {
            experiences.push(junction.elementid)
          }
        }
      }

      const distinct: Set<string> = new Set(experiences);
      this.tagSearch.emit({ activeTags: this.activeTags, experienceIds: distinct });
    } catch (e) {
      this.toastrSvc.error(e.message, '', {disableTimeOut: true});
    } finally {
      this.dropdown.hide();
    }
  }

  intersection = (arr1: TagJunction[], arr2: TagJunction[]) => {
    const res = [];
    for(let i = 0; i < arr1.length; i++) {
       if(arr2.find(x => x.elementid === arr1[i].elementid))
         res.push(arr1[i]);
    }
    return res;
 };

  close(): void {
    this.dropdown.hide();
  }

  onDropdownClose() {
    this.shouldReactToKeyPressEvent = false;
  }

  clearQuery() {
    this.searchFilter = '';
  }
}
