import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  NextAdminService,
  NextFormService,
  SignatureService,
  SigWebService,
  UserResolverService,
} from '@next/shared/next-services';
import {
  ConfigType,
  DefaultSignatures,
  FormDTO,
  MMPayloadSignaturePromptAction,
  MultiMonitorService,
  RelationshipListSource,
  SignatureFor,
  SignaturePromptAction,
  SignatureType,
  TaskToSign,
  WindowType,
} from '@next/shared/common';
import SignaturePad from 'signature_pad';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  BehaviorSubject,
  forkJoin,
  lastValueFrom,
  Observable,
  Subject,
} from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Relationship } from '../models/models';
import { TranslateService } from '@ngx-translate/core';
import { TitleCasePipe } from '@angular/common';

// User Enum
export interface User {
  id: string;
  fullName: string;
  lastName: string;
  firstName: string;
}

export interface SignatureProperties {
  enableTypedSignature?: boolean,
  captureSignerName: boolean,
  captureRelationship: boolean,
  signatureFor: string,
  signatureName: string,
  signatureType: SignatureType,
  defaultStatement: string,
  relationshipSource: string,
  pdfSettings: string [],
  title: string,
  lockedFieldNames?: string[]
}

const TOTAL_ATTEMPTS = 10;

@Component({
  selector: 'next-signature-prompt',
  templateUrl: './signature-prompt.component.html',
  styleUrls: ['./signature-prompt.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignaturePromptComponent implements OnInit, AfterViewInit, OnDestroy {
  RelationshipListSource: typeof RelationshipListSource = RelationshipListSource;
  @ViewChild('codeElement') codeElement: ElementRef;
  @ViewChild('drawCanvas') drawCanvas: ElementRef;
  @ViewChild('container') container: ElementRef;

  @Output() exitModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeReassignModal: EventEmitter<string> = new EventEmitter<string>();
  @Output() signature: EventEmitter<any> = new EventEmitter<any>();
  @Output() selfSignSignature: EventEmitter<any> = new EventEmitter<any>();

  @Input() form: FormDTO;
  @Input() taskToSign: TaskToSign;
  @Input() batchSign: boolean = false;
  @Input() signatureProperties: SignatureProperties = {
    captureSignerName: false,
    captureRelationship: false,
    signatureFor: 'other',
    signatureName: '',
    signatureType: SignatureType.DrawnSignature,
    defaultStatement: this.translateSvc.instant('GUIDEDEXPERIENCE.SETTINGS_SIGNATURE_DEFAULT_STATEMENT'),
    relationshipSource: '',
    pdfSettings: [],
    title: ''
  };
  @Input() useTopaz: boolean = false;
  @Input() patientName: string;
  @Input() isPatientView: boolean = false;

  signerID: string;
  users: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  loggedInUser: User = { id: null, fullName: null, lastName: null, firstName: null };
  userList: User[] = [];

  // Signature Canvas Properties
  sigValue: any = null;
  sigName: string = null;
  drawPad: SignaturePad;
  showCanvas = false;

  formId: string = '';
  experienceVersionId: string = '';

  // Access Code Form Control Properties
  remainingAttempts: number = TOTAL_ATTEMPTS;
  codeValue: string = '';
  invalidMatch: boolean = false;
  accessCodeDisabled: boolean = false;
  accessCode: UntypedFormGroup = new UntypedFormGroup({
    ['code']: new UntypedFormControl('', { updateOn: 'change'})
  });

  // Access Code Masking Properties
  hideCode = true;
  masking = 'ZZZZZZZZZZZZZZZZZZZZZZZZ';
  pattern: any = {
    'Z' : { pattern : /\w/, symbol : '•' },
    'Y' : { pattern : /\w/ }
  };
  selectedRelationship: string = '';
  patientUnableToSign: boolean = false;
  relationshipListSource = []
  isTypedSignature: boolean = false;
  observerCleanup$: Subject<void> = new Subject();

  constructor (
    private toastrSvc: ToastrService,
    private formSvc: NextFormService,
    private adminSvc: NextAdminService,
    private signatureSvc: SignatureService,
    public userSvc: UserResolverService,
    private translateSvc: TranslateService,
    private titleCasePipe: TitleCasePipe,
    private sigWebSvc: SigWebService,
    private mmSvc: MultiMonitorService
  ) {
    this.mmSvc.sync.receiveRequestSignaturePromptAction$().pipe(
      filter(() => !this.useTopaz),
      tap((message: MMPayloadSignaturePromptAction) => this.onSignaturePromptAction(message.type, message.data)),
      takeUntil(this.observerCleanup$)
    ).subscribe();
  }

  ngOnInit(): void {
    this.determineRouteVariables();
    this.isTypedSignature = this.setIsTypeSignature();
    forkJoin([
      this.adminSvc.getUsers(), // All System Users
      this.signatureSvc.getAssignedSignatures(this.experienceVersionId, this.formId),
      this.adminSvc.getConfigsByType(ConfigType.AutoSign)
    ]).pipe(
      takeUntil(this.observerCleanup$),
      tap(async ([users, signatures, config ]) => {
        const user = this.userSvc.user;
        this.loggedInUser = { id: user.oid, firstName: user.firstName, lastName: user.lastName, fullName: `${user.firstName} ${user.lastName}`};
        this.userList = users.map(el => ({ id: el.id, firstName: el.firstname, lastName: el.lastname, fullName: `${el.firstname} ${el.lastname}`}));
        this.users.next(this.userList);

        const signatureDTO = (signatures || []).find(fieldDTO => fieldDTO['fieldname'] === this.signatureProperties.signatureName);
        if (signatureDTO) {
          const assignedUser: User = users.find(user => user.id === signatureDTO.assigntoid);
          this.signerID = assignedUser?.id || this.loggedInUser.id;
          this.codeValue = '';
        }
        else {
          this.signerID = this.loggedInUser.id;
        }

        if (config && config[0]?.data.defaultStatement && !this.signatureProperties.defaultStatement) {
          this.signatureProperties.defaultStatement = config[0]?.data.defaultStatement;
        }

        if (this.signatureProperties.captureSignerName && this.signatureProperties.signatureFor === SignatureFor.Patient) {
          this.sigName = this.patientName;
        }
      })
    ).subscribe();

    if (this.signatureProperties.relationshipSource) {
      this.setRelationshipSource(this.signatureProperties.relationshipSource);
    }

    this.showCanvas = this.signatureProperties.signatureFor !== SignatureFor.Staff;
    this.accessCode.valueChanges.subscribe(value => {
      setTimeout(() => {
        this.codeValue = value.code;
        if (!this.codeValue) this.invalidMatch = false;
      });
    });
  }

  onSignaturePromptAction(actionType: SignaturePromptAction, data: any): void {
    switch (actionType) {
      case SignaturePromptAction.CANCEL: {
        this.cancel();
        break;
      }
      case SignaturePromptAction.SIGN: {
        this.updateSignature(data.sigValue, data.canvasWidth, data.canvasHeight);
        break;
      }
      case SignaturePromptAction.CLEAR: {
        this.clearSignature();
        break;
      }
      case SignaturePromptAction.APPLY: {
        if (this.useTopaz) this.sigWebSvc.stop();
        this.exitModal.emit(null);
        break;
      }
      case SignaturePromptAction.RELATION_CHANGE: {
        this.selectedRelationship = data;
        break;
      }
      case SignaturePromptAction.SIGNER_NAME_CHANGE: {
        this.sigName = data;
        break;
      }
    }
  }

  updateSignature(sigValue: any[], prevCanvasWidth: number, prevCanvasHeight: number): void {
    this.sigValue = sigValue;

    const canvas: HTMLCanvasElement = this.drawCanvas.nativeElement;
    const xRatio: number = canvas.width / prevCanvasWidth;
    const yRatio: number = canvas.height / prevCanvasHeight;

    this.resizeData(this.sigValue, xRatio, yRatio);
    this.drawPad.fromData(this.sigValue);
  }

  ngOnDestroy(): void {
    this.observerCleanup$.next();
    this.observerCleanup$.complete();
  }

  setIsTypeSignature() {
    switch(this.signatureProperties.signatureFor) {
      case SignatureFor.Other:
      case SignatureFor.Patient:
        return this.signatureProperties.enableTypedSignature
      case SignatureFor.Staff:
        return this.signatureProperties.signatureType === SignatureType.TypedSignature
    }
  }

  ngAfterViewInit(): void {
    // Set up the canvas if canvas view on start
    // Timeout appears to be needed due to modal drop-in animation
    if (this.showCanvas && !this.batchSign) {
      setTimeout(() => {
        this.setupDrawCanvas(true);
      });
    }
  }

  cancel(): void {
    this.exitModal.emit(null);
    this.signerID = null;
    this.accessCode.patchValue({ ['code']: ''});
    if (!this.batchSign) {
      this.clearSignature();
    }

    if (this.useTopaz) {
      this.sigWebSvc.stop();
    }
    this.mmSvc.sync.sendRequestSignaturePromptAction.toRemote({type: SignaturePromptAction.CANCEL, data: null});
  }

  async sign(code: string = ''): Promise<void> {
    const accessCode: string = code || this.codeValue;
    const signatureType: SignatureType = this.signatureProperties.signatureType || SignatureType.DrawnSignature;
    this.remainingAttempts -= 1;

    if (this.remainingAttempts <= 0) {
      this.accessCodeDisabled = true;
      this.accessCode.patchValue({ ['code']: ''});
      this.accessCode.controls['code'].disable();
      this.signerID = null;
    }

    if (accessCode && this.signerID) {
      const codeApi$ = this.adminSvc.getPreference(this.signerID, 'ACCESSCODE').pipe(
        map(response => response[0].data.ACCESSCODE));
      const serverCode = await lastValueFrom(codeApi$);
      const sigApi$: Observable<any> = this.adminSvc.getPreference(this.signerID, 'DEFAULTSIGNATURES').pipe(
        map((response) => response[0].data));
      const signatureDefaults = await lastValueFrom(sigApi$);

      const signatureValue = this.getSignatureDefaultOfType(signatureDefaults, signatureType);
      signatureValue.Relationship = '';
      signatureValue.Name = this.loggedInUser.fullName;

      if (serverCode === accessCode) {
        const staffSigData = {
          id: this.signerID,
          signatureType: signatureType,
          Name: this.signatureProperties.signatureName,
          CaptureSignerName: this.signatureProperties.captureSignerName,
          CaptureRelationship: this.signatureProperties.captureRelationship,
          Value: signatureValue,
          applySignature: false
        };

        this.signature.emit(staffSigData);
        this.accessCode.patchValue({ ['code']: ''});
        this.exitModal.emit(null);
        this.mmSvc.sync.sendRequestSignaturePromptAction.toRemote({type: SignaturePromptAction.APPLY, data: staffSigData});
      }
      else {
        this.invalidMatch = true;
      }
    }
  }

  async selfSign(): Promise<void> {
    const sigType: SignatureType = this.signatureProperties.signatureType;
    const userOid: string = this.loggedInUser.id;
    const api$: Observable<DefaultSignatures> = this.adminSvc.getPreference(userOid, 'DEFAULTSIGNATURES').pipe(
      map(response => response[0].data as DefaultSignatures));
    const defaultSignatures = await lastValueFrom(api$);

    const signatureValue = this.getSignatureDefaultOfType(defaultSignatures, sigType);
    signatureValue.Relationship = '';
    signatureValue.Name = this.loggedInUser.fullName;

    const selfSigData = {
      id: userOid,
      signatureType: sigType,
      Name: this.signatureProperties.signatureName,
      CaptureSignerName: this.signatureProperties.captureSignerName,
      CaptureRelationship: this.signatureProperties.captureRelationship,
      Value: signatureValue,
      applySignature: false
    };
    this.selfSignSignature.emit(selfSigData);
    this.exitModal.emit(null);
    this.mmSvc.sync.sendRequestSignaturePromptAction.toRemote({type: SignaturePromptAction.APPLY, data: selfSigData});
  }

  async applySignatureAsync(): Promise<void> {

    // If we are using Topaz, the system may still be processing the signature.
    // The patient will typically press OK on the device, but a staffer could also
    // be assisting the patient and they are accepting the sig in the Sig Modal.
    if (this.useTopaz) {
      await this.sigWebSvc.waitAndFinish();
    }

    let signatureEventData: any;
    if (this.patientUnableToSign) {
      signatureEventData = {
        Name: this.signatureProperties.signatureName,
        CaptureSignerName: this.signatureProperties.captureSignerName,
        CaptureRelationship: this.signatureProperties.captureRelationship,
        signatureType: SignatureType.TypedSignature,
        Value: { Text: this.sigName.substring(0,100), SignedDate: new Date(), Name: this.patientName, Relationship: this.selectedRelationship  },
        applySignature: true
      };
    }
    else if (this.isTypedSignature) {
      signatureEventData = {
        Name: this.signatureProperties.signatureName,
        CaptureSignerName: this.signatureProperties.captureSignerName,
        CaptureRelationship: this.signatureProperties.captureRelationship,
        signatureType: SignatureType.TypedSignature,
        Value: { Text: this.sigName.substring(0,100), SignedDate: new Date(), Name: this.sigName.substring(0,100), Relationship: this.selectedRelationship  },
        applySignature: true
      };
    }
    else {
      signatureEventData = {
        Name: this.signatureProperties.signatureName,
        CaptureSignerName: this.signatureProperties.captureSignerName,
        CaptureRelationship: this.signatureProperties.captureRelationship,
        signatureType: SignatureType.DrawnSignature,
        Value: { Strokes: this.sigValue, SignedDate: new Date(), Text: '', Name: this.sigName, Relationship: this.selectedRelationship },
        applySignature: true,
      };
    }

    if (this.patientUnableToSign) {
      this.adminSvc.createMetric("PatientUnableToSign", { user: this.signerID, reason: this.sigName, date: signatureEventData.SignedDate, formid: this.formId })
    }
    this.signature.emit(signatureEventData);
    this.exitModal.emit(null);
    this.mmSvc.sync.sendRequestSignaturePromptAction.toRemote({type: SignaturePromptAction.APPLY, data: signatureEventData});
  }

  signWithoutCode(): void {
    this.batchSign = false;
    this.showCanvas = true;
    this.setupDrawCanvas(true);
  }

  async onEnterKeyPressSubmit(): Promise<void> {
    await this.sign();
  }

  onAssign() {
    const assignedUser = this.userList.find(user => user.id === this.signerID);
    this.formSvc.reassignSignatureTask(this.form.id, assignedUser.id, this.signatureProperties.signatureName).subscribe({
      error: (err) => { this.toastrSvc.error(err.message, '', {disableTimeOut: true}); },
      complete: () => { this.closeReassignModal.emit(assignedUser.fullName); }
    });
  }

  toggleHiddenCode(): void {
    this.hideCode = !this.hideCode;
    this.masking = 'ZZZZZZZZZZZZZZZZZZZZZZZZ';
    if (this.hideCode) {
      this.pattern = {
        'Z' : { pattern : /\w/, symbol : '•' },
        'Y' : { pattern : /\w/, symbol : null }
      };
    }
    else {
      this.pattern = {
        'Z' : { pattern : /\w/, symbol : null },
        'Y' : { pattern : /\w/, symbol : null }
      };
    }
  }

  hideCodeCb(): void {
    this.hideCode = true;
    this.masking = 'ZZZZZZZZZZZZZZZZZZZZZZZZ';
    this.pattern = {
      'Z' : { pattern : /\w/, symbol : '•' },
      'Y' : { pattern : /\w/, symbol : null }
    };
  }

  setupDrawCanvas(resize: boolean = false): void {
    const modal = document.getElementsByClassName("modal-iop-signature-prompt")[0] as any;
    modal.style.maxWidth = "1200px";

    const canvas: HTMLCanvasElement = <HTMLCanvasElement>this.drawCanvas.nativeElement;
    const prevWidth = canvas.width;
    const prevHeight = canvas.height;
    const currentWidth = this.container.nativeElement.offsetWidth;

    canvas.width = currentWidth;
    canvas.height = currentWidth / 5;
    canvas.style.width = currentWidth + 'px';
    canvas.style.height = (currentWidth / 5) + 'px';

    if (this.useTopaz) {
      this.sigWebSvc.start(this);
    } else {
      // Capture from Mouse/Touch
      this.drawPad = new SignaturePad(canvas, {
        maxWidth: 3,
        minWidth: 1
      });

      this.mmSvc.listenToSignaturePoints(this.drawPad, this.drawCanvas.nativeElement, WindowType.CLINICAL);

      this.drawPad.addEventListener('beginStroke', () => {
        this.setCSSUserSelect('none');
      }, { once: true });

      this.drawPad.addEventListener('endStroke', () => {
        this.saveDrawing();
        this.setCSSUserSelect('auto');
      }, { once: false });

      if (this.sigValue && resize) {
        this.updateSignature(this.sigValue, prevWidth, prevHeight);
      }
    }
  }

  resizeData(data: any, ratioX: number, ratioY: number): void {
    if (data) {
      data.forEach(function (stroke) {
        stroke.points.forEach(function (point: any) {
          point.x *= ratioX;
          point.y *= ratioY;
        });
      });
    }
  }

  onClearSignature(): void {
    this.clearSignature();
    this.mmSvc.sync.sendRequestSignaturePromptAction.toRemote({type: SignaturePromptAction.CLEAR, data: null});
  }

  clearSignature(): void {
    if (this.useTopaz) {
      this.sigWebSvc.clear();
    }
    else {
      this.drawPad?.clear();
    }
    this.sigValue = null;
  }

  saveDrawing(): void {
    if (this.drawPad) {
      this.sigValue = this.drawPad.toData();
    }
  }

  setCSSUserSelect(val: string): void {
    window.document.body.style.userSelect = val;
  }

  private determineRouteVariables(): void {
    if (this.form) {
      this.formId = this.form.id;
      this.experienceVersionId = this.form.experienceversionid;
    } else {
      this.formId = this.taskToSign.task.formid;
      this.experienceVersionId = this.taskToSign.task.experienceversionid;
    }
  }

  toggleIsTypedSignature(isTypedSignature: boolean): void {
    if (this.isTypedSignature === isTypedSignature) {
      return;
    }

    this.isTypedSignature = isTypedSignature;
    this.resetSignature();
    this.mmSvc.sync.sendRequestSignaturePromptAction.toRemote({
      type: SignaturePromptAction.TYPED_SIGNATURE, data: this.isTypedSignature || this.patientUnableToSign
    });
  }

  togglePatientUnableToSign(): void {
    this.patientUnableToSign = !this.patientUnableToSign;
    this.resetSignature();
    this.mmSvc.sync.sendRequestSignaturePromptAction.toRemote({
      type: SignaturePromptAction.PATIENT_CANNOT_SIGN, data: this.isTypedSignature || this.patientUnableToSign
    });

    if (this.patientUnableToSign && this.signatureProperties.captureRelationship) {
      const options = this.signatureProperties.relationshipSource === RelationshipListSource.UsePDFSettings
        ? this.relationshipListSource.map(el => el.value)
        : this.relationshipListSource;

      if (options.map(el => el.toLowerCase()).includes(Relationship.Self.toLowerCase())) {
        this.selectedRelationship = this.titleCasePipe.transform(Relationship.Self);
      }
      else if (options.map(el => el.toLowerCase()).includes(Relationship.Patient.toLowerCase())) {
        this.selectedRelationship = this.titleCasePipe.transform(Relationship.Patient);
      }
      else {
        this.selectedRelationship = '';
      }
    }
  }

  resetSignature(): void {
    this.sigName =
      this.signatureProperties.signatureFor.toLocaleLowerCase() === SignatureFor.Patient.toLocaleLowerCase() &&
      this.signatureProperties.captureSignerName && !this.signatureProperties.captureRelationship &&
      !this.patientUnableToSign
        ? this.patientName
        : '';
    this.selectedRelationship = '';
    this.clearSignature();
  }

  get attemptsObject(): any {
    return { attempts: this.remainingAttempts, totalAttempts: TOTAL_ATTEMPTS }
  }

  async setRelationshipSource(relationshipListSource: string) {
    switch (relationshipListSource) {
      case '':
        this.relationshipListSource = [];
        this.selectedRelationship = '';
        break;
      case RelationshipListSource.UsePDFSettings:
        this.relationshipListSource = this.signatureProperties.pdfSettings;
        this.selectedRelationship = this.relationshipListSource[0].value;
        break;
      default:
        this.relationshipListSource = await lastValueFrom(this.adminSvc.getTypedSignatureRelationshipSource(relationshipListSource));
        this.selectedRelationship = this.relationshipListSource[0];
        break;
    }
  }

  onRelationshipChange(event) {
    const val: string = (event.target as HTMLInputElement).value;
    this.sigName =
      (val?.toUpperCase() === Relationship.Self || val?.toUpperCase() === Relationship.Patient) &&
        (this.signatureProperties.signatureFor.toLowerCase() === SignatureFor.Patient.toLowerCase() || this.signatureProperties.signatureFor.toLowerCase() === SignatureFor.Other.toLowerCase())
        ? this.patientName
        : '';
    this.mmSvc.sync.sendRequestSignaturePromptAction.toRemote({ type: SignaturePromptAction.SIGNER_NAME_CHANGE, data: this.sigName });
  }

  enableApply() {
    const isValidUnableToSign: boolean = !this.patientUnableToSign || !!this.sigName;
    const isRelationshipValid: boolean = !this.signatureProperties.captureRelationship || !!this.selectedRelationship;
    const isCaptureSignerValid: boolean = !this.signatureProperties.captureSignerName || !!this.sigName;
    const isValidTypedSignature: boolean = !this.isTypedSignature || !!this.sigName;
    const isValidStrokesSignature: boolean = this.isTypedSignature || this.patientUnableToSign || !!this.sigValue?.length;

    return isValidUnableToSign && isRelationshipValid && isCaptureSignerValid && isValidStrokesSignature && isValidTypedSignature;
  }

  signatureTypeNameChange(value: string): void {
    this.mmSvc.sync.sendRequestSignaturePromptAction.toRemote({ type: SignaturePromptAction.SIGNER_NAME_CHANGE, data: value });
  }

  signatureRelationshipChangeNg(value: string): void {
    this.mmSvc.sync.sendRequestSignaturePromptAction.toRemote({ type: SignaturePromptAction.RELATION_CHANGE, data: value });
  }

  isRelationshipRequired(): boolean {
    return this.signatureProperties.captureRelationship
      && (this.sigValue || this.isTypedSignature)
      && !this.selectedRelationship;
  }

  isTypeNameRequired(): boolean {
    return (
      (this.signatureProperties.signatureFor.toLocaleLowerCase() === SignatureFor.Staff.toLocaleLowerCase() &&
        !this.sigName && this.sigValue) ||
      (this.signatureProperties.signatureFor.toLocaleLowerCase() === SignatureFor.Patient.toLocaleLowerCase() &&
        this.signatureProperties.captureSignerName && !this.signatureProperties.captureRelationship && !this.sigName)
    );
  }

  private getSignatureDefaultOfType(signatures: DefaultSignatures, signatureType: SignatureType) {
    const value: any = {
      SignedDate: new Date(),
    };
    switch (signatureType) {
      case SignatureType.DrawnSignature:
      case SignatureType.Signature:
        value.Strokes = signatures.STROKES;
        break;
      case SignatureType.TypedSignature:
        value.Text = signatures.TEXT;
        break;
      case SignatureType.Initials:
        value.Text = signatures.INITIALS;
        break;
    }
    return value;
  }

  @HostListener("window:resize") orientation(): void {
    if (this.drawPad) {
      this.drawPad.off();
      this.setupDrawCanvas(true);
    }
  }
}
