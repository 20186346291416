import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TextboxComponent } from '../../textbox.component';
import { FormControl, FormGroup } from "@angular/forms";
import { InputType, TextboxInputType } from "@next/shared/common";
import { Utility } from "@next/shared/ui";

@Component({
  selector: 'next-datetime',
  templateUrl: './datetime.component.html',
  styleUrls: ['../../textbox.component.css']
})
export class DateTimeComponent extends TextboxComponent implements OnInit, AfterViewInit {
  TextboxInputType: typeof TextboxInputType = TextboxInputType;

  @ViewChildren('pickerField') pickerFields: QueryList<ElementRef>;
  showFormattedPickerValue: boolean = false;
  datePickerConfig = {
    minDate: new Date(1900, 1, 1),
    maxDate: null
  };
  dateTextInputFg: FormGroup = new FormGroup({
    text: new FormControl({value: '', disabled: false}),
    value: new FormControl({value: '', disabled: false})
  });

  ngOnInit() {
    super.ngOnInit();

    if (this.field.inputType === TextboxInputType.DATE || this.field.inputType === TextboxInputType.DATETIME) {
      // Configure datepicker for date / date-time
      this.datePickerConfig = {
        minDate: this.field.pastDates ? new Date(1900, 1, 1) : new Date(),
        maxDate: this.field.futureDates ? null : new Date()
      };
    }

    if (this.form.contains(this.field.name)) {
      this.valueFormGroup = this.form.get(`${this.field.name}.Value`) as FormGroup;
      this.setInitialPickerValue(this.field.inputType, this.valueFormGroup.value.Text);
    }
  }

  setInitialPickerValue(inputType: string, initialValue: string) {
    // if there's an initial value: format, update, and show formatted view
    if (initialValue && (inputType === TextboxInputType.DATE
      || inputType === TextboxInputType.TIME || inputType === TextboxInputType.DATETIME)) {
      const initialValue = this.valueFormGroup.value.Text;
      this.dateTextInputFg.patchValue({['text']: initialValue}, {emitEvent: false});
      this.updatePickerValue(inputType);
      this.showFormattedPickerValue = true;
    }
  }

  showPicker() {
    if ("showPicker" in HTMLInputElement.prototype) { // showPicker() is supported
      this.showFormattedPickerValue = false; // switch to date-type input
      setTimeout(() => {
        const pickerInput: any = this.pickerFields.first.nativeElement;
        pickerInput.showPicker();
        pickerInput.focus(); // to switch to showing formatted view after blur / outside click
      }, 0);
    }
  }

  onPickerFocus() {
    if (!this.field.readonly) {
      this.showFormattedPickerValue = false;
      setTimeout(() => {
        this.pickerFields.first.nativeElement.focus();
      }, 0);
    }
  }

  onPickerBlur(inputType: TextboxInputType | InputType) {
    if (!this.field.readonly) {
      this.showFormattedPickerValue = true;
      this.updatePickerValue(inputType);
    }
  }

  updatePickerValue(inputType: TextboxInputType | InputType): void {
    const formValue = this.dateTextInputFg.value['text'];
    let textValue = '';
    if (formValue) {
      if (inputType === TextboxInputType.DATE) {
        textValue = Utility.toFormattedDateStr(this.field.dateFormat, `${formValue}T00:00`);
      } else if (inputType === TextboxInputType.TIME) {
        textValue = Utility.toFormattedDateStr(this.field.dateFormat, `1970-01-01T${formValue}`);
      } else if (inputType === TextboxInputType.DATETIME) {
        textValue = Utility.toFormattedDateStr(this.field.dateFormat, formValue);
      }
    } else {
      this.showFormattedPickerValue = false;
    }

    // Patch the value Date form control
    this.valueFormGroup.patchValue({['Text']: formValue}, { emitEvent: true });
    this.valueFormGroup.patchValue({['DisplayText']: textValue}, { emitEvent: false });
    // Patch the text Date control
    this.dateTextInputFg.patchValue({['value']: textValue }, { emitEvent: false });
  }
}
