import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  CanLoad,
} from '@angular/router';
import {
  TokenService,
} from '@next/shared/common';
import { Observable, first, of } from 'rxjs';
import { NextAdminService } from '../admin.service';
import { NextAuthService } from '../auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanLoad {
  jwtHelper = new JwtHelperService();
  token: any;
  notification: string = '';

  constructor (
    private tokenService: TokenService,
    private router: Router,
    private nextAdminService: NextAdminService,
    private authService: NextAuthService
  ) { }

  canActivate(): Observable<boolean> {
     return this.processAuthentication();
  }

  canLoad(): Observable<boolean> {
    return this.processAuthentication()
  }

  processAuthentication(): Observable<boolean> {
    if (sessionStorage.getItem('lockedMode')) {
      sessionStorage.removeItem('lockedMode');
      this.router.navigate(['logout/auto'], { queryParams: { currentUrl: window.location.pathname, logoutInactivity: false, isMainComponent: false } });
    }
      // Get current token or app_token, if available
    const urlParams = new URLSearchParams(window.location.search);
    this.token = urlParams.get('token');

    // If token exists then automatically save it
    if (this.token) {
      //try saving to our storage, if it cannot it means we don't have access (possibly due to incognito mode) so return true;
      try {
        this.tokenService.saveAccessToken(this.token);
      } catch {
        return of(true);
      }
    }

    // If there exists a token in localstorage and that token is not expired then user can have access
    if (this.tokenService.hasAccessToken() && !this.jwtHelper.isTokenExpired(this.tokenService.getAccessToken())) {
      return of(true);
    }

    // If there is no token, then redirect to SSO and return false to prevent routes from activating
    this.redirect(urlParams);
    return of(false);
  }

  redirect(params: URLSearchParams) {
    // Since we are redirecting them to log in, flush out any legacy token.
    this.tokenService.clear();

    // This should be the first call we make
    this.authService.authorizationEndpoint(this.authService.connectionParams(params)).pipe(first())
    .subscribe(({authorization_endpoint}) => {
      this.checkForNotificationsOnLoad(authorization_endpoint);
    });
  }

  checkForNotificationsOnLoad(currentUrl: string) {
    // get the authorize url here
    this.nextAdminService.checkBeforeLoginNotification().subscribe(result => {
      if (this.hasActiveNotification(result)) {
        this.router.navigate(['beforelogin'], { queryParams: { currentUrl }});
      }
      else {
        window.location.href = currentUrl;
      }
    });
  }

  // Check if there is an active notification
  hasActiveNotification(notifications: any[]) {
    if (notifications.length === 0) return false;

    const firstNotification = notifications[0];
    const { loginscreentext, loginscreenstart, loginscreenend } = firstNotification.data;

    return loginscreentext?.length > 0 && this.checkForActiveTimePeriod(loginscreentext, loginscreenstart, loginscreenend);
  }

  checkForActiveTimePeriod(text: string, start: string | Date, end: string | Date): boolean {
    if (!text) {
      return false;
    }

    if (start === null || end === null) {
      return false;
    }

    const startDate = new Date(start);
    const endDate = new Date(end);
    const currentDate = new Date();
    return startDate <= currentDate && currentDate <= endDate;
  }
}
