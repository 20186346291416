import { Injectable } from '@angular/core';

declare let
    SetTabletState,
    SetLCDCaptureMode,
    KeyPadClearHotSpotList,
    ClearSigWindow,
    LCDSetWindow,
    SetSigWindow,
    LCDWriteString,
    KeyPadAddHotSpot,
    LcdRefresh,
    ClearTablet,
    SetDisplayPenWidth,
    KeyPadQueryHotSpot,
    SigWebEvent,
    TabletModelNumber,
    GetTabletLogicalXSize,
    GetTabletLogicalYSize,
    NumberOfTabletPoints,

    // Event Triggered Function
    onSigPenUp,

    // Undocumented
    LcdWriteImageStream,
    GetNumberOfStrokes,
    GetNumPointsForStroke,
    GetPointXValue,
    GetPointYValue;

@Injectable({
    providedIn: 'root'
})
export class SigWebService {

    DEVICE_WIDTH = 240;
    DEVICE_HEIGHT = 64;

    // Timers
    tmr = null;         // This drives the Topaz Canvas Refresh Rate
    eventTmr = null;    // This picks up Topaz Events - This must run effeciently
    sigTmr = null;      // This allows us to build our sig array as quickly as possible

    // Context State
    nextStroke = 0;
    nextPoint = 0;
    clickedOk = false;
    clickedClear = false;
    processingSig = false;

    /**
     * Is a supported Topaz Device connected?
     * This requires the SigWeb driver to be installed.
     * @returns True if a device has been detected that we support, else False
     */
    detectTopazDevice(): boolean {
        try {
            SetTabletState(1);
            const retmod = TabletModelNumber();

            // Note: SigLite will return 0, as will missconfigured BSB devices
            // We may want to try to use GetTabletComTest() for detecting SigLite
            if (retmod === "11" || retmod === "12" || retmod === "15") {
                return true;
            }
            // Wrong Version of Sig Tablet
        }
        catch (e) {
            // Sig Tablet not connected
        }

        return false;
    }

    async sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    /**
     * Remove the Signature from the device.
     * This also resets all of the internals for starting a new signature.
     */
    clear() {
        ClearSigWindow(1);
        ClearTablet();
        LcdRefresh(2, 0, 0, this.DEVICE_WIDTH, this.DEVICE_HEIGHT);

        this.nextStroke = 0;
        this.nextPoint = 0;
        this.clickedOk = false;
        this.clickedClear = false;
        this.processingSig = false;
    }

    /**
     * When we are done processing, display the completion message and stop the device.
     */
    async waitAndFinish() {

        // Display the completion message
        LcdRefresh(0, 0, 0, this.DEVICE_WIDTH, this.DEVICE_HEIGHT);
        LCDWriteString(0, 2, 35, 25, "9pt Arial", 15, "Signature capture complete.");

        // We may still have processing to wait for.
        // That said, we are going to pause for 2 seconds anyway.  We can hide this processing
        // time in our message wait delay.
        // This counter will let us know how long we've spent process so that we don't display
        // the message any longer than we need to.
        let counter = 0;

        // Wait for completion
        while (this.processingSig) {
            await this.sleep(100);
            counter++;
        }

        // Reset Topaz device after 2 seconds
        setTimeout(() => {
            this.stop();
        }, 2000 - (counter * 100));
    }

    /**
     * Stops the device and cleans up the device state.
     */
    stop() {
        clearInterval(this.eventTmr);
        clearInterval(this.sigTmr);

        LcdRefresh(0, 0, 0, this.DEVICE_WIDTH, this.DEVICE_HEIGHT);
        LCDSetWindow(0, 0, this.DEVICE_WIDTH, this.DEVICE_HEIGHT);
        SetSigWindow(1, 0, 0, this.DEVICE_WIDTH, this.DEVICE_HEIGHT);
        KeyPadClearHotSpotList();
        SetLCDCaptureMode(1);
        SetTabletState(0, this.tmr);
    }

    /**
     * Initialize the device and start collecting our signature.
     *
     * @param dialog The dialog associated with this Topaz device
     */
    start(dialog) {

        // Get Canvas
        const canvas: HTMLCanvasElement = <HTMLCanvasElement>dialog.drawCanvas.nativeElement;
        const ctx = canvas.getContext('2d');

        // Setup device event timers
        clearInterval(this.eventTmr);
        clearInterval(this.sigTmr);
        SetTabletState(0, this.tmr);
        this.eventTmr = setInterval(SigWebEvent, 100);
        this.tmr = SetTabletState(1, ctx, 100) || this.tmr;

        // Intialize device
        SetLCDCaptureMode(2);
        LcdRefresh(0, 0, 0, this.DEVICE_WIDTH, this.DEVICE_HEIGHT);
        ClearSigWindow(1);
        SetDisplayPenWidth(12); // Default is 5, and appears too thin.

        KeyPadClearHotSpotList();
        KeyPadAddHotSpot(0, 3, 10, 5, 53, 14);   //CLEAR
        KeyPadAddHotSpot(1, 3, 185, 5, 25, 14);  //OK

        // Configure display
        // Draw our UI, not through a BMP image, but dynamically
        // This has the potential to scale with more screen options in the future
        LCDWriteString(1, 2, 15, 3, "8pt Verdana", 14, "CLEAR");
        LCDWriteString(1, 2, 195, 3, "8pt Verdana", 14, "OK");
        LcdWriteImageStream(1, 2, 0, 17, 240, 1, 'B'.repeat(240)); // Top bar
        LcdWriteImageStream(1, 2, 0, 63, 240, 1, 'B'.repeat(240)); // Bottom bar
        LcdWriteImageStream(1, 2, 0, 18, 1, 45, 'B'.repeat(45)); // Left bar
        LcdWriteImageStream(1, 2, 239, 18, 1, 45, 'B'.repeat(45)); // Right bar
        LcdWriteImageStream(1, 2, 0, 60, 240, 1, 'BWW' + 'BBBBBBBBBBWWWWWW'.repeat(15).slice(0, -4) + 'B'); // Dotted Line
        LcdWriteImageStream(1, 2, 0, 50, 13, 9,
            'BWWWBWWWWWWWB' +
            'BWWWWBWWWWWBW' +
            'BWWWWWBWWWBWW' +
            'BWWWWWWBWBWWW' +
            'BWWWWWWWBWWWW' +
            'BWWWWWWBWBWWW' +
            'BWWWWWBWWWBWW' +
            'BWWWWBWWWWWBW' +
            'BWWWBWWWWWWWB'
        ); // X Marks the Spot

        LCDSetWindow(0, 17, this.DEVICE_WIDTH, this.DEVICE_HEIGHT - 18);
        SetSigWindow(1, 0, 17, this.DEVICE_WIDTH, this.DEVICE_HEIGHT - 18);

        // Initialize us to a clean state
        this.clear();

        // Calculate Canvas Scale
        const scale = {
            x: canvas.width / GetTabletLogicalXSize(),
            y: canvas.height / GetTabletLogicalYSize()
        };

        /**
         * A function registered in SigWeb for Pen Up events.
         * This is driven by a timer and needs to run as fast as possible.
         */
        onSigPenUp = () => {

            // Flag is OK has been pressed
            if (!this.clickedOk) {
                this.clickedOk = KeyPadQueryHotSpot(1) > 0;

                if (this.clickedOk) {
                    // Flash button click
                    LcdRefresh(1, 192, 1, 24, 14);
                }
            }

            // If not, maybe Clear?
            // If Clear has been pressed, quickly wipe the screen
            if (!this.clickedClear) {
                this.clickedClear = KeyPadQueryHotSpot(0) > 0;

                if (this.clickedClear) {
                    // Flash button click
                    LcdRefresh(1, 10, 1, 53, 14);
                }
            }
        };

        /**
         * This processes all events like Clear and OK, as well as runs in a timed loop
         * in order to process all strokes.  We convert each stroke into an array of points.
         *
         * @returns Void
         */
        const processStrokes = async () => {

            clearInterval(this.sigTmr);

            // Used to make sure that other threads wait until we are done processing
            this.processingSig = true;

            if (this.clickedClear)
            {
                // Clear through the dialog function
                // This function may do other internal dialog related cleanup
                // This will call back into our clear() function above
                dialog.clearSignature();

                // Restart the Signature Timer
                this.sigTmr = setInterval(processStrokes, 250);

                return;
            }

            // Build our signature object
            await this.captureSignature(dialog, scale);

            // If OK was pressed before we started or while we were processing, we are done
            if (this.clickedOk)
            {
                // It is possible that we are processing a signature so slowly that more was drawn
                // and OK was pressed.  In this case, this second call will commplete the signature.
                // If there is not more to process this second call will exit cleanly.
                await this.captureSignature(dialog, scale);

                // Assure that we have captured enough points!
                const numPoints = Number(NumberOfTabletPoints());
                if (numPoints > 50) {

                    // We are done!
                    this.processingSig = false;

                    // Applying will finalize our signature
                    await dialog.applySignatureAsync();

                    return;
                }

                // Reset because we didn't capture enough points
                dialog.clearSignature(); // Only take a real signature
                this.clickedOk = false;
            }

            // We are finished.  If a thread is waiting, it should now know it can move one.
            this.processingSig = false;

            // Restart the Signature Timer
            this.sigTmr = setInterval(processStrokes, 250);
        };

        // Start our signature processor
        this.sigTmr = setInterval(processStrokes, 250);
    }

    /**
     * This routine will capture all strokes and points drawn since the last time it was
     * called and add it to our signature property in the dialog.
     *
     * @param dialog The dialog holding our Canvas
     * @param scale X,Y information on how to scale our image to fit the region on the PDF
     * @returns Void
     */
    async captureSignature(dialog: any, scale: any) {

        // Format:
        // [{"color":"black","points":[{"time":1658513577769,"x":347.953125,"y":189} ...

        // Build Signature
        const strokeCount = Number(GetNumberOfStrokes());

        // Setting a Sig Value will trigger the Accept button on the dialog to pop up!
        if (dialog.sigValue === null && strokeCount > 0) {
            dialog.sigValue = [];
        }

        const strokes = dialog.sigValue;

        // Drawing can be so intensive we have to take a breath
        let breathCounter = 0;

        for (let strokeIdx = this.nextStroke; strokeIdx < strokeCount; strokeIdx++) {
            const pointCount = Number(GetNumPointsForStroke(strokeIdx));

            let lastX = -1;
            let lastY = -1;

            // Init the Stroke
            if (!strokes[strokeIdx]) {
                strokes[strokeIdx] = {
                    color: "black",
                    points: []
                };
            }

            const stroke = strokes[strokeIdx];

            // Populate it with points
            // We can pick up where we left off!
            for (let pointIdx = this.nextPoint; pointIdx < pointCount; pointIdx++) {
                const x = GetPointXValue(strokeIdx, pointIdx);
                const y = GetPointYValue(strokeIdx, pointIdx);

                // Remove duplicate points
                if (x === lastX && y === lastY) {
                    continue;
                }
                lastX = x;
                lastY = y;

                // Add the point
                stroke.points.push({
                    x: x * scale.x,
                    y: y * scale.y
                });

                // After 100 points, regardless of strokes, take a breath for other events to process
                breathCounter++;
                if (breathCounter > 100) {
                    if (!this.clickedOk) await this.sleep(100);

                    // Do not wait, clear it instantly!
                    if (this.clickedClear) return;
                    breathCounter = 0;
                }
            }

            // If there is another stroke, start over at 0
            // If there isn't, set the next point idx we should be looking for
            this.nextPoint = strokeCount === strokeIdx + 1 ? stroke.points.length : 0;
        }

        // Always leave off where the next stroke is your current stroke.
        // If there aren't any new points to add, move to the next stroke.
        if (strokes?.length > 0) {
            this.nextStroke = strokes.length - 1;
        }
    }
}
