import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { StateViewerService } from '../state/state-viewer.service';

@Injectable({
  providedIn: 'root',
})
export class ClinicalFormViewerPrefillResolver {
  constructor (private stateSvc: StateViewerService) { }
  resolve(): Observable<any> | Observable<never> {
    return of({
      Patient: this.stateSvc.selectedForms.currentForm?.patientdata,
      Appointment: this.stateSvc.selectedForms.currentForm?.appointment
    });
  }
}

