/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TagService } from '@next/shared/next-services';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from "ngx-toastr";
import { GuidedExperienceDTO, Tag, TagJunction, TagMode } from '@next/shared/common';

@Component({
  selector: 'next-manage-tags',
  templateUrl: './manage-tags.component.html',
  styleUrls: ['./manage-tags.component.scss']
})

export class ManageTagsComponent implements OnInit {
  TagMode: typeof TagMode = TagMode;  // Allow this enum to be used in the template

  @Input() experiences: GuidedExperienceDTO[];
  @Output() closeWindow: EventEmitter<boolean> = new EventEmitter<boolean>();

  mode: TagMode = TagMode.search;
  allTags: Tag[] = [];
  tags: Tag[] = [];

  manageTag: UntypedFormGroup = new UntypedFormGroup({
    newtag: new UntypedFormControl('', { validators: [Validators.required] }),
    edittag: new UntypedFormControl(''),
    search: new UntypedFormControl('')
  });

  formArray: UntypedFormArray = new UntypedFormArray([]);

  pattern: any = {
    'Z' : { pattern : /^(?!.*[\/{}=$%@"<>`~+]).*/ }
  };

  constructor (
    private tagService: TagService,
    private fb: UntypedFormBuilder,
    private translateSvc: TranslateService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData(): void {
    this.tagService.getAllWithActiveTagsIndicatedMultiple(this.experiences.map(e => e.id)).subscribe((tags: Tag[]) => {
      this.tags = this.allTags = tags;
      this.formArray = new UntypedFormArray([]);
      for (const tag of tags) {
        const record: UntypedFormGroup = this.fb.group({
          name: [tag.tag || '', Validators.required],
          id: tag.id,
          count: tag.count,
          currentreferences: tag.currentreferences,
          active: tag.active
        });
        this.formArray.push(record);
      }
    });
  }

  toggleActiveTag(tag: Tag): void {
    tag.active = !tag.active;
    this.formArray.markAsDirty();
  }

  submit(): void {
    if (this.formArray.dirty) {
      const tagData = this.formArray.value;
      const expData = this.experiences.map(e => e.id);
      const payload = [];
      for (const id of expData) {
        for (const tag of tagData) {
          payload.push({
            tagid: tag.active ? tag.id : null,
            elementid: id,
            elementtype: tag.active ? 'forms' : 'marked-for-delete'
          } as TagJunction);
        }
      }

      this.tagService.applyTag(payload).subscribe(res => {
        this.mode = TagMode.search;
        this.closeWindow.emit(true);
      });
    } else {
      this.closeWindow.emit(false);
    }
  }

  saveTag(field): void {
    this.tagService.editTag(field.value.id, {
      id: field.value.id,
      tag: field.value.name,
      count: null,
      active: null
    }).subscribe({
      next: (res) => {
        this.toastr.success(this.translateSvc.instant('TAGS.UPDATE.MSG_TAG_UPDATED', { label: field.value.name }));
        field.markAsPristine();
      },
      error: (err) => { this.toastr.error(err.message, '', {disableTimeOut: true}) }
    });
  }

  deleteTag(field, i: number): void {
    this.tagService.deleteTag(field.id).subscribe(res => {
        this.loadData();
        this.toastr.success(this.translateSvc.instant('TAGS.DELETE.MSG_DELETE_SUCCESS', {label: field.name}));
      }, (err) => this.toastr.error(err.message, '', {disableTimeOut: true})
    );
  }

  addTag(): void {
    const tag = this.manageTag.value;
    const formData: Tag = {
      id: null,
      tag: tag.newtag,
      count: null,
      active: null
    };

    if (!this.tagNameExists(formData.tag)) {
      this.tagService.createTag(formData).subscribe(x => {
        this.toastr.success(this.translateSvc.instant('TAGS.CREATE.MSG_TAG_CREATED', { label: formData.tag }));
        this.manageTag.patchValue({['newtag']: '' });
        this.manageTag.markAsPristine();
        this.loadData();
      });
    } else {
      this.toastr.error(this.translateSvc.instant('TAGS.CREATE.MSG_TAG_ALREADY_EXIST', { label: formData.tag }),'',{disableTimeOut: true});
    }
  }

  tagNameExists(searchValue: string): boolean {
    return (this.tags.filter(t => t.tag.toLowerCase() === searchValue.toLowerCase())).length > 0;
  }

  onManageTagsExit(): void {
    this.closeWindow.emit(this.formArray.dirty);
  }

  updateMode(mode: TagMode) {
    this.mode = mode;
    if (mode.search) {
      this.loadData();
    }
  }
}
