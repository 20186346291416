import { InjectionToken } from '@angular/core';

export interface FormFastConfig {
  production: boolean;
  apiUrl: string;
  platform: Platform;

  timeout: number;
  nextViewerUrl: string;
  maxUploadFileSizeMB: number;
  appVersion: string;
}

export enum Platform {
  PLATFORM = 'platform',
  NEXT = 'next'
}

export const FormFastConfigService = new InjectionToken<FormFastConfig>(
  'FormFastConfig'
);
