
/**
 * These are all the current activity history event types used in our activity_history table
 */
export const EventTypes = {
    PATIENT_CREATED: 'patient_created',
    PATIENT_UPDATED: 'patient_updated',
    PATIENT_DELETED: 'patient_deleted',
    PATIENT_CHANGED: 'patient_changed',
    PATIENT_MERGED: 'patient_merged',
    PATIENT_VIEWED: 'patient_viewed',

    APPOINTMENT_CREATED: 'appointment_created',
    APPOINTMENT_UPDATED: 'appointment_updated',
    APPOINTMENT_DELETED: 'appointment_deleted',
    APPOINTMENT_CHANGED: 'appointment_changed',
    APPOINTMENT_MERGED: 'appointment_merged',
    APPOINTMENT_MOVED: 'appointment_moved',
    APPOINTMENT_STATUS: 'appointment_status',
    APPOINTMENT_VIEWED: 'appointment_viewed',

    FORM_VIEWED: 'form_viewed',
    FORM_DELETED: 'form_deleted',
    FORM_MOVED: 'form_moved',
    FORM_ADDED: 'form_added',
    FORM_ARCHIVED: 'form_archived',
    FORM_SIGNED: 'form_signed',
    FORM_PRINTED: 'form_printed',
    FORM_SAVED: 'form_saved',
    FORM_DOWNLOADED: 'form_downloaded'
} as const;

export interface ActivityHistory {
    mrn: string,
    accountnumber?: string,
    firstname?: string,
    lastname?: string,
    facility?: string,
    department?: string,
    formname?: string,
    formid?: string,
    experienceversionid?: string,
    data?: {
        accountnumber?: string,
        mrn?: string,
        status?: string,
    },
    eventdate: Date    
}

export type EventType = typeof EventTypes[keyof typeof EventTypes];

export interface PostActivityHistory {
    eventType: EventType,
    activityData: ActivityHistory
}

export type ActivityProcessType = 'appointment' | 'patient';