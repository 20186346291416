<div class="container-fluid">
  <div *ngIf="!invalidAccessCode" class="d-flex flex-column flex-wrap justify-content-center align-items-center text-center mx-2 mb-2 mt-0">
    <h3 class="mb-1">
      {{"ACCESS_CODE_PROMPT.TITLE" | translate }}
    </h3>
    <span class="mb-1">
      {{"ACCESS_CODE_PROMPT.TEXT_1" | translate }}
    </span>
    <div class="input-group" [formGroup]="accessCode">
      <input
        #codeValue
        id="form-access-code"
        type="text"
        aria-label="form-access-code"
        autocomplete="off"
        aria-autocomplete="none"
        class="form-control"
        formControlName="code"
        nextAutoFocusElement="{{codeValue}}"
        [mask]="masking"
        [patterns]="pattern"
        [hiddenInput]="true"
        [maxLength]="8"
        [ngClass]="{ 'is-invalid' : invalidAccessCode }"
        (blur)="hideCodeCb()"
        (keydown.enter)="validateCode()"/>
      <div class="input-group-append">
        <button
          id="toggle-code-mask"
          type="button"
          class="btn btn-outline-dark h-auto font-weight-bold"
          style="min-width: 54px"
          (click)="toggleHiddenCode()">
          <fa-icon [icon]="hideCode ? ['fas', 'eye'] : ['fas', 'eye-slash']" size="lg"></fa-icon>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="invalidAccessCode" class="d-flex flex-column flex-wrap justify-content-center align-items-center m-2 text-center">
    <h4>
      {{"ACCESS_CODE_PROMPT.ERROR" | translate }}
    </h4>
    {{"ACCESS_CODE_PROMPT.TEXT_2" | translate }}
  </div>
  <p-footer>
      <div *ngIf="!invalidAccessCode" class="d-flex flex-row flex-wrap justify-content-between">
          <button class="btn btn-sign text-truncate" id="cancel-btn-valid" (click)="closeDisplay()">
              {{"ACCESS_CODE_PROMPT.CANCEL" | translate }}
          </button>
          <button class="btn btn-sign text-truncate" id="unlock-btn-valid" (click)="validateCode()">
              {{"ACCESS_CODE_PROMPT.UNLOCK" | translate }}
          </button>
      </div>
      <div *ngIf="invalidAccessCode" class="d-flex flex-row flex-wrap justify-content-between">
          <button class="btn btn-sign text-truncate" id="cancel-btn-invalid" (click)="tryAgain()">
              {{"ACCESS_CODE_PROMPT.TRY_AGAIN" | translate }}
          </button>
          <button class="btn btn-sign text-truncate" id="unlock-btn-invalid" (click)="closeDisplay()">
              {{"ACCESS_CODE_PROMPT.CLOSE" | translate }}
          </button>
      </div>
  </p-footer>
</div>
