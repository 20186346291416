<div class="form-group">
  <button type="button" (click)="openPopup()" class="btn btn-primary btn-block" [ngClass]="{
    'gci-button' : field.class.includes('gci-button '),
    'gci-button-inverse' : field.class.includes('gci-button-inverse'),
    'border-danger' : requiresValidation && !isValid
  }">
    <div class="position-relative">
      {{field.text}}
      <span *ngIf="requiresValidation && !isValid" class="position-absolute right-0"><fa-icon [icon]="['fas', 'exclamation-triangle']" class="exclamation-triangle"></fa-icon></span>
      <span *ngIf="requiresValidation && isValid" class="position-absolute right-0"><fa-icon [icon]="['fas', 'check-circle']" class="check-circle"></fa-icon></span>
    </div>
  </button>

  <div *ngIf="isOpened()" class="popup p-2" [@subFieldIn]="display" [formGroup]="form">
    <div class="container-fluid gci-content-container pl-lg-5 pr-lg-5 popup-scroll-area">
      <ng-container *ngIf="field.title">
        <div class="popup-header page-title">
          <h2>{{ field.title }}</h2>
        </div>
        <hr>
      </ng-container>

      <!-- Popup Contents -->
      <div *ngFor="let subField of field.popupField.fields">
        <next-viewer-element [form]="form" [field]="subField" [initialState]="initialState" [needsValidation]="needsValidation" (valueChanged)="emitValueChanged($event)"></next-viewer-element>
      </div>
    </div>

    <div class="popup-bottom">
      <button class="btn btn-primary btn-block" (click)="closePopup()">{{ 'POPUP.POPUP_CLOSE' | translate }}</button>
    </div>
  </div>
</div>
