import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { UploadDialogComponent } from './components/upload-dialog/upload-dialog.component';
import { FilterPipe } from './pipes/filter.pipe';
import { DisplayHtmlPipe } from './pipes/display-html.pipe';
import { FilterBoxComponent } from './components/filter-box/filter-box.component';
import { DropZoneDirective } from './directives/drop-zone.directive';
import { FormSwitchComponent } from './components/form-switch/form-switch.component';
import { SecurePipe } from './pipes/secure.pipe';
import { PdfCropToolComponent } from './components/pdf-crop-tool/pdf-crop-tool.component';
import { PdfPageComponent } from './components/pdf-page/pdf-page.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SortByPipe } from './pipes/sortby.pipe';
import { LogoutComponent } from './components/logout/logout.component';
import { GenerateComponentDirective } from './directives/generate-component.directive';
import { AutoFocusElementDirective } from './directives/auto-focus-element.directive';
import { SvgEmbeddedComponent } from './components/svg-embedded/svg-embedded.component';
import { ReplaceLineBreaksPipe } from './pipes/replaceLineBreaks.pipe';
import { PdfRendererComponent } from './components/pdf-crop-tool/pdf-renderer/pdf-renderer.component';
import { InputDialogComponent } from './components/input-dialog/input-dialog.component';
import { ConfirmClickComponent } from './components/confirmation-button/confirm-click.component';
import { PatientSearchHighlightPipe } from './pipes/patient-search-highlight.pipe';
import { AppointmentFilterPipe } from './pipes/appointment-filter.pipe';
import { SortByAppointmentStatusAndDatePipe } from './pipes/sort-by-appointment-status-and-date.pipe';
import { FormatAppointmentStatusPipe } from './pipes/format-appointment-status.pipe';
import { TruncatePipe } from "./pipes/truncate.pipe";
import { FormValidationErrorComponent } from "./components/form-validation-error/form-validation-error.component";
import { FormFieldValidationDirective } from "./directives/form-field-validation.directive";
import { InformDialogComponent } from './components/inform-dialog/inform-dialog.component';
import { DisplayModelPropertyDirective } from './directives/display-model-property.directive';
import { UhiEmbeddedDirective } from './directives/uhi-embedded.directive';

const components = [
  ConfirmClickComponent,
  ConfirmationDialogComponent,
  FilterBoxComponent,
  FormSwitchComponent,
  LogoutComponent,
  UploadDialogComponent,
  PdfPageComponent,
  PdfCropToolComponent,
  PdfRendererComponent,
  SvgEmbeddedComponent,
  InputDialogComponent,
  FormValidationErrorComponent,
  InformDialogComponent
];

const directives = [
  DropZoneDirective,
  GenerateComponentDirective,
  AutoFocusElementDirective,
  FormFieldValidationDirective,
  DisplayModelPropertyDirective,
  UhiEmbeddedDirective
];

const pipes = [
  AppointmentFilterPipe,
  DisplayHtmlPipe,
  FilterPipe,
  PatientSearchHighlightPipe,
  ReplaceLineBreaksPipe,
  SecurePipe,
  SortByPipe,
  SortByAppointmentStatusAndDatePipe,
  FormatAppointmentStatusPipe,
  TruncatePipe,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        FontAwesomeModule,
        ReactiveFormsModule
    ],
    declarations: [
        ...components,
        ...directives,
        ...pipes
    ],
    exports: [
        ...components,
        ...directives,
        ...pipes
    ]
})
export class SharedUiModule {}
