import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogoService {
  
  async getBase64Logo(url: string) {
    if (url) {
      const response: Response = await fetch(url);
      const blob: Blob = await response.blob();
      const [ uri ]: string[] = await this.fileToDataURI(blob);
      return uri;
    }
    return '';
  }

  async fileToDataURI(b: Blob | File, sizer: number = 1): Promise<any[]> {
    return new Promise(((resolve, reject) => {
      const r = new FileReader();
      r.readAsDataURL(b);
      r.onloadend = (e) => {
        const content = e.target.result;
        const img = document.createElement('img');
        img.src = content as string;
        img.onload = () => {
          const w = img.width / sizer;
          const h = img.height / sizer;
          resolve([content, w, h])
        }
        img.onerror = () => {
          const w = img.width / sizer;
          const h = img.height / sizer;
          resolve(['', w, h])
        }
      };
      r.onerror = () => reject(r.error);
      r.onabort = () => reject(new Error('Read aborted'));
    }));
  }

  formatBase64ForPayload(base64: string) {
    if (!base64) {
      return '';
    }

    return base64;
  }

}
