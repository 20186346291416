import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedUiModule } from '@next/shared/ui';
import { TranslateModule } from '@ngx-translate/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TagSearchComponent } from './tag-components/tag-search/tag-search.component';
import { TagPillComponent } from './tag-components/tag-pill/tag-pill.component';
import { ProcessesTabComponent } from './processes-tab/processes-tab.component';
import { AddFormsModalComponent } from './add-forms-modal/add-forms-modal.component';
import { AddFormsHeaderComponent } from './add-forms-modal/components/header/add-forms-header.component';
import { FormsTableComponent } from './forms-table/forms-table.component';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { DialogModule } from 'primeng/dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccessCodePromptComponent } from './access-code-prompt/access-code-prompt.component';
import { SignaturePromptComponent } from './signature-prompt/signature-prompt.component';
import { ViewBatchSignComponent } from './signature-prompt/components/view-batch-sign/view-batch-sign.component';
import { ClinicalFormViewerComponent } from './clinical-form-viewer/clinical-form-viewer.component';
import { ManageTagsComponent } from './tag-components/manage-tags/manage-tags.component';
import { BarcodeLauncherComponent } from './barcode-launcher/barcode-launcher.component';
import { BarcodeScannerComponent } from './barcode-scanner/barcode-scanner.component';
import { DashboardMenuComponent } from './dashboard-menu/dashboard-menu.component';
import { NoSelectionComponent } from './no-selection/no-selection.component';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';
import { BeforeLoginNotificationComponent } from './before-login-notification/before-login-notification.component';
import { PickListModule } from 'primeng/picklist';
import { GridColumnModalComponent } from './grid-column-modal/grid-column-modal.component';
import { GridSettingModalComponent } from './grid-setting-modal/grid-setting-modal.component';
import { ActivityLogComponent } from './activity-log/activity-log.component';
import { FormSubmissionService } from './services/form-submission.service';
import { GridConfigurationComponent } from './grid-configuration/grid-configuration.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { FormViewComponent } from './forms-table/components/form-view/form-view.component';
import { PatientSearchComponent } from './patient-search/patient-search.component';
import { LogoService } from '@next/shared/common';
import { GridSettingSaveModalComponent } from './grid-setting-save-modal/grid-setting-save-modal.component';
import { AppointmentFormsAccordionComponent } from './appointment-forms-accordion/appointment-forms-accordion.component';
import { FormsTableInlineComponent } from './forms-table-inline/forms-table-inline.component';
import { AccordionModule } from 'primeng/accordion';
import { BadgeModule } from 'primeng/badge';
import { FormsUtilityService } from './services/forms-utility.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppointmentFlaggedFormsAccordionComponent } from './appointment-flagged-forms-accordion/appointment-flagged-forms-accordion.component';
import { FlaggedFormsTableComponent } from './flagged-forms-table/flagged-forms-table.component';
import { FlaggedFormsTableInlineComponent } from './flagged-forms-table-inline/flagged-forms-table-inline.component';
import { MoveFlaggedFormsAppointmentAccordionComponent } from './modals/move-flagged-forms-modal/move-flagged-forms-appointment-accordion/move-flagged-forms-appointment-accordion.component';
import { MoveFlaggedFormsModalComponent } from './modals/move-flagged-forms-modal/move-flagged-forms-modal.component';
import { ClinicalFooterWebComponent } from './clinical-form-viewer/components/clinical-footer-web/clinical-footer-web.component';
import { ClinicalFooterPdfComponent } from './clinical-form-viewer/components/clinical-footer-pdf/clinical-footer-pdf.component';

const components = [
  ManageTagsComponent,
  TagSearchComponent,
  TagPillComponent,
  ProcessesTabComponent,
  AddFormsModalComponent,
  AddFormsHeaderComponent,
  FormsTableComponent,
  SignaturePromptComponent,
  AccessCodePromptComponent,
  ClinicalFormViewerComponent,
  ClinicalFooterPdfComponent,
  ClinicalFooterWebComponent,
  BarcodeLauncherComponent,
  BarcodeScannerComponent,
  DashboardMenuComponent,
  NoSelectionComponent,
  NotificationModalComponent,
  BeforeLoginNotificationComponent,
  GridColumnModalComponent,
  GridSettingModalComponent,
  ActivityLogComponent,
  GridConfigurationComponent,
  ViewBatchSignComponent,
  FormViewComponent,
  PatientSearchComponent,
  GridSettingSaveModalComponent,
  AppointmentFormsAccordionComponent,
  FormsTableInlineComponent,
  AppointmentFlaggedFormsAccordionComponent,
  FlaggedFormsTableComponent,
  FlaggedFormsTableInlineComponent,
  MoveFlaggedFormsAppointmentAccordionComponent,
  MoveFlaggedFormsModalComponent,
];

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  imports: [
    MenuModule,
    InputTextModule,
    NgSelectModule,
    DialogModule,
    TableModule,
    CommonModule,
    SharedUiModule,
    TranslateModule,
    AlertModule.forRoot(),
    FontAwesomeModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    BsDropdownModule,
    TooltipModule.forRoot(),
    PickListModule,
    NgxMaskModule.forRoot(maskConfig),
    TabsModule,
    AccordionModule,
    BadgeModule,
    NgxSpinnerModule,
    PaginatorModule,
  ],
  declarations: [...components],
  exports: [...components],
  providers: [
    FormSubmissionService,
    FormsUtilityService,
    LogoService,
    DatePipe,
    TitleCasePipe,
  ],
})
export class NextClinicalFeatureSharedModule {}
