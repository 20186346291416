
export interface ExternalConnection {
  data: any,
  label: string,
  type: string,
  provider: string,
  id?: string,
  updatedOn?: string,
  createdOn?: string
}

export enum ExternalConnectionTypes {
  PaymentGateway = "paymentgateway",
  UrlShortening = "urlshortening",
  SMSGateway = "smsgateway",
  HealthcareInterfaceEngine = "healthcareinterfaceengine",
  ReportUrl = "reporturl",
  EHRIntegration = "ehrintegration"
}

export interface SphereConnection {
  sphereCustomerId: string,
  sphereLicenseKey: string,
  sphereUrl: string,
  sphereResponseUrl: string
}
