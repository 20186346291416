import {Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { StateViewerService } from '../state/state-viewer.service';
import { NextAdminService } from '@next/shared/next-services';
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Subject } from 'rxjs';
import { takeUntil, tap } from "rxjs/operators";
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'next-access-code-prompt',
  templateUrl: './access-code-prompt.component.html',
  styleUrls: ['./access-code-prompt.component.scss']
})
export class AccessCodePromptComponent implements OnInit, AfterViewInit {
  @ViewChild('codeValue') codeValue: ElementRef;
  cleanup: Subject<void> = new Subject();
  onClose: Subject<boolean> = new Subject<boolean>();

  userId: string;
  attempts = 0;
  display = false;
  invalidAccessCode = false;

  accessCode: UntypedFormGroup = new UntypedFormGroup({
    ['code']: new UntypedFormControl('', { updateOn: 'change'})
  });

  // Access Code Masking Properties
  hideCode = true;
  masking = 'ZZZZZZZZ';
  pattern: any = {
    'Z' : { pattern : /\w/, symbol : '•' },
    'Y' : { pattern : /\w/ }
  };

  constructor(
    private stateViewerSvc: StateViewerService,
    private router: Router,
    private adminSvc: NextAdminService,
    public modalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    this.stateViewerSvc.promptAccessCode$.pipe(
      takeUntil(this.cleanup),
      tap(showPrompt => {
        this.display = showPrompt;
      })
    ).subscribe();
  }

  ngAfterViewInit(): void {
    this.accessCode.valueChanges.subscribe(value => {
      setTimeout(() => {
        if (!value.code) this.invalidAccessCode = false;
      });
    });
  }

  validateCode(): void {
    const codeValue = this.accessCode.value.code;
    this.attempts += 1;
    if (this.attempts >= 10) {
      sessionStorage.clear();
      this.router.navigate(['logout/auto'], {
        queryParams: {
          currentUrl: window.location.pathname,
          logoutInactivity: true,
          isMainComponent: false
        }
      });
      return;
    }

    this.adminSvc.getPreference(this.userId, 'ACCESSCODE').subscribe(res => {
      if (codeValue === res[0].data.ACCESSCODE) {
        this.onClose.next(null);
        this.modalRef.hide();
      }
      else {
        this.invalidAccessCode = true;
      }
    });
  }

  tryAgain(): void {
    this.invalidAccessCode = false;
  }

  closeDisplay(): void {
    this.modalRef.hide();
  }

  toggleHiddenCode(): void {
    this.hideCode = !this.hideCode;
    this.masking = 'ZZZZZZZZ';
    if (this.hideCode) {
      this.pattern = {
        'Z' : { pattern : /\w/, symbol : '•' },
        'Y' : { pattern : /\w/, symbol : null }
      };
    }
    else {
      this.pattern = {
        'Z' : { pattern : /\w/, symbol : null },
        'Y' : { pattern : /\w/, symbol : null }
      };
    }
  }

  hideCodeCb(): void {
    this.hideCode = true;
    this.masking = 'ZZZZZZZZ';
    this.pattern = {
      'Z' : { pattern : /\w/, symbol : '•' },
      'Y' : { pattern : /\w/, symbol : null }
    };

  }

}
