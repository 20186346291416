<div class="container-fluid h-100" [formGroup]="gridview">
    <div class="row d-flex px-0 pb-2 align-items-center modal-header justify-content-between title">
      <div class="col-2">
        <button id='grid-close-button' class='btn-plain pointer-on-hover text_button' (click)='close(false)'>
          {{ 'SHARED_GRID.GRID_SETTINGS.CLOSE_BUTTON' | translate }}
        </button>
      </div>
      <div class="col-8 text-center">
        <h6>{{'SHARED_GRID.GRID_SETTINGS.TITLE' | translate }}</h6>
      </div>
      <div class="col-2">  
      </div>
    </div>
    <div class='d-block row'>
      <div class='d-flex flex-column' style="height: 150px;">
        <div>
          <div  class='row m-2 align-items-center'>
            <div class='col-8'>
            </div>
            <div class='col-4 text-center'>
            </div>
          </div>
        </div>

        <div class="d-flex flex-row justify-content-around p-2">
          <input data-test="new-grid-setting"
                 id="new-grid-setting"
                 class="w-75 mr-3 form-control"
                 type="text"
                 maxlength="30"
                 formControlName="newgridsetting"
                 placeholder="New Saved View"
                 (change)="canSave = true"
                 nextAutoFocusElement />
          <button 
          data-test="save-new-grid-setting-button"
          id="save-new-grid-setting-button"
          class="btn primaryButton w-20 ml-3"
          [attr.disabled]='gridview.value["newgridsetting"] || !canSave ? null : true'
          (click)="createSetting()">
        {{ 'SHARED_GRID.GRID_SETTINGS.SAVE_BUTTON' | translate }}
        </button>
        </div>
      </div>
    </div>
  </div>
