import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { MainComponent } from '@next/gx-viewer/feature-shell';
import { Subject, takeUntil, tap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { StateViewerService } from '../../../state/state-viewer.service';
import { SelectedForms } from '@next/shared/common';

@Component({
  selector: 'next-clinical-footer-web',
  templateUrl: './clinical-footer-web.component.html',
  styleUrls: ['./clinical-footer-web.component.css'],
})
export class ClinicalFooterWebComponent implements OnChanges, OnDestroy {
  @Input() viewerComponent: ComponentRef<MainComponent> = null;
  @Output() nextFormEvent: EventEmitter<null> = new EventEmitter<null>();
  obsChange: Subject<void> = new Subject<void>();

  currentPage: number;
  totalPages: number;
  loading: boolean = false;
  selectedForms: SelectedForms;

  constructor(private translateSvc: TranslateService, private viewerStateSvc: StateViewerService) {
    this.selectedForms = viewerStateSvc.selectedForms;
  }

  ngOnChanges(): void {
    this.obsChange.next();
    const viewerComponent = this.viewerComponent.instance;
    viewerComponent.instance$.pipe(
      filter(gxInstance => !!gxInstance && !!gxInstance.experience),
      filter(() => viewerComponent.viewMode$.getValue() === 'web'),
      tap(gxInstance => {
        const currentPage = viewerComponent.nxtWebViewer.selectedPage;
        const totalPages = gxInstance.experience.pages.filter(p => p.pageType === 'FULL').length;
        this.currentPage = currentPage;
        this.totalPages = totalPages;

        viewerComponent.nxtWebViewer.loading.pipe(
          tap(loading => this.loading = loading),
          takeUntil(this.obsChange)
        ).subscribe();
      }),
      takeUntil(this.obsChange)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.obsChange.complete();
  }

  previousPage(): void {
    this.viewerComponent.instance.nxtWebViewer.goPreviousPageEmbedded();
    this.currentPage = this.viewerComponent.instance.nxtWebViewer.selectedPage;
  }

  nextPage(): void {
    if (this.viewerComponent.instance.nxtWebViewer.viewerFooter.embeddedIsPageValid()) {
      this.viewerComponent.instance.nxtWebViewer.goNextPageEmbedded();
      this.currentPage = this.viewerComponent.instance.nxtWebViewer.selectedPage;
    }
  }

  saveForm(): void {
    // Do Save, just saves existing, doesn't toast, or move to next form
    this.viewerComponent.instance.nxtWebViewer.saveFormEmbedded();
  }

  finalize(): void {
    if (this.viewerComponent.instance.nxtWebViewer.viewerFooter.embeddedIsPageValid()) {
      this.viewerComponent.instance.toggleView('pdf');
    }
  }
}
