import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';
@Directive({
  selector: '[nextAutoFocusElement]'
})
export class AutoFocusElementDirective implements AfterViewInit {

  // Conditionally focus an element. If not specified, the element will always be focused.
  @Input() shouldFocus: boolean = true;

  constructor(private reference: ElementRef) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.shouldFocus) {
        this.reference.nativeElement.focus();
      }
    });
  }
}
