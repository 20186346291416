import { Component, ElementRef, forwardRef, Input, TemplateRef, ViewChild, ViewContainerRef, ComponentRef, OnDestroy, ComponentFactory, ComponentFactoryResolver } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Form, UploadFile } from '@next/shared/common';
import { PdfRendererComponent } from "./pdf-renderer/pdf-renderer.component";
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

const CROPPED_PDF_WIDTH = 740;

@Component({
  selector: 'next-pdf-crop-tool',
  templateUrl: './pdf-crop-tool.component.html',
  styleUrls: ['./pdf-crop-tool.component.css']
})
export class PdfCropToolComponent implements OnDestroy {
  @Input() pdf: Form;

  page: number;
  scale: number;
  transforms: number[] = [];
  imgFile: UploadFile;
  previewModal: BsModalRef;
  previewModalPDFCanvas: HTMLCanvasElement;
  croppedPdfBufferRef: ComponentRef<PdfRendererComponent>
  submitImage: Subject<UploadFile> = new Subject<UploadFile>();

  @ViewChild(forwardRef(() => PdfRendererComponent), {static: false}) pdfImage: PdfRendererComponent;
  @ViewChild('modalBody') modalBody: ElementRef;
  @ViewChild('extractModal') extractModal: ElementRef;
  @ViewChild("croppedPdfBufferContainer", { read: ViewContainerRef }) croppedPdfBufferContainer;
  @ViewChild('previewTemplate') private previewTemplate: TemplateRef<any>;

  constructor (
    public modalRef: BsModalRef,
    protected modalSvc: BsModalService,
    protected translateSvc: TranslateService,
    protected resolver: ComponentFactoryResolver
  ) { }

  ngOnDestroy() {
    if(this.croppedPdfBufferRef) {
      this.croppedPdfBufferRef.destroy();
    }
  }

  init(page: number = 1): void {
    this.page = page;
    this.scale = 1;

    this.modalBody.nativeElement.style.width = this.pdfImage.naturalWidth;
    this.modalBody.nativeElement.style.height = this.pdfImage.naturalHeight;

    const canvas = this.createDrawCanvas(this.pdfImage.naturalWidth, this.pdfImage.naturalHeight);
    const pdfComponent = document.getElementById('pdfComponent');
    this.modalBody.nativeElement.insertBefore(canvas, pdfComponent);
  }

  submit(): any {
    this.createBufferedPreviewModalPDF();
  }

  private fetchImage(): any{
    fetch(this.createImage()).then(
      (res: Response) => {
        const source: any = {
          fileID: this.pdf.id,
          x: this.transforms[0],
          y: this.transforms[1],
          width: this.transforms[2],
          height: this.transforms[3],
          page: this.page,
        };
        res.blob().then(
          (blob: Blob) => {
            const file: File = new File([blob], `${this.pdf.name}_img${new Date().getTime().toString()}.jpeg`, blob);
            this.imgFile = { file: file, status: null, progress: null, config: { 'source' : source}};
            this.submitImage.next(this.imgFile);
          });
      });
  }

  private createImage(): any {
    const outputCanvas = document.createElement('canvas');
    outputCanvas.width = this.previewModalPDFCanvas.width;
    outputCanvas.height = this.previewModalPDFCanvas.height;
    outputCanvas.getContext('2d').drawImage(this.previewModalPDFCanvas, 0, 0, this.previewModalPDFCanvas.width, this.previewModalPDFCanvas.height, 0, 0, outputCanvas.width, outputCanvas.height);
    return outputCanvas.toDataURL('type/jpeg', 1);
  }

  exit(): void {
    if (this.transforms.length >= 4) {
      const warnModal: BsModalRef = this.modalSvc.show(ConfirmationDialogComponent, {
        class: 'modal-confirmation',
        backdrop: true,
        ignoreBackdropClick: true,
        initialState: {
          title: this.translateSvc.get('PDFEXTRACTOR.DIALOGS.CLOSE_UNSAVED_TITLE'),
          message: this.translateSvc.get('PDFEXTRACTOR.DIALOGS.CLOSE_UNSAVED_MESSAGE'),
          cancelButton: this.translateSvc.get('PDFEXTRACTOR.DIALOGS.CLOSE_UNSAVED_CANCEL_BTN'),
          confirmButton: this.translateSvc.get('PDFEXTRACTOR.DIALOGS.CLOSE_UNSAVED_CONFIRM_BTN')
        }
      });
      warnModal.content.onClose.subscribe(confirmed => {
        if (confirmed) {
          warnModal.hide();
          this.modalRef.hide();
        }
      });
    }
    else {
      this.modalRef.hide();
    }
  }

  preview(): void {
    const initialState: any = {
      url : this.pdf.url,
      pageNum : this.page,
      scale : this.scale,
      startX : this.transforms[0],
      startY : this.transforms[1],
      width: this.transforms[2],
      height: this.transforms[3],
    };
    const config: any = {
      class: 'modal-lg',
      backdrop: true,
      keyboard: false,
      ignoreBackdropClick: true,
      initialState: initialState
    };

    this.previewModal = this.modalSvc.show(this.previewTemplate, config);
    this.extractModal.nativeElement.classList.add('d-none');
  }

  exitPreview(): void {
    this.previewModal.hide();
    this.extractModal.nativeElement.classList.remove('d-none');
  }

  pageUp(): void {
    if (this.page + 1 <= this.pdfImage.pageCount) {
      this.page++;
      this.changePage();
    }
  }

  pageDown(): void {
    if (this.page - 1 > 0 ) {
      this.page--;
      this.changePage();
    }
  }

  changePage(): void {
    this.clearCanvas(document.getElementById('drawCanvas') as HTMLCanvasElement);
    this.pdfImage.setupPage(this.pdfImage.thisPdf, this.page);
    this.init(this.page);
  }

  private clearCanvas(canvas: HTMLCanvasElement): void {
    this.transforms = [];
    const cx = canvas.getContext('2d');
    cx.clearRect(0, 0, canvas.width, canvas.height);
    cx.beginPath();
  }

  private drawShape(canvas: HTMLCanvasElement): void {
    let mouseIsDown = false;
    const cx = canvas.getContext('2d', {
      preserveDrawingBuffer: true
    }) as CanvasRenderingContext2D;
    let mouseMoveObserver: Subscription;

    fromEvent(canvas, 'mousedown').subscribe((res: MouseEvent) => {
      this.clearCanvas(canvas);
      mouseIsDown = true;

      const rect = canvas.getBoundingClientRect();
      const originPoint = {
        x: res.clientX,
        y: res.clientY
      };
      const startPoint = {
        x: res.clientX - rect.left,
        y: res.clientY - rect.top
      };
      this.transforms[0] = Math.round(startPoint.x);
      this.transforms[1] = Math.round(startPoint.y);

      mouseMoveObserver = fromEvent(canvas, 'mousemove').subscribe((movRes: MouseEvent) => {
        if (!mouseIsDown) return;
        this.transforms[2] = Math.round(movRes.clientX - originPoint.x);
        this.transforms[3] = Math.round(movRes.clientY - originPoint.y);

        window.requestAnimationFrame(() => {
          cx.clearRect(0, 0, canvas.width, canvas.height);

          // Draw shapes onto the canvas
          cx.globalCompositeOperation = 'xor';
          cx.beginPath();
          cx.fillStyle = 'gray';
          cx.fillRect(0, 0, canvas.width, canvas.height);
          cx.beginPath();
          cx.fillStyle = 'black';
          cx.fillRect(startPoint.x, startPoint.y, this.transforms[2], this.transforms[3]);
          cx.beginPath();
          cx.globalCompositeOperation = 'multiply';
          cx.rect(startPoint.x, startPoint.y, this.transforms[2], this.transforms[3]);
          cx.stroke();
          cx.beginPath();
        });
      });
    });

    fromEvent(canvas, 'mouseup').subscribe(() => {
      mouseIsDown = false;
      mouseMoveObserver.unsubscribe();
    });
  }

  private createDrawCanvas(width: number, height: number): HTMLCanvasElement {
    if (document.getElementById('drawCanvas')) {
      this.modalBody.nativeElement.removeChild(document.getElementById('drawCanvas'));
    }
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.id = 'drawCanvas'
    canvas.width = width;
    canvas.height = height;
    canvas.style.transform = 'translate3d(0,0,0)';
    canvas.style.position = 'absolute';
    canvas.style.opacity = '50%';
    canvas.style.cursor = 'crosshair';
    canvas.style.boxShadow = '0 0 10px rgba(128, 128, 128, 1)';
    canvas.style.backfaceVisibility = 'hidden';
    this.drawShape(canvas);

    return canvas;
  }

  private calculatePDFScaleForPreview(): number {
    return this.transforms[2] > 0 ? CROPPED_PDF_WIDTH / this.transforms[2] : 1;
  }

  private onPDFPreviewModalCanvasCreated(canvas: HTMLCanvasElement): void {
    this.previewModalPDFCanvas = canvas;
  }

  private createBufferedPreviewModalPDF(): void {
    this.croppedPdfBufferContainer.clear();
    const factory: ComponentFactory<PdfRendererComponent> = this.resolver.resolveComponentFactory(PdfRendererComponent);
    this.croppedPdfBufferRef = this.croppedPdfBufferContainer.createComponent(factory);

    this.croppedPdfBufferRef.instance.url = this.pdf.url;
    this.croppedPdfBufferRef.instance.scale = this.calculatePDFScaleForPreview();
    this.croppedPdfBufferRef.instance.pageNum = this.page;
    this.croppedPdfBufferRef.instance.startX = this.transforms[0];
    this.croppedPdfBufferRef.instance.startY = this.transforms[1];
    this.croppedPdfBufferRef.instance.width = this.transforms[2];
    this.croppedPdfBufferRef.instance.height = this.transforms[3];
    this.croppedPdfBufferRef.instance.styleWidth = "'100%'";

    this.croppedPdfBufferRef.instance.canvasCreated.subscribe(canvas => {
      this.onPDFPreviewModalCanvasCreated(canvas);
      this.fetchImage();
    });
  }
}
