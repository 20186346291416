<div class="container">
    <div class="row">
        <div class="card d-flex align-items-center mt-5">
          <div class="col-sm-12 d-flex align-items-center">
            <img class="w-25 mx-auto" src="../../assets/images/interlace.png" alt="interlacehealth_logo" />
          </div>
          <div class="col-sm-12 d-flex align-items-center">
            <h2 id="nova-error-title" class="mx-auto">{{ 'ERRORS.TITLE' | translate }}</h2>
          </div>
          <div class="col-sm-12 d-flex align-items-center flex-column">
            <h5 id="nova-error-message" class="mx-auto">{{ 'ERRORS.REDIRECT_ERROR' | translate }}</h5>
            <div class="d-flex justify-content-center mt-4">
              <button
                id="nova-error-logout-button"
                class="btn btn-primary"
                type="button"
                (click)="logout()">
                {{ 'LOGOUT.LOGOUT_BUTTON' | translate }}
              </button>
            </div>
          </div>
        </div>
    </div>
  </div>
