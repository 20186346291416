<div class="container-fluid p-0 modal-content" [formGroup]="gridview">
    <div class="d-flex px-0 pb-2 align-items-center modal-header justify-content-between">
      <div class="p-2">
        <button id='grid-close-button' class='btn-plain pointer-on-hover text_button' (click)='close(false)'>
          {{ 'SHARED_GRID.GRID_SETTINGS.CLOSE_BUTTON' | translate }}
        </button>
      </div>
      <div class="p-2 text-center">
        <h6>{{'SHARED_GRID.GRID_SETTINGS.TITLE' | translate }}</h6>
      </div>
      <div class="px-3">
        <button *ngIf="!isEditMode && !stateSvc.enterpriseSettings.explicitRecordSearch" 
          data-test="grid-edit-button"
          id="grid-edit-button" 
          class="btn ih-btn-primary-o d-inline-block">
          <fa-icon [icon]="['fas', 'pencil-alt']" class="color-purple icon-header" (click)="editmode(true)"></fa-icon>
        </button>   
        <button *ngIf="isEditMode" 
          data-test="grid-done-button"
          id="grid-done-button" 
          type="button" 
          class="btn primaryButton pointer-on-hover" 
          [disabled]="!enableSaveBtn()" 
          (click)="editmode(false)">
          {{ 'SHARED_GRID.GRID_SETTINGS.DONE_BUTTON' | translate }}
        </button>  
      </div>
    </div>
    <div class='list-container'>
      <div class='container-fluid h-100'>
        <div class='d-flex px-2 pt-2 justify-content-center w-100'>
           <div class='col-2 d-inline-flex align-items-center justify-content-center'>
            </div>
            
            <div 
              class='col-8 d-inline-flex align-items-center justify-content-center'>
            </div>
            
            <div *ngIf="isEditMode" class='col-2 d-inline-flex align-items-center justify-content-center'>
              {{'SHARED_GRID.GRID_SETTINGS.DEFAULT_LABEL' | translate }}  
            </div>
        </div>

        <ng-container formArrayName="settings">
          <div class="row py-2 h-100">
            <div class="col-12">
              <div id='persons-column' class="column">
                <div *ngFor='let setting of settings.controls; let i = index'
                  class='d-flex p-2 justify-content-center w-100 item' [formGroupName]="i">
                  <button
                    id="setting-btn-{{i}}"
                    [attr.data-test]="setting.value.settingname + '-button'"
                    *ngIf="!isEditMode"
                    class='d-flex p-3 justify-content-start row cursor-pointer hover-item w-100 persons-button '
                    disabled="{{!stateSvc.enterpriseSettings.explicitRecordSearch? null: 'disabled'}}"
                    (click)="selectSetting(setting.value.id, setting.value.settingname)" role="button"
                    >
                    {{ setting.value.settingname }}
                  </button>

                  <ng-container *ngIf="isEditMode">
                    <div class='col-2 d-inline-flex align-items-center justify-content-center'>
                      <button [attr.data-test]="setting.value.settingname + '-delete-button'"
                        id="delete-setting-btn-{{i}}" 
                        class="btn btn-outline-danger" 
                        nextConfirmClick
                        (clickConfirmed)="deleteSetting(i)">
                      </button>
                    </div>
                    
                    <div 
                      class='col-8 d-inline-flex align-items-center justify-content-center' 
                      (click)="rowEdit(i, $event)">
                      <input 
                        type="text" 
                        class="form-control" 
                        [attr.data-test]="setting.value.settingname + '-edit-setting-name'"
                        id='edit-setting-name_{{i}}' 
                        [maxLength]="30" 
                        formControlName="settingname"
                        [ngClass]="{'not-valid': setting.value.duplicateNameError}" 
                        (change)="$event.stopPropagation()"
                        (blur)="saveSettingName(setting.value, $event)">
                      <div *ngIf="setting.value.duplicateNameError" class="alert-text">
                        {{'SHARED_GRID.SAVE_MENU.UNIQUE_VIEW_NAME' | translate }}
                      </div>
                    </div>
                    
                    <div 
                      [attr.data-test]="setting.value.settingname + '-default-button'"
                      id="is-default-btn-{{i}}" 
                      class='col-2 d-inline-flex align-items-center justify-content-center color-purple'
                      (click)="saveSettingDefault(setting.value)">
                      <fa-icon [icon]="setting.value.isdefault ? ['fas', 'check-circle'] : ['far', 'circle']" size="lg"></fa-icon>
                    </div>

                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
       
      </div>
    </div>
  </div>
