<div class="form-group rounded" [ngClass]="{ 'd-none' : field.hidden }" [formGroup]="dateTextInputFg">
  <label for="{{ field.name }}" class="m-0 field_text">
    {{ field.text }}
    <span *ngIf="field.required" class="required bold">&nbsp;*</span>
  </label>
  <!-- Date -->
  <div class="input-group" *ngIf="field.inputType === TextboxInputType.DATE">
    <div class="input-group-prepend cursor-pointer" id="btn-date-picker-toggle" role="button"
         (click)="showPicker()" [style.pointerEvents]="field.readonly ? 'none' : 'auto'">
      <span class="input-group-text">
        <fa-icon [icon]="['far', 'calendar-alt']" class="margin-center"></fa-icon>
      </span>
    </div>
    <input [hidden]="showFormattedPickerValue"
           #pickerField
           type="date"
           id="{{ field.name + '_datePicker' }}"
           name="{{ field.name + '_datePicker' }}"
           class="form-control"
           autocomplete="off"
           formControlName="text"
           [readonly]="field.readonly"
           min="{{datePickerConfig.minDate | date: 'yyyy-MM-dd'}}"
           max="{{datePickerConfig.maxDate | date: 'yyyy-MM-dd'}}"
           (blur)="onPickerBlur(TextboxInputType.DATE)">
    <input *ngIf="showFormattedPickerValue"
           type="text"
           id="{{ field.name + '_datePicker_formatted' }}"
           class="form-control"
           autocomplete="off"
           formControlName="value"
           [readonly]="field.readonly"
           (focus)="onPickerFocus()">
  </div>
  <!-- Time -->
  <div class="input-group" *ngIf="field.inputType === TextboxInputType.TIME">
    <div class="input-group-prepend cursor-pointer" id="btn-time-picker-toggle" role="button"
         (click)="showPicker()" [style.pointerEvents]="field.readonly ? 'none' : 'auto'">
      <span class="input-group-text">
        <fa-icon [icon]="['far', 'clock']" class="margin-center"></fa-icon>
      </span>
    </div>
    <input [hidden]="showFormattedPickerValue"
           #pickerField
           type="time"
           id="{{ field.name + '_timePicker' }}"
           name="{{ field.name + '_timePicker' }}"
           class="form-control"
           autocomplete="off"
           formControlName="text"
           [readonly]="field.readonly"
           (blur)="onPickerBlur(TextboxInputType.TIME)">
    <input *ngIf="showFormattedPickerValue"
           type="text"
           id="{{ field.name + '_timePicker_formatted' }}"
           class="form-control"
           autocomplete="off"
           formControlName="value"
           [readonly]="field.readonly"
           (focus)="onPickerFocus()">
  </div>
  <!-- DateTime -->
  <div class="input-group" *ngIf="field.inputType === TextboxInputType.DATETIME">
    <div class="input-group-prepend cursor-pointer" id="btn-datetime-picker-toggle" role="button"
         (click)="showPicker()" [style.pointerEvents]="field.readonly ? 'none' : 'auto'">
      <span class="input-group-text">
        <fa-icon [icon]="['far', 'calendar-clock']" class="margin-center"></fa-icon>
      </span>
    </div>
    <input [hidden]="showFormattedPickerValue"
           #pickerField
           type="datetime-local"
           id="{{ field.name + '_dateTimePicker' }}"
           name="{{ field.name + '_dateTimePicker' }}"
           class="form-control"
           autocomplete="off"
           formControlName="text"
           [readonly]="field.readonly"
           min="{{datePickerConfig.minDate | date: 'yyyy-MM-ddTHH:mm'}}"
           max="{{datePickerConfig.maxDate | date: 'yyyy-MM-ddTHH:mm'}}"
           (blur)="onPickerBlur(TextboxInputType.DATETIME)">
    <input *ngIf="showFormattedPickerValue"
           type="text"
           id="{{ field.name + '_dateTimePicker_formatted' }}"
           class="form-control"
           autocomplete="off"
           formControlName="value"
           [readonly]="field.readonly"
           (focus)="onPickerFocus()">
  </div>
  <!------------------------
  Validation Error Message
  ------------------------->
  <ng-container *ngIf="valueFormGroup?.get('Text')">
    <span *ngIf="valueFormGroup?.get('Text').invalid && needsValidation && !newField" class="float-right text-sizing color-danger">
      {{ getFormattingError(valueFormGroup?.get('Text')) | translate:getFormattingErrorParam(valueFormGroup?.get('Text')) }}
    </span>
  </ng-container>
</div>
