import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StateViewerService } from '../..';
import {NextClinicalService, NextUhiService, UserResolverService} from "@next/shared/next-services";
import { EncryptedSession, UhiSessionMode } from "@next/shared/common";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import bwipjs from 'bwip-js';

@Component({
  selector: 'next-barcode-launcher',
  templateUrl: './barcode-launcher.component.html',
  styleUrls: ['./barcode-launcher.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BarcodeLauncherComponent implements OnInit {
  @Input() patientId: string;
  @Input() accountId: string;
  @ViewChild('barcodeModalTemplate') private barcodeModalTemplate: TemplateRef<any>;
  private barcodeModal: BsModalRef;
  barcodeImageSrc: string;
  barcodeThumbnailImageSrc: string;
  barcodeUrl: string;

  get isTestEnvironment(): boolean { return this.userSvc.isTestEnvironment; }

  constructor (
    private modalSvc: BsModalService,
    private stateViewerSvc: StateViewerService,
    private toastSvc: ToastrService,
    private translateSvc: TranslateService,
    private userSvc: UserResolverService,
    private clinicalSvc: NextClinicalService) {}

  ngOnInit() {
    // Create a blank QR code that will act as a button to open the real QR code in a modal.
    this.barcodeThumbnailImageSrc = this.createQrCode(' ');
  }

  async openBarcodeModalAsync(): Promise<void> {
    const url = await this.createUrlAsync();

    if (!url) return;

    this.barcodeUrl = url;
    this.barcodeImageSrc = this.createQrCode(url);

    this.barcodeModal = this.modalSvc.show(this.barcodeModalTemplate, {
      class: 'modal-md',
      backdrop: true,
      keyboard: true,
      ignoreBackdropClick: false
    });
  }

  closeModal(): void {
    this.barcodeModal?.hide();
  }

  /**
   * Using the selected patient, encounter, and form(s), calls the UHI service to create the encrypted payload
   * and returns a URL to load the remote viewer.
   */
  private async createUrlAsync(): Promise<string> {
    try {
      const encryptedSession: EncryptedSession = await firstValueFrom(this.clinicalSvc.getRemoteTransferSession({
          accountnumber: this.accountId,
          mrn: this.patientId
      }));

      let url = `${window.location.origin}/remote/transfer?session=${encryptedSession.urlEncoded}`;

      // If specific forms have been selected, include their ID's in the remote transfer URL.
      if (this.stateViewerSvc.selectedForms.forms.length) {
        url += `&formIds=${this.stateViewerSvc.selectedForms.forms.map(f => f.id).join(',')}`;
      }

      return url;
    } catch (e) {
      this.toastSvc.error(
        this.translateSvc.instant('BARCODE_LAUNCHER.ERROR_MESSAGE'),
        this.translateSvc.instant('BARCODE_LAUNCHER.ERROR_TITLE'),
        { disableTimeOut: true }
      );
      return '';
    }
  }

  /**
   * Encodes the data in a QR code image.
   * @param data The data to encode in the QR code.
   * @returns The data URL of the generated QR code image.
   */
  private createQrCode(data: string): string {
    try {
      const options = {
        bcid: 'qrcode',
        text: data,
        includetext: false,
        scale: 5,
        width: 50,
        height: 50,
        padding: 0
      };

      return bwipjs.toCanvas('canvas-barcode', options).toDataURL('image/png');
    } catch (e) {
      return '';
    }
  }
}
