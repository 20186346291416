import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'next-filter-box',
  templateUrl: './filter-box.component.html',
  styleUrls: ['./filter-box.component.css']
})
export class FilterBoxComponent {

  @Input() border: boolean;
  @Input() placeholder: string;
  @Input() shouldFocus: boolean;
  @Output() filterChanged: EventEmitter<string> = new EventEmitter<string>();

  filter: string;

  onFilterChanged(newValue) {
    this.filter = newValue;
    this.filterChanged.emit(this.filter);
  }
}
