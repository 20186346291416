import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GridSetting, SettingEnum } from '@next/shared/common';
import { NextAdminService } from '@next/shared/next-services';
import { ConfirmationDialogComponent } from '@next/shared/ui';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from "ngx-toastr";
import { Observable, Subject, first, lastValueFrom } from 'rxjs';
import { StoreService } from '../state/store.service';

// Interfaces for your form
interface GridSettingFormGroup {
  settings: FormArray<FormGroup<SettingFormGroup>>;
}

interface SettingFormGroup {
  id: FormControl<string
  | undefined
  >;
  settingname: FormControl<string>;
  isdefault: FormControl<boolean>;
  isedit: FormControl<boolean>;
  duplicateNameError: FormControl<boolean>;
}

@Component({
  selector: 'next-grid-setting-modal',
  templateUrl: './grid-setting-modal.component.html',
  styleUrls: ['./grid-setting-modal.component.scss']
})
export class GridSettingModalComponent implements OnInit {
  @Input() sortby: Observable<[string]>;
  @Input() filterby: Observable<any>;
  @Input() displaycolumn: Observable<any>;
  @Input() userid: string;
  @Input() screen: SettingEnum;

  isEditMode: boolean = false;
  allGridSettings: GridSetting[];
  onClose: Subject<boolean> = new Subject<boolean>();
  gridview = new FormGroup<GridSettingFormGroup>({ settings: new FormArray<FormGroup<SettingFormGroup>>([]) });

  get settings() {
    return this.gridview.get('settings') as FormArray<FormGroup<SettingFormGroup>>;
  }

  @HostListener('document:keydown.enter', ['$event'])
  onEnterPressed() {
    if (!this.gridview.valid) {
      return;
    }
  }

  constructor(
    public stateSvc: StoreService,
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private adminSvc: NextAdminService,
    private translateSvc: TranslateService,
    private toastr: ToastrService,
    private modalSvc: BsModalService
  ) {  }

  ngOnInit() {
    this.loadData();
  }

  loadData(): void {
    this.settings.disable();
    this.gridview.disable();
    this.adminSvc.getAllGridSettings(this.screen, this.userid).subscribe(data => {
      this.allGridSettings = data;
      for (const setting of data) {
        const record = this.formBuilder.group({
          id: new FormControl(setting.id, { validators: [
            Validators.required
          ]}),
          settingname: new FormControl(setting.settingname),
          isdefault: new FormControl(setting.isdefault, { validators: [
            Validators.required
          ]}),
          isedit: new FormControl(false),
          duplicateNameError: new FormControl(false)
        });
        this.settings.push(record);
      }
    });
  }

  deleteSetting(settingIndex: number) {
    const query = this.settings.controls[settingIndex].value;
    if (this.settings.controls.length > 1 && !query.isdefault) {
      this.settings.removeAt(settingIndex);
      this.gridview.markAsDirty();
    } else {
      this.toastr.error(this.translateSvc.instant('SHARED_GRID.SAVE_MENU.DELETE_REQUIREMENT_MESSAGE'),'',{disableTimeOut: true});
    }
  }

  selectSetting(id, name) {
    this.close(
      {
        id: id,
        name: name
      }
    );
  }

  close(setting) {
    if(this.gridview.dirty) {
      const config = {
        class: 'modal-confirmation',
        ignoreBackdropClick: true,
        keyboard: false,
        initialState: {
          title: this.translateSvc.get('ADMINPANEL.HL7CONFIG.CONFIRM_UNSAVED_TITLE'),
          message: this.translateSvc.get('ADMINPANEL.HL7CONFIG.CONFIRM_UNSAVED_DESC'),
          cancelButton: this.translateSvc.get('ADMINPANEL.HL7CONFIG.BTN_CANCEL'),
          confirmButton: this.translateSvc.get('ADMINPANEL.HL7CONFIG.BTN_CONFIRM')
        }
      };
      const closeModal = this.modalSvc.show(ConfirmationDialogComponent, config);
      closeModal.content.onClose.pipe(first()).subscribe(result => {
        if(result) {
          this.modalRef.hide();
        }
       });
    } else{
        this.onClose.next(setting);
        this.modalRef.hide();
    }
  }

  editmode(flag) {
    this.isEditMode = flag;
    if (this.gridview.disabled) {
      this.gridview.enable();
    }
    if(!this.isEditMode) {
      const updatedGridSettings: GridSetting[] = [];
      this.settings.controls.forEach(setting => {
        const query = this.allGridSettings.find(x => x.id == setting.value.id);
        const data: GridSetting = {
          userid: query.userid,
          id: query.id,
          isdefault: setting.value.isdefault,
          settingname: setting.value.settingname,
          displaycolumn: query.displaycolumn,
          sortby: query.sortby,
          filterby: query.filterby,
          screen: query.screen,
          daterangefilter: query.daterangefilter,
        };
        updatedGridSettings.push(data);
      });

      this.adminSvc.saveAllSetting(updatedGridSettings).pipe(first()).subscribe(() => {
        this.gridview.markAsPristine();
        this.toastr.success(this.translateSvc.instant('SHARED_GRID.SAVE_MENU.SAVE_MESSAGE'));
        const defaultSetting = updatedGridSettings.find(x=>x.isdefault);
        this.close({id: defaultSetting?.id, name: defaultSetting?.settingname});
      });
    }
  }

  rowEdit(index: number, e: Event) {
    e.stopPropagation();
    if (!this.settings.at(index).enabled) {
      this.settings.disable()
      this.settings.at(index).enable()
    }
  }

  async saveSettingName(setting, e: Event) {
      e.stopPropagation();
      const settingEventName = (e.target as HTMLInputElement).value;
      const settings = await lastValueFrom(this.adminSvc.getGridSettingByName(settingEventName))
      setting.duplicateNameError = setting.isedit = settings.filter(x =>x.id !== setting.id).length > 0;
      if(!setting.duplicateNameError){
         setting.settingname =  settingEventName;
         this.gridview.markAsDirty();
      }
  }

  saveSettingDefault(setting) {
    this.settings.controls.forEach(element => {
      if (element.value.id == setting.id) {
        element.value.isdefault = true;
      } else {
        element.value.isdefault = false;
      }
    });

    this.gridview.patchValue({
      settings: this.settings.value
    });

    this.gridview.markAsDirty();
  }

  save(data) {
    this.adminSvc.updateSetting(data).pipe(first()).subscribe(() => {
      this.toastr.success(this.translateSvc.instant('SHARED_GRID.SAVE_MENU.SAVE_MESSAGE'));
      this.loadData();
    });
  }

  enableSaveBtn() {
   return this.gridview.dirty && this.gridview.valid && this.settings.controls.every(x => !x.value.duplicateNameError)
  }
}
