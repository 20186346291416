import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from '../state/store.service';
import { CollectionDTO, CollectionJunctionDTO, CollectionType, FormDTO, FormStatus, GuidedExperienceDTO, Tag } from '@next/shared/common';
import { TagSearchComponent } from '../tag-components/tag-search/tag-search.component';
import { NextAdminService, NextExperienceService, UserResolverService } from '@next/shared/next-services';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { PreferenceType } from '../models/models';
import { StateViewerService } from '../state/state-viewer.service';
import { NgxSpinnerService } from "ngx-spinner";
import { App } from "@next/shared/ui";

export interface TreeNode {
  name: string;
  label: string;
  id: string;
  type: string;
  versionHistory: any[];
  parent: string;
  children?: TreeNode[]
}

@Component({
  selector: 'next-add-forms-modal',
  templateUrl: './add-forms-modal.component.html',
  styleUrls: ['./add-forms-modal.component.scss']
})
/**
 * The behavior of this component will change based on the provided parameters
 *
 * - treeMode             <boolean> - Present a tree navigation or a flat list
 * - showPackets          <boolean> - Present Packets in the library
 * - flattenPacketOutput  <boolean> - Packets on output are flattened into experiences
 */
export class AddFormsModalComponent implements OnInit {
  @ViewChild('tagSearch') tagSearch: TagSearchComponent;

  @Output() modalClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() modalAdd: EventEmitter<any> = new EventEmitter<any[]>();
  @Output() modalPrint: EventEmitter<any> = new EventEmitter<any>();
  @Output() modalSign: EventEmitter<any> = new EventEmitter<any>();

  active: any[] = [];                 // Selected Entries
  treeExperiences = [];               // All experiences, in tree format
  listExperiences = [];               // All experiences, in list format
  @Input() treeMode = false;          // Render tree mode navigation if true
  @Input() showPackets = true;        // Present Packets in the tree view
  @Input() flattenPacketOutput = false; // Flatten packets before emitting them in Outputs
  @Input() showTitle = false;
  @Input() hideActionPrint = false;
  @Input() hideActionSign = false;
  @Input() hideActionAdd = false;

  previous: TreeNode[][] = [];      // Array of previous node trees
  previousLabel: string[] = [''];   // Array of folder names

  checkboxSelect = false;   // Multi-select mode
  addAllCheckboxSelected = false; // Initial value for the Add All Forms radio button
  filter = '';              // Search-box Value
  tags: {activeTags: Tag[], experienceIds: Set<string>};        // Tags in use
  root: any[] = [];         // Backup of original source (list or tree)

  favFilter = false;
  criteria = [];

  constructor (
    private expSvc: NextExperienceService,
    private stateSvc: StoreService,
    public translateSvc: TranslateService,
    private adminSvc: NextAdminService,
    public userSvc: UserResolverService,
    public viewerSvc: StateViewerService,
    public spinnerSvc: NgxSpinnerService
  ) { }

  /**
   * Constructs the node tree traversable
   * in menu from flattened experiences &
   * categories collection
   *
   * @param nodes {Object[]} - An array of flattened experiencesDTOs
   *                          and categories to assemble tree with.
   * @private
   */
  private static buildTree(nodes: any[]): TreeNode[] {

    /**
     * recursive func utility
     * @param itemArray - array of objects to transform
     * @param { idKey, pKey, cKey } - node properties:
     *                                id, parent, children
     */
    const nestUtility = ({ idKey='id', pKey='parent', cKey='children'}, itemArray = []) => {
      const tree = [];
      const childrenOf = { };
      for (const item of itemArray) {
        const { [idKey]: id, [pKey]: parentId = 0 } = item;
        childrenOf[id] = childrenOf[id] || [];
        item[cKey] = childrenOf[id];
        if (item.type === CollectionType.Packet || item.collectionType === CollectionType.Packet) {
          item.children = item.experiences;
        }
        if (parentId) {
          if (Array.isArray(parentId)) {
            for (const parent of parentId) {
              if (!childrenOf[parent]) childrenOf[parent] = []; // replace undefined with empty array
              if (parent === '') tree.push(item);               // if parent is empty string add item to root
              else childrenOf[parent].push(item);               // push item to parent's children array
            }
          }
          else {
            if (!childrenOf[parentId]) childrenOf[parentId] = []; // replace undefined with empty array
            if (!parent) tree.push(item);                         // if parent is empty string add item to root
            else childrenOf[parentId].push(item);                 // push item to parent's children array
          }
        }
        else {
          tree.push(item);  // if no parent add item to root
        }
      }
      return tree;
    }
    /** end func utility */
    return nestUtility({ idKey: 'id', pKey: 'parent', cKey: 'children' }, nodes);
  }

  mapExperiences(experiences: GuidedExperienceDTO[], junctions: CollectionJunctionDTO[] = [], collections: CollectionDTO[] = [], favexperiences: string[] = []) {
    return experiences.map((exp: GuidedExperienceDTO) => {
      const iCollections: any[] = this.getExperienceCollections(exp, junctions, collections);
      const parentProperty = iCollections.map(col => col.id);
      if (!iCollections.some(el => el.type === CollectionType.Folder)) {
        parentProperty.push('');
      }
      return {
        id: exp.id,
        vid: exp.vid,
        name: exp.name,
        status: exp.status,
        version: exp.version,
        parent: parentProperty,
        category: iCollections.filter(col => col.type === CollectionType.Folder).map(folder => folder.name).join('/') || '',
        tags: exp.tags,
        type: this.translateSvc.instant('MANAGE_FORMS.FORM_LABEL'),       // hard-coded
        language: this.translateSvc.instant('MANAGE_FORMS.LANGUAGE_EN'),  // hard-coded
        pdftemplateid: exp.pdftemplateid,
        isFavorite: favexperiences.includes(exp.id),
        facilities: Array.from(JSON.parse(exp.metadatafacilities as any)),
        departments: Array.from(JSON.parse(exp.metadatadepartments as any)),
        modifiedby: exp.modifiedby
      } as any;
    });
  }

  ngOnInit() {
    this.spinnerSvc.show(App.SPINNERS.ADD_FORM_SPINNER);
    forkJoin([this.expSvc.getCollectionBundle(), this.adminSvc.getAllPreferenceForUser(this.userSvc.user.oid)]).pipe(
      map((response: any) => {
        const [
          collectionDTOs,
          junctionDTOs,
          publishedDTOs
        ] = response[0];
        const favexperiences: string[] = response[1].find(x => x.type === PreferenceType.FavoriteExperiences)?.data.EXPERIENCES.map(x=>x);
        const experiences: any[] = this.mapExperiences(publishedDTOs, junctionDTOs, collectionDTOs, favexperiences);
        const collections: any[] = this.mapCollections(publishedDTOs, junctionDTOs, collectionDTOs);
        const folders: any[] = collections.filter(coll => coll.type === CollectionType.Folder);
        const packets: any[] = collections.filter(coll => coll.type === CollectionType.Packet);
        return [ ...folders, ...packets, ...experiences ].sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : -1);
      })
    ).subscribe((dataset: any[]) => {
      this.listExperiences = this.root = (
        this.showPackets
          ? dataset.filter(element => element.type !== CollectionType.Folder)
          : dataset.filter(element => ![CollectionType.Folder, CollectionType.Packet].includes(element.type)));
      if (this.treeMode) {
        this.treeExperiences = this.root = (
          this.showPackets
            ? AddFormsModalComponent.buildTree(dataset)
            : AddFormsModalComponent.buildTree(dataset.filter(el => el.type !== CollectionType.Packet)));
        this.previous = [this.root];
        this.previousLabel = [''];
      }
      this.spinnerSvc.hide(App.SPINNERS.ADD_FORM_SPINNER);

      if (this.viewerSvc.favoriteFilter) {
        this.favFilter = true;
        this.criteria.push({operator: "equals", key: "isFavorite", value: this.favFilter});
      }

      if(this.stateSvc.selectedFacility) {
        this.criteria = this.criteria.filter(x =>x.key !== 'facilities');
        if(this.stateSvc.selectedFacility.id != '-1') {
          //'' empty string indicates that filter results should include forms with all facilites.
          this.criteria.push({operator: "contains", key: "facilities", value: new Set([this.stateSvc.selectedFacility.id, '']) });
        }
      }

      if(this.stateSvc.selectedDepartment) {
        this.criteria = this.criteria.filter(x =>x.key !== 'departments');
        if(this.stateSvc.selectedDepartment.id != '-1') {
          //'' empty string indicates that filter results should include forms with all facilites.
          this.criteria.push({operator: "contains", key: "departments", value: new Set([this.stateSvc.selectedDepartment.id, '']) });
        }
      }

      if(this.criteria.length)
        this.applyFilter();
    });

    this.tags = {
      activeTags: [],
      experienceIds: new Set<string>()
    }
  }

  mapCollections(experiences: GuidedExperienceDTO[], junctions: CollectionJunctionDTO[], collections: CollectionDTO[]) {
    return collections.map((collection: CollectionDTO) => {
      switch (collection.type) {
        case CollectionType.Folder: {
          return {
            id: collection.id,
            name: collection.name,
            parent: collection.parent,
            type: collection.type,
            expanded: collection.expanded,
          } as any;
        }
        case CollectionType.Packet: {
          const iCollections = this.getCollectionCollections(collection, junctions, collections);
          const iExperiences = this.getPacketExperiences(collection, junctions, experiences);
          return {
            id: collection.id,
            name: collection.name,
            type: collection.type,
            parent: iCollections.length ? iCollections.map(col => col.id) : [''],
            experiences: iExperiences,
            category: iCollections.filter(col => col.type === CollectionType.Folder).map(folder => folder.name).join('/') || '',
            tags: collection.tags,
            status: 'active', // hard-coded, packets can only contain published forms
            language: this.translateSvc.instant('MANAGE_FORMS.LANGUAGE_EN'),  // hard-coded
          } as any;
        }
      }
    });
  }

  getPacketExperiences(packet, junctions, experiences) {
    const iJunctions = junctions.filter(j => j.collection === packet.id);
    const iExperiences = iJunctions.map(j => experiences.find(ex => ex.id === j.element)).filter(a => a);
    return iExperiences.length
      ? iExperiences.map(exp => Object.assign(exp, { type : CollectionType.Form }))
      : [];
  }

  getExperienceCollections(experience: GuidedExperienceDTO, junctions: CollectionJunctionDTO[] = [], collections: CollectionDTO[] = []) {
    const iJunctions = junctions.filter(j => j.element === experience.id);
    const iCollections = iJunctions.map(j => collections.find(collection => collection.id === j.collection));
    const iFolders = iCollections.filter(collection => collection.type === CollectionType.Folder);
    return iFolders.length
      ? iFolders
      : [];
  }

  getCollectionCollections(collection: CollectionDTO, junctions: CollectionJunctionDTO[], collections: CollectionDTO[]) {
    const iCollections = junctions.filter(j => j.element === collection.id).map(iJunctions => collections.find(col => col.id === iJunctions.collection));
    return iCollections.length
      ? iCollections
      : [];
  }

  /**
   * Pop previous array, setting the result
   * as the current node tree
   */
  openPrevious(): void {
    if (this.previous.length > 1) {
      this.treeExperiences = this.previous.pop();
      this.previousLabel.pop();
    }
  }

  onPrint() {
    this.modalPrint.emit(this.flattenPacketOutput ? this.flatten(this.active) : this.active);
  }

  /**
   * Prune the previous arrays
   * and revert to the root tree
   */
  openRoot(): void {
    this.previous = [this.root];
    this.previousLabel = [''];
    this.treeExperiences = this.root;
  }

  /**
   * Opens a category on click.
   * Push current tree structure to previous[] array
   * then set current tree to the former's children nodes
   *
   * @param element {any[]} - The current tree node array
   */
  openFolder(element): void {
    this.previous.push(this.treeExperiences);
    this.previousLabel.push(element.name);
    this.treeExperiences = element.children;
  }

  selectExperienceCheckbox(node): void {
    if (this.active.some(el => el.id === node.id)) {
      const iNode: any = this.active.find(el => el.id === node.id);
      this.active.splice(this.active.indexOf(iNode), 1);
      if (!this.active.length) {
        this.checkboxSelect = false;
      }
    } else {
      this.active.push(node);
      this.checkboxSelect = true;
    }

    this.refreshSelectAllButton();
  }

  selectExperienceButton(node): void {
    this.checkboxSelect = false;
    this.addAllCheckboxSelected = false;

    if (this.active.length === 1 && this.active.includes(node)) {
      this.active = [];
    } else {
      this.active = [node];
    }
  }

  isSelected(node): boolean {
    return this.active.map(a => a.id).includes(node.id);
  }

  /**
   * From a set of experience ids, filter
   * the visible published experiences by experience id
   *
   * If no tags selected, show root
   *
   * If tags and experience ids, filter tree by experience ids
   *
   * If tags and no experience ids, show empty tree
   *
   * @param event {Object} - Object containing tags and experience ids
   * @property event.activeTags {Object} - The tags that are active after tag search
   * @property event.experienceIds {string[]} - Ids of experiences to show
   */
  onTagFilter(event: { activeTags: Tag[], experienceIds: Set<string> }): void {
    this.active = [];
    this.stateSvc.clearExperiencesStore();
    this.tags = event;
    this.criteria = this.criteria.filter(x =>x.key != "id");
    //add id filter only if experienceIds as avaliable.
    if (event.experienceIds.size > 0) {
      this.criteria.push({operator: "contains", key: "id", value: this.tags.experienceIds});
    }
    this.applyFilter();
  }

  flattenTree(data) {
    return data.reduce((r, { children, ...rest }) => {
      if (children) r.push(...this.flattenTree(children))
      r.push(rest)
      return r;
    }, [])
  }

  /**
   * Toggling a Tag or removing a Text filter may expose forms that were not selected
   */
  refreshSelectAllButton(): void {
    const experiences = this.treeMode ? this.treeExperiences : this.listExperiences;
    this.addAllCheckboxSelected = this.active.length === experiences.length;
  }

  /**
   * Remove an active tag filter and update
   * the menu list
   *
   * After removing a tag, if no tag filters remain
   * revert the tree list to root
   * @param e
   */
  async removeActiveTag(e: Tag): Promise<void> {
    this.tags.activeTags.splice(this.tags.activeTags.indexOf(e), 1);
    await this.tagSearch.submit();
    // Removing a Tag may expose forms that were not selected
    this.refreshSelectAllButton();
  }

  clearFilter(): void {
    this.filter = '';
    this.criteria = this.criteria.filter(x => x.key != "name");
    this.applyFilter();

    // Removing a Text filter may expose forms that were not selected
    this.refreshSelectAllButton();
  }

  onSubmit(): void {
    this.modalAdd.emit(this.active);
  }

  flatten(items: any[]): FormDTO[] {
    const forms: FormDTO[] = [];
    for (const element of items) {
      switch (element.type) {
        case CollectionType.Packet: {
          const packetContent: FormDTO[] = element.children.map(el => {
            return {
              name: el.name,
              experienceid: el.id,
              experienceversionid: el.vid,
              pdftemplateid: el.pdftemplateid,
              status: FormStatus.NotStarted,
              version: el.version,
              tags: el.tags,
              language: el.language,
              lastarchivedon: null,
              historyid: null,
              patientdataid: null,
              patientdata: null,
              appointmentid: null,
              appointment: null,
              datasets: el.datasets,
              datasetcount: el.datasetcount,
              tomovelater: false
            } as FormDTO;
          });
          forms.push(...packetContent);
          break;
        }
        case CollectionType.Form: {
          forms.push({
            name: element.name,
            experienceid: element.id,
            experienceversionid: element.vid,
            pdftemplateid: element.pdftemplateid,
            status: FormStatus.NotStarted,
            version: element.version,
            tags: element.tags,
            language: element.language,
            lastarchivedon: null,
            historyid: null,
            patientdataid: null,
            patientdata: null,
            appointmentid: null,
            appointment: null,
            datasets: element.datasets,
            datasetcount: element.datasetcount,
            tomovelater: false,
            modifiedby: element.modifiedby
          } as FormDTO);
          break;
        }
      }
    }
    return forms;
  }

  get formLabel(): { name: string } {
    return { name: this.stateSvc.enterpriseSettings.forms || this.translateSvc.instant('MANAGE_FORMS.FORM_LABEL')}
  }

  get enterpriseName(): { name: string } {
    return { name: this.stateSvc.enterpriseSettings.forms || this.translateSvc.instant('MANAGE_FORMS.MANAGE_FORMS_TITLE_DEFAULT')}
  }

  isSelectedAddAll(): boolean {
    return this.addAllCheckboxSelected;
  }

  toggleSelectAddAllCheckbox() {
    if (!this.treeMode) {
      if (!this.addAllCheckboxSelected) {
        this.active = [...this.listExperiences];
      }
      else {
        this.active = [];
      }
    }
    else {
      if (!this.addAllCheckboxSelected) {
        this.active = [...this.treeExperiences];
      }
      else {
        this.active = [];
      }
    }
    this.addAllCheckboxSelected = !this.addAllCheckboxSelected;
  }

  onFavFilter() {
    this.favFilter = !this.favFilter;
    this.viewerSvc.favoriteFilter = this.favFilter;
    this.favFilter
      ? this.criteria.push({operator: "equals", key: "isFavorite", value: this.favFilter})
      : this.criteria = this.criteria.filter(x => x.key != "isFavorite");
    this.applyFilter();
  }

  onSearch(event){
    this.criteria = this.criteria.filter(x => x.key != "name");
    if (event.target.value.length) {
       this.criteria.push({operator: "includes", key: "name", value: event.target.value});
    }
    this.applyFilter();
  }

  private applyFilter(): void {
    if (!this.criteria.length) {
      this.treeExperiences = this.treeMode ? this.root : [];
      this.listExperiences = this.treeMode ? [] : this.root;
    }
    else {
      const flattenTree = this.flattenTree(this.root);
      this.treeExperiences = this.treeMode ? this.filterData(flattenTree, this.criteria)  : [];
      this.listExperiences = this.treeMode ? [] : this.filterData(flattenTree, this.criteria) ;
    }
  }

  private filterData(data, criteria) {
    const predicates = {
      equals: (key, value) => (obj) => obj[key] === value,
      includes: (key, value: string) => (obj) => obj[key].toLowerCase().includes(value.toLowerCase()),
      indexOf: (key, value: string) => (obj) => (obj[key]??[]).indexOf(value) > -1,
      contains: (key, value: Set<string>) => (obj) => value.size> 0 && (Array.isArray(obj[key])
        ? [...value].some(x =>
            {
              const val = Array.from(obj[key])
              return (val.length === 0 && x === '') || val.includes(x)
            })
        : value.has(obj[key])
      )
    };

    return criteria.map(({ operator, key, value }) => predicates[operator](key, value))
      .reduce((acc, condition) => acc.filter(condition), data);
  }
}
