import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgswEventsService } from '@next/shared/ui';
import { IconService } from '@next/shared/common';

@Component({
  selector: 'next-root',
  template: `
    <router-outlet></router-outlet>
    <ngx-spinner name="designer-spinner" [fullScreen]="true" size="medium" color="#000000" type="ball-clip-rotate"></ngx-spinner>
  `
})
export class AppComponent {
  constructor(translate: TranslateService, iconService: IconService, private ngswEventsSvc: NgswEventsService) {
    translate.use('en');
    iconService.addIcons();
    this.ngswEventsSvc.subscribeToServiceWorkerEvents();
  }
}
