import {Component, EventEmitter, Input, Output} from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'next-add-forms-header',
  templateUrl: './add-forms-header.component.html',
  styleUrls: ['./add-forms-header.component.scss']
})
export class AddFormsHeaderComponent {
  @Input() active: any[] = [];
  @Input() showTitle: boolean = false;
  @Input() title: string = '';
  @Input() hideActionPrint: boolean = false;
  @Input() hideActionSign: boolean = false;
  @Input() hideActionAdd: boolean = false;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() print: EventEmitter<void> = new EventEmitter<void>();
  @Output() sign: EventEmitter<any> = new EventEmitter<void>();
  @Output() add: EventEmitter<void> = new EventEmitter<void>();

  constructor (public translateSvc: TranslateService) { }
}
