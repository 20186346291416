import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FormSubmission } from '@next/shared/common';
import { Injectable } from '@angular/core';
import { NextSubmissionService } from '../submission.service';

@Injectable({
  providedIn: 'root',
})
export class NextSubmissionResolverService {
  constructor(private submissionSvc: NextSubmissionService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<FormSubmission> | Observable<Partial<FormSubmission>> | Observable<never> {
    if (route.queryParams.formId) {
      return this.submissionSvc.getSubmission(route.queryParams.formId).pipe(
        catchError((err) => {
          // If none found, return a partial FormSubmission of just ID, this way the viewer
          // will respect this UUID as the Form.ID on form create
          if (err.status === 404) {
            return of({
              id: route.queryParams.formId
            });
          }

          // If the call actually failed, let the caller know.
          return throwError(() => err);
        }));
    }
    return of(null);
  }
}
