import { AppPattern } from "../consants/pattern.constant";

export class Utility {
  static isValidUrl(url: string, regExp?: RegExp) {
    if (!url || url.length < 5) {
      return false;
    }

    if (regExp) {
      return regExp.test(url);
    }

    return AppPattern.URL_LOCAL.test(url) || AppPattern.URL.test(url);
  }

  static getContentAfterPortalHost(url: string) {
    if (!url || url.length < 5 || !AppPattern.URL_CONTENT_AFTER_HOST.test(url)) {
      return "/";
    }
    return (url.match(AppPattern.URL_CONTENT_AFTER_HOST)[1])
  }

  static isValidEmail(email: string) {
    if (!email || email.length < 5) {
      return false;
    }
    return AppPattern.EMAIL.test(email);
  }

  // if not an array, convert to an array; null => [null], {} => [{}], [{}] => [{}], [{}, {}] => [{}, {}]
  static convertToArray(items: any[] = null, item: any = null) {
    let itemArr = [null];
    if (items) {
      itemArr = [].concat(items);
    } else if (item) {
      itemArr = [].concat(item);
    }
    return itemArr;
  }

  /**
   * https://stackoverflow.com/questions/8847109/formatting-the-date-time-with-javascript
   * @param sFormat {string} - Preferred format
   * @param date {Date}      - JS Date Object or Date string to convert to string
   * @returns {*}
   */
  static toFormattedDateStr(sFormat, date) {
    if (!(date instanceof Date)) date = new Date(date);                   // If a String date is provided instead of a Date object, attempt conversion
    if (!(date instanceof Date && !isNaN(<any>date))) date = new Date();  // If String date conversion failed, new Date object
    const nDay = date.getDay(),
      nDate = date.getDate(),
      nMonth = date.getMonth(),
      nYear = date.getFullYear(),
      nHour = date.getHours(),
      aDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      aMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      aDayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334],
      isLeapYear = function() {
        return (nYear%4===0 && nYear%100!==0) || nYear%400===0;
      },
      getThursday = function() {
        const target = new Date(date);
        target.setDate(nDate - ((nDay+6)%7) + 3);
        return target;
      },
      zeroPad = function(nNum, nPad) {
        return ('' + (Math.pow(10, nPad) + nNum)).slice(1);
      };

    const getShortTimeZone = function() {
      const options: Intl.DateTimeFormatOptions = { timeZoneName: 'short' };
      // all available locales on the user's browser
      const allLocales = Intl.DateTimeFormat.supportedLocalesOf(
        [...new Set([...navigator.languages, navigator.language, 'en-US'])]
      );
      const formatter = new Intl.DateTimeFormat(allLocales, options);
      const parts = formatter.formatToParts(date);
      const timeZonePart = parts.find(part => part.type === 'timeZoneName');
      return timeZonePart ? timeZonePart.value : '';
    };

    return sFormat.replace(/%[a-z]/gi, function(sMatch) {
      return {
        '%a': aDays[nDay].slice(0,3),
        '%A': aDays[nDay],
        '%b': aMonths[nMonth].slice(0,3),
        '%B': aMonths[nMonth],
        '%c': date.toUTCString(),
        '%C': Math.floor(nYear/100),
        '%d': zeroPad(nDate, 2),
        '%e': nDate,
        '%F': date.toISOString().slice(0,10),
        '%G': getThursday().getFullYear(),
        '%g': ('' + getThursday().getFullYear()).slice(2),
        '%H': zeroPad(nHour, 2),
        '%I': zeroPad((nHour+11)%12 + 1, 2),
        '%j': zeroPad(aDayCount[nMonth] + nDate + ((nMonth>1 && isLeapYear()) ? 1 : 0), 3),
        '%k': '' + nHour,
        '%l': (nHour+11)%12 + 1,
        '%m': zeroPad(nMonth + 1, 2),
        '%M': zeroPad(date.getMinutes(), 2),
        '%p': (nHour<12) ? 'am' : 'pm',
        '%P': (nHour<12) ? 'AM' : 'PM',
        '%s': Math.round(date.getTime()/1000),
        '%S': zeroPad(date.getSeconds(), 2),
        '%u': nDay || 7,
        '%V': (() => {
          const target = getThursday(),
            n1stThu = target.valueOf();
          target.setMonth(0, 1);
          const nJan1 = target.getDay();
          if (nJan1!==4) {
            target.setMonth(0, 1 + ((4-nJan1)+7)%7);
          }
          return zeroPad(1 + Math.ceil((n1stThu - target.valueOf())/604800000), 2);
        })(),
        '%w': '' + nDay,
        '%x': date.toLocaleDateString(),
        '%X': date.toLocaleTimeString(),
        '%y': ('' + nYear).slice(2),
        '%Y': nYear,
        '%z': date.toTimeString().replace(new RegExp(/.+GMT([+-]\d+).+/), '$1'),
        '%Z': getShortTimeZone()
      }[sMatch] || sMatch;
    });
  }
}
