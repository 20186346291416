import { Component, Input, OnInit } from '@angular/core';
import { NextFormService } from '@next/shared/next-services';
import {
  AppointmentForms,
  EnterpriseAppointmentAccordionHeaderConfig,
  FormType,
} from '@next/shared/common';
import { FormsUtilityService } from '../../../services/forms-utility.service';
import { Router } from '@angular/router';
import { StoreService } from '../../../state/store.service';
import { TranslateService } from '@ngx-translate/core';
import { AppointmentFormsSelected } from '../../../appointment-forms-accordion/appointment-forms-accordion.component';

@Component({
  selector: 'next-move-flagged-forms-appointment-accordion',
  templateUrl: './move-flagged-forms-appointment-accordion.component.html',
  styleUrls: ['../../../appointment-forms-accordion/appointment-forms-accordion.component.scss']
})
export class MoveFlaggedFormsAppointmentAccordionComponent implements OnInit {
  protected readonly FormType = FormType;

  @Input() fromMoveFormsModal: boolean = false;
  @Input() appointmentForms: AppointmentForms[] = [];
  appointmentFormsSelected: AppointmentFormsSelected[];
  readonly appointmentHeaderConfiguration: EnterpriseAppointmentAccordionHeaderConfig;

  constructor (
    protected formSvc: NextFormService,
    protected formsUtilSvc: FormsUtilityService,
    protected router: Router,
    protected storeSvc: StoreService,
    protected translateSvc: TranslateService) {
    this.appointmentHeaderConfiguration = this.storeSvc.enterpriseSettings.appointmentHeaders;
  }

  ngOnInit(): void {
    this.appointmentFormsSelected = this.appointmentForms.map(appointmentForm => new AppointmentFormsSelected(appointmentForm.appointment, appointmentForm.forms, true));
  }
}
