import { Component } from '@angular/core';
import { TextboxComponent } from '../../textbox.component';

@Component({
  selector: 'next-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['../../textbox.component.css']
})
export class PhoneComponent extends TextboxComponent {
  phonePattern: RegExp = new RegExp(/(^\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$)|(^\d{10}$)/);
}
