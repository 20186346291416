<div class="d-flex">
  <div class="p-2 ml-auto">
    <button
      type="button"
      class="btn rounded-pill shadow-sm mr-2"
      (click)="commandPrint()"
      [disabled]="selectedForms.length < 1">
      {{ 'PATIENT_FORMS.ADD_FORMS.PRINT' | translate }}
    </button>
    <button
      id="fill-out-form"
      type="button"
      class="btn rounded-pill shadow-sm mr-2"
      (click)="fillOutForm()"
      [disabled]="selectedForms.length < 1">
      {{ 'PATIENT_FORMS.ADD_FORMS.FILL_OUT' | translate }}
    </button>
    <button class="d-inline-block p-0 add-form-btn" id="add-form"  (click)="showAddForm()">
      <fa-icon [icon]="['fas', 'plus']" style="font-size: 1rem;"></fa-icon><span style="font-size: 1rem;">&nbsp;{{ 'PATIENT_FORMS.ADD_FORMS.ADD_FORM' | translate }}</span>
    </button>
  </div>
</div>

<div class='height-remaining'>
  <p-table
    [value]="forms"
    [dataKey]="'id'"
    [selectionMode]="'multiple'"
    [metaKeySelection]="true"
    [selection]="selectedForms"
    [columns]="columnHeaders"
    [rowHover]="true"
    [scrollable]="true"
    (selectionChange)='selectionChanged($event)'
    [resizableColumns]="true"
    responsiveLayout="scroll"
    columnResizeMode="expand"
    [scrollHeight]="'flex'">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class='min-btn-width' id="header-checkbox">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th *ngFor="let col of columns" [id]="col.field" pResizableColumn [pSortableColumn]="col['field'] === 'status' ? 'statusorder' : col['field']" class='text-overflow' [ngStyle]="{'display': col.field === 'statusorder'? 'none':''}">
          <div class="d-flex justify-content-between align-items-center">
            {{ col.header }}
            <p-sortIcon field="{{col['field'] === 'status' ? 'statusorder' : col['field'] }}"></p-sortIcon>
            <p-columnFilter type="text" [field]="col.field" display="menu"></p-columnFilter>
          </div>
        </th>
        <th id="additional">
          {{ 'PATIENT_FORMS.COLUMN_HEADERS.ADDITIONAL' | translate }}
        </th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-formRow
      let-rowIndex="rowIndex"
      let-columns="columns">
      <tr id='{{ "assigned-form_" + rowIndex }}'>
        <td class='min-btn-width'>
          <p-tableCheckbox (click)="$event?.stopPropagation(); menu.hide();" [value]="formRow"></p-tableCheckbox>
        </td>
        <td *ngFor="let col of columns" class='text-overflow align-middle' (click)="rowClicked(formRow)" [ngStyle]="{'display': col.field === 'statusorder'? 'none':''}">
          <ng-container *ngIf="col.field === 'name' && formRow['tomovelater']">
            <fa-icon [icon]="['fas', 'history']" flip="horizontal" class="text-muted"></fa-icon>
          </ng-container>
          <ng-container *ngIf="col.field !== 'updatedon' && col.field !== 'lastarchivedon' && col.field !== 'formType' && col.field !== 'statusorder'">
            {{col.field === 'status' ? formatStatus(formRow[col.field]): formRow[col.field]}}
          </ng-container>
          <ng-container *ngIf="col.field === 'lastarchivedon'">
            {{ formRow["lastarchivedon"] | date:'M/d/yyyy h:mm a'}}
          </ng-container>
          <ng-container *ngIf="col.field === 'updatedon'">
            {{( formRow["updatedon"] | date:'M/d/yyyy h:mm a') }}
          </ng-container>
          <ng-container *ngIf="col.field === 'formtype'">
            IH Platform
          </ng-container>
        </td>
        <td>
          <button class="d-inline-block p-0 options" (click)="rowMenuClicked(formRow); menu.toggle($event);">
            <fa-icon [icon]="['fas', 'ellipsis-h']" size="lg"></fa-icon>
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
