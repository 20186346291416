<div class="modal-header">
  <button id='batch-sign-close' type="reset" class="close pull-right" aria-label="close" (click)="exitModal.emit()">
    <fa-icon [icon]='["fas", "times-circle"]'></fa-icon>
  </button>
</div>
<div class="modal-body px-0">
  <div class="container-fluid">
    <div class="row form-group pb-2">
      <div class="col-12 text-center d-flex flex-column align-items-center" [formGroup]="codeFG">
        <span class="font-weight-bold">{{ "SIGNATURE_PROMPT.CODE_EXTRA_1" | translate}}</span>
        <span>{{ "SIGNATURE_PROMPT.CODE_EXTRA_2" | translate}}</span>
        <div class='input-group'>
          <input
            id="access-code-input-batch"
            type="text"
            aria-label="access-code-input-no-batch"
            class="form-control access-code"
            formControlName="code"
            autofocus='autofocus'
            autocomplete="off"
            [mask]='masking'
            [maxLength]='8'
            [patterns]='pattern'
            [hiddenInput]='true'
            [ngClass]="{ 'is-invalid': invalid }"
            (focusout)='masking="ZZZZZZZZ"'
            (keydown.enter)="masking='ZZZZZZZZ';submitSign();"/>
          <div *ngIf="invalid" class="invalid-feedback">
            {{ "SIGNATURE_PROMPT.INVALID_MATCH" | translate:attemptsObject }}
          </div>
          <div class='input-group-append'>
            <button id='view-code-toggle' class='btn btn-outline-secondary' type='button' (click)='toggleHideCode()'>
              <fa-icon [icon]='hideCode ? ["fas", "eye"] : ["fas", "eye-slash"]'></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row flex-wrap justify-content-around">
      <button class="btn-sign p-2" id="cancel-btn" (click)="exitModal.emit()">
        {{"SIGNATURE_PROMPT.CANCEL" | translate }}
      </button>
      <button class="btn-sign p-2" id="sign-btn" (click)="submitSign()" type="button" [disabled]="accessCodeDisabled">
        {{"SIGNATURE_PROMPT.SIGN" | translate }}
      </button>
    </div>
  </div>
</div>
