import {
  ApiService,
  FormFastConfig,
  FormFastConfigService,
  TokenService,
  PermissionSet, Permission
} from '@next/shared/common';
import {  Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionService extends ApiService {

  constructor(@Inject(FormFastConfigService) config: FormFastConfig, tokenSvc: TokenService, private http: HttpClient) {
    super(config, tokenSvc);
  }
  getPermissionSets(): Observable<PermissionSet[]> {
    return this.protectedEndpoint<PermissionSet[]>(
      this.http.get<PermissionSet[]>(`${this.config.apiUrl}permission/permissionset`, this.getHeaders())
    );
  }

  getActivePermissionsbyPermissionSetId(permissionSet): Observable<Permission[]> {
    return this.protectedEndpoint<Permission[]>(
      this.http.get<Permission[]>(`${this.config.apiUrl}permission/permissionset/${permissionSet}/permissions`, this.getHeaders())
    );
  }

  getAllPermissions(): Observable<Permission[]> {
    return this.protectedEndpoint<Permission[]>(
      this.http.get<Permission[]>(`${this.config.apiUrl}permission`, this.getHeaders()).pipe(map(permissions => {
        const privileges = permissions.filter(permission => permission.scope);
        const scopes = permissions.filter(permission => !permission.scope);
        // Sort permissions by scope so that each privilige (i.e. rows that contain a non-null scope property) are directly below their scope.
        return scopes.reduce((acc, scope) => {
          const scopePermissions = privileges.filter(privilege => privilege.scope === scope.id);
          scopePermissions.unshift(scope);
          return [...acc, ...scopePermissions];
        }, [] as Permission[]);
      }))
    );
  }

  postPermissionSet(permissionSet): Observable<PermissionSet> {
    return this.protectedEndpoint<PermissionSet>(
      this.http.post<PermissionSet>(`${this.config.apiUrl}permission/permissionset`,permissionSet ,this.getHeaders())
    );
  }

  putPermissionSetPermissions(permissionToPermissionSet, permissionSetId): Observable<boolean> {
    return this.protectedEndpoint<boolean>(
      this.http.put<boolean>(`${this.config.apiUrl}permission/permissionset/${permissionSetId}/permissions`,permissionToPermissionSet ,this.getHeaders())
    );
  }

  putPermissionSet(permissionSet: PermissionSet, permissionSetId): Observable<boolean> {
    return this.protectedEndpoint<boolean>(
      this.http.put<boolean>(`${this.config.apiUrl}permission/permissionset/${permissionSetId}`,permissionSet ,this.getHeaders())
    );
  }

  deletePermissionSetPermissions(permissionSetId: string): Observable<boolean> {
    return this.protectedEndpoint<boolean>(
      this.http.delete<boolean>(`${this.config.apiUrl}permission/permissionset/${permissionSetId}`,this.getHeaders())
    );
  }

  getPermissionSet(permissionSetId: string): Observable<PermissionSet> {
    return this.protectedEndpoint<PermissionSet>(
      this.http.get<PermissionSet>(`${this.config.apiUrl}permission/permissionset/${permissionSetId}`,this.getHeaders())
    );
  }

}
