import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import {
  Appointment,
  AppointmentFormStatus,
  Facility,
  FormDTO,
  FormEmbedded,
  FormStatus,
  FormStatusSortOrder,
  FormType,
  GxProcessing,
  Image,
  LogoService,
  Patient,
  PatientFormStateDisplay,
  SelectedForms,
} from '@next/shared/common';
import {
  ArchiveService,
  NextAdminService,
  NextExperienceService,
  NextFormService, NextImageService,
  PatientService,
  UserResolverService,
} from '@next/shared/next-services';
import { App, ConfirmationDialogComponent } from '@next/shared/ui';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MenuItem } from 'primeng/api';
import { Observable, Subject, forkJoin, mergeMap, takeUntil } from 'rxjs';
import { AddFormsModalComponent } from '../add-forms-modal/add-forms-modal.component';
import { NgxSpinnerService } from "ngx-spinner";
import { MoveFlaggedFormsModalComponent } from '../modals/move-flagged-forms-modal/move-flagged-forms-modal.component';
import { FlaggedFormsService } from '../services/flagged-forms.service';
import { FormsUtilityService } from '../services/forms-utility.service';
import { StateViewerService } from '../state/state-viewer.service';
import { StoreService } from '../state/store.service';

@Component({
  selector: 'next-forms-table',
  templateUrl: './forms-table.component.html',
  styleUrls: ['./forms-table.component.scss'],
})
export class FormsTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() prefill: any = {};
  @Input() assignToId: string;
  @Input() patientData: Patient;
  @Input() assignType: FormType;
  @Input() enterpriseAppointmentName: string;
  @Output() appointmentStatusChanged: EventEmitter<string> = new EventEmitter<string>();
  observerCleanup$: Subject<void> = new Subject();

  patientName: string;
  columnHeaders: any[] = []; // primeNG Table config
  menuItems: MenuItem[] = []; // primeNG Menu config

  modalRef: BsModalRef;
  shareMessage: string;
  forms: FormDTO[] = [];
  archiveLabel: string;
  viewLabel: string;
  facilityList: Facility[] = [];
  images: Image[] = [];
  servicingFacility: string;
  selectedForm: FormDTO;
  selectedForms: FormDTO[] = [];
  hasFlaggedFormsForAppointment: boolean = false;

  constructor(
    protected userSvc: UserResolverService,
    protected modalSvc: BsModalService,
    protected experienceSvc: NextExperienceService,
    protected translateSvc: TranslateService,
    protected formSvc: NextFormService,
    public stateViewerSvc: StateViewerService,
    protected router: Router,
    protected toastrSvc: ToastrService,
    protected patientSvc: PatientService,
    protected archiveSvc: ArchiveService,
    protected stateSvc: StoreService,
    protected gxProcessing: GxProcessing,
    protected changeDetectorRef: ChangeDetectorRef,
    protected logoService: LogoService,
    protected imageSvc: NextImageService,
    protected nextAdminService: NextAdminService,
    protected spinnerSvc: NgxSpinnerService,
    protected formsUtilSvc: FormsUtilityService,
    protected flaggedFormsSvc: FlaggedFormsService
  ) {}

  ngOnInit() {
    this.patientName = `${this.patientData.firstname} ${this.patientData.lastname}`;
    this.loadMenuSettings();
    this.loadFacilityAndImagesData();
  }

  ngOnDestroy(): void {
    this.observerCleanup$.next();
    this.observerCleanup$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.assignToId?.currentValue) {
      this.stateViewerSvc.selectedForms = new SelectedForms(null);
      this.selectedForms = [];
      this.selectedForm = null;
      this.loadData();
    }
  }

  selectionChanged(forms: FormDTO[]): void {
    // PrimeNG table row change CB (only executes on checkbox click)
    this.selectedForms = forms;
    this.updateStateServiceSelectedForms();
  }

  rowMenuClicked(form: FormEmbedded): void {
    // PrimeNG table row context ellipsis menu clicked CB
    if (!this.selectedForms.some(f => f.id === form.id)) {
      this.selectedForms = [form];
      this.updateStateServiceSelectedForms();
    }

    this.selectedForm = form;
    this.menuItems.find(x => x.label === this.archiveLabel).disabled = form.status.toLowerCase() === FormStatus.NotStarted;
  }

  rowClicked(form: FormEmbedded): void {
    // PrimeNG table row click row CB
    this.servicingFacility = form.servicingfacility;
    this.selectedForms = [form];
    this.updateStateServiceSelectedForms();
  }

  protected updateStateServiceSelectedForms(): void {
    const updatedForms = this.stateViewerSvc.selectedForms;
    updatedForms.removeMultiple(this.forms);
    updatedForms.AddMultiple(this.selectedForms);
    this.stateViewerSvc.selectedForms = updatedForms;
  }

  async showAddForm(): Promise<void> {
    this.modalRef = this.modalSvc.show(AddFormsModalComponent, {
      ignoreBackdropClick: false,
      keyboard: true,
      class: 'modal-lg',
      initialState: {
        treeMode: true,
        showPackets: true,
        flattenPacketOutput: true,
        showTitle: false,
        hideActionPrint: false,
        hideActionSign: false,
        hideActionAdd: false
      }
    });
    this.modalRef.content.modalClose.pipe(
    ).subscribe(() => this.modalRef.hide());
    this.modalRef.content.modalPrint.pipe(
    ).subscribe((selected) => {
      if (selected.length) this.printCallback(selected);
    });
    this.modalRef.content.modalSign.pipe(
    ).subscribe(async (selected) => {
      if (selected.length) {
        this.modalRef.hide();
        const response: FormDTO[] = await this.addCallback(selected);
        await this.signCallback(response);
      }
    });
    this.modalRef.content.modalAdd.pipe(
    ).subscribe((selected) => {
      if (selected.length) {
        this.modalRef.hide();
        this.addCallback(selected);
      }
    });
  }

  async printCallback(items: any[]): Promise<void> {
    const { Patient: patient, Appointment: appointment } = this.getPrefill();
    const forms: FormDTO[] = (items || []).map((item) => ({
      ...item,
      formid: item.id,
      personid: this.patientData.id,
      patientdataid: patient.id,
      patientdata: patient,
      appointmentid: appointment?.id,
      appointment: appointment,
      firstname: patient.firstname,
      lastname: patient.lastname,
      mrn: patient.mrn,
      servicingfacility: appointment?.servicingfacility
    } as FormDTO));
    this.servicingFacility = appointment?.servicingfacility;
    await this.commandPrint(forms);
  }

  async signCallback(items: any[]) {
    const { Patient: patient, Appointment: appointment } = this.getPrefill();
    const forms: FormDTO[] = (items || []).map((item) => ({
      ...item,
      formid: item.id,
      personid: patient.id,
      patientdataid: patient.id,
      patientdata: patient,
      appointmentid: appointment?.id,
      appointment: appointment,
      firstname: patient.firstname,
      lastname: patient.lastname,
      mrn: patient.mrn,
      servicingfacility: appointment?.servicingfacility
    } as FormDTO));
    this.stateViewerSvc.selectedForms = new SelectedForms(forms);
    this.fillOutForm();
  }

  async addCallback(items: any[]): Promise<FormDTO[]> {
    const { Patient: patient, Appointment: appointment } = this.getPrefill();
    const forms: FormDTO[] = (items || []).map((item) => ({
      ...item,
      formid: item.id,
      personid: patient.id,
      patientdataid: patient.id,
      patientdata: patient,
      appointmentid: appointment?.id,
      appointment: appointment,
      firstname: patient.firstname,
      lastname: patient.lastname,
      mrn: patient.mrn,
      servicingfacility: appointment?.servicingfacility
    } as FormDTO));
    try {
      const result: FormDTO[] = await this.formSvc.bulkFormInsert(forms).toPromise();
      this.loadData();
      return result;
    } catch (e) {
      this.toastrSvc.error(e.message, '', { disableTimeOut: true });
      return [];
    }
  }

  async fillOutForm(viewMode = false) {
    this.stateViewerSvc.disableParameterModal = false;
    this.stateViewerSvc.viewMode = viewMode;
    await this.router.navigate(['form/viewer']);
  }

  formatStatus(statusString: string): string {
    return PatientFormStateDisplay[statusString.toUpperCase()];
  }

  statusOrder(statusString: string): number {
    return FormStatusSortOrder[statusString.toUpperCase()];
  }

  async commandView() {
    try {
      await this.formsUtilSvc.viewFormAsync(this.selectedForm);
    } catch (e) {
      this.toastrSvc.error(this.translateSvc.instant('TOASTR_MESSAGE.VIEW_FORM_ERROR'),'',{disableTimeOut: true});
    }
  }

  commandDelete(): void {
    const config = {
      initialState: {
        title: this.translateSvc.get('CONFIRMATION_DEL.TITLE'),
        message: this.translateSvc.get('CONFIRMATION_DEL.TASK'),
        cancelButton: this.translateSvc.get('CONFIRMATION_DEL.CANCEL'),
        confirmButton: this.translateSvc.get('CONFIRMATION_DEL.CONFIRM')
      },
      class: 'modal-confirmation',
      ignoreBackdropClick: true,
      keyboard: false
    };
    this.modalRef = this.modalSvc.show(ConfirmationDialogComponent, config);
    this.modalRef.content.onClose.subscribe(result => {
      if (result) {
        this.spinnerSvc.show(App.SPINNERS.MAIN_SPINNER);
        this.formSvc.removeFormsFromModel(this.stateViewerSvc.selectedForms.forms).subscribe(() => {
          this.loadData();
        });
        this.spinnerSvc.hide(App.SPINNERS.MAIN_SPINNER);
        this.selectedForms = [];
        this.stateViewerSvc.selectedForms = new SelectedForms(null);
      }
    }, (err) => {
      this.spinnerSvc.hide(App.SPINNERS.MAIN_SPINNER);
      this.toastrSvc.error(err.message, '', {disableTimeOut: true});
    });
  }

  commandSign(): void {
    this.fillOutForm();
  }

  commandArchive(): void {
    this.spinnerSvc.show(App.SPINNERS.MAIN_SPINNER);
    const archivalObservables: Array<Observable<unknown>> = [];
    for (const form of this.stateViewerSvc.selectedForms.forms) {
      const http$: Observable<unknown> = this.archiveSvc.archive(form.id);
      archivalObservables.push(http$);
    }

    forkJoin(archivalObservables).subscribe({
      next: () => {
        this.loadData();
        this.spinnerSvc.hide(App.SPINNERS.MAIN_SPINNER);
        this.toastrSvc.success(this.translateSvc.instant('TOASTR_MESSAGE.ARCHIVE_SUCCESS'));
      },
      error: () => {
        this.spinnerSvc.hide(App.SPINNERS.MAIN_SPINNER);
        this.toastrSvc.error(this.translateSvc.instant('TOASTR_MESSAGE.ARCHIVE_ERROR'), '', {disableTimeOut: true});
      }
    });
  }

  async commandPrint(opForms: FormDTO[] = null): Promise<void> {
    try {

      const forms: FormDTO[] = opForms || this.stateViewerSvc.selectedForms.forms;

      await this.formsUtilSvc.printFormsAsync(forms);
    } catch (e) {
      this.toastrSvc.error(this.translateSvc.instant('TOASTR_MESSAGE.PRINT_FORM_ERROR'), '', {disableTimeOut: true});
    }
  }

  loadData() {
    if (this.assignType == FormType.Patient) {
      this.callGetFormsByPatientObservable();
    } else if (this.assignType == FormType.Appointment) {
      this.callGetFormsByAppointmentObservable();
    }
  }

  protected callGetFormsByPatientObservable(): void {
    this.formSvc.getFormsByPatients(this.assignToId).subscribe({
      next: (forms: FormDTO[]) => {
        this.getFormsByPatient(forms);
      },
      error: (error) => {
        this.toastrSvc.error(error.message, '', {disableTimeOut: true});
      }
    });
  }

  // This is overridden in the flagged forms table component
  protected callGetFormsByAppointmentObservable(): void {
    this.formSvc.getFormsByAppointments(this.patientData.id, this.assignToId).subscribe({
      next: (forms: FormDTO[]) => {
        this.getFormsByAppointment(forms);
      },
      error: (error) => {
        this.toastrSvc.error(error.message, '', {disableTimeOut: true});
      }
    });
  }

  private sortForms(forms: FormDTO[]): void {
    this.forms = forms ?? [];
    for (const form of this.forms) {
      form.statusorder = this.statusOrder(form.status);
    }
    this.forms.sort((a, b) => { return a.statusorder - b.statusorder || (a.name > b.name ? 1:- 1) }); // sort by status and then name
  }

  protected getFormsByPatient(forms: FormDTO[]): void {
    this.sortForms(forms);
    this.changeDetectorRef.detectChanges();
  }

  protected getFormsByAppointment(forms: FormDTO[]): void {
    const appointments = [ ...(this.stateSvc.searchedAppointments?.appointments ?? []), ...(this.stateSvc.todaysAppointments?.appointments ?? []) ];
    this.sortForms(forms);
    for (const form of this.forms) {
      const appointment: Appointment = appointments.find(a => a.id === form.appointmentid);
      if (appointment) {
        form.appointment = appointment;
        form.patientdata = appointment.patientdata;
      }
    }
    // Propagate appointment status changes up
    const appointmentStatus: string = this.getAppointmentFormStatus(this.forms);
    this.appointmentStatusChanged.emit(appointmentStatus);
    this.changeDetectorRef.detectChanges();

    // show flagged forms if there are any, and it is the first time selecting this record
    if (this.hasFlaggedFormsForAppointment && this.stateSvc.searchedAppointments.isFirstTimeSelectingRecord(`${this.assignToId}_flagged_forms`)) {
      this.showFlaggedForms(FormType.Appointment);
    }
    // show add modal if there are no forms assigned, and it is the first time selecting this record
    else if (this.forms.length == 0 && this.stateSvc.searchedAppointments.isFirstTimeSelectingRecord(this.assignToId)) {
      this.showAddForm();
    }
  }

  getAppointmentFormStatus(forms: FormDTO[]): string {
    if (!forms || !forms.length) return AppointmentFormStatus.none;

    let status = '';
    const notStarted = forms.filter(q => q.status.toLowerCase() === 'not_started');
    const inProgress = forms.filter(q => q.status.toLowerCase() === 'in_progress');
    const patientSignature = forms.filter(q => q.status.toLowerCase() === 'patient_sign');
    const staffSignature = forms.filter(q => q.status.toLowerCase() === 'staff_sign');
    const otherSignature = forms.filter(q => q.status.toLowerCase() === 'other_sign');
    const complete = forms.filter(q => q.status.toLowerCase() === 'complete');

    if (notStarted.length === forms.length) {
      status = AppointmentFormStatus.not_started;
    } else if (complete.length === forms.length) {
      status = AppointmentFormStatus.complete;
    } else if (inProgress.length > 0 || (complete.length > 0 && notStarted.length > 0)) {
      status = AppointmentFormStatus.in_progress;
    } else if (patientSignature.length > 0 || staffSignature.length > 0 || otherSignature.length > 0) {
      status = AppointmentFormStatus.action_required;
    }
    return status;
  }

  loadMenuSettings() {
    this.translateSvc.get('PATIENT_FORMS.SELECT_APPOINTMENT.MESSAGE', this.appointmentName).subscribe(t => this.shareMessage = t);
    this.translateSvc.get(['PATIENT_FORMS.COLUMN_HEADERS', 'PATIENT_FORMS.TABLE_MENU']).subscribe(val => {
      const headers = val['PATIENT_FORMS.COLUMN_HEADERS'];
      const menu = val['PATIENT_FORMS.TABLE_MENU'];
      this.archiveLabel = menu.ARCHIVE;
      this.viewLabel = menu.VIEW;

      this.columnHeaders = [
        { field: "name", header: headers.NAME },
        { field: "status", header: headers.STATUS },
        { field: "statusorder", header: headers.STATUS },
        { field: "lastarchivedon", header: headers.ARCHIVED },
        { field: "updatedon", header: headers.MODIFIED },
        { field: "tags", header: headers.TAG }
      ];

      // Setup ellipsis column actions
      this.menuItems.push({label: menu.VIEW, command: () => { this.commandView() }});
      this.menuItems.push({label: menu.DELETE, command: () => { this.commandDelete() }});
      this.menuItems.push({label: menu.SIGN, command: () => { this.commandSign() }});
      this.menuItems.push({label: menu.PRINT_FORM, command: () => { this.commandPrint() }});
      this.menuItems.push({label: menu.ARCHIVE, command: () => { this.commandArchive() }});

    });
  }

  loadFacilityAndImagesData() {
    this.imageSvc.getImages().subscribe(x => this.images = x);
    this.nextAdminService.getFacilityGroupByUserGroups().subscribe(result => {
      if (result) {
        this.facilityList = result.filter(x => x.isactive);
      }
    });
  }

  get appointmentName(): { appointment: string } {
    return { appointment: this.enterpriseAppointmentName };
  }

  showFlaggedForms(openedFrom: FormType): void {
    const selectedAppointment = this.stateSvc.searchedAppointments.selectedAppointments[0] || this.stateSvc.todaysAppointments.selectedAppointments[0];
    const config: ModalOptions = {
      class: 'modal-xl',
      backdrop: true,
      ignoreBackdropClick: false,
      initialState: { openedFrom: openedFrom, selectedPatient: this.patientData, excludeFormsForAppointmentId: selectedAppointment.id }
    };

    this.modalRef = this.modalSvc.show(MoveFlaggedFormsModalComponent, config);
    this.modalRef.content.modalClose.pipe(
      takeUntil(this.observerCleanup$)
    ).subscribe(() => this.modalRef.hide());

    this.modalRef.content.modalFormsToMove.pipe(
      mergeMap((formsToMove: FormDTO[]) => {
        const patientId = this.patientData.id;
        const appointmentId = selectedAppointment.id;
        return this.formSvc.moveFlaggedForms(patientId, appointmentId, formsToMove.map((form) => form.id));
      }),
      takeUntil(this.observerCleanup$)
    ).subscribe({
      next: () => {
        this.modalRef.hide();
        this.loadData();
      },
      error: () => {
        this.toastrSvc.error(this.translateSvc.instant('APPOINTMENTS.TOASTR_MESSAGE.MOVE_FLAGGED_FORMS_ERROR'), '', {disableTimeOut: true});
      }
    });
  }

  private getPrefill(): { Patient: Patient, Appointment: Appointment } {
    switch (this.assignType) {
      case FormType.Patient:
        return { Patient: this.prefill, Appointment: null };
      case FormType.Appointment:
        return { Patient: this.prefill.patientdata, Appointment: this.prefill };
    }
  }
}
