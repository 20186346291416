<div class="container-fluid">

  <div class="row modal-header">
    <h4 class="modal-title"><fa-icon [icon]="['fas', 'file-upload']" class="fa-uploadHeader"></fa-icon> {{ title }}</h4>
    <div class="close pull-right" aria-label="Close" id="close" (click)="exit()" [ngClass]="uploadBtnMiddle() ? 'disable-click' : 'enable-click'">
      <span aria-hidden="true"><fa-icon [icon]="['fas', 'times']" class="fa-uploadHeader"></fa-icon></span>
    </div>
  </div>

  <div class="modal-body no-gutters mb-3" [nextDropZone]="!uploadExecuted" (filesDropped)="onActionAddFiles($event)" (filesHover)="dropzoneState($event)">

    <div class="duplicateWarning-field" *ngIf="!uploadBtnFinish() && newFilesIncludeDuplicate" >
      <div class="duplicateWarning-field-meta">
        <fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon>&nbsp;
        <span>{{ duplicateErrorMessage }}</span>
      </div>
    </div>

    <div class="container dropzone" [ngClass]="dropzoneActive ? 'actionArea-Active' : 'actionArea-Inactive'">
      <div class=" no-gutters" *ngIf="!uploadBtnFinish()">
        <div class="col-md-12 text-center" style="margin-top:15px">
          <div [innerHtml]="addFileInfoMessage | displayHtml"></div>
          <button class="btn btn-secondary" type="button" [disabled]="uploadExecuted" (click)="inputElement.click()">
            {{ addButton }}
          </button>
        </div>
      </div>

      <div class=" no-gutters results-field" *ngIf="uploadBtnFinish()">
        <div *ngIf="getSuccessCount() > 0" class="col-12 no-gutters">
          <div class="col-md-1 float-right">
          </div>
          <div class="col-11 w-100 float-left detail-text no-gutters" style="margin-top:12px">
            {{ getSuccessCount() }}  {{ successUploadMessage }}
          </div>
        </div>
        <div *ngIf="getFailCount() > 0" class="col-12 w-100 no-gutters">
          <div class="col-md-1 float-right">
          </div>
          <div class="col-11 w-100 float-left detail-text no-gutters" style="margin-top:12px">
            {{ getFailCount() }} {{ failureUploadMessage }}
          </div>
        </div>
      </div>

      <div class="container-fluid input-items no-gutters" [ngStyle]="{'border-width' : fileList.length > 0 ? '1px' : '0'}">
        <ul *ngFor="let file of fileList; let i = index" class="file-list">
          <li class="list-group-item no-gutters" data-toggle="tooltip" [title]="fileItemHoverInfo(file)">

            <div class="row">
              <div class="col-sm-10 no-gutters ellipsis-overflow"  [title]="file.file.name || null">
                <fa-icon [icon]="['far', 'file-image']" class="btn no-hover fa-fileImage"></fa-icon>{{ file.file.name }}
              </div>

              <div *ngIf="fileIconStart(file)" class="col-sm-2 btn-custom">
                <fa-icon [icon]="['fas', 'times']" class="fa-fileClose" (click)="removeFile(i)"></fa-icon>
              </div>

              <div *ngIf="fileIconUploadSuccess(file)" id="icon-success" class="col-sm-2">
                <fa-icon [icon]="['fas', 'check']" class="fa-fileCheck"></fa-icon>
              </div>

              <div *ngIf="fileIconMiddle(file)" class="col-sm-2">
                <p class="progress-text">
                  {{ file.progress }}<fa-icon [icon]="['fas', 'percent']"></fa-icon>
                </p>
              </div>

              <div *ngIf="fileIconUploadError(file)" id="icon-error" class="col-sm-2">
                <fa-icon [icon]="['fas', 'exclamation-triangle']" class="fa-fileWarning"></fa-icon>
              </div>
            </div>

            <div class="loadbar">
              <div *ngIf="fileIconStart(file)" class="progress-inactive progress"></div>
              <div *ngIf="fileIconMiddle(file)" class="progress progress-mid" [ngStyle]="{'width': file.progress + '%' }"></div>
              <div *ngIf="fileIconUploadSuccess(file)" class="progress-complete-success progress"></div>
              <div *ngIf="fileIconUploadError(file)" class="progress-complete-failure progress"></div>
            </div>
          </li>
        </ul>
      </div>
      <ul>
        <li class="list-unstyled">
          <div class="row">
            <input style="display:none"
                   type="file"
                   class="form-control-file standalone"
                   aria-describedby="fileHelp"
                   (click)="inputElement.value = null"
                   (change)="onActionAddFiles(onSelectEventToFileList($event))"
                   [accept]="allowedFileTypes.join(',')"
                   multiple
                   #inputElement
                   id="fileUpload">
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="modal-footer">
    <button *ngIf="!uploadExecuted" type="button" class="btn btn-default" (click)="exit()">
      <span>{{ cancelButton }}</span>
    </button>
    <button id="upload" type="button" class="btn btn-default btn-primary upload-button" (click)="uploadExecuted ? exit() : confirm()" [disabled]="uploadBtnMiddle() || fileList.length < 1">
      <span *ngIf="!uploadExecuted">
        <fa-icon [icon]="['fas', 'upload']" class="upload-button-icon"></fa-icon>
        {{ uploadButton }}
      </span>
      <span *ngIf="uploadBtnFinish()">
        <fa-icon [icon]="['fas', 'check']" class="upload-button-icon"></fa-icon>
        {{ uploadButtonComplete }}
      </span>
      <span *ngIf="uploadBtnMiddle()">
        <fa-icon [icon]="['fas', 'spinner']" [pulse]="true" size="1x" class="spinner mx-auto"></fa-icon>
        {{ uploadButtonProcessing }}
      </span>
    </button>
  </div>

</div>

