<nav class="navbar position-absolute viewer-footer p-0">
  <!--Footer Back & Forward Buttons & Pages Icons-->
  <div class="container-fluid p-0">
    <div class="row w-100 m-0 footer-row">
      <div class="col-12">
        <div class="container-fluid">
          <div class="col-12 page-row">
            <span *ngFor="let page of experience.pages; let pageIndex = index" class="p-1 page-icons">
              <div *ngIf="pageIndex !== selectedPage"><fa-icon [icon]="['far', 'circle']"></fa-icon></div>
              <div *ngIf="pageIndex === selectedPage"><fa-icon [icon]="['fas', 'circle']"></fa-icon></div>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 nav-buttons mb-5 px-1 px-lg-3">
        <div class="container-fluid p-0">
          <button *ngIf="isFirstPage()" class="btn btn-light backcancel" (click)="cancelForm()">Cancel</button>
          <button *ngIf="!isFirstPage()" class="btn btn-light backcancel" (click)="prevPage()">Back</button>
          <div *ngIf="allowSave" class="save-later" (click)="save()">Save for Later</div>
          <button [disabled]="submitDisabled" class="btn btn-light nextsubmit" (click)="isLastPage(experience) ? submit() : nextPage()">{{isLastPage(experience) ? 'Submit' : 'Next'}}</button>
        </div>
      </div>
    </div>
  </div>
</nav>
